// https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/menu.ts

import { StyleConfig } from '_types';

export const Menu: StyleConfig = {
  //
  baseStyle: (props) => {
    const { colorMode } = props;

    return {
      button: {},
      command: {},
      divider: {},
      groupTitle: {},
      item: {},
      list: {},
    };
  },

  sizes: {},

  variants: {
    cardUtility: (props) => {
      const { colorMode } = props;

      return {
        button: {
          _focus: {
            _invalid: {
              outlineColor:
                colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
            },
            borderColor:
              colorMode === 'dark' ? 'theme-primary-b' : 'theme-primary-b',
            outlineColor:
              colorMode === 'dark' ? 'theme-primary-b' : 'theme-primary-b',
            outlineOffset: '2px',
            outlineWidth: '1px',
            zIndex: 1,
          },
          _hover: {
            borderColor:
              colorMode === 'dark' ? 'theme-primary-b' : 'theme-primary-b',
          },
          border: '1px solid',
          borderColor: 'transparent',
          borderRadius: '4px',
        },
        command: {},
        divider: {},
        groupTitle: {},
        item: {
          _hover: {
            bg: 's-neutral-hovered-light',
          },
          padding: '0.75rem 1.5rem',
        },
        list: {
          bg: colorMode === 'dark' ? 's-neutral-subdued' : 's-neutral-subdued',
          boxShadow:
            '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
          padding: '0',
          zIndex: 10,
        },
      };
    },

    datePicker: (props) => {
      const { colorMode } = props;

      return {
        button: {
          _focus: {
            _invalid: {
              outlineColor:
                colorMode === 'dark'
                  ? 'brd-critical-light'
                  : 'brd-critical-light',
            },
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-light'
                : 'theme-primary-b-light',
            outlineColor:
              colorMode === 'dark'
                ? 'theme-primary-b-light'
                : 'theme-primary-b-light',
            outlineOffset: '1px',
            outlineWidth: '1px',
            zIndex: 1,
          },
          _hover: {
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-light'
                : 'theme-primary-b-light',
          },
          border: '1px solid',
          borderColor:
            colorMode === 'dark'
              ? 'brd-neutral-subdued-light'
              : 'brd-neutral-subdued-light',
          borderRadius: '6px',
          h: 9,
          overflow: 'hidden',
        },
        command: {},
        divider: {},
        groupTitle: {},
        item: {},
        list: {
          bg:
            colorMode === 'dark'
              ? 's-neutral-subdued-light'
              : 's-neutral-subdued-light',
          boxShadow:
            '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
          padding: '0',
          zIndex: 10,
        },
      };
    },

    filter: (props) => {
      const { colorMode } = props;

      return {
        button: {},
        command: {},
        divider: {},
        groupTitle: {},
        item: {},
        list: {
          bg: colorMode === 'dark' ? '#ffffff' : '#ffffff',
          boxShadow:
            '0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)',
          zIndex: 10,
        },
      };
    },
  },
};
