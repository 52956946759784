import { configureStore } from '@reduxjs/toolkit';
import { availabilityManageSlice } from 'features/Availability/Manage/store';
import { bookingsEditBookingSlice } from 'features/Bookings/EditBooking/store';
import { bookingsListBookingsCancelledSlice } from 'features/Bookings/ListBookingsCancelled/store';
import { bookingsListBookingsCompletedSlice } from 'features/Bookings/ListBookingsCompleted/store';
import { bookingsListBookingsTodaySlice } from 'features/Bookings/ListBookingsToday/store';
import { bookingsListBookingsUpcomingSlice } from 'features/Bookings/ListBookingsUpcoming/store';
import { coachesCombinedAvailabilitySlice } from 'features/Coaches/CoachCombinedAvailability/store';
import { membersEditMemberSlice } from 'features/Members/EditMember/store';
import { membersListLessonNoteMembersSlice } from 'features/Members/ListLessonNoteMembers/store';
import { membersListLessonNotesSlice } from 'features/Members/ListLessonNotes/store';
import { membersListMembersSlice } from 'features/Members/ListMembers/store';
import { membersNewLessonNoteSlice } from 'features/Members/NewLessonNote/store';
import { myAccountSlice } from 'features/MyAccount/store';
import { useDispatch } from 'react-redux';
import { authSlice } from './auth';
import { uiSlice } from './ui';

export const store = configureStore({
  devTools: true,
  reducer: {
    auth: authSlice.reducer,
    availabilityManage: availabilityManageSlice.reducer,
    bookingsEditBooking: bookingsEditBookingSlice.reducer,
    bookingsListBookingsCancelled: bookingsListBookingsCancelledSlice.reducer,
    bookingsListBookingsCompleted: bookingsListBookingsCompletedSlice.reducer,
    bookingsListBookingsToday: bookingsListBookingsTodaySlice.reducer,
    bookingsListBookingsUpcoming: bookingsListBookingsUpcomingSlice.reducer,
    coachesCombinedAvailability: coachesCombinedAvailabilitySlice.reducer,
    membersEditMember: membersEditMemberSlice.reducer,
    membersListLessonNoteMembers: membersListLessonNoteMembersSlice.reducer,
    membersListLessonNotes: membersListLessonNotesSlice.reducer,
    membersListMembers: membersListMembersSlice.reducer,
    membersNewLessonNote: membersNewLessonNoteSlice.reducer,
    myAccount: myAccountSlice.reducer,
    ui: uiSlice.reducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch();
