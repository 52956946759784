//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/alert.ts

import { StyleConfig } from '_types';

export const Alert: StyleConfig = {
  baseStyle: (props) => {
    const { _colorMode, _colorScheme } = props;

    return {
      container: {},
      description: {},
      icon: {},
      spinner: {},
      title: {},
    };
  },

  sizes: {},

  variants: {},
};
