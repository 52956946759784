import {
  BookingType,
  CoachGroupBooking,
  Direction,
  OrderBy,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookingQuickLook {
  bookings: CoachGroupBooking[];
  isShowModal: boolean;
}

export interface States {
  bookingQuickLook: BookingQuickLook | null;
  filterBookingId: string;
  filterBookingType: BookingType | string;
  filterMemberName: string;
  filterVenue: string;
  isGridReady: boolean;
  listBookingsTodayEndCursor: string;
  listBookingsTodayEndTime: string | null;
  listBookingsTodayFirst: number | null;
  listBookingsTodayOrderBy: OrderBy;
  listBookingsTodayPageNumber: number;
  listBookingsTodayPageSize: number;
  listBookingsTodayRowIndex: number;
  listBookingsTodayStartTime: string | null;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  bookingQuickLook: null,
  filterBookingId: '',
  filterBookingType: '',
  filterMemberName: '',
  filterVenue: '',
  isGridReady: false,
  listBookingsTodayEndCursor: '',
  listBookingsTodayEndTime: null,
  listBookingsTodayFirst: null,
  listBookingsTodayOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsTodayPageNumber: 0,
  listBookingsTodayPageSize: 100,
  listBookingsTodayRowIndex: -1,
  listBookingsTodayStartTime: null,
  pageInfo: null,
  recordLoaded: 0,
};

export const bookingsListBookingsTodaySlice = createSlice({
  initialState,
  name: 'bookingsListBookingsToday',
  reducers: {
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setFilterBookingId: (state, { payload }: PayloadAction<string>) => {
      state.filterBookingId = payload;
    },
    setFilterBookingType: (
      state,
      { payload }: PayloadAction<BookingType | string>,
    ) => {
      state.filterBookingType = payload;
    },
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterVenue: (state, { payload }: PayloadAction<string>) => {
      state.filterVenue = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListBookingsTodayEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsTodayEndCursor = payload;
    },
    setListBookingsTodayEndTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsTodayEndTime = payload;
    },
    setListBookingsTodayFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBookingsTodayFirst = payload;
    },
    setListBookingsTodayOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsTodayOrderBy = payload;
    },
    setListBookingsTodayPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsTodayPageNumber = payload;
    },
    setListBookingsTodayPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsTodayPageSize = payload;
    },
    setListBookingsTodayRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsTodayRowIndex = payload;
    },
    setListBookingsTodayStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsTodayStartTime = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default bookingsListBookingsTodaySlice.reducer;

export const {
  setBookingQuickLook: setBookingQuickLookAction,
  setFilterBookingId: setFilterBookingIdAction,
  setFilterBookingType: setFilterBookingTypeAction,
  setFilterMemberName: setFilterMemberNameAction,
  setFilterVenue: setFilterVenueAction,
  setIsGridReady: setIsGridReadyAction,
  setListBookingsTodayEndCursor: setListBookingsTodayEndCursorAction,
  setListBookingsTodayEndTime: setListBookingsTodayEndTimeAction,
  setListBookingsTodayFirst: setListBookingsTodayFirstAction,
  setListBookingsTodayOrderBy: setListBookingsTodayOrderByAction,
  setListBookingsTodayPageNumber: setListBookingsTodayPageNumberAction,
  setListBookingsTodayPageSize: setListBookingsTodayPageSizeAction,
  setListBookingsTodayRowIndex: setListBookingsTodayRowIndexAction,
  setListBookingsTodayStartTime: setListBookingsTodayStartTimeAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = bookingsListBookingsTodaySlice.actions;
