import { Permission } from '_types';
import { Ability, AbilityBuilder } from '@casl/ability';
import { createContextualCan } from '@casl/react';
import { UserType } from '@generated/schema';
import { createContext } from 'react';
import { store } from 'store';

type Actions = 'manage';
type AppAbility = Ability<[Actions, Permission]>;

//* Helpers
const defineRulesFor = (p: Permission[]) => {
  const { can, rules } = new AbilityBuilder(Ability);

  if (p) {
    p.forEach((permission) => {
      can('manage', permission);
    });
  }

  return rules;
};

//* *************************************

export const ability = new Ability<[any, any]>();

//* Can function
const fn = (action: Actions, subject: Permission) =>
  ability.can(action, subject);

export default fn;

store.subscribe(() => {
  const authenticatedAdmin = store.getState().auth.authenticatedAdmin;

  if (
    authenticatedAdmin &&
    authenticatedAdmin.permissions &&
    authenticatedAdmin.userType
  ) {
    let permissions: Permission[] = authenticatedAdmin.permissions as any;

    if (authenticatedAdmin.userType === UserType.MasterOwner) {
      permissions = [Permission.ALL];
    }

    const rules = defineRulesFor(permissions);
    ability.update(rules);
  }
});

//* *************************************

export const AbilityContext = createContext<AppAbility>(undefined!);
export const Can = createContextualCan(AbilityContext.Consumer);
