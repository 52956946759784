import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type AlertModal = {
  isOpen: boolean;
  message: string;
  title: string;
};

type AlertModalInput = {
  message: string;
  title: string;
};

export type NavGroups = {
  availability: boolean;
  bookings: boolean;
  members: boolean;
  profile: boolean;
  settings: boolean;
  support: boolean;
};

export interface Breadcrumb {
  label: string;
  path: string;
  states?: Record<string, unknown>;
  tip?: string;
}

interface States {
  alertModal: AlertModal;
  appPath: string;
  breadcrumbs: Breadcrumb[];
  greeting: Greetings;
  groupBookingStyles: Record<string, string>;
  isCallingBlockingApi: boolean;
  isShowDrawer: boolean;
  navGroups: NavGroups;
}

type Greetings = 'Good morning' | 'Good afternoon' | 'Good evening' | null;

const initialState: States = {
  alertModal: {
    isOpen: false,
    message: '',
    title: '',
  },
  appPath: '',
  breadcrumbs: [],
  greeting: null,
  groupBookingStyles: {},
  isCallingBlockingApi: false,
  isShowDrawer: false,
  navGroups: {
    availability: true,
    bookings: true,
    members: true,
    profile: true,
    settings: true,
    support: true,
  },
};

export const uiSlice = createSlice({
  initialState,
  name: 'ui',
  reducers: {
    addGroupBookingStyle: (
      state,
      { payload }: PayloadAction<Record<string, string>>,
    ) => {
      state.groupBookingStyles = {
        ...state.groupBookingStyles,
        ...payload,
      };
    },
    clearGroupBookingStyles: (state) => {
      state.groupBookingStyles = {};
    },
    closeAlertModal: (state) => {
      state.alertModal = { ...initialState.alertModal };
    },
    logout: (state) => {
      state.isCallingBlockingApi = false;
      state.alertModal = { ...initialState.alertModal };
    },
    openAlertModal: (state, { payload }: PayloadAction<AlertModalInput>) => {
      state.alertModal = {
        isOpen: true,
        message: payload.message,
        title: payload.title,
      };
    },
    replaceBreadcrumbs: (state, { payload }: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumbs = payload;

      localStorage.setItem('breadcrumbs', JSON.stringify(payload));
    },
    setAppPath: (state, { payload }: PayloadAction<string>) => {
      state.appPath = payload;
    },
    setBreadcrumbs: (state, { payload }: PayloadAction<Breadcrumb>) => {
      const breadcrumbs = state.breadcrumbs;
      const index = breadcrumbs.findIndex((b) => b.label === payload.label);

      if (index > -1 && index !== breadcrumbs.length - 1) {
        const truncated = breadcrumbs.slice(0, index + 1);
        state.breadcrumbs = truncated;

        localStorage.setItem('breadcrumbs', JSON.stringify(truncated));
      }

      if (index < 0) {
        const merged = [...breadcrumbs, payload];
        state.breadcrumbs = merged;

        localStorage.setItem('breadcrumbs', JSON.stringify(merged));
      }
    },
    setGreeting: (state, { payload }: PayloadAction<Greetings>) => {
      state.greeting = payload;
    },
    setIsCallingBlockingApi: (state, { payload }: PayloadAction<boolean>) => {
      state.isCallingBlockingApi = payload;
    },
    setIsShowDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowDrawer = payload;
    },
    setNavGroups: (state, { payload }: PayloadAction<NavGroups>) => {
      state.navGroups = payload;
    },
    toggleDrawer: (state) => {
      state.isShowDrawer = !state.isShowDrawer;
    },
    toggleNavGroup: (state, { payload }: PayloadAction<keyof NavGroups>) => {
      const key = payload;
      state.navGroups[key] = !state.navGroups[key];

      localStorage.setItem(
        'uiConfig',
        JSON.stringify({ navGroups: state.navGroups }),
      );
    },
  },
});

export default uiSlice.reducer;

export const {
  addGroupBookingStyle: addGroupBookingStyleAction,
  clearGroupBookingStyles: clearGroupBookingStylesAction,
  closeAlertModal: closeAlertModalAction,
  logout: logoutAction,
  openAlertModal: openAlertModalAction,
  replaceBreadcrumbs: replaceBreadcrumbsAction,
  setAppPath: setAppPathAction,
  setBreadcrumbs: setBreadcrumbsAction,
  setGreeting: setGreetingAction,
  setIsCallingBlockingApi: setIsCallingBlockingApiAction,
  setIsShowDrawer: setIsShowDrawerAction,
  setNavGroups: setNavGroupsAction,
  toggleDrawer: toggleDrawerAction,
  toggleNavGroup: toggleNavGroupAction,
} = uiSlice.actions;
