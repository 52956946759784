import { VStack } from '@chakra-ui/react';
import { Card, CardSection } from 'components/_basic/Card';
import { LogoMark } from 'components/_svgs/LogoMark';
import React, { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { AuthMode } from '../types';
import { ForgotPasswordFields } from './ForgotPasswordFields';
import { ForgotPasswordSubmitFields } from './ForgotPasswordSubmitFields';
import { LoginFields } from './LoginFields';
import { initialState, useMain } from './useMain';
import { Utility } from './Utility';

function Component() {
  const [authMode, setAuthMode] = React.useState<AuthMode>('login');

  const { formHandler, methods } = useMain({
    authMode,
    setAuthMode,
  });

  const { reset } = methods;

  useEffect(() => {
    reset(initialState);
  }, [authMode, reset]);

  return (
    <div className="relative w-full max-w-sm shadow-xl">
      <Card>
        <CardSection>
          <div className="flex items-start pt-3">
            <div className="mr-3">
              <LogoMark className="w-8" />
            </div>
            <div className="font-semibold font-base">HCGC Pro Coach Portal</div>
          </div>
          <FormProvider {...methods}>
            <form className="py-6">
              <VStack spacing={5}>
                {authMode === 'login' && <LoginFields />}
                {authMode === 'forgotPassword' && <ForgotPasswordFields />}
                {authMode === 'forgotPasswordSubmit' && (
                  <ForgotPasswordSubmitFields />
                )}
                <Utility
                  authMode={authMode}
                  formHandler={formHandler}
                  setAuthMode={setAuthMode}
                />
              </VStack>
            </form>
          </FormProvider>
        </CardSection>
      </Card>
    </div>
  );
}

export const LoginComponent = Component;
