//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/drawer.ts

import { StyleConfig } from '_types';

export const Drawer: StyleConfig = {
  //
  baseStyle: (props) => {
    const { colorMode } = props;

    return {
      body: {},
      closeButton: {
        _focus: {
          boxShadow: 'none',
          outlineColor: 'theme-primary-b',
          outlineStyle: 'dashed',
          outlineWidth: '1px',
        },
      },
      dialog: {},
      dialogContainer: {},
      footer: {},
      header: {},
      overlay: {},
    };
  },

  sizes: {},

  variants: {
    utility: (props) => {
      const { colorMode } = props;

      return {
        body: {},
        closeButton: {},
        dialog: {},
        dialogContainer: {},
        footer: {},
        header: {},
      };
    },
  },
};
