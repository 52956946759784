import { PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface SelectMember {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
}

export interface States {
  isGridReady: boolean;
  listLessonNotesEndCursor: string;
  listLessonNotesPageNumber: number;
  listLessonNotesPageSize: number;
  listLessonNotesRowIndex: number;
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  isGridReady: false,
  listLessonNotesEndCursor: '',
  listLessonNotesPageNumber: 0,
  listLessonNotesPageSize: 100,
  listLessonNotesRowIndex: -1,
  memberIndividualId: '',
  memberName: '',
  memberUuid: '',
  pageInfo: null,
  recordLoaded: 0,
};

export const membersListLessonNotesSlice = createSlice({
  initialState,
  name: 'membersListLessonNotes',
  reducers: {
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListLessonNotesEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listLessonNotesEndCursor = payload;
    },
    setListLessonNotesPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listLessonNotesPageNumber = payload;
    },
    setListLessonNotesPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listLessonNotesPageSize = payload;
    },
    setListLessonNotesRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listLessonNotesRowIndex = payload;
    },
    setMember: (state, { payload }: PayloadAction<SelectMember>) => {
      state.memberIndividualId = payload.memberIndividualId;
      state.memberName = payload.memberName;
      state.memberUuid = payload.memberUuid;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default membersListLessonNotesSlice.reducer;

export const {
  setIsGridReady: setIsGridReadyAction,
  setListLessonNotesEndCursor: setListLessonNotesEndCursorAction,
  setListLessonNotesPageNumber: setListLessonNotesPageNumberAction,
  setListLessonNotesPageSize: setListLessonNotesPageSizeAction,
  setListLessonNotesRowIndex: setListLessonNotesRowIndexAction,
  setMember: setMemberAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = membersListLessonNotesSlice.actions;
