//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/popover.ts

import { StyleConfig } from '_types';

export const Popover: StyleConfig = {
  //
  // @ts-ignore
  baseStyle: (props) => {
    const { colorMode } = props;

    return {
      arrow: {},
      body: {
        border: '1px solid',
        borderColor: colorMode === 'dark' ? 'gray.200' : 'gray.200',
        borderRadius: '0.25rem',
      },
      closeButton: {},
      content: {
        _focus: {
          boxShadow:
            '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1)',
          outline: 0,
        },
        width: 'auto',
      },
      footer: {},
      header: {},
      popper: {
        _focus: {
          boxShadow: 'none',
          outline: 0,
        },
      },
    };
  },

  sizes: {},

  // variants: {
  //   variantName: (props) => {
  //     const { colorMode } = props;

  //     return {};
  //   },
  // },
};
