import styled from '@emotion/styled';
import { ReactNode } from 'react';
import tw from 'twin.macro';

interface CardProps {
  Section?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  fullHeight?: boolean;
  height?: string;
  isDisabled?: boolean;
}

interface CardSectionProps {
  fullHeight?: boolean;
  height?: string;
}

const CardContainer = styled.div<CardSectionProps>`
  ${tw`box-border relative w-full mx-auto bg-white border-0 border-solid rounded-lg border-brd-neutral-subdued-light shadow-card text-t-base-light dark:bg-white`};
  height: ${(props) => props.height || 'auto'};
  height: ${(props) => props.fullHeight && '100%'};
`;

const Overlay = styled.div`
  ${tw`absolute inset-0 bg-white cursor-not-allowed opacity-60 dark:bg-white`};
`;

const FooterContainer = styled.div`
  ${tw`relative w-full p-5`};
`;

export const Card = (props: CardProps) => {
  const {
    children,
    footer,
    fullHeight = false,
    height,
    isDisabled = false,
  } = props;

  return (
    <CardContainer fullHeight={fullHeight} height={height}>
      {children}
      {isDisabled && <Overlay />}
      {footer && <FooterContainer>{footer}</FooterContainer>}
    </CardContainer>
  );
};

export * from './Section';
export * from './CardHeading';
