//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/form-label.ts

import { StyleConfig } from '_types';

export const FormLabel: StyleConfig = {
  //
  baseStyle: (props) => {
    const { _colorScheme, colorMode } = props;

    return {
      _disabled: {
        opacity: 0.4,
      },
      color: colorMode === 'dark' ? 't-base-light' : 't-base-light',
      fontSize: 'sm',
      fontWeight: 'bold',
      marginEnd: 3,
      mb: 1,
      opacity: 1,
      transitionDuration: 'normal',
      transitionProperty: 'common',
    };
  },
  sizes: {},
};
