//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/tabs.ts

import { StyleConfig } from '_types';

export const Tabs: StyleConfig = {
  //
  baseStyle: {
    root: {},
    tab: {
      fontFamily: `-apple-system,"system-ui","San Francisco","Segoe UI",Roboto,"Helvetica Neue"`,
      transitionDuration: 'normal',
      transitionProperty: 'common',
    },
    tablist: {},
    tabpanel: {},
  },
  sizes: {},

  variants: {
    enclosed: (props) => {
      const { _colorScheme, colorMode } = props;

      return {
        root: {},
        tab: {
          _focus: {
            boxShadow: 'none',
            outlineColor: 'theme-primary-b-dark',
            outlineOffset: '2px',
            outlineStyle: 'dashed',
            outlineWidth: '1px',
          },
          _hover: {
            bg: colorMode === 'dark' ? '#ffffff' : '#ffffff',
            color: colorMode === 'dark' ? '#000000' : '#000000',
          },
          _selected: {
            bg: colorMode === 'dark' ? '#ffffff' : '#ffffff',
            borderBottomColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            color: colorMode === 'dark' ? '#000000' : '#000000',
          },
          bg:
            colorMode === 'dark'
              ? 's-neutral-subdued-light'
              : 's-neutral-subdued-light',
          borderBottomColor:
            colorMode === 'dark' ? 'transparent' : 'transparent',
          borderBottomWidth: '4px',
          borderColor:
            colorMode === 'dark'
              ? 'brd-neutral-subdued-light'
              : 'brd-neutral-subdued-light',
          color: colorMode === 'dark' ? 't-subdued-light' : 't-subdued-light',
          fontWeight: '600',
          margin: '0 1px',
        },
        tablist: {},
        tabpanel: {
          padding: '1rem 0 0',
        },
      };
    },
  },
};
