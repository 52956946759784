import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type SelectedMember = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
};

export interface State {
  date: string;
  isShowModalSelectBooking: boolean;
  isShowModalSelectMember: boolean;
  lessonTypeLabel: string;
  selectedMember: SelectedMember;
}

export const initialState: State = {
  date: '',
  isShowModalSelectBooking: false,
  isShowModalSelectMember: false,
  lessonTypeLabel: '',
  selectedMember: {
    memberIndividualId: '',
    memberName: '',
    memberUuid: '',
  },
};

export const membersNewLessonNoteSlice = createSlice({
  initialState,
  name: 'membersNewLessonNote',
  reducers: {
    resetData: () => initialState,
    setDate: (state, { payload }: PayloadAction<string>) => {
      state.date = payload;
    },
    setIsShowModalSelectBooking: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalSelectBooking = payload;
    },
    setIsShowModalSelectMember: (
      state,
      { payload }: PayloadAction<boolean>,
    ) => {
      state.isShowModalSelectMember = payload;
    },
    setLessonTypeLabel: (state, { payload }: PayloadAction<string>) => {
      state.lessonTypeLabel = payload;
    },
    setSelectedMember: (state, { payload }: PayloadAction<SelectedMember>) => {
      state.selectedMember = payload;
    },
  },
});

export default membersNewLessonNoteSlice.reducer;

export const {
  resetData: resetDataAction,
  setDate: setDateAction,
  setIsShowModalSelectBooking: setIsShowModalSelectBookingAction,
  setIsShowModalSelectMember: setIsShowModalSelectMemberAction,
  setLessonTypeLabel: setLessonTypeLabelAction,
  setSelectedMember: setSelectedMemberAction,
} = membersNewLessonNoteSlice.actions;
