import {
  BookingType,
  CoachGroupBooking,
  Direction,
  OrderBy,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookingQuickLook {
  bookings: CoachGroupBooking[];
  isShowModal: boolean;
}

export interface States {
  bookingQuickLook: BookingQuickLook | null;
  filterBookingId: string;
  filterBookingType: BookingType | string;
  filterMemberName: string;
  filterVenue: string;
  isGridReady: boolean;
  listBookingsCancelledEndCursor: string;
  listBookingsCancelledFirst: number | null;
  listBookingsCancelledOrderBy: OrderBy;
  listBookingsCancelledPageNumber: number;
  listBookingsCancelledPageSize: number;
  listBookingsCancelledRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  bookingQuickLook: null,
  filterBookingId: '',
  filterBookingType: '',
  filterMemberName: '',
  filterVenue: '',
  isGridReady: false,
  listBookingsCancelledEndCursor: '',
  listBookingsCancelledFirst: 1000,
  listBookingsCancelledOrderBy: {
    direction: Direction.Desc,
    field: 'startTime',
  },
  listBookingsCancelledPageNumber: 0,
  listBookingsCancelledPageSize: 100,
  listBookingsCancelledRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const bookingsListBookingsCancelledSlice = createSlice({
  initialState,
  name: 'bookingsListBookingsCancelled',
  reducers: {
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setFilterBookingId: (state, { payload }: PayloadAction<string>) => {
      state.filterBookingId = payload;
    },
    setFilterBookingType: (
      state,
      { payload }: PayloadAction<BookingType | string>,
    ) => {
      state.filterBookingType = payload;
    },
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterVenue: (state, { payload }: PayloadAction<string>) => {
      state.filterVenue = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListBookingsCancelledEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsCancelledEndCursor = payload;
    },
    setListBookingsCancelledFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBookingsCancelledFirst = payload;
    },
    setListBookingsCancelledOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsCancelledOrderBy = payload;
    },
    setListBookingsCancelledPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCancelledPageNumber = payload;
    },
    setListBookingsCancelledPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCancelledPageSize = payload;
    },
    setListBookingsCancelledRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCancelledRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default bookingsListBookingsCancelledSlice.reducer;

export const {
  setBookingQuickLook: setBookingQuickLookAction,
  setFilterBookingId: setFilterBookingIdAction,
  setFilterBookingType: setFilterBookingTypeAction,
  setFilterMemberName: setFilterMemberNameAction,
  setFilterVenue: setFilterVenueAction,
  setIsGridReady: setIsGridReadyAction,
  setListBookingsCancelledEndCursor: setListBookingsCancelledEndCursorAction,
  setListBookingsCancelledFirst: setListBookingsCancelledFirstAction,
  setListBookingsCancelledOrderBy: setListBookingsCancelledOrderByAction,
  setListBookingsCancelledPageNumber: setListBookingsCancelledPageNumberAction,
  setListBookingsCancelledPageSize: setListBookingsCancelledPageSizeAction,
  setListBookingsCancelledRowIndex: setListBookingsCancelledRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = bookingsListBookingsCancelledSlice.actions;
