//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/input.ts

import { StyleConfig } from '_types';

export const Input: StyleConfig = {
  baseStyle: {
    field: {
      _autofill: {},
      fontWeight: 400,
    },
    fontFamily: `-apple-system,"system-ui","San Francisco","Segoe UI",Roboto,"Helvetica Neue"`,
  },

  sizes: {},

  variants: {
    outline: (props) => {
      const { colorMode } = props;

      return {
        field: {
          _focus: {
            _invalid: {
              borderColor:
                colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
              outlineColor:
                colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
            },
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            boxShadow: 'none',
            outlineColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            outlineOffset: '1px',
            outlineWidth: '1px',
            zIndex: 1,
          },
          _hover: {
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
          },
          _invalid: {
            bg:
              colorMode === 'dark'
                ? 's-critical-subdued'
                : 's-critical-subdued',
            borderColor: colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
            boxShadow: 'none',
          },
          _placeholder: {
            color: colorMode === 'dark' ? 't-placeholder' : 't-placeholder',
          },
          bg: colorMode === 'dark' ? 's-input-light' : 's-input-light',
          border: '1px solid',
          borderColor:
            colorMode === 'dark'
              ? 'brd-neutral-subdued-light'
              : 'brd-neutral-subdued-light',
          color: colorMode === 'dark' ? '#000000' : '#000000',
          fontSize: 'sm',
          fontWeight: '600',
          h: 9,
          lineHeight: '1',
        },
      };
    },

    readOnly: (props) => {
      const { colorMode } = props;

      return {
        field: {
          _placeholder: {
            color: colorMode === 'dark' ? 't-placeholder' : 't-placeholder',
          },
          bg:
            colorMode === 'dark'
              ? 's-highlight-subdued'
              : 's-highlight-subdued',
          border: '1px solid',
          borderColor: colorMode === 'dark' ? 'transparent' : 'transparent',
          color: colorMode === 'dark' ? '#000000' : '#000000',
          cursor: 'not-allowed',
          fontSize: 'sm',
          fontWeight: '600',
          h: 9,
          lineHeight: '1',
        },
      };
    },

    search: (props) => {
      const { colorMode } = props;

      return {
        field: {
          _focus: {
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            outlineColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            outlineOffset: '2px',
            outlineWidth: '1px',
            zIndex: 1,
          },
          _hover: {
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
          },
          _placeholder: {
            color: colorMode === 'dark' ? 't-placeholder' : 't-placeholder',
          },
          bg: colorMode === 'dark' ? '#ffffff' : '#ffffff',
          border: '1px solid',
          borderColor:
            colorMode === 'dark'
              ? 'brd-neutral-subdued-light'
              : 'brd-neutral-subdued-light',
          color: colorMode === 'dark' ? 't-base' : 't-base',
          fontSize: 'sm',
          fontWeight: '400',
          lineHeight: '1',
        },
      };
    },
  },
};
