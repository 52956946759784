//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/form-error.ts

import { StyleConfig } from '_types';

export const FormError: StyleConfig = {
  //
  baseStyle: {
    icon: {},
    text: {
      color: 't-critical',
      fontSize: 'sm',
      mt: 1,
    },
  },
  sizes: {},
};
