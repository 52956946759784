import {
  CoachAvailabilityCalendarItems,
  CoachGroupBooking,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookingQuickLook {
  bookings: CoachGroupBooking[];
  isShowModal: boolean;
}

export interface AvailabiltyState {
  bi08: string;
  bi09: string;
  bi10: string;
  bi11: string;
  bi12: string;
  bi13: string;
  bi14: string;
  bi15: string;
  bi16: string;
  bi17: string;
  bi18: string;
  bi19: string;
  bi20: string;
  bi21: string;
  bs08: string;
  bs09: string;
  bs10: string;
  bs11: string;
  bs12: string;
  bs13: string;
  bs14: string;
  bs15: string;
  bs16: string;
  bs17: string;
  bs18: string;
  bs19: string;
  bs20: string;
  bs21: string;
  date: string;
  l08: string;
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
  l21: string;
}

interface InitialLocations {
  l08: string;
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
  l21: string;
}

export interface State extends AvailabiltyState {
  availableItems: CoachAvailabilityCalendarItems[];
  bi08: string;
  bi09: string;
  bi10: string;
  bi11: string;
  bi12: string;
  bi13: string;
  bi14: string;
  bi15: string;
  bi16: string;
  bi17: string;
  bi18: string;
  bi19: string;
  bi20: string;
  bi21: string;
  biOutdoor: string;
  bookingQuickLook: BookingQuickLook | null;
  bs08: string;
  bs09: string;
  bs10: string;
  bs11: string;
  bs12: string;
  bs13: string;
  bs14: string;
  bs15: string;
  bs16: string;
  bs17: string;
  bs18: string;
  bs19: string;
  bs20: string;
  bs21: string;
  currentDate: string;
  date: string;
  hasOutdoorBooking: boolean;
  initialLocations: InitialLocations;
  isShowUtilityDrawer: boolean;
  l08: string;
  l09: string;
  l10: string;
  l11: string;
  l12: string;
  l13: string;
  l14: string;
  l15: string;
  l16: string;
  l17: string;
  l18: string;
  l19: string;
  l20: string;
  l21: string;
  locationsHaveChanged: boolean;
}

const initialState: State = {
  availableItems: [],
  bi08: '',
  bi09: '',
  bi10: '',
  bi11: '',
  bi12: '',
  bi13: '',
  bi14: '',
  bi15: '',
  bi16: '',
  bi17: '',
  bi18: '',
  bi19: '',
  bi20: '',
  bi21: '',
  biOutdoor: '',
  bookingQuickLook: null,
  bs08: '',
  bs09: '',
  bs10: '',
  bs11: '',
  bs12: '',
  bs13: '',
  bs14: '',
  bs15: '',
  bs16: '',
  bs17: '',
  bs18: '',
  bs19: '',
  bs20: '',
  bs21: '',
  currentDate: new Date().toDateString(),
  date: '',
  hasOutdoorBooking: false,
  initialLocations: {
    l08: '',
    l09: '',
    l10: '',
    l11: '',
    l12: '',
    l13: '',
    l14: '',
    l15: '',
    l16: '',
    l17: '',
    l18: '',
    l19: '',
    l20: '',
    l21: '',
  },
  isShowUtilityDrawer: false,
  l08: '',
  l09: '',
  l10: '',
  l11: '',
  l12: '',
  l13: '',
  l14: '',
  l15: '',
  l16: '',
  l17: '',
  l18: '',
  l19: '',
  l20: '',
  l21: '',
  locationsHaveChanged: false,
};

interface SetClubhousePayload {
  clubhouseCode: string;
  time: string;
}

interface SetBookingPayload {
  bookingDescription: string;
  bookingId: string;
  time: string;
}

interface SetOutdoorBookingPayload {
  biOutdoor: string;
  hasOutdoorBooking: boolean;
}

export const availabilityManageSlice = createSlice({
  initialState,
  name: 'availabilityManage',
  reducers: {
    setAvailableItems: (
      state,
      { payload }: PayloadAction<CoachAvailabilityCalendarItems[]>,
    ) => {
      state.availableItems = payload;
    },
    setBooking: (state, { payload }: PayloadAction<SetBookingPayload>) => {
      const { bookingDescription, bookingId } = payload;
      const dAccessor = `bd${payload.time}` as keyof AvailabiltyState;
      state[dAccessor] = bookingDescription;
      const iAccessor = `bi${payload.time}` as keyof AvailabiltyState;
      state[iAccessor] = bookingId;
    },
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setClubhouse: (state, { payload }: PayloadAction<SetClubhousePayload>) => {
      const { clubhouseCode, time } = payload;
      const accessor = `l${time}` as keyof AvailabiltyState;
      state[accessor] = clubhouseCode;
    },
    setCurrentDate: (state, { payload }: PayloadAction<string>) => {
      state.currentDate = payload;
    },
    setDate: (state, { payload }: PayloadAction<string>) => {
      state.date = payload;
    },
    setInitalLocations: (
      state,
      { payload }: PayloadAction<InitialLocations>,
    ) => {
      state.initialLocations = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setLocationsHaveChaged: (state, { payload }: PayloadAction<boolean>) => {
      state.locationsHaveChanged = payload;
    },
    setOutdoorBooking: (
      state,
      { payload }: PayloadAction<SetOutdoorBookingPayload>,
    ) => {
      const { biOutdoor, hasOutdoorBooking } = payload;
      state.hasOutdoorBooking = hasOutdoorBooking;
      state.biOutdoor = biOutdoor;
    },
  },
});

export default availabilityManageSlice.reducer;

export const {
  setAvailableItems: setAvailableItemsAction,
  setBooking: setBookingAction,
  setBookingQuickLook: setBookingQuickLookAction,
  setClubhouse: setClubhouseAction,
  setCurrentDate: setCurrentDateAction,
  setDate: setDateAction,
  setInitalLocations: setInitalLocationsAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setLocationsHaveChaged: setLocationsHaveChagedAction,
  setOutdoorBooking: setOutdoorBookingAction,
} = availabilityManageSlice.actions;
