import { PageInfo } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface States {
  filterMemberName: string;
  filterMemberTier: string;
  isGridReady: boolean;
  isShowUtilityDrawer: boolean;
  listMembersEndCursor: string;
  listMembersPageNumber: number;
  listMembersPageSize: number;
  listMembersRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  filterMemberName: '',
  filterMemberTier: '',
  isGridReady: false,
  isShowUtilityDrawer: false,
  listMembersEndCursor: '',
  listMembersPageNumber: 0,
  listMembersPageSize: 100,
  listMembersRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const membersListMembersSlice = createSlice({
  initialState,
  name: 'membersListMembers',
  reducers: {
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterMemberTier: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberTier = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setIsShowUtilityDrawer: (state, { payload }: PayloadAction<boolean>) => {
      state.isShowUtilityDrawer = payload;
    },
    setListMembersEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listMembersEndCursor = payload;
    },
    setListMembersPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listMembersPageNumber = payload;
    },
    setListMembersPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listMembersPageSize = payload;
    },
    setListMembersRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listMembersRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default membersListMembersSlice.reducer;

export const {
  setFilterMemberName: setFilterMemberNameAction,
  setFilterMemberTier: setFilterMemberTierAction,
  setIsGridReady: setIsGridReadyAction,
  setIsShowUtilityDrawer: setIsShowUtilityDrawerAction,
  setListMembersEndCursor: setListMembersEndCursorAction,
  setListMembersPageNumber: setListMembersPageNumberAction,
  setListMembersPageSize: setListMembersPageSizeAction,
  setListMembersRowIndex: setListMembersRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = membersListMembersSlice.actions;
