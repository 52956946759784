//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/checkbox.ts

import { StyleConfig } from '_types';

export const Switch: StyleConfig = {
  //
  baseStyle: {},
  sizes: {},

  variants: {
    info: (props) => {
      const { colorMode } = props;

      return {
        container: {},
        thumb: {},
        track: {
          _checked: {
            bg: colorMode === 'dark' ? 'f-icon-neutral' : 'f-icon-neutral',
          },
          _focus: {
            boxShadow: 'none',
            outlineColor:
              colorMode === 'dark' ? 'theme-primary-b' : 'theme-primary-b',
            outlineOffset: '3px',
            outlineStyle: 'dashed',
            outlineWidth: '1px',
          },
          bg: colorMode === 'dark' ? 'f-icon-neutral' : 'f-icon-neutral',
        },
      };
    },

    primary: (props) => {
      const { colorMode } = props;

      return {
        container: {},
        thumb: {},
        track: {
          _checked: {
            bg:
              colorMode === 'dark'
                ? 'theme-primary-s-dark'
                : 'theme-primary-s-dark',
          },
          _focus: {
            boxShadow: 'none',
            outlineColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            outlineOffset: '3px',
            outlineStyle: 'dashed',
            outlineWidth: '1px',
          },
          bg: colorMode === 'dark' ? 's-icon-neutral' : 's-icon-neutral',
        },
      };
    },
  },
};
