import { email } from '_lib/constants';
import { usePasswordVisibility } from '_lib/hooks/usePasswordVisibility';
import { VStack } from '@chakra-ui/react';
import { Input } from 'components/_basic/Input';
import { State } from './useMain';

function Component() {
  const { showPassword, ToggleButton } = usePasswordVisibility();

  return (
    <div className="w-full">
      <VStack spacing={5}>
        <Input<State>
          label="Username (Email)"
          name="email"
          requiredText="Email is required"
          validationRules={{
            ...email,
          }}
        />
        <Input<State>
          inputRightElement={<ToggleButton />}
          label="Password"
          name="password"
          requiredText="Password is required"
          type={showPassword ? 'text' : 'password'}
        />
      </VStack>
    </div>
  );
}

export const LoginFields = Component;
