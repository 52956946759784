import { usePasswordVisibility } from '_lib/hooks/usePasswordVisibility';
import { VStack } from '@chakra-ui/react';
import { Input } from 'components/_basic/Input';
import { State } from './useMain';

function Component() {
  const { showPassword, ToggleButton } = usePasswordVisibility();

  return (
    <div className="w-full">
      <VStack spacing={5}>
        <Input<State>
          inputRightElement={<ToggleButton />}
          label="New password"
          name="newPassword"
          requiredText="New password is required"
          type={showPassword ? 'text' : 'password'}
        />
        <Input<State>
          inputRightElement={<ToggleButton />}
          label="Confirm new password"
          name="confirmPassword"
          requiredText="Repeat new password"
          type={showPassword ? 'text' : 'password'}
        />
        <Input<State>
          label="Verification code"
          name="verificationCode"
          requiredText="Verification code is required"
        />
      </VStack>
    </div>
  );
}

export const ForgotPasswordSubmitFields = Component;
