import styled from '@emotion/styled';
import { ReactNode } from 'react';
import tw from 'twin.macro';

interface CardSectionProps {
  children: ReactNode;
  divider?: boolean;
  moreActions?: ReactNode;
  padContent?: boolean;
  title?: string | ReactNode;
}

interface ContainerProps {
  divider: boolean;
}

interface ContentContainerProps {
  padContent: boolean;
}

const Container = styled.div<ContainerProps>`
  ${tw`w-full h-full p-5`};
  ${(props) => props.divider && tw`border-b border-divider`};
`;

const ContentContainer = styled.div<ContentContainerProps>`
  ${tw`w-full h-full`};
  ${(props) => props.padContent && tw`pb-4`}
`;

const Header = styled.div`
  ${tw`relative flex justify-between mb-3`};
  min-height: 26px;
`;

export const CardSection = (props: CardSectionProps) => {
  const {
    children,
    divider = false,
    moreActions,
    padContent = false,
    title,
  } = props;

  return (
    <Container divider={divider}>
      {(title || moreActions) && (
        <Header>
          <div className="absolute text-base font-semibold text-t-base-light -top-2">
            {title}
          </div>
          {moreActions && (
            <div className="absolute right-0 -top-2">{moreActions}</div>
          )}
        </Header>
      )}
      <ContentContainer padContent={padContent}>{children}</ContentContainer>
    </Container>
  );
};
