//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/select.ts

import { StyleConfig } from '_types';

export const Select: StyleConfig = {
  baseStyle: {
    field: {
      fontFamily: `-apple-system,"system-ui","San Francisco","Segoe UI",Roboto,"Helvetica Neue"`,
      fontWeight: 400,
    },
  },

  sizes: {},

  variants: {
    // filter: (props) => {
    //   const { colorMode } = props;

    //   return {
    //     field: {
    //       bg: colorMode === 'dark' ? 'transparent' : 'transparent',
    //     },
    //     icon: {},
    //   };
    // },

    outline: (props) => {
      const { colorMode } = props;

      return {
        field: {
          _focus: {
            _invalid: {
              borderColor:
                colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
              outlineColor:
                colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
            },
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            boxShadow: 'none',
            outlineColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            outlineOffset: '1px',
            outlineWidth: '1px',
            zIndex: 1,
          },
          _hover: {
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
          },
          _invalid: {
            bg:
              colorMode === 'dark'
                ? 's-critical-subdued-light'
                : 's-critical-subdued-light',
            borderColor: colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
            boxShadow: 'none',
          },
          _placeholder: {
            color: colorMode === 'dark' ? 't-placeholder' : 't-placeholder',
          },
          bg: colorMode === 'dark' ? 's-input-light' : 's-input-light',
          border: '1px solid',
          borderColor:
            colorMode === 'dark'
              ? 'brd-neutral-subdued-light'
              : 'brd-neutral-subdued-light',
          color: colorMode === 'dark' ? '#000000' : '#000000',
          fontSize: 'sm',
          fontWeight: '600',
          h: 9,
          lineHeight: '1',
        },
        icon: {},
      };
    },

    outlineSm: (props) => {
      const { colorMode } = props;

      return {
        field: {
          _focus: {
            _invalid: {
              borderColor:
                colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
              outlineColor:
                colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
            },
            borderColor:
              colorMode === 'dark'
                ? 'brd-neutral-subdued-light'
                : 'brd-neutral-subdued-light',
            boxShadow: 'none',
            outlineColor: colorMode === 'dark' ? 'transparent' : 'transparent',
            outlineOffset: '10px',
            outlineWidth: '1px',
            zIndex: 1,
          },
          _hover: {
            borderColor:
              colorMode === 'dark'
                ? 'brd-neutral-subdued-light'
                : 'brd-neutral-subdued-light',
          },
          _invalid: {
            bg:
              colorMode === 'dark'
                ? 's-critical-subdued-light'
                : 's-critical-subdued-light',
            borderColor: colorMode === 'dark' ? 'brd-critical' : 'brd-critical',
            boxShadow: 'none',
          },
          _placeholder: {
            color: colorMode === 'dark' ? 't-placeholder' : 't-placeholder',
          },
          bg: colorMode === 'dark' ? 's-input-light' : 's-input-light',
          border: '1px solid',
          borderColor:
            colorMode === 'dark'
              ? 'brd-neutral-subdued-light'
              : 'brd-neutral-subdued-light',
          color: colorMode === 'dark' ? '#000000' : '#000000',
          fontSize: 'sm',
          fontWeight: '600',
          h: 7,
          lineHeight: '1',
        },
        icon: {},
      };
    },
  },
};
