import { StyleOptions } from '_types';
import { extendTheme, ThemeConfig } from '@chakra-ui/react';
import { Alert } from './components/alert';
import { Badge } from './components/badge';
import { Button } from './components/button';
import { Checkbox } from './components/checkbox';
import { Divider } from './components/divider';
import { Drawer } from './components/drawer';
import { FormError } from './components/form-error';
import { FormLabel } from './components/form-label';
import { Input } from './components/input';
import { Menu } from './components/menu';
import { Modal } from './components/modal';
import { NumberInput } from './components/number-input';
import { PinInput } from './components/pin-input';
import { Popover } from './components/popover';
import { Select } from './components/select';
import { Switch } from './components/switch';
import { Table } from './components/table';
import { Tabs } from './components/tabs';
import { Tag } from './components/tag';
import { Textarea } from './components/textarea';
import { Tooltip } from './components/tooltip';
import { colors, fill, schemes } from './extendedChakra';

const config: ThemeConfig = {
  initialColorMode: 'light',
  //* useSystemColorMode -> Page refresh causes site to adopt system's color mode
  useSystemColorMode: false,
};

export const theme = extendTheme({
  colors: {
    ...colors,
    ...fill,
    ...schemes,
  },
  components: {
    Alert,
    Badge,
    Button,
    Checkbox,
    Divider,
    Drawer,
    FormError,
    FormLabel,
    Input,
    Menu,
    Modal,
    NumberInput,
    PinInput,
    Popover,
    Select,
    Switch,
    Table,
    Tabs,
    Tag,
    Textarea,
    Tooltip,
  },
  config,
  styles: {
    global: (props: StyleOptions) => {
      //
      const { colorMode } = props;

      return {
        body: {
          margin: 0,
          overflowY: 'scroll',
        },
        'html, body': {
          backgroundColor: colorMode === 'dark' ? '#ffffff' : '#ffffff',
        },
      };
    },
  },
});
