import {
  CoachAvailabilityDayItemTemplate,
  CoachClubhouse,
  CountryCode,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface States {
  baseCountryCode: CountryCode | null;
  baseGmtOffset: number | null;
  clubhouses: CoachClubhouse[];
  editMyAccountTabIndex: number;
  noOfTemplates: number;
  slug: string;
  templates: CoachAvailabilityDayItemTemplate[];
}

const initialState: States = {
  baseCountryCode: null,
  baseGmtOffset: null,
  clubhouses: [],
  editMyAccountTabIndex: 0,
  noOfTemplates: 0,
  slug: '',
  templates: [],
};

interface SetMyAccountPayload {
  baseCountryCode: CountryCode;
  baseGmtOffset: number;
  clubhouses: CoachClubhouse[];
  slug: string;
}

export const myAccountSlice = createSlice({
  initialState,
  name: 'myAccount',
  reducers: {
    setEditMyAccountTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.editMyAccountTabIndex = payload;
    },
    setMyAccount: (state, { payload }: PayloadAction<SetMyAccountPayload>) => {
      state.clubhouses = payload.clubhouses;
      state.slug = payload.slug;
      state.baseCountryCode = payload.baseCountryCode;
      state.baseGmtOffset = payload.baseGmtOffset;
    },
    setMyClubhouses: (state, { payload }: PayloadAction<CoachClubhouse[]>) => {
      state.clubhouses = payload;
    },
    setNoOfTemplates: (state, { payload }: PayloadAction<number>) => {
      state.noOfTemplates = payload;
    },
    setTemplates: (
      state,
      { payload }: PayloadAction<CoachAvailabilityDayItemTemplate[]>,
    ) => {
      state.templates = payload;
    },
  },
});

export default myAccountSlice.reducer;

export const {
  setEditMyAccountTabIndex: setEditMyAccountTabIndexAction,
  setMyAccount: setMyAccountAction,
  setMyClubhouses: setMyClubhousesAction,
  setNoOfTemplates: setNoOfTemplatesAction,
  setTemplates: setTemplatesAction,
} = myAccountSlice.actions;
