//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/tag.ts

import { StyleConfig } from '_types';

export const Tag: StyleConfig = {
  baseStyle: (props) => {
    const { _colorMode, _colorScheme } = props;

    return {
      closeButton: {},
      container: {},
      label: {},
    };
  },

  sizes: {},

  variants: {
    subtle: (props) => {
      const { _colorMode, colorScheme } = props;

      let bg = '';
      let color = '';

      if (colorScheme === 'success') {
        bg = 's-success-subdued';
        color = '#000000';
      }

      if (colorScheme === 'highlight') {
        bg = 's-highlight-subdued';
        color = '#000000';
      }

      return {
        closeButton: {},
        container: {
          bg,
          color,
        },
        label: {},
      };
    },
  },
};
