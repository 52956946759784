export enum Permission {
  ALL = 'all',
  BOOKINGS = 'boo',
  CLUBHOUSE = 'clu',
  COACHES = 'coa',
  MARKETING = 'mar',
  MEMBERS = 'mem',
  MEMBERSHIP_SETTINGS = 'mse',
  ORGANIZATION = 'org',
  PARTNERS = 'par',
  ROSTERING = 'ros',
  SYSTEM_USER = 'sus',
}

export type ModuleKey = `${Permission}`;
