import {
  BookingType,
  CoachGroupBooking,
  Direction,
  OrderBy,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookingQuickLook {
  bookings: CoachGroupBooking[];
  isShowModal: boolean;
}

export interface States {
  bookingQuickLook: BookingQuickLook | null;
  filterBookingId: string;
  filterBookingType: BookingType | string;
  filterMemberName: string;
  filterVenue: string;
  isGridReady: boolean;
  listBookingsCompletedEndCursor: string;
  listBookingsCompletedEndTime: string | null;
  listBookingsCompletedFirst: number | null;
  listBookingsCompletedOrderBy: OrderBy;
  listBookingsCompletedPageNumber: number;
  listBookingsCompletedPageSize: number;
  listBookingsCompletedRowIndex: number;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  bookingQuickLook: null,
  filterBookingId: '',
  filterBookingType: '',
  filterMemberName: '',
  filterVenue: '',
  isGridReady: false,
  listBookingsCompletedEndCursor: '',
  listBookingsCompletedEndTime: null,
  listBookingsCompletedFirst: null,
  listBookingsCompletedOrderBy: {
    direction: Direction.Desc,
    field: 'startTime',
  },
  listBookingsCompletedPageNumber: 0,
  listBookingsCompletedPageSize: 100,
  listBookingsCompletedRowIndex: -1,
  pageInfo: null,
  recordLoaded: 0,
};

export const bookingsListBookingsCompletedSlice = createSlice({
  initialState,
  name: 'bookingsListBookingsCompleted',
  reducers: {
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setFilterBookingId: (state, { payload }: PayloadAction<string>) => {
      state.filterBookingId = payload;
    },
    setFilterBookingType: (
      state,
      { payload }: PayloadAction<BookingType | string>,
    ) => {
      state.filterBookingType = payload;
    },
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterVenue: (state, { payload }: PayloadAction<string>) => {
      state.filterVenue = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListBookingsCompletedEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsCompletedEndCursor = payload;
    },
    setListBookingsCompletedEndTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsCompletedEndTime = payload;
    },
    setListBookingsCompletedFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBookingsCompletedFirst = payload;
    },
    setListBookingsCompletedOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsCompletedOrderBy = payload;
    },
    setListBookingsCompletedPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCompletedPageNumber = payload;
    },
    setListBookingsCompletedPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCompletedPageSize = payload;
    },
    setListBookingsCompletedRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsCompletedRowIndex = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default bookingsListBookingsCompletedSlice.reducer;

export const {
  setBookingQuickLook: setBookingQuickLookAction,
  setFilterBookingId: setFilterBookingIdAction,
  setFilterBookingType: setFilterBookingTypeAction,
  setFilterMemberName: setFilterMemberNameAction,
  setFilterVenue: setFilterVenueAction,
  setIsGridReady: setIsGridReadyAction,
  setListBookingsCompletedEndCursor: setListBookingsCompletedEndCursorAction,
  setListBookingsCompletedEndTime: setListBookingsCompletedEndTimeAction,
  setListBookingsCompletedFirst: setListBookingsCompletedFirstAction,
  setListBookingsCompletedOrderBy: setListBookingsCompletedOrderByAction,
  setListBookingsCompletedPageNumber: setListBookingsCompletedPageNumberAction,
  setListBookingsCompletedPageSize: setListBookingsCompletedPageSizeAction,
  setListBookingsCompletedRowIndex: setListBookingsCompletedRowIndexAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = bookingsListBookingsCompletedSlice.actions;
