//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/tag.ts

import { StyleConfig } from '_types';

export const Badge: StyleConfig = {
  baseStyle: (props) => {
    const { _colorMode, _colorScheme } = props;

    return {
      textTransform: 'uppercase',
    };
  },

  sizes: {},

  variants: {
    subtle: (props) => {
      const { _colorMode, colorScheme } = props;

      let bg = '';
      let color = '';

      if (colorScheme === 'success') {
        bg = 's-success-subdued';
        color = '#000000';
      }

      if (colorScheme === 'highlight') {
        bg = 's-highlight-subdued';
        color = '#000000';
      }

      if (colorScheme === 'danger') {
        bg = 's-danger';
        color = '#ffffff';
      }

      if (colorScheme === 'info') {
        bg = 's-icon-neutral';
        color = '#ffffff';
      }

      if (colorScheme === 'warning') {
        bg = 's-warning';
        color = '#000000';
      }

      return {
        bg,
        color,
        fontWeight: 600,
      };
    },
  },
};
