import { email } from '_lib/constants';
import { VStack } from '@chakra-ui/react';
import { Input } from 'components/_basic/Input';
import { State } from './useMain';

function Component() {
  return (
    <div className="w-full">
      <VStack spacing={5}>
        <Input<State>
          label="Email"
          name="email"
          requiredText="Email is required"
          validationRules={{
            ...email,
          }}
        />
      </VStack>
    </div>
  );
}

export const ForgotPasswordFields = Component;
