import { Button, VStack } from '@chakra-ui/react';
import { BaseSyntheticEvent, Dispatch, SetStateAction } from 'react';
import { AuthMode } from '../types';

interface UtilityProps {
  authMode: AuthMode;
  formHandler: (
    _v?: BaseSyntheticEvent<object, any, any> | undefined,
  ) => Promise<void>;
  setAuthMode: Dispatch<SetStateAction<AuthMode>>;
}

function Component(props: UtilityProps) {
  const { authMode, formHandler, setAuthMode } = props;

  return (
    <div className="w-full">
      <VStack spacing={6}>
        <div className="w-full pt-4">
          {authMode === 'login' && (
            <Button
              colorScheme="primary-action"
              type="submit"
              variant="solid"
              width="100%"
              onClick={formHandler}
            >
              Log in
            </Button>
          )}
          {authMode === 'forgotPassword' && (
            <Button
              colorScheme="primary-action"
              type="submit"
              variant="solid"
              width="100%"
              onClick={formHandler}
            >
              Get reset code
            </Button>
          )}
          {authMode === 'forgotPasswordSubmit' && (
            <Button
              colorScheme="primary-action"
              type="submit"
              variant="solid"
              width="100%"
              onClick={formHandler}
            >
              Reset password
            </Button>
          )}
        </div>
        <div className="flex justify-start w-full">
          {authMode === 'login' && (
            <Button
              size="sm"
              variant="link"
              onClick={() => setAuthMode('forgotPassword')}
            >
              Forgot password
            </Button>
          )}
          {authMode === 'forgotPassword' && (
            <Button
              size="sm"
              variant="link"
              onClick={() => setAuthMode('login')}
            >
              Back to login
            </Button>
          )}
          {authMode === 'forgotPasswordSubmit' && (
            <Button
              size="sm"
              variant="link"
              onClick={() => setAuthMode('login')}
            >
              Cancel
            </Button>
          )}
        </div>
      </VStack>
    </div>
  );
}

export const Utility = Component;
