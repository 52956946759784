import {
  BookingStatus,
  BookingType,
  CoachGroupBooking,
  Direction,
  OrderBy,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DrawerTempLinkButtonValue } from 'components/_basic/DrawerComponents';
import { DateRangeMode } from 'components/DateFilter';

export interface BookingQuickLook {
  bookings: CoachGroupBooking[];
  isShowModal: boolean;
}

export interface States {
  bookingQuickLook: BookingQuickLook | null;
  isGridReady: boolean;

  listBookingsDateRangeMode: DateRangeMode;
  listBookingsEndCursor: string;
  listBookingsEndTime: string | null;
  listBookingsFilterBookingId: string;
  listBookingsFilterBookingStatus: BookingStatus;
  listBookingsFilterBookingType: BookingType | string;
  listBookingsFilterCoach: string;
  listBookingsFilterVenue: string;
  listBookingsFirst: number | null;
  listBookingsFirstDate: string | null;
  listBookingsLastRecordDate: string | null;
  listBookingsListBookingsActiveFirst: number | null;
  listBookingsOrderBy: OrderBy;
  listBookingsPageInfo: PageInfo | null | undefined;
  listBookingsPageNumber: number;
  listBookingsPageSize: number;
  listBookingsRecordLoaded: number;
  listBookingsRowIndex: number;
  listBookingsStartTime: string | null;

  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
  selectedMember: DrawerTempLinkButtonValue;
  tabIndex: number;
}

const initialState: States = {
  bookingQuickLook: null,
  isGridReady: false,

  listBookingsDateRangeMode: 'from-today-asc',
  listBookingsEndCursor: '',
  listBookingsEndTime: null,
  listBookingsFilterBookingId: '',
  listBookingsFilterBookingStatus: BookingStatus.Active,
  listBookingsFilterBookingType: '',
  listBookingsFilterCoach: '',
  listBookingsFilterVenue: '',
  listBookingsFirst: 1000,
  listBookingsFirstDate: null,
  listBookingsLastRecordDate: null,
  listBookingsListBookingsActiveFirst: 1000,
  listBookingsOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsPageInfo: null,
  listBookingsPageNumber: 0,
  listBookingsPageSize: 100,
  listBookingsRecordLoaded: 0,
  listBookingsRowIndex: -1,
  listBookingsStartTime: null,

  memberIndividualId: '',
  memberName: '',
  memberUuid: '',
  membershipUuid: '',
  selectedMember: {
    id: '',
    label: '',
  },
  tabIndex: 0,
};

type MemberIDs = {
  memberIndividualId: string;
  memberName: string;
  memberUuid: string;
  membershipUuid: string;
};

export const membersEditMemberSlice = createSlice({
  initialState,
  name: 'membersEditMember',
  reducers: {
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },

    setListBookingsDateRangeMode: (
      state,
      { payload }: PayloadAction<DateRangeMode>,
    ) => {
      state.listBookingsDateRangeMode = payload;
    },
    setListBookingsEndCursor: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsEndCursor = payload;
    },
    setListBookingsEndTime: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsEndTime = payload;
    },
    setListBookingsFilterBookingId: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsFilterBookingId = payload;
    },
    setListBookingsFilterBookingStatus: (
      state,
      { payload }: PayloadAction<BookingStatus>,
    ) => {
      state.listBookingsFilterBookingStatus = payload;
    },
    setListBookingsFilterBookingType: (
      state,
      { payload }: PayloadAction<BookingType | string>,
    ) => {
      state.listBookingsFilterBookingType = payload;
    },
    setListBookingsFilterCoach: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsFilterCoach = payload;
    },
    setListBookingsFilterVenue: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsFilterVenue = payload;
    },
    setListBookingsFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBookingsFirst = payload;
    },
    setListBookingsFirstDate: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsFirstDate = payload;
    },
    setListBookingsLastRecordDate: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsLastRecordDate = payload;
    },
    setListBookingsListBookingsActiveFirst: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsListBookingsActiveFirst = payload;
    },
    setListBookingsOrderBy: (state, { payload }: PayloadAction<OrderBy>) => {
      state.listBookingsOrderBy = payload;
    },
    setListBookingsPageInfo: (
      state,
      { payload }: PayloadAction<PageInfo | null>,
    ) => {
      state.listBookingsPageInfo = payload;
    },
    setListBookingsPageNumber: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageNumber = payload;
    },
    setListBookingsPageSize: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsPageSize = payload;
    },

    setListBookingsRecordLoaded: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsRecordLoaded = payload;
    },
    setListBookingsRowIndex: (state, { payload }: PayloadAction<number>) => {
      state.listBookingsRowIndex = payload;
    },
    setListBookingsStartTime: (state, { payload }: PayloadAction<string>) => {
      state.listBookingsStartTime = payload;
    },

    setMemberIDs: (state, { payload }: PayloadAction<MemberIDs>) => {
      state.memberUuid = payload.memberUuid;
      state.membershipUuid = payload.membershipUuid;
      state.memberName = payload.memberName;
      state.memberIndividualId = payload.memberIndividualId;
    },

    setSelectedMember: (
      state,
      { payload }: PayloadAction<DrawerTempLinkButtonValue>,
    ) => {
      state.selectedMember = payload;
    },

    setTabIndex: (state, { payload }: PayloadAction<number>) => {
      state.tabIndex = payload;
    },
  },
});

export default membersEditMemberSlice.reducer;

export const {
  setBookingQuickLook: setBookingQuickLookAction,
  setIsGridReady: setIsGridReadyAction,
  setListBookingsDateRangeMode: setListBookingsDateRangeModeAction,
  setListBookingsEndCursor: setListBookingsEndCursorAction,
  setListBookingsEndTime: setListBookingsEndTimeAction,
  setListBookingsFilterBookingId: setListBookingsFilterBookingIdAction,
  setListBookingsFilterBookingStatus: setListBookingsFilterBookingStatusAction,
  setListBookingsFilterBookingType: setListBookingsFilterBookingTypeAction,
  setListBookingsFilterCoach: setListBookingsFilterCoachAction,
  setListBookingsFilterVenue: setListBookingsFilterVenueAction,
  setListBookingsFirst: setListBookingsFirstAction,
  setListBookingsFirstDate: setListBookingsFirstDateAction,
  setListBookingsLastRecordDate: setListBookingsLastRecordDateAction,
  setListBookingsListBookingsActiveFirst:
    setListBookingsListBookingsActiveFirstAction,
  setListBookingsOrderBy: setListBookingsOrderByAction,
  setListBookingsPageInfo: setListBookingsPageInfoAction,
  setListBookingsPageNumber: setListBookingsPageNumberAction,
  setListBookingsPageSize: setListBookingsPageSizeAction,
  setListBookingsRecordLoaded: setListBookingsRecordLoadedAction,
  setListBookingsRowIndex: setListBookingsRowIndexAction,
  setListBookingsStartTime: setListBookingsStartTimeAction,
  setMemberIDs: setMemberIDsAction,
  setSelectedMember: setSelectedMemberAction,
  setTabIndex: setTabIndexAction,
} = membersEditMemberSlice.actions;
