import { AuthenticatedAdmin } from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthState = 'loading' | 'authenticated' | 'notAuthenticated';

export interface Auth {
  accessToken: string | null;
  authState: AuthState;
  authenticatedAdmin: AuthenticatedAdmin | null;
}

interface LoginPayload {
  accessToken: string;
  authenticatedAdmin: AuthenticatedAdmin;
}

interface UpdateAccessTokensPayload {
  accessToken: string;
}

const initialState: Auth = {
  accessToken: null,
  authState: 'loading',
  authenticatedAdmin: null,
};

export const authSlice = createSlice({
  initialState,
  name: 'auth',
  reducers: {
    login: (state, { payload }: PayloadAction<LoginPayload>) => {
      state.authState = 'authenticated';
      state.authenticatedAdmin = payload.authenticatedAdmin;
      state.accessToken = payload.accessToken;
    },
    logout: (state) => {
      state.authState = 'notAuthenticated';
      state.authenticatedAdmin = null;
      state.accessToken = null;
    },
    setAuthState: (state, { payload }: PayloadAction<AuthState>) => {
      state.authState = payload;
    },
    updateAccessToken: (
      state,
      { payload }: PayloadAction<UpdateAccessTokensPayload>,
    ) => {
      state.accessToken = payload.accessToken;
    },
    updateAuthenticatedAdmin: (
      state,
      { payload }: PayloadAction<AuthenticatedAdmin>,
    ) => {
      state.authenticatedAdmin = payload;
    },
  },
});

export const {
  login: loginAction,
  logout: logoutAction,
  setAuthState: setAuthStateAction,
  updateAccessToken: updateAccessTokenAction,
  updateAuthenticatedAdmin: updateAuthenticatedAdminAction,
} = authSlice.actions;
