//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/modal.ts

import { StyleConfig } from '_types';

export const Modal: StyleConfig = {
  baseStyle: (props) => {
    const { _colorMode, _colorScheme } = props;

    return {
      body: {},
      closeButton: {},
      dialog: {},
      dialogContainer: {},
      footer: {},
      header: {},
      overlay: {},
    };
  },

  sizes: {},

  variants: {
    primary: (props) => {
      const { _colorScheme, colorMode } = props;

      return {
        body: {
          bg: colorMode === 'dark' ? '#ffffff' : '#ffffff',
          color: colorMode === 'dark' ? '#000000' : '#000000',
        },
        border: '1px red solid;',
        closeButton: {
          _focus: {
            boxShadow: 'none',
            outlineColor: 'theme-primary-b-dark',
            outlineWidth: '1px',
          },
          color: colorMode === 'dark' ? '#000000' : '#000000',
        },
        dialog: {
          bg: colorMode === 'dark' ? '#ffffff' : '#ffffff',
          borderRadius: 'lg',
          overflow: 'hidden',
        },
        dialogContainer: {},
        footer: {},
        header: {
          bg: colorMode === 'dark' ? '#ffffff' : '#ffffff',
          color: colorMode === 'dark' ? '#000000' : '#000000',
        },
        overlay: {},
      };
    },
  },
};
