/* eslint-disable no-case-declarations */
import { useStatusHandler } from '_lib/hooks/useStatusHandler';
import {
  SystemOperationStatus,
  useAdminForgotPasswordMutation,
  useAdminForgotPasswordSubmitMutation,
  useAdminLoginMutation,
} from '@generated/schema';
import { Dispatch, SetStateAction, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { loginAction } from 'store/auth';
import { AuthMode } from '../types';

interface UseMainProps {
  authMode: AuthMode;
  setAuthMode: Dispatch<SetStateAction<AuthMode>>;
}

export interface State {
  confirmPassword: string;
  email: string;
  newPassword: string;
  password: string;
  resetToken: string;
  verificationCode: string;
}

export const initialState: State = {
  confirmPassword: '',
  email: '',
  newPassword: '',
  password: '',
  resetToken: '',
  verificationCode: '',
};

export const useMain = (props: UseMainProps) => {
  const { authMode, setAuthMode } = props;

  const [login] = useAdminLoginMutation({
    fetchPolicy: 'no-cache',
  });

  const [forgotPassword] = useAdminForgotPasswordMutation({
    fetchPolicy: 'no-cache',
  });

  const [forgotPasswordSubmit] = useAdminForgotPasswordSubmitMutation({
    fetchPolicy: 'no-cache',
  });

  const [forgotPasswordObj, setForgotPasswordObj] = useState({
    email: '',
    resetToken: '',
  });

  const { statusHandler } = useStatusHandler();

  const dispatch = useDispatch();

  const methods = useForm({
    defaultValues: initialState,
  });

  const { setError } = methods;

  const onSubmit = async (formValues: State) => {
    const { confirmPassword, email, newPassword, password, verificationCode } =
      formValues;

    try {
      switch (authMode) {
        case 'login':
          const { data: loginData } = await login({
            variables: {
              input: {
                email,
                password,
              },
            },
          });

          if (loginData && loginData.adminLogin) {
            const { accessToken, authenticatedAdmin } = loginData.adminLogin;
            dispatch(
              loginAction({
                accessToken,
                authenticatedAdmin,
              }),
            );
          }
          break;

        case 'forgotPassword':
          const { data: forgotPasswordData } = await forgotPassword({
            variables: {
              input: {
                email,
              },
            },
          });

          if (forgotPasswordData) {
            setForgotPasswordObj({
              email: forgotPasswordData.adminForgotPassword.email,
              resetToken: forgotPasswordData.adminForgotPassword.resetToken,
            });
            setAuthMode('forgotPasswordSubmit');
          }
          break;

        case 'forgotPasswordSubmit':
          if (formValues.newPassword !== formValues.confirmPassword) {
            setError('newPassword', {
              message: 'Passwords do not match',
              type: 'custom',
            });
            setError('confirmPassword', {
              message: 'Passwords do not match',
              type: 'custom',
            });

            return;
          }

          const { data: forgotPasswordSubmitData } = await forgotPasswordSubmit(
            {
              variables: {
                input: {
                  confirmPassword,
                  email: forgotPasswordObj.email,
                  newPassword,
                  resetToken: forgotPasswordObj.resetToken,
                  verificationCode,
                },
              },
            },
          );

          if (forgotPasswordSubmitData) {
            const { adminForgotPasswordSubmit } = forgotPasswordSubmitData;

            if (
              adminForgotPasswordSubmit ===
              SystemOperationStatus.VerificationCodeMismatch
            ) {
              setError(
                'verificationCode',
                {
                  message: 'Incorrect code. Please try again.',
                  type: 'custom',
                },
                { shouldFocus: true },
              );
            }

            if (adminForgotPasswordSubmit === SystemOperationStatus.Success) {
              setAuthMode('login');
            }
          }
          break;

        default:
          break;
      }
    } catch (error) {
      statusHandler({
        error,
      });
    }
  };

  const formHandler = methods.handleSubmit(onSubmit);

  return {
    formHandler,
    methods,
  };
};
