//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/table.ts

import { StyleConfig } from '_types';

export const Table: StyleConfig = {
  //
  baseStyle: {
    caption: {
      fontWeight: 'medium',
      mt: 4,
      textAlign: 'center',
    },
    table: {
      borderCollapse: 'collapse',
      fontVariantNumeric: 'lining-nums tabular-nums',
      width: '100%',
    },
    td: {
      textAlign: 'start',
    },

    th: {
      fontWeight: 'bold',
      letterSpacing: 'wider',
      textAlign: 'start',
      textTransform: 'uppercase',
    },
  },

  sizes: {},

  variants: {
    simple: (props) => {
      const { color, colorMode } = props;

      return {
        caption: {},
        table: {
          width: '100%',
        },
        tbody: {
          td: {
            borderBottomColor: colorMode === 'dark' ? 'divider' : 'divider',
            borderTopColor:
              colorMode === 'dark' ? 'transparent' : 'transparent',
            color: colorMode === 'dark' ? '#000000' : '#000000',
            fontSize: 'sm',
            py: 3,
          },
          tr: {
            transitionDuration: 'normal',
            transitionProperty: 'common',
          },
        },
        td: {},
        tfoot: {
          bg:
            colorMode === 'dark'
              ? 'rgba(241, 242, 243, 1)'
              : 'rgba(241, 242, 243, 1)',
          td: {
            py: 3,
          },
          tr: {
            fontSize: 'xs',
            fontWeight: 'bold',
            letterSpacing: 'wider',
            textTransform: 'uppercase',
          },
        },
        thead: {
          th: {
            borderColor: colorMode === 'dark' ? 'transparent' : 'transparent',
            color: colorMode === 'dark' ? '#000000' : '#000000',
            py: 3,
          },
          tr: {
            bg: colorMode === 'dark' ? '#d0eae4' : '#d0eae4',
          },
        },
      };
    },
  },
};
