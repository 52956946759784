import {
  BookingType,
  CoachGroupBooking,
  Direction,
  OrderBy,
  PageInfo,
} from '@generated/schema';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BookingQuickLook {
  bookings: CoachGroupBooking[];
  isShowModal: boolean;
}
export interface States {
  bookingQuickLook: BookingQuickLook | null;
  filterBookingId: string;
  filterBookingType: BookingType | string;
  filterMemberName: string;
  filterVenue: string;
  isGridReady: boolean;
  listBookingsUpcomingEndCursor: string;
  listBookingsUpcomingFirst: number | null;
  listBookingsUpcomingOrderBy: OrderBy;
  listBookingsUpcomingPageNumber: number;
  listBookingsUpcomingPageSize: number;
  listBookingsUpcomingRowIndex: number;
  listBookingsUpcomingStartTime: string | null;
  pageInfo: PageInfo | null | undefined;
  recordLoaded: number;
}

const initialState: States = {
  bookingQuickLook: null,
  filterBookingId: '',
  filterBookingType: '',
  filterMemberName: '',
  filterVenue: '',
  isGridReady: false,
  listBookingsUpcomingEndCursor: '',
  listBookingsUpcomingFirst: 1000,
  listBookingsUpcomingOrderBy: {
    direction: Direction.Asc,
    field: 'startTime',
  },
  listBookingsUpcomingPageNumber: 0,
  listBookingsUpcomingPageSize: 100,
  listBookingsUpcomingRowIndex: -1,
  listBookingsUpcomingStartTime: null,
  pageInfo: null,
  recordLoaded: 0,
};

export const bookingsListBookingsUpcomingSlice = createSlice({
  initialState,
  name: 'bookingsListBookingsUpcoming',
  reducers: {
    setBookingQuickLook: (
      state,
      { payload }: PayloadAction<BookingQuickLook | null>,
    ) => {
      state.bookingQuickLook = payload;
    },
    setFilterBookingId: (state, { payload }: PayloadAction<string>) => {
      state.filterBookingId = payload;
    },
    setFilterBookingType: (
      state,
      { payload }: PayloadAction<BookingType | string>,
    ) => {
      state.filterBookingType = payload;
    },
    setFilterMemberName: (state, { payload }: PayloadAction<string>) => {
      state.filterMemberName = payload;
    },
    setFilterVenue: (state, { payload }: PayloadAction<string>) => {
      state.filterVenue = payload;
    },
    setIsGridReady: (state, { payload }: PayloadAction<boolean>) => {
      state.isGridReady = payload;
    },
    setListBookingsUpcomingEndCursor: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsUpcomingEndCursor = payload;
    },
    setListBookingsUpcomingFirst: (
      state,
      { payload }: PayloadAction<number | null>,
    ) => {
      state.listBookingsUpcomingFirst = payload;
    },
    setListBookingsUpcomingOrderBy: (
      state,
      { payload }: PayloadAction<OrderBy>,
    ) => {
      state.listBookingsUpcomingOrderBy = payload;
    },
    setListBookingsUpcomingPageNumber: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsUpcomingPageNumber = payload;
    },
    setListBookingsUpcomingPageSize: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsUpcomingPageSize = payload;
    },
    setListBookingsUpcomingRowIndex: (
      state,
      { payload }: PayloadAction<number>,
    ) => {
      state.listBookingsUpcomingRowIndex = payload;
    },
    setListBookingsUpcomingStartTime: (
      state,
      { payload }: PayloadAction<string>,
    ) => {
      state.listBookingsUpcomingStartTime = payload;
    },
    setPageInfo: (state, { payload }: PayloadAction<PageInfo>) => {
      state.pageInfo = payload;
    },
    setRecordLoaded: (state, { payload }: PayloadAction<number>) => {
      state.recordLoaded = payload;
    },
  },
});

export default bookingsListBookingsUpcomingSlice.reducer;

export const {
  setBookingQuickLook: setBookingQuickLookAction,
  setFilterBookingId: setFilterBookingIdAction,
  setFilterBookingType: setFilterBookingTypeAction,
  setFilterMemberName: setFilterMemberNameAction,
  setFilterVenue: setFilterVenueAction,
  setIsGridReady: setIsGridReadyAction,
  setListBookingsUpcomingEndCursor: setListBookingsUpcomingEndCursorAction,
  setListBookingsUpcomingFirst: setListBookingsUpcomingFirstAction,
  setListBookingsUpcomingOrderBy: setListBookingsUpcomingOrderByAction,
  setListBookingsUpcomingPageNumber: setListBookingsUpcomingPageNumberAction,
  setListBookingsUpcomingPageSize: setListBookingsUpcomingPageSizeAction,
  setListBookingsUpcomingRowIndex: setListBookingsUpcomingRowIndexAction,
  setListBookingsUpcomingStartTime: setListBookingsUpcomingStartTimeAction,
  setPageInfo: setPageInfoAction,
  setRecordLoaded: setRecordLoadedAction,
} = bookingsListBookingsUpcomingSlice.actions;
