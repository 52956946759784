import { IconButton } from '@chakra-ui/react';
import { Visibility } from 'components/_icons/Visibility';
import { VisibilityOff } from 'components/_icons/VisibilityOff';
import { useState } from 'react';

export const usePasswordVisibility = () => {
  const [showPassword, setShowPassword] = useState(false);

  const ToggleButton = () => (
    <div className="relative -mt-1">
      <IconButton
        aria-label="toggle visibility"
        tabIndex={-1}
        variant="icon"
        onClick={() => setShowPassword((prev) => !prev)}
      >
        {showPassword ? (
          <VisibilityOff className="w-4 h-4" />
        ) : (
          <Visibility className="w-4 h-4" />
        )}
      </IconButton>
    </div>
  );

  return {
    ToggleButton: ToggleButton,
    showPassword,
  };
};
