import { store } from 'store';
import {
  logoutAction as authLogout,
  updateAccessTokenAction,
} from 'store/auth';
import { logoutAction as uiLogout } from 'store/ui';
import apolloClient from './apolloClient';

export const fetchRefreshToken = async () => {
  const response = await fetch(process.env.REACT_APP_GRAPHQL_ENDPOINT, {
    body: JSON.stringify({
      query: `
        mutation AdminRefreshAccessToken {
          adminRefreshAccessToken {
            accessToken
          }
        }
      `,
    }),
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  const isJson = response.headers
    .get('content-type')
    ?.includes('application/json');

  const json = isJson ? await response.json() : null;
  const { data, errors } = json;

  if (errors) {
    errors.forEach((error: any) => {
      if (error.message === 'NO_REFRESH_TOKEN') {
        apolloClient.clearStore();
        store.dispatch(uiLogout());
        store.dispatch(authLogout());
      }
    });
  }

  const { adminRefreshAccessToken } = data;
  const { accessToken, kgat, kgrt } = adminRefreshAccessToken;

  localStorage.setItem('kgrt', kgrt);

  store.dispatch(
    updateAccessTokenAction({
      accessToken,
    }),
  );

  return null;
};
