import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
  JSON: any;
  JSONObject: any;
};

export enum AcTransactionType {
  Adjustment = 'ADJUSTMENT',
  Award = 'AWARD',
  Payment = 'PAYMENT',
  Purchase = 'PURCHASE',
  Refund = 'REFUND',
  Signup = 'SIGNUP',
  Transfer = 'TRANSFER'
}

export enum AccountType {
  Main = 'MAIN',
  Sub = 'SUB'
}

export enum AccountTypeFilter {
  Member = 'MEMBER',
  NonMember = 'NON_MEMBER',
  Staff = 'STAFF',
  Test = 'TEST'
}

export type AceCoinLedgerTransaction = {
  __typename?: 'AceCoinLedgerTransaction';
  account?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  description: Scalars['String'];
  direction: Scalars['Int'];
  id: Scalars['Int'];
  invoiceNo?: Maybe<Scalars['String']>;
  lineTotal: Scalars['Float'];
  transactionDateTime: Scalars['DateTime'];
  transactionId: Scalars['String'];
  type: AcTransactionType;
  venue?: Maybe<Scalars['String']>;
};

export type AceCoinRatioInput = {
  amtAward?: InputMaybe<Scalars['Int']>;
  amtPurchase?: InputMaybe<Scalars['Int']>;
};

export type AceCoinTransaction = {
  __typename?: 'AceCoinTransaction';
  account?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  description: Scalars['String'];
  direction: Scalars['Int'];
  id: Scalars['Int'];
  invoiceNo?: Maybe<Scalars['String']>;
  transactionDateTime: Scalars['DateTime'];
  transactionId: Scalars['String'];
  type: AcTransactionType;
  venue?: Maybe<Scalars['String']>;
};

export enum AceCoinTransactionType {
  Adjustment = 'ADJUSTMENT',
  Award = 'AWARD',
  AwardDiscount = 'AWARD_DISCOUNT',
  Discount = 'DISCOUNT',
  Payment = 'PAYMENT',
  Purchase = 'PURCHASE',
  Refund = 'REFUND',
  Transfer = 'TRANSFER'
}

export type Admin = SystemUser & {
  __typename?: 'Admin';
  authorizedClubhouses?: Maybe<Array<Maybe<Scalars['String']>>>;
  baseCountryCode?: Maybe<CountryCode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Gender>;
  lastAccessTokenDate?: Maybe<Scalars['DateTime']>;
  lastLogin?: Maybe<LoginInfo>;
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  logins?: Maybe<Array<Maybe<LoginInfo>>>;
  mobileNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<AdminOrganization>;
  permissions: Array<Maybe<Scalars['String']>>;
  profileImageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<AdminStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  userType?: Maybe<UserType>;
};

export type AdminActivateMembership = {
  __typename?: 'AdminActivateMembership';
  payment?: Maybe<HitPayPaymentRequest>;
};

export type AdminActivateMembershipInput = {
  activationOrderNo: Scalars['String'];
  membershipUuid: Scalars['String'];
  paymentOption: AdminCreateMembershipPaymentOption;
  paymentRef?: InputMaybe<Scalars['String']>;
};

export type AdminAdminUpdatePasswordInput = {
  adminId: Scalars['String'];
  password: Scalars['String'];
};

export type AdminArgs = {
  userId: Scalars['String'];
};

export type AdminAuthResponse = {
  __typename?: 'AdminAuthResponse';
  accessToken: Scalars['String'];
  authenticatedAdmin: AuthenticatedAdmin;
};

export type AdminBasic = {
  __typename?: 'AdminBasic';
  baseCountryCode?: Maybe<CountryCode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastAccessTokenDate?: Maybe<Scalars['DateTime']>;
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  organization?: Maybe<AdminOrganization>;
  profileImageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<AdminStatus>;
  userId: Scalars['String'];
  userName: Scalars['String'];
  userType?: Maybe<UserType>;
};

export type AdminBooking = {
  __typename?: 'AdminBooking';
  cancellationDateTime?: Maybe<Scalars['DateTime']>;
  cancellationNotificationByAppSent?: Maybe<Scalars['Boolean']>;
  cancellationNotificationByEmailSent?: Maybe<Scalars['Boolean']>;
  cancellationNotificationBySmsSent?: Maybe<Scalars['Boolean']>;
  cancellationNotificationMessage?: Maybe<Scalars['String']>;
  cancellationNotificationSentBy?: Maybe<SentBy>;
  cancellationNotificationSentByAdminId?: Maybe<Scalars['String']>;
  cancellationNotificationSentByAdminName?: Maybe<Scalars['String']>;
  cancellationNotificationSentDateTime?: Maybe<Scalars['DateTime']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancelledBy?: Maybe<CancelledBy>;
  cancelledByAdminName?: Maybe<Scalars['String']>;
  clubhouseCode?: Maybe<Scalars['String']>;
  coachDateId?: Maybe<Scalars['String']>;
  coachId?: Maybe<Scalars['String']>;
  coachName?: Maybe<Scalars['String']>;
  commentAdminToCoach?: Maybe<Scalars['String']>;
  commentAdminToMember?: Maybe<Scalars['String']>;
  commentByCoach?: Maybe<Scalars['String']>;
  commentInternal?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<CreatedBy>;
  createdByAdminName?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  dateMonthStr?: Maybe<Scalars['String']>;
  dateStr?: Maybe<Scalars['String']>;
  dayOfWeekStr?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  durationStr?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  endTimeStr?: Maybe<Scalars['String']>;
  facilityType?: Maybe<FacilityType>;
  fieldLessonDetails?: Maybe<Scalars['String']>;
  gameCourseType?: Maybe<GameCourseType>;
  groupBookingId?: Maybe<Scalars['String']>;
  groupMembers?: Maybe<Array<Maybe<GroupMember>>>;
  id: Scalars['String'];
  isGroupBooking: Scalars['Boolean'];
  lateCancellationDetails?: Maybe<Scalars['JSON']>;
  member: Scalars['String'];
  memberHasGroupMembers: Scalars['Boolean'];
  memberIndividualId: Scalars['String'];
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  noOfPlayers: Scalars['Int'];
  resourceName?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  startTimeStr?: Maybe<Scalars['String']>;
  status: BookingStatus;
  type: BookingType;
  venue?: Maybe<Scalars['String']>;
  yearStr?: Maybe<Scalars['String']>;
};

export type AdminBookingArgs = {
  bookingId: Scalars['String'];
};

export type AdminBookingAvailabilityArgs = {
  clubhouseCode?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  duration?: InputMaybe<Scalars['Int']>;
  durationType?: InputMaybe<DurationType>;
  facilityType?: InputMaybe<FacilityType>;
};

export type AdminBookingBasic = {
  __typename?: 'AdminBookingBasic';
  cancellationDateTime?: Maybe<Scalars['DateTime']>;
  coachDisplayName?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  dateMonthStr?: Maybe<Scalars['String']>;
  dayOfWeekStr?: Maybe<Scalars['String']>;
  durationStr?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  endTimeStr?: Maybe<Scalars['String']>;
  gameCourseType?: Maybe<GameCourseType>;
  groupBookingId?: Maybe<Scalars['String']>;
  groupBookingIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
  isGroupBooking: Scalars['Boolean'];
  member: Scalars['String'];
  memberUuid: Scalars['String'];
  resourceName?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  startTimeStr?: Maybe<Scalars['String']>;
  status: BookingStatus;
  type: BookingType;
  venue?: Maybe<Scalars['String']>;
  yearStr?: Maybe<Scalars['String']>;
};

export type AdminBookingBasicMember = {
  __typename?: 'AdminBookingBasicMember';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AdminBookingBlackoutBasic = {
  __typename?: 'AdminBookingBlackoutBasic';
  clubhouseCode?: Maybe<Scalars['String']>;
  clubhouseName?: Maybe<Scalars['String']>;
  coachDisplayName?: Maybe<Scalars['String']>;
  coachSlug?: Maybe<Scalars['String']>;
  countryCode?: Maybe<CountryCode>;
  date: Scalars['String'];
  type: Scalars['String'];
};

export type AdminBookingBlackoutClubhouse = {
  __typename?: 'AdminBookingBlackoutClubhouse';
  block08: Scalars['Boolean'];
  block09: Scalars['Boolean'];
  block10: Scalars['Boolean'];
  block11: Scalars['Boolean'];
  block12: Scalars['Boolean'];
  block13: Scalars['Boolean'];
  block14: Scalars['Boolean'];
  block15: Scalars['Boolean'];
  block16: Scalars['Boolean'];
  block17: Scalars['Boolean'];
  block18: Scalars['Boolean'];
  block19: Scalars['Boolean'];
  block20: Scalars['Boolean'];
  block21: Scalars['Boolean'];
  block22: Scalars['Boolean'];
  block23: Scalars['Boolean'];
  clubhouseCode?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  facilityObjs: Array<Maybe<AdminClubhouseFacilityAvailabilityFacilityObj>>;
  isDayBlocked: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AdminBookingBlackoutClubhouseArgs = {
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
};

export type AdminBookingBlackoutClubhouseUpdateInput = {
  block08: Scalars['Boolean'];
  block09: Scalars['Boolean'];
  block10: Scalars['Boolean'];
  block11: Scalars['Boolean'];
  block12: Scalars['Boolean'];
  block13: Scalars['Boolean'];
  block14: Scalars['Boolean'];
  block15: Scalars['Boolean'];
  block16: Scalars['Boolean'];
  block17: Scalars['Boolean'];
  block18: Scalars['Boolean'];
  block19: Scalars['Boolean'];
  block20: Scalars['Boolean'];
  block21: Scalars['Boolean'];
  block22: Scalars['Boolean'];
  block23: Scalars['Boolean'];
  blockDay: Scalars['Boolean'];
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
  facilityAvailability: Array<InputMaybe<AdminBookingBlackoutCreateFacilityAvailabilityInput>>;
};

export type AdminBookingBlackoutCoach = {
  __typename?: 'AdminBookingBlackoutCoach';
  block08: Scalars['Boolean'];
  block09: Scalars['Boolean'];
  block10: Scalars['Boolean'];
  block11: Scalars['Boolean'];
  block12: Scalars['Boolean'];
  block13: Scalars['Boolean'];
  block14: Scalars['Boolean'];
  block15: Scalars['Boolean'];
  block16: Scalars['Boolean'];
  block17: Scalars['Boolean'];
  block18: Scalars['Boolean'];
  block19: Scalars['Boolean'];
  block20: Scalars['Boolean'];
  block21: Scalars['Boolean'];
  coachDisplayName: Scalars['String'];
  coachSlug: Scalars['String'];
  date: Scalars['String'];
  isDayBlocked: Scalars['Boolean'];
};

export type AdminBookingBlackoutCoachArgs = {
  coachSlug: Scalars['String'];
  date: Scalars['String'];
};

export type AdminBookingBlackoutCoachUpdateInput = {
  block08: Scalars['Boolean'];
  block09: Scalars['Boolean'];
  block10: Scalars['Boolean'];
  block11: Scalars['Boolean'];
  block12: Scalars['Boolean'];
  block13: Scalars['Boolean'];
  block14: Scalars['Boolean'];
  block15: Scalars['Boolean'];
  block16: Scalars['Boolean'];
  block17: Scalars['Boolean'];
  block18: Scalars['Boolean'];
  block19: Scalars['Boolean'];
  block20: Scalars['Boolean'];
  block21: Scalars['Boolean'];
  coachSlug: Scalars['String'];
  date: Scalars['String'];
  isDayBlocked: Scalars['Boolean'];
};

export type AdminBookingBlackoutCreate = {
  __typename?: 'AdminBookingBlackoutCreate';
  errorMessage: Scalars['String'];
  isCreateAllowed: Scalars['Boolean'];
};

export type AdminBookingBlackoutCreateFacilityAvailabilityInput = {
  facilityCode: Scalars['String'];
  isBlocked: Scalars['Boolean'];
  time: Scalars['String'];
};

export type AdminBookingBlackoutCreateInput = {
  clubhouseCode?: InputMaybe<Scalars['String']>;
  coachSlug?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  date: Scalars['String'];
  type: AdminBookingBlackoutCreateType;
};

export enum AdminBookingBlackoutCreateType {
  Clubhouse = 'CLUBHOUSE',
  Coach = 'COACH',
  Country = 'COUNTRY'
}

export type AdminBookingBlackoutDeleteInput = {
  clubhouseCode?: InputMaybe<Scalars['String']>;
  coachSlug?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<CountryCode>;
  date: Scalars['String'];
  type?: InputMaybe<AdminBookingBlackoutCreateType>;
};

export type AdminBookingBlackoutsArgs = {
  temp?: InputMaybe<Scalars['String']>;
};

export type AdminBookingBookingFacilityUpdateInput = {
  bookingId: Scalars['String'];
  facilityCode: Scalars['String'];
};

export type AdminBookingCancelInput = {
  bookingId: Scalars['String'];
  cancellationReason?: InputMaybe<Scalars['String']>;
  groupCancel?: InputMaybe<Scalars['Boolean']>;
  imposePenalty?: InputMaybe<Scalars['Boolean']>;
  isSameDay?: InputMaybe<Scalars['Boolean']>;
  nextHour?: InputMaybe<Scalars['String']>;
  notificationMessage?: InputMaybe<Scalars['String']>;
  notifyByApp: Scalars['Boolean'];
  notifyByEmail: Scalars['Boolean'];
  notifyBySms: Scalars['Boolean'];
  removeFromSystem?: InputMaybe<Scalars['Boolean']>;
};

export type AdminBookingCheckout = {
  __typename?: 'AdminBookingCheckout';
  amountInAceCoins: Scalars['String'];
  amountInCredit: Scalars['String'];
  amountInLocalCurrency?: Maybe<Scalars['String']>;
  balanceBefore: Scalars['String'];
  description: Scalars['String'];
  rateInAceCoins: Scalars['String'];
  rateInLocalCurrency?: Maybe<Scalars['String']>;
};

export type AdminBookingCoach = {
  __typename?: 'AdminBookingCoach';
  baseCountryCode?: Maybe<CountryCode>;
  coachPublished?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type AdminBookingCoachAvailabilityCalendarItems = {
  __typename?: 'AdminBookingCoachAvailabilityCalendarItems';
  calendarItems: Array<Maybe<CoachAvailabilityCalendarItems>>;
  clubhouses: Array<Maybe<CoachClubhouse>>;
};

export type AdminBookingCoachAvailabilityCalendarItemsArgs = {
  coachId: Scalars['String'];
  currentDate: Scalars['String'];
  mode: CoachAvailabilityCalendarItemsMode;
};

export type AdminBookingCoachAvailabilityDayItem = {
  __typename?: 'AdminBookingCoachAvailabilityDayItem';
  bi0800?: Maybe<Scalars['String']>;
  bi0900?: Maybe<Scalars['String']>;
  bi1000?: Maybe<Scalars['String']>;
  bi1100?: Maybe<Scalars['String']>;
  bi1200?: Maybe<Scalars['String']>;
  bi1300?: Maybe<Scalars['String']>;
  bi1400?: Maybe<Scalars['String']>;
  bi1500?: Maybe<Scalars['String']>;
  bi1600?: Maybe<Scalars['String']>;
  bi1700?: Maybe<Scalars['String']>;
  bi1800?: Maybe<Scalars['String']>;
  bi1900?: Maybe<Scalars['String']>;
  bi2000?: Maybe<Scalars['String']>;
  bi2100?: Maybe<Scalars['String']>;
  blockedClubhousesDay: Array<Maybe<Scalars['String']>>;
  blockedClubhousesHours: Array<Maybe<Scalars['String']>>;
  bs0800?: Maybe<Scalars['String']>;
  bs0900?: Maybe<Scalars['String']>;
  bs1000?: Maybe<Scalars['String']>;
  bs1100?: Maybe<Scalars['String']>;
  bs1200?: Maybe<Scalars['String']>;
  bs1300?: Maybe<Scalars['String']>;
  bs1400?: Maybe<Scalars['String']>;
  bs1500?: Maybe<Scalars['String']>;
  bs1600?: Maybe<Scalars['String']>;
  bs1700?: Maybe<Scalars['String']>;
  bs1800?: Maybe<Scalars['String']>;
  bs1900?: Maybe<Scalars['String']>;
  bs2000?: Maybe<Scalars['String']>;
  bs2100?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  l0800?: Maybe<Scalars['String']>;
  l0900?: Maybe<Scalars['String']>;
  l1000?: Maybe<Scalars['String']>;
  l1100?: Maybe<Scalars['String']>;
  l1200?: Maybe<Scalars['String']>;
  l1300?: Maybe<Scalars['String']>;
  l1400?: Maybe<Scalars['String']>;
  l1500?: Maybe<Scalars['String']>;
  l1600?: Maybe<Scalars['String']>;
  l1700?: Maybe<Scalars['String']>;
  l1800?: Maybe<Scalars['String']>;
  l1900?: Maybe<Scalars['String']>;
  l2000?: Maybe<Scalars['String']>;
  l2100?: Maybe<Scalars['String']>;
};

export type AdminBookingCoachAvailabilityDayItemArgs = {
  dateId: Scalars['String'];
};

export type AdminBookingCoachAvailabilitySlotItem = {
  __typename?: 'AdminBookingCoachAvailabilitySlotItem';
  hr1?: Maybe<SlotItem>;
  hr2?: Maybe<SlotItem>;
};

export type AdminBookingCoachAvailabilitySlotItemArgs = {
  clubhouseCode: Scalars['String'];
  dateId: Scalars['String'];
  ignoreCoachAvailability?: InputMaybe<Scalars['Boolean']>;
  time: Scalars['String'];
};

export type AdminBookingCombinedCoachAvailabilityDayItem = {
  __typename?: 'AdminBookingCombinedCoachAvailabilityDayItem';
  bi0800?: Maybe<Scalars['String']>;
  bi0900?: Maybe<Scalars['String']>;
  bi1000?: Maybe<Scalars['String']>;
  bi1100?: Maybe<Scalars['String']>;
  bi1200?: Maybe<Scalars['String']>;
  bi1300?: Maybe<Scalars['String']>;
  bi1400?: Maybe<Scalars['String']>;
  bi1500?: Maybe<Scalars['String']>;
  bi1600?: Maybe<Scalars['String']>;
  bi1700?: Maybe<Scalars['String']>;
  bi1800?: Maybe<Scalars['String']>;
  bi1900?: Maybe<Scalars['String']>;
  bi2000?: Maybe<Scalars['String']>;
  bi2100?: Maybe<Scalars['String']>;
  biOutdoor?: Maybe<Scalars['String']>;
  bs0800?: Maybe<Scalars['String']>;
  bs0900?: Maybe<Scalars['String']>;
  bs1000?: Maybe<Scalars['String']>;
  bs1100?: Maybe<Scalars['String']>;
  bs1200?: Maybe<Scalars['String']>;
  bs1300?: Maybe<Scalars['String']>;
  bs1400?: Maybe<Scalars['String']>;
  bs1500?: Maybe<Scalars['String']>;
  bs1600?: Maybe<Scalars['String']>;
  bs1700?: Maybe<Scalars['String']>;
  bs1800?: Maybe<Scalars['String']>;
  bs1900?: Maybe<Scalars['String']>;
  bs2000?: Maybe<Scalars['String']>;
  bs2100?: Maybe<Scalars['String']>;
  clubhouses: Array<Maybe<CoachClubhouse>>;
  date?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  hasOutdoorBooking?: Maybe<Scalars['Boolean']>;
  l0800?: Maybe<Scalars['String']>;
  l0900?: Maybe<Scalars['String']>;
  l1000?: Maybe<Scalars['String']>;
  l1100?: Maybe<Scalars['String']>;
  l1200?: Maybe<Scalars['String']>;
  l1300?: Maybe<Scalars['String']>;
  l1400?: Maybe<Scalars['String']>;
  l1500?: Maybe<Scalars['String']>;
  l1600?: Maybe<Scalars['String']>;
  l1700?: Maybe<Scalars['String']>;
  l1800?: Maybe<Scalars['String']>;
  l1900?: Maybe<Scalars['String']>;
  l2000?: Maybe<Scalars['String']>;
  l2100?: Maybe<Scalars['String']>;
};

export type AdminBookingCombinedCoachAvailabilityDayItemArgs = {
  coachId: Scalars['String'];
  dateId: Scalars['String'];
};

export type AdminBookingEdge = {
  __typename?: 'AdminBookingEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminBookingBasic>;
};

export type AdminBookingFacilityAvailabilityCalendarItems = {
  __typename?: 'AdminBookingFacilityAvailabilityCalendarItems';
  calendarItems: Array<Maybe<FacilityAvailabilityCalendarItems>>;
};

export type AdminBookingFacilityAvailabilityCalendarItemsArgs = {
  clubhouseCode: Scalars['String'];
  currentDate: Scalars['String'];
};

export type AdminBookingFacilityAvailabilityDayItem = {
  __typename?: 'AdminBookingFacilityAvailabilityDayItem';
  nonPutting?: Maybe<NonPuttingFacilityDayItem>;
  putting?: Maybe<PuttingFacilityDayItem>;
};

export type AdminBookingFacilityAvailabilityDayItemArgs = {
  dateId: Scalars['String'];
};

export type AdminBookingFacilityAvailabilitySlotItem = {
  __typename?: 'AdminBookingFacilityAvailabilitySlotItem';
  hr1?: Maybe<SlotItem>;
  hr2?: Maybe<SlotItem>;
  hr3?: Maybe<SlotItem>;
  hr4?: Maybe<SlotItem>;
  hr5?: Maybe<SlotItem>;
  putting30?: Maybe<SlotItem>;
  putting60?: Maybe<SlotItem>;
};

export type AdminBookingFacilityAvailabilitySlotItemArgs = {
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
  isPutting: Scalars['Boolean'];
  time: Scalars['String'];
};

export type AdminBookingGameCheckoutArgs = {
  duration: FacilityUsageDuration;
  facilityType: FacilityType;
  gameType: GameType;
  memberUuid: Scalars['String'];
  noOfPlayers: Scalars['Int'];
};

export type AdminBookingGameCreate = {
  __typename?: 'AdminBookingGameCreate';
  bookingId: Scalars['String'];
  status: SystemOperationStatus;
};

export type AdminBookingGameCreateInput = {
  clubhouseCode: Scalars['String'];
  commentAdminToMember?: InputMaybe<Scalars['String']>;
  commentInternal?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  duration: FacilityUsageDuration;
  facilityCode: Scalars['String'];
  gameType: GameType;
  isTrial: Scalars['Boolean'];
  memberUuid?: InputMaybe<Scalars['String']>;
  noOfPlayers: Scalars['Int'];
  notificationMessage?: InputMaybe<Scalars['String']>;
  notifyByApp: Scalars['Boolean'];
  notifyByEmail: Scalars['Boolean'];
  notifyBySms: Scalars['Boolean'];
  rebookId?: InputMaybe<Scalars['String']>;
  time: Scalars['String'];
};

export type AdminBookingGroupMember = {
  __typename?: 'AdminBookingGroupMember';
  individualId: Scalars['String'];
  memberName: Scalars['String'];
  memberUuid: Scalars['String'];
  selfPackageId: Scalars['String'];
  selfPackageName: Scalars['String'];
};

export type AdminBookingGroupMembers = {
  __typename?: 'AdminBookingGroupMembers';
  members: Array<Maybe<AdminBookingGroupMember>>;
};

export type AdminBookingGroupMembersArgs = {
  membershipUuid: Scalars['String'];
  serviceId?: InputMaybe<Scalars['String']>;
};

export type AdminBookingLessonIndoorCheckoutArgs = {
  coachId: Scalars['String'];
  isLessonExtended: Scalars['Boolean'];
  lessonType: IndoorLessonType;
  memberUuid: Scalars['String'];
};

export type AdminBookingLessonIndoorCreate = {
  __typename?: 'AdminBookingLessonIndoorCreate';
  bookingId: Scalars['String'];
  status: SystemOperationStatus;
};

export type AdminBookingLessonIndoorCreateInput = {
  clubhouseCode: Scalars['String'];
  coachSlug: Scalars['String'];
  commentAdminToCoach?: InputMaybe<Scalars['String']>;
  commentAdminToMember?: InputMaybe<Scalars['String']>;
  commentInternal?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  facilityCode: Scalars['String'];
  isTrial: Scalars['Boolean'];
  isTwoHours: Scalars['Boolean'];
  memberUuid?: InputMaybe<Scalars['String']>;
  notificationMessage?: InputMaybe<Scalars['String']>;
  notifyByApp: Scalars['Boolean'];
  notifyByEmail: Scalars['Boolean'];
  notifyBySms: Scalars['Boolean'];
  partner?: InputMaybe<GroupLessonPartner>;
  time: Scalars['String'];
};

export type AdminBookingLessonOutdoorCheckoutArgs = {
  coachId: Scalars['String'];
  memberUuid: Scalars['String'];
};

export type AdminBookingLessonOutdoorCreate = {
  __typename?: 'AdminBookingLessonOutdoorCreate';
  bookingId: Scalars['String'];
  status: SystemOperationStatus;
};

export type AdminBookingLessonOutdoorCreateInput = {
  coachSlug: Scalars['String'];
  commentAdminToCoach?: InputMaybe<Scalars['String']>;
  commentAdminToMember?: InputMaybe<Scalars['String']>;
  commentInternal?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  details: Scalars['String'];
  memberUuid?: InputMaybe<Scalars['String']>;
  notificationMessage?: InputMaybe<Scalars['String']>;
  notifyByApp: Scalars['Boolean'];
  notifyByEmail: Scalars['Boolean'];
  notifyBySms: Scalars['Boolean'];
  rebookId?: InputMaybe<Scalars['String']>;
  teeTime: Scalars['String'];
  venue: Scalars['String'];
};

export type AdminBookingPractiseCheckoutArgs = {
  duration: FacilityUsageDuration;
  facilityType: FacilityType;
  memberUuid: Scalars['String'];
  noOfPlayers: Scalars['Int'];
};

export type AdminBookingPractiseCreate = {
  __typename?: 'AdminBookingPractiseCreate';
  bookingId: Scalars['String'];
  status: SystemOperationStatus;
};

export type AdminBookingPractiseCreateInput = {
  clubhouseCode: Scalars['String'];
  commentAdminToMember?: InputMaybe<Scalars['String']>;
  commentInternal?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  duration: FacilityUsageDuration;
  facilityCode: Scalars['String'];
  isTrial: Scalars['Boolean'];
  memberUuid?: InputMaybe<Scalars['String']>;
  notificationMessage?: InputMaybe<Scalars['String']>;
  notifyByApp: Scalars['Boolean'];
  notifyByEmail: Scalars['Boolean'];
  notifyBySms: Scalars['Boolean'];
  rebookId?: InputMaybe<Scalars['String']>;
  time: Scalars['String'];
};

export type AdminBookingResponse = {
  __typename?: 'AdminBookingResponse';
  edges?: Maybe<Array<Maybe<AdminBookingEdge>>>;
  firstBookingDate?: Maybe<Scalars['DateTime']>;
  lastBookingDate?: Maybe<Scalars['DateTime']>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminBookingSendCancellationNotificationInput = {
  bookingId: Scalars['String'];
  notificationMessage?: InputMaybe<Scalars['String']>;
  notifyByApp: Scalars['Boolean'];
  notifyByEmail: Scalars['Boolean'];
  notifyBySms: Scalars['Boolean'];
};

export type AdminBookingSettings = {
  __typename?: 'AdminBookingSettings';
  cancellationConditions: CancellationConditions;
};

export type AdminBookingSettingsArgs = {
  bookingId: Scalars['String'];
};

export type AdminBookingUpdateInput = {
  bookingId: Scalars['String'];
  commentAdminToCoach?: InputMaybe<Scalars['String']>;
  commentAdminToMember?: InputMaybe<Scalars['String']>;
  commentInternal?: InputMaybe<Scalars['String']>;
  fieldLessonDetails?: InputMaybe<Scalars['String']>;
  groupBookingId?: InputMaybe<Scalars['String']>;
};

export type AdminBookingUpdateToGroupInput = {
  bookingId: Scalars['String'];
  facilityCode: Scalars['String'];
  notificationMessage?: InputMaybe<Scalars['String']>;
  notifyByApp: Scalars['Boolean'];
  notifyByEmail: Scalars['Boolean'];
  notifyBySms: Scalars['Boolean'];
  partnerMemberUuid: Scalars['String'];
};

export type AdminBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  bookingIdFilter?: InputMaybe<Scalars['String']>;
  bookingType?: InputMaybe<BookingType>;
  coachId?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  memberUuid?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BookingStatus>;
  venueFilter?: InputMaybe<Scalars['String']>;
};

export type AdminClientLoginGuestInput = {
  accessToken: Scalars['String'];
  clientGuid: Scalars['String'];
  nickName: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminClientSetupInput = {
  clubhouseCode?: InputMaybe<Scalars['String']>;
};

export type AdminClientStatusArgs = {
  accessToken: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminClubhouse = {
  __typename?: 'AdminClubhouse';
  addressCity?: Maybe<Scalars['String']>;
  addressCountry?: Maybe<Scalars['String']>;
  addressCountryIso?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressStreet1?: Maybe<Scalars['String']>;
  addressStreet2?: Maybe<Scalars['String']>;
  clubhouseCode?: Maybe<Scalars['String']>;
  clubhouseId: Scalars['String'];
  colorCode?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  kGolfClientId?: Maybe<Scalars['String']>;
  kGolfClientSecret?: Maybe<Scalars['String']>;
  kGolfPassword?: Maybe<Scalars['String']>;
  kGolfShopGuid?: Maybe<Scalars['String']>;
  kGolfUsername?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  noOfPrivateRooms?: Maybe<Scalars['Int']>;
  noOfPuttingGreen?: Maybe<Scalars['Int']>;
  noOfSimulators?: Maybe<Scalars['Int']>;
  organization: ClubhouseOrganization;
  organizationId: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminClubhouseArgs = {
  clubhouseId: Scalars['String'];
};

export type AdminClubhouseBasic = {
  __typename?: 'AdminClubhouseBasic';
  addressCountryIso?: Maybe<CountryCode>;
  clubhouseCode: Scalars['String'];
  clubhouseId: Scalars['String'];
  name: Scalars['String'];
};

export type AdminClubhouseEdge = {
  __typename?: 'AdminClubhouseEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminClubhouseBasic>;
};

export type AdminClubhouseFacilitiesArgs = {
  shopGuid: Scalars['String'];
};

export type AdminClubhouseFacilityAvailability = {
  __typename?: 'AdminClubhouseFacilityAvailability';
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
  facilityObjs: Array<Maybe<AdminClubhouseFacilityAvailabilityFacilityObj>>;
  isDayBlocked: Scalars['Boolean'];
  name: Scalars['String'];
};

export type AdminClubhouseFacilityAvailabilityArgs = {
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
};

export type AdminClubhouseFacilityAvailabilityFacilityObj = {
  __typename?: 'AdminClubhouseFacilityAvailabilityFacilityObj';
  availability: AdminClubhouseFacilityAvailabilityFacilityObjAvailability;
  facilityCode: Scalars['String'];
  facilityName: Scalars['String'];
  facilityType: FacilityType;
};

export type AdminClubhouseFacilityAvailabilityFacilityObjAvailability = {
  __typename?: 'AdminClubhouseFacilityAvailabilityFacilityObjAvailability';
  bi0800?: Maybe<Scalars['String']>;
  bi0830?: Maybe<Scalars['String']>;
  bi0900?: Maybe<Scalars['String']>;
  bi0930?: Maybe<Scalars['String']>;
  bi1000?: Maybe<Scalars['String']>;
  bi1030?: Maybe<Scalars['String']>;
  bi1100?: Maybe<Scalars['String']>;
  bi1130?: Maybe<Scalars['String']>;
  bi1200?: Maybe<Scalars['String']>;
  bi1230?: Maybe<Scalars['String']>;
  bi1300?: Maybe<Scalars['String']>;
  bi1330?: Maybe<Scalars['String']>;
  bi1400?: Maybe<Scalars['String']>;
  bi1430?: Maybe<Scalars['String']>;
  bi1500?: Maybe<Scalars['String']>;
  bi1530?: Maybe<Scalars['String']>;
  bi1600?: Maybe<Scalars['String']>;
  bi1630?: Maybe<Scalars['String']>;
  bi1700?: Maybe<Scalars['String']>;
  bi1730?: Maybe<Scalars['String']>;
  bi1800?: Maybe<Scalars['String']>;
  bi1830?: Maybe<Scalars['String']>;
  bi1900?: Maybe<Scalars['String']>;
  bi1930?: Maybe<Scalars['String']>;
  bi2000?: Maybe<Scalars['String']>;
  bi2030?: Maybe<Scalars['String']>;
  bi2100?: Maybe<Scalars['String']>;
  bi2130?: Maybe<Scalars['String']>;
  bi2200?: Maybe<Scalars['String']>;
  bi2230?: Maybe<Scalars['String']>;
  bi2300?: Maybe<Scalars['String']>;
  bi2330?: Maybe<Scalars['String']>;
};

export type AdminClubhouseResponse = {
  __typename?: 'AdminClubhouseResponse';
  edges?: Maybe<Array<Maybe<AdminClubhouseEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminClubhousesArgs = {
  after?: InputMaybe<Scalars['String']>;
  countryFilter?: InputMaybe<CountryCode>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type AdminCoach = {
  __typename?: 'AdminCoach';
  baseCountryCode?: Maybe<CountryCode>;
  clubhouses?: Maybe<Array<Maybe<CoachClubhouse>>>;
  coachBirthPlace?: Maybe<Scalars['String']>;
  coachBirthdate?: Maybe<Scalars['String']>;
  coachExperiences?: Maybe<Scalars['JSON']>;
  coachExpertise?: Maybe<Array<Maybe<Scalars['String']>>>;
  coachLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  coachLevel?: Maybe<CoachLevel>;
  coachListOrder?: Maybe<Scalars['Int']>;
  coachPGANo?: Maybe<Scalars['String']>;
  coachPGAType?: Maybe<Scalars['String']>;
  coachPGAYear?: Maybe<Scalars['String']>;
  coachPublished?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Gender>;
  lastAccessTokenDate?: Maybe<Scalars['DateTime']>;
  lastLogin?: Maybe<LoginInfo>;
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  logins: Array<Maybe<LoginInfo>>;
  mobileNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<AdminOrganization>;
  profileImageUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status?: Maybe<AdminStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  userType: UserType;
};

export type AdminCoachArgs = {
  userId: Scalars['String'];
};

export type AdminCoachAvailabilityCalendarItemsArgs = {
  coachId: Scalars['String'];
  currentDate: Scalars['String'];
};

export type AdminCoachBasic = {
  __typename?: 'AdminCoachBasic';
  baseCountryCode?: Maybe<CountryCode>;
  coachPublished?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastAccessTokenDate?: Maybe<Scalars['DateTime']>;
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  organization?: Maybe<AdminOrganization>;
  profileImageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<AdminStatus>;
  userId: Scalars['String'];
  userName: Scalars['String'];
};

export type AdminCoachCombinedAvailability = {
  __typename?: 'AdminCoachCombinedAvailability';
  coachId: Scalars['String'];
  coachSlug: Scalars['String'];
  dateObjs: Array<Maybe<AdminCoachCombinedAvailabilityDateObj>>;
  name: Scalars['String'];
};

export type AdminCoachCombinedAvailabilityArgs = {
  currentDate: Scalars['String'];
};

export type AdminCoachCombinedAvailabilityDateObj = {
  __typename?: 'AdminCoachCombinedAvailabilityDateObj';
  clubhouses: Array<Maybe<Scalars['String']>>;
  date: Scalars['String'];
  hasIndoorBooking: Scalars['Boolean'];
  hasOutdoorBooking: Scalars['Boolean'];
  isDayBlocked: Scalars['Boolean'];
};

export type AdminCoachEdge = {
  __typename?: 'AdminCoachEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminCoachBasic>;
};

export type AdminCoachResponse = {
  __typename?: 'AdminCoachResponse';
  edges?: Maybe<Array<Maybe<AdminCoachEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminCoachesArgs = {
  after?: InputMaybe<Scalars['String']>;
  countryFilter?: InputMaybe<CountryCode>;
  first?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type AdminCreateAdminInput = {
  authorizedClubhouses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  baseCountryCode?: InputMaybe<CountryCode>;
  displayName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  profileImageFile?: InputMaybe<File>;
  userType?: InputMaybe<UserType>;
};

export type AdminCreateMembershipInput = {
  clubhouseCode?: InputMaybe<Scalars['String']>;
  companyContactPersonDesignation?: InputMaybe<Scalars['String']>;
  companyContactPersonEmail?: InputMaybe<Scalars['String']>;
  companyContactPersonFirstName?: InputMaybe<Scalars['String']>;
  companyContactPersonLastName?: InputMaybe<Scalars['String']>;
  companyIndustry?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  mainAceCoinRatio?: InputMaybe<AceCoinRatioInput>;
  mainMembers?: InputMaybe<Array<InputMaybe<CreateMemberInput>>>;
  membershipSlug: Scalars['String'];
  paymentOption: AdminCreateMembershipPaymentOption;
  paymentRef?: InputMaybe<Scalars['String']>;
  reservedId?: InputMaybe<Scalars['String']>;
  subAceCoinRatio?: InputMaybe<AceCoinRatioInput>;
  subMembers?: InputMaybe<Array<InputMaybe<CreateMemberInput>>>;
};

export enum AdminCreateMembershipPaymentOption {
  BankTransfer = 'BANK_TRANSFER',
  Cash = 'CASH',
  CreditCard = 'CREDIT_CARD',
  Hitpay = 'HITPAY',
  Later = 'LATER',
  Na = 'NA',
  Paynow = 'PAYNOW',
  PosEmenu = 'POS_EMENU'
}

export type AdminCreditTransactionEdge = {
  __typename?: 'AdminCreditTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CreditTransaction>;
};

export type AdminCreditTransactionResponse = {
  __typename?: 'AdminCreditTransactionResponse';
  edges?: Maybe<Array<Maybe<AdminCreditTransactionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminCreditTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  orderBy?: InputMaybe<OrderBy>;
};

export type AdminDeleteAdminInput = {
  userId: Scalars['String'];
};

export type AdminDeleteCoachInput = {
  userId: Scalars['String'];
};

export type AdminDeleteMembershipInput = {
  membershipUuid: Scalars['String'];
};

export type AdminEdge = {
  __typename?: 'AdminEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminBasic>;
};

export type AdminExtendClientTimeInput = {
  accessToken: Scalars['String'];
  clientGuid: Scalars['String'];
  shopGuid: Scalars['String'];
  timeInSeconds: Scalars['Int'];
};

export type AdminForgotPasswordInput = {
  email: Scalars['String'];
};

export type AdminForgotPasswordResponse = {
  __typename?: 'AdminForgotPasswordResponse';
  email: Scalars['String'];
  resetToken: Scalars['String'];
};

export type AdminForgotPasswordSubmitInput = {
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type AdminGameHistory = {
  __typename?: 'AdminGameHistory';
  currentPage: Scalars['Int'];
  list: Array<Maybe<AdminGameHistoryListItem>>;
  maxPage: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AdminGameHistoryArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  bays?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  gameTypeField?: InputMaybe<Scalars['Boolean']>;
  gameTypeFourSome?: InputMaybe<Scalars['Boolean']>;
  gameTypePutting?: InputMaybe<Scalars['Boolean']>;
  gameTypeShot?: InputMaybe<Scalars['Boolean']>;
  gameTypeShotContest?: InputMaybe<Scalars['Boolean']>;
  gameTypeStroke?: InputMaybe<Scalars['Boolean']>;
  nickName?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type AdminGameHistoryListItem = {
  __typename?: 'AdminGameHistoryListItem';
  clientName: Scalars['String'];
  completeHoleCount: Scalars['Int'];
  endTime?: Maybe<Scalars['Float']>;
  gameRoundId: Scalars['Int'];
  gameType: Scalars['String'];
  gameTypeCode: Scalars['Int'];
  playerCount: Scalars['Int'];
  startTime: Scalars['Float'];
};

export type AdminGameRoundScoreCard = {
  __typename?: 'AdminGameRoundScoreCard';
  gameRoundInfo: GameRoundGameInfo;
  players: Array<Maybe<GameRoundPlayerInfo>>;
};

export type AdminGroupBooking = {
  __typename?: 'AdminGroupBooking';
  clubhouseCode?: Maybe<Scalars['String']>;
  coachName?: Maybe<Scalars['String']>;
  commentAdminToCoach?: Maybe<Scalars['String']>;
  commentAdminToMember?: Maybe<Scalars['String']>;
  commentInternal?: Maybe<Scalars['String']>;
  dateMonthStr?: Maybe<Scalars['String']>;
  dateStr?: Maybe<Scalars['String']>;
  dayOfWeekStr?: Maybe<Scalars['String']>;
  durationStr?: Maybe<Scalars['String']>;
  endTimeStr: Scalars['String'];
  facilityMode?: Maybe<Scalars['String']>;
  facilityStartTime?: Maybe<Scalars['String']>;
  facilityType?: Maybe<FacilityType>;
  fieldLessonDetails?: Maybe<Scalars['String']>;
  gameCourseType?: Maybe<GameCourseType>;
  id: Scalars['String'];
  member: Scalars['String'];
  memberIndividualId: Scalars['String'];
  mobileNumber: Scalars['String'];
  noOfPlayers: Scalars['Int'];
  resourceName?: Maybe<Scalars['String']>;
  startTimeStr?: Maybe<Scalars['String']>;
  type: BookingType;
  venue?: Maybe<Scalars['String']>;
  yearStr?: Maybe<Scalars['String']>;
};

export type AdminGroupBookingsArgs = {
  bookingIds: Array<Scalars['String']>;
};

export type AdminJwtAccessToken = {
  __typename?: 'AdminJwtAccessToken';
  adminName?: Maybe<Scalars['String']>;
  baseCountryCode?: Maybe<Scalars['String']>;
  clubhouses: Array<Maybe<Scalars['String']>>;
  organizationId?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileImageUrl?: Maybe<Scalars['String']>;
  ud?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AdminJwtRefreshToken = {
  __typename?: 'AdminJwtRefreshToken';
  adminName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileImageUrl?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userType?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type AdminKGolfMember = {
  __typename?: 'AdminKGolfMember';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  cdn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  connectKakao?: Maybe<Scalars['Boolean']>;
  connectWechat?: Maybe<Scalars['Boolean']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  handed?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  isTermsAgreeNotificationEmail?: Maybe<Scalars['Boolean']>;
  isTermsAgreeNotificationSMS?: Maybe<Scalars['Boolean']>;
  mobilePhone?: Maybe<Scalars['String']>;
  mobilePhoneCountry?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  profilePath?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AdminKGolfMemberArgs = {
  memberUuid: Scalars['String'];
};

export type AdminLockClientInput = {
  accessToken: Scalars['String'];
  clientGuid: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminLoginInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AdminManagementSystemProps = {
  __typename?: 'AdminManagementSystemProps';
  currentAppVersion: Scalars['String'];
};

export type AdminManagementSystemReportingInput = {
  action: Scalars['String'];
  payload?: InputMaybe<Scalars['JSON']>;
};

export type AdminManagementSystemSupportTicket = {
  __typename?: 'AdminManagementSystemSupportTicket';
  approvedBy?: Maybe<Scalars['String']>;
  body: Scalars['String'];
  bodyUpdatedAt?: Maybe<Scalars['DateTime']>;
  category?: Maybe<SupportTicketCategory>;
  dateClosed?: Maybe<Scalars['DateTime']>;
  dateOpened?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  manHoursRequired: Scalars['Int'];
  openedBy: Scalars['String'];
  priority?: Maybe<SupportTicketPriority>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<SupportTicketStatus>;
  title: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  userProfileImageUrl?: Maybe<Scalars['String']>;
};

export type AdminManagementSystemSupportTicketArgs = {
  id: Scalars['String'];
};

export type AdminManagementSystemSupportTicketBasic = {
  __typename?: 'AdminManagementSystemSupportTicketBasic';
  dateClosed?: Maybe<Scalars['DateTime']>;
  dateOpened?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  initiatorId?: Maybe<Scalars['String']>;
  initiatorName?: Maybe<Scalars['String']>;
  priority?: Maybe<SupportTicketPriority>;
  status?: Maybe<SupportTicketStatus>;
  title?: Maybe<Scalars['String']>;
};

export type AdminManagementSystemSupportTicketComment = {
  __typename?: 'AdminManagementSystemSupportTicketComment';
  editedOn?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  message: Scalars['String'];
  postedOn: Scalars['DateTime'];
  userId: Scalars['String'];
  userName: Scalars['String'];
  userProfileImageUrl?: Maybe<Scalars['String']>;
};

export type AdminManagementSystemSupportTicketCommentAddInput = {
  message: Scalars['String'];
  supportTicketId: Scalars['String'];
};

export type AdminManagementSystemSupportTicketCommentDeleteInput = {
  commentId: Scalars['String'];
};

export type AdminManagementSystemSupportTicketCommentUpdateInput = {
  commentId: Scalars['String'];
  message?: InputMaybe<Scalars['String']>;
  supportTicketId?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type AdminManagementSystemSupportTicketCommentsArgs = {
  supportTicketId: Scalars['String'];
};

export type AdminManagementSystemSupportTicketCreate = {
  __typename?: 'AdminManagementSystemSupportTicketCreate';
  id: Scalars['String'];
};

export type AdminManagementSystemSupportTicketCreateInput = {
  body: Scalars['String'];
  category: SupportTicketCategory;
  priority: SupportTicketPriority;
  title: Scalars['String'];
  userId: Scalars['String'];
};

export type AdminManagementSystemSupportTicketDeleteInput = {
  supportTicketId: Scalars['String'];
};

export type AdminManagementSystemSupportTicketEdge = {
  __typename?: 'AdminManagementSystemSupportTicketEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminManagementSystemSupportTicketBasic>;
};

export type AdminManagementSystemSupportTicketResponse = {
  __typename?: 'AdminManagementSystemSupportTicketResponse';
  edges?: Maybe<Array<Maybe<AdminManagementSystemSupportTicketEdge>>>;
  firstTicketDate?: Maybe<Scalars['DateTime']>;
  lastTicketDate?: Maybe<Scalars['DateTime']>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminManagementSystemSupportTicketUpdateInput = {
  body?: InputMaybe<Scalars['String']>;
  category?: InputMaybe<SupportTicketCategory>;
  manHour?: InputMaybe<Scalars['Int']>;
  priority?: InputMaybe<SupportTicketPriority>;
  status?: InputMaybe<SupportTicketStatus>;
  supportTicketId: Scalars['String'];
  title?: InputMaybe<Scalars['String']>;
  userId?: InputMaybe<Scalars['String']>;
};

export type AdminManagementSystemSupportTicketUser = {
  __typename?: 'AdminManagementSystemSupportTicketUser';
  userId: Scalars['String'];
  userName: Scalars['String'];
};

export type AdminManagementSystemSupportTicketsArgs = {
  after?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<Scalars['String']>;
};

export type AdminManagementSystemUserTelemetryInput = {
  pageName: Scalars['String'];
  resourceId?: InputMaybe<Scalars['String']>;
  resourceName?: InputMaybe<Scalars['String']>;
  resourceType?: InputMaybe<UserTelemetryResourceType>;
  ts?: InputMaybe<Scalars['String']>;
};

export type AdminMember = {
  __typename?: 'AdminMember';
  accountType?: Maybe<AccountType>;
  activationLineItems: Array<Maybe<AdminMemberActivationLineItem>>;
  activationOrderNo: Scalars['String'];
  activationPrice: Scalars['Float'];
  addressCity?: Maybe<Scalars['String']>;
  addressCountry?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressStreet1?: Maybe<Scalars['String']>;
  addressStreet2?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  companyContactPersonEmail?: Maybe<Scalars['String']>;
  companyContactPersonFirstName?: Maybe<Scalars['String']>;
  companyContactPersonLastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<CreatedBy>;
  createdByAdminId?: Maybe<Scalars['String']>;
  createdByAdminName?: Maybe<Scalars['String']>;
  credit: Scalars['Float'];
  creditSpent: Scalars['Float'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gameCredit: Scalars['Float'];
  gender?: Maybe<Gender>;
  handed: Handed;
  hasGroupMembers: Scalars['Boolean'];
  hasSimulatorPrivilege: Scalars['Boolean'];
  hasSubscriptions: Scalars['Boolean'];
  individualId: Scalars['String'];
  internalAccountType?: Maybe<InternalAccountType>;
  isSimulatorUseRedeemed: Scalars['Boolean'];
  kGolfAccountCreationError?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  locker: Scalars['Boolean'];
  memberStatus: MemberStatus;
  memberUuid: Scalars['String'];
  members: Array<Maybe<AdminMemberMember>>;
  membershipStatus: MembershipStatus;
  membershipType?: Maybe<MembershipTypeBasic>;
  membershipUuid: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  partialNricOrFin?: Maybe<Scalars['String']>;
  practiseCredit: Scalars['Float'];
  profileImageUrl?: Maybe<Scalars['String']>;
  pushTokens: Array<Maybe<Scalars['String']>>;
  relationshipToMainMember?: Maybe<Relationship>;
  serviceNote?: Maybe<Scalars['String']>;
  signUpSidasInsoles: Scalars['Int'];
  startDate?: Maybe<Scalars['DateTime']>;
  termEndDate?: Maybe<Scalars['DateTime']>;
  voucherField: Scalars['Float'];
  voucherIndoor: Scalars['Float'];
};

export type AdminMemberAcTransactionEdge = {
  __typename?: 'AdminMemberAcTransactionEdge';
  cursor?: Maybe<Scalars['Int']>;
  node?: Maybe<AceCoinTransaction>;
};

export type AdminMemberAcTransactionResponse = {
  __typename?: 'AdminMemberAcTransactionResponse';
  edges?: Maybe<Array<Maybe<AdminMemberAcTransactionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminMemberAcTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  memberUuid?: InputMaybe<Scalars['String']>;
  membershipUuid?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<Scalars['String']>;
  transactionTypeFilter?: InputMaybe<AcTransactionType>;
};

export type AdminMemberActivationLineItem = {
  __typename?: 'AdminMemberActivationLineItem';
  itemName: Scalars['String'];
  salesPrice: Scalars['Float'];
};

export type AdminMemberArgs = {
  memberUuid: Scalars['String'];
};

export type AdminMemberBdfAddInput = {
  addressCity?: InputMaybe<Scalars['String']>;
  addressCountry: Scalars['String'];
  addressPostalCode: Scalars['String'];
  addressState?: InputMaybe<Scalars['String']>;
  addressStreet1: Scalars['String'];
  addressStreet2?: InputMaybe<Scalars['String']>;
  birthdayDay: Scalars['String'];
  birthdayMonth: Scalars['String'];
  birthdayYear: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender: Gender;
  handed: Handed;
  lastName: Scalars['String'];
  membershipUuid: Scalars['String'];
  mobileNumber: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  partialNricOrFin?: InputMaybe<Scalars['String']>;
  paymentOption: AdminCreateMembershipPaymentOption;
  paymentRef?: InputMaybe<Scalars['String']>;
  providedPassword?: InputMaybe<Scalars['String']>;
  relationshipToMainMember?: InputMaybe<Relationship>;
  sameAddressAsMainMember?: InputMaybe<Scalars['Boolean']>;
};

export type AdminMemberBasic = {
  __typename?: 'AdminMemberBasic';
  accountType?: Maybe<AccountType>;
  aceCoins?: Maybe<Scalars['Float']>;
  appVersion?: Maybe<Scalars['String']>;
  companyContactPersonFirstName?: Maybe<Scalars['String']>;
  companyContactPersonLastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  credits?: Maybe<Scalars['Float']>;
  firstName: Scalars['String'];
  hasGroupMembers?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  individualId: Scalars['String'];
  internalAccountType?: Maybe<InternalAccountType>;
  kGolfAccountCreationError?: Maybe<Scalars['Boolean']>;
  lastName: Scalars['String'];
  lessonIndoorCredits?: Maybe<Scalars['Float']>;
  memberName?: Maybe<Scalars['String']>;
  memberStatus?: Maybe<MemberStatus>;
  membershipId: Scalars['String'];
  membershipType?: Maybe<MembershipTypeBasic>;
  practiseCredits?: Maybe<Scalars['Float']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type AdminMemberCreateKGolfAccountInput = {
  memberUuid: Scalars['String'];
};

export type AdminMemberCreditUpdateInput = {
  amount: Scalars['Float'];
  description: Scalars['String'];
  invoiceNo?: InputMaybe<Scalars['String']>;
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentRef?: InputMaybe<Scalars['String']>;
  transactionType: TransactionType;
  type: AceCoinTransactionType;
};

export type AdminMemberEdge = {
  __typename?: 'AdminMemberEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminMemberBasic>;
};

export type AdminMemberInvoiceDeleteInput = {
  invoiceNo: Scalars['String'];
};

export type AdminMemberInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  individualIdFilter?: InputMaybe<Scalars['String']>;
  memberUuidFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  paymentMethodFilter?: InputMaybe<PaymentMethod>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type AdminMemberMember = {
  __typename?: 'AdminMemberMember';
  accountType?: Maybe<AccountType>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  individualId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AdminMemberNotificationEdge = {
  __typename?: 'AdminMemberNotificationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminNotificationBasic>;
};

export type AdminMemberNotificationResponse = {
  __typename?: 'AdminMemberNotificationResponse';
  edges?: Maybe<Array<Maybe<AdminMemberNotificationEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminMemberNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  memberUuidFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type AdminMemberProductGroupPackage = {
  __typename?: 'AdminMemberProductGroupPackage';
  amount: Scalars['Float'];
  individualId: Scalars['String'];
  memberName: Scalars['String'];
  packageName: Scalars['String'];
  serviceId: Scalars['String'];
};

export type AdminMemberProductGroupPackages = {
  __typename?: 'AdminMemberProductGroupPackages';
  packages?: Maybe<Array<Maybe<AdminMemberProductGroupPackage>>>;
};

export type AdminMemberProductGroupPackagesArgs = {
  membershipUuid?: InputMaybe<Scalars['String']>;
  serviceId?: InputMaybe<Scalars['String']>;
};

export type AdminMemberProductPackage = {
  __typename?: 'AdminMemberProductPackage';
  amount: Scalars['Float'];
  comment?: Maybe<Scalars['String']>;
  dateAdded?: Maybe<Scalars['DateTime']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  expiryDateStr: Scalars['String'];
  expiryWarning?: Maybe<Scalars['Boolean']>;
  extensions: Array<Maybe<AdminMemberProductPackageExtension>>;
  groupId?: Maybe<Scalars['String']>;
  groupServices?: Maybe<Array<Maybe<AdminMemberProductGroupPackage>>>;
  hasSupplementaryRedemption: Scalars['Boolean'];
  individualId?: Maybe<Scalars['String']>;
  isDaily: Scalars['Boolean'];
  isDailyRedeemed: Scalars['Boolean'];
  isGroup: Scalars['Boolean'];
  memberName?: Maybe<Scalars['String']>;
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  packageName: Scalars['String'];
  packageType: Scalars['String'];
  parentServiceId?: Maybe<Scalars['String']>;
  serviceId: Scalars['String'];
  subServices?: Maybe<Array<Maybe<AdminMemberProductPackageSubService>>>;
  supplementaryAmount: Scalars['Float'];
  supplementaryUnitName: Scalars['String'];
  transactions: Array<Maybe<ServiceTransactionBasic>>;
  unitType: Scalars['String'];
};

export type AdminMemberProductPackageArgs = {
  serviceId: Scalars['String'];
};

export type AdminMemberProductPackageDeleteInput = {
  serviceId: Scalars['String'];
};

export type AdminMemberProductPackageExtension = {
  __typename?: 'AdminMemberProductPackageExtension';
  authorizedAdjusterName?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  dateExtended?: Maybe<Scalars['DateTime']>;
  daysAdded?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  newExpiryDate?: Maybe<Scalars['DateTime']>;
  prevExpiryDate?: Maybe<Scalars['DateTime']>;
};

export type AdminMemberProductPackageExtensionUpdateInput = {
  comment?: InputMaybe<Scalars['String']>;
  extensionId: Scalars['String'];
};

export type AdminMemberProductPackageExtensions = {
  __typename?: 'AdminMemberProductPackageExtensions';
  extensions?: Maybe<Array<Maybe<AdminMemberProductPackageExtension>>>;
};

export type AdminMemberProductPackageExtensionsArgs = {
  serviceId: Scalars['String'];
};

export type AdminMemberProductPackageGroupInput = {
  mode: AdminMemberProductPackageGroupMode;
  sourceServiceId: Scalars['String'];
  targetServiceId: Scalars['String'];
};

export enum AdminMemberProductPackageGroupMode {
  Link = 'LINK',
  Unlink = 'UNLINK'
}

export type AdminMemberProductPackageManageInput = {
  comment?: InputMaybe<Scalars['String']>;
  mode: AdminMemberProductPackageManageMode;
  newExpiryDate?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['String'];
};

export enum AdminMemberProductPackageManageMode {
  Extend = 'EXTEND',
  Forfeit = 'FORFEIT',
  Update = 'UPDATE'
}

export type AdminMemberProductPackageShareInput = {
  amount: Scalars['Float'];
  comment?: InputMaybe<Scalars['String']>;
  serviceId: Scalars['String'];
  targetMemberUuid: Scalars['String'];
};

export type AdminMemberProductPackageSubService = {
  __typename?: 'AdminMemberProductPackageSubService';
  amount: Scalars['Float'];
  dateAdded: Scalars['DateTime'];
  individualId: Scalars['String'];
  memberName: Scalars['String'];
  memberUuid: Scalars['String'];
  parentServiceId: Scalars['String'];
  serviceId: Scalars['String'];
};

export type AdminMemberProductPackageTransferInput = {
  amount: Scalars['Float'];
  direction: Scalars['String'];
  sourceServiceId: Scalars['String'];
  targetServiceId: Scalars['String'];
};

export type AdminMemberProfileUpdate = {
  __typename?: 'AdminMemberProfileUpdate';
  fileUploadCredentials?: Maybe<FileUploadCredentials>;
  member: AdminMember;
};

export type AdminMemberProfileUpdateInput = {
  addressCity?: InputMaybe<Scalars['String']>;
  addressCountry?: InputMaybe<Scalars['String']>;
  addressPostalCode?: InputMaybe<Scalars['String']>;
  addressState?: InputMaybe<Scalars['String']>;
  addressStreet1?: InputMaybe<Scalars['String']>;
  addressStreet2?: InputMaybe<Scalars['String']>;
  bankTransferRef?: InputMaybe<Scalars['String']>;
  birthdayDay?: InputMaybe<Scalars['String']>;
  birthdayMonth?: InputMaybe<Scalars['String']>;
  birthdayYear?: InputMaybe<Scalars['String']>;
  companyName?: InputMaybe<Scalars['String']>;
  deleteImage?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  handed?: InputMaybe<Handed>;
  lastName?: InputMaybe<Scalars['String']>;
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  nationality?: InputMaybe<Scalars['String']>;
  partialNricOrFin?: InputMaybe<Scalars['String']>;
  profileImageFile?: InputMaybe<File>;
  relationshipToMainMember?: InputMaybe<Relationship>;
  sameAddressAsMainMember?: InputMaybe<Scalars['Boolean']>;
  serviceNote?: InputMaybe<Scalars['String']>;
};

export type AdminMemberReassignMembershipIdInput = {
  membershipUuid?: InputMaybe<Scalars['String']>;
  proposedId?: InputMaybe<Scalars['String']>;
};

export type AdminMemberResendOnboardingEmailInput = {
  membershipUuid: Scalars['String'];
};

export type AdminMemberResponse = {
  __typename?: 'AdminMemberResponse';
  edges?: Maybe<Array<Maybe<AdminMemberEdge>>>;
  pageInfo?: Maybe<AdminMemberResponsePageInfo>;
};

export type AdminMemberResponsePageInfo = {
  __typename?: 'AdminMemberResponsePageInfo';
  endCursor?: Maybe<Scalars['String']>;
  failedKGolfAccounts?: Maybe<Scalars['Int']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['Int']>;
};

export type AdminMemberSendResetPasswordEmailInput = {
  memberUuid: Scalars['String'];
  password?: InputMaybe<Scalars['String']>;
};

export type AdminMemberServiceTransactionDeleteInput = {
  serviceTransactionId: Scalars['String'];
};

export type AdminMemberServiceTransactionDevUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  date?: InputMaybe<Scalars['String']>;
  deprecatedTxnId?: InputMaybe<Scalars['String']>;
  serviceTransactionId: Scalars['String'];
  venue?: InputMaybe<Scalars['String']>;
};

export type AdminMemberServiceTransactionEdge = {
  __typename?: 'AdminMemberServiceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ServiceTransactionBasic>;
};

export type AdminMemberServiceTransactionResponse = {
  __typename?: 'AdminMemberServiceTransactionResponse';
  edges?: Maybe<Array<Maybe<AdminMemberServiceTransactionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminMemberServiceTransactionUpdateInput = {
  amount?: InputMaybe<Scalars['Float']>;
  coach?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
  facilityType?: InputMaybe<Scalars['String']>;
  serviceTransactionId: Scalars['String'];
  venue?: InputMaybe<Scalars['String']>;
};

export type AdminMemberServiceTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  membershipUuid: Scalars['String'];
  orderBy?: InputMaybe<OrderBy>;
  serviceTypeFilter?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
  transactionTypeFilter?: InputMaybe<ServiceTransactionType>;
};

export type AdminMemberServiceUpdateInput = {
  amount: Scalars['Float'];
  coach?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  date?: InputMaybe<Scalars['DateTime']>;
  direction: ServiceTransactionDirection;
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  facilityType?: InputMaybe<Scalars['String']>;
  isSupplementaryRedemption?: InputMaybe<Scalars['Boolean']>;
  mainPackageOnly?: InputMaybe<Scalars['Boolean']>;
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  serviceId?: InputMaybe<Scalars['String']>;
  serviceType?: InputMaybe<Scalars['String']>;
  transactionType: ServiceTransactionType;
  venue?: InputMaybe<Scalars['String']>;
};

export type AdminMemberSimulatorUseUpdateInput = {
  comment: Scalars['String'];
  date?: InputMaybe<Scalars['DateTime']>;
  facilityType?: InputMaybe<Scalars['String']>;
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  venue?: InputMaybe<Scalars['String']>;
};

export type AdminMemberSubscriptionEdge = {
  __typename?: 'AdminMemberSubscriptionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminMembershipSubscription>;
};

export type AdminMemberSubscriptionResponse = {
  __typename?: 'AdminMemberSubscriptionResponse';
  edges?: Maybe<Array<Maybe<AdminMemberSubscriptionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionEndDate?: Maybe<Scalars['DateTime']>;
  subscriptionStartDate?: Maybe<Scalars['DateTime']>;
};

export type AdminMemberSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  membershipUuid: Scalars['String'];
  orderBy?: InputMaybe<OrderBy>;
};

export type AdminMemberTierUpdateInput = {
  adjustment: Scalars['Float'];
  award: Scalars['Int'];
  lessonIndoor: Scalars['Int'];
  lessonOutdoor: Scalars['Int'];
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  purchase: Scalars['Int'];
  versionSlug: Scalars['String'];
};

export type AdminMemberTypeUpdateInput = {
  internalAccountType: InternalAccountType;
  membershipUuid: Scalars['String'];
};

export type AdminMemberUpdatePasswordInput = {
  memberUuid: Scalars['String'];
  password: Scalars['String'];
};

export type AdminMembersArgs = {
  accountTypeFilter?: InputMaybe<AccountTypeFilter>;
  after?: InputMaybe<Scalars['String']>;
  countryFilter?: InputMaybe<CountryCode>;
  excludeMemberUuidFilter?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  first?: InputMaybe<Scalars['Int']>;
  mode?: InputMaybe<AdminMembersMode>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  statusFilter?: InputMaybe<MemberStatus>;
  tierFilter?: InputMaybe<Scalars['String']>;
};

export enum AdminMembersMode {
  Booking = 'BOOKING'
}

export type AdminMembershipService = {
  __typename?: 'AdminMembershipService';
  amount: Scalars['Float'];
  countryCode?: Maybe<CountryCode>;
  dateAdded?: Maybe<Scalars['DateTime']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  expiryWarning?: Maybe<Scalars['Boolean']>;
  groupServiceId?: Maybe<Scalars['String']>;
  hasSupplementaryRedemption: Scalars['Boolean'];
  isDaily: Scalars['Boolean'];
  isDailyRedeemed: Scalars['Boolean'];
  isGroup: Scalars['Boolean'];
  memberName?: Maybe<Scalars['String']>;
  orderNo?: Maybe<Scalars['String']>;
  packageType: Scalars['String'];
  parentServiceId?: Maybe<Scalars['String']>;
  serviceId: Scalars['String'];
  serviceName: Scalars['String'];
  supplementaryAmount: Scalars['Float'];
  supplementaryUnitName: Scalars['String'];
  unitType: Scalars['String'];
};

export type AdminMembershipServiceEdge = {
  __typename?: 'AdminMembershipServiceEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminMembershipService>;
};

export type AdminMembershipServiceResponse = {
  __typename?: 'AdminMembershipServiceResponse';
  edges?: Maybe<Array<Maybe<AdminMembershipServiceEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminMembershipServicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  checkIsDailyRedeemed?: InputMaybe<Scalars['Boolean']>;
  countryFilter?: InputMaybe<CountryCode>;
  expiryMode?: InputMaybe<AdminMembershipServicesExpiryMode>;
  first?: InputMaybe<Scalars['Int']>;
  itemCodeFilter?: InputMaybe<Scalars['String']>;
  membershipUuid?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  serviceIdFilter?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<AdminMembershipServicesStatus>;
};

export enum AdminMembershipServicesExpiryMode {
  NoExpiry = 'NO_EXPIRY',
  Week_1 = 'WEEK_1',
  Week_2 = 'WEEK_2',
  Week_3 = 'WEEK_3',
  Week_4 = 'WEEK_4'
}

export enum AdminMembershipServicesStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED'
}

export type AdminMembershipSubscription = {
  __typename?: 'AdminMembershipSubscription';
  amount?: Maybe<Scalars['Float']>;
  creditDeducted?: Maybe<Scalars['Boolean']>;
  days?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type AdminMembershipSubscriptionActionDatesInput = {
  membershipUuid: Scalars['String'];
  resetPause?: InputMaybe<Scalars['Boolean']>;
  resetStart?: InputMaybe<Scalars['Boolean']>;
};

export type AdminMembershipSubscriptionBasic = {
  __typename?: 'AdminMembershipSubscriptionBasic';
  aceCoins: Scalars['Float'];
  endDate?: Maybe<Scalars['DateTime']>;
  mainMemberMainId: Scalars['String'];
  mainMemberName: Scalars['String'];
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  startDate?: Maybe<Scalars['DateTime']>;
  status: SubscriptionStatus;
  subscriptionBalance: Scalars['Float'];
};

export type AdminMembershipSubscriptionEdge = {
  __typename?: 'AdminMembershipSubscriptionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminMembershipSubscriptionBasic>;
};

export type AdminMembershipSubscriptionPauseInput = {
  endPauseDate?: InputMaybe<Scalars['String']>;
  immediatePause: Scalars['Boolean'];
  membershipUuid: Scalars['String'];
  startPauseDate?: InputMaybe<Scalars['String']>;
  withEndDate: Scalars['Boolean'];
};

export type AdminMembershipSubscriptionResponse = {
  __typename?: 'AdminMembershipSubscriptionResponse';
  edges?: Maybe<Array<Maybe<AdminMembershipSubscriptionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminMembershipSubscriptionStartInput = {
  immediateStart: Scalars['Boolean'];
  membershipUuid: Scalars['String'];
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type AdminMembershipSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  status?: InputMaybe<SubscriptionStatus>;
};

export type AdminMembershipType = {
  __typename?: 'AdminMembershipType';
  baseCountryCode?: Maybe<CountryCode>;
  compDailySimulatorUse?: Maybe<Scalars['Int']>;
  compDailySimulatorUseType?: Maybe<CompDailySimulatorUseType>;
  compFieldLessons?: Maybe<Scalars['Int']>;
  compIndoorLessons?: Maybe<Scalars['Int']>;
  compLocker?: Maybe<Scalars['Boolean']>;
  designatedPrivateRoom?: Maybe<Scalars['Boolean']>;
  discount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  initialCredits?: Maybe<Scalars['Int']>;
  internalName?: Maybe<Scalars['String']>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  isEnabled?: Maybe<Scalars['Boolean']>;
  lineItemCode?: Maybe<Scalars['String']>;
  loungeAccess?: Maybe<Scalars['Boolean']>;
  mainTypeSlug?: Maybe<Scalars['String']>;
  monthlySub?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Int']>;
  publicName?: Maybe<Scalars['String']>;
  signupProductSidasInsoles?: Maybe<Scalars['Boolean']>;
  subscriptionlineItemCode?: Maybe<Scalars['String']>;
  supplementaryTypeSlug?: Maybe<Scalars['String']>;
  tagBgColorCode?: Maybe<Scalars['String']>;
  tagText?: Maybe<Scalars['String']>;
  tagTextColorCode?: Maybe<Scalars['String']>;
  tier?: Maybe<MembershipTypeTier>;
  versionSlug?: Maybe<Scalars['String']>;
};

export type AdminMembershipTypeArgs = {
  versionSlug?: InputMaybe<Scalars['String']>;
};

export type AdminMembershipTypeBasic = {
  __typename?: 'AdminMembershipTypeBasic';
  baseCountryCode?: Maybe<CountryCode>;
  id?: Maybe<Scalars['String']>;
  internalName?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  tier?: Maybe<MembershipTypeTier>;
  versionSlug?: Maybe<Scalars['String']>;
};

export type AdminMembershipTypes = {
  __typename?: 'AdminMembershipTypes';
  lifestyle?: Maybe<Array<Maybe<AdminMembershipTypeBasic>>>;
  numberOfTypes?: Maybe<Scalars['Int']>;
  premium?: Maybe<Array<Maybe<AdminMembershipTypeBasic>>>;
  vip?: Maybe<Array<Maybe<AdminMembershipTypeBasic>>>;
};

export type AdminMembershipTypesArgs = {
  temp?: InputMaybe<Scalars['String']>;
};

export type AdminNewMembership = {
  __typename?: 'AdminNewMembership';
  fileUploadCredentials?: Maybe<AdminNewMembershipFileUploadCredentials>;
  memberUuid?: Maybe<Scalars['String']>;
  payment?: Maybe<HitPayPaymentRequest>;
};

export type AdminNewMembershipFileUploadCredentials = {
  __typename?: 'AdminNewMembershipFileUploadCredentials';
  mainMembers?: Maybe<Array<Maybe<FileUploadObject>>>;
  subMembers?: Maybe<Array<Maybe<FileUploadObject>>>;
};

export type AdminNotification = {
  __typename?: 'AdminNotification';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<CreatedBy>;
  createdByAdminId?: Maybe<Scalars['String']>;
  createdByAdminName?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  message: Scalars['String'];
  mode?: Maybe<NotificationMode>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminNotificationArgs = {
  notificationId: Scalars['String'];
};

export type AdminNotificationBasic = {
  __typename?: 'AdminNotificationBasic';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<CreatedBy>;
  createdByAdminId?: Maybe<Scalars['String']>;
  createdByAdminName?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  message: Scalars['String'];
  mode?: Maybe<NotificationMode>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminNotificationCreateInput = {
  expiry: Scalars['Boolean'];
  expiryDate?: InputMaybe<Scalars['DateTime']>;
  memberUuid?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  mode: NotificationMode;
  title: Scalars['String'];
};

export type AdminNotificationDeleteInput = {
  notificationId: Scalars['String'];
};

export type AdminNotificationEdge = {
  __typename?: 'AdminNotificationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminNotificationBasic>;
};

export type AdminNotificationMemberDeleteInput = {
  memberUuid: Scalars['String'];
  notificationId: Scalars['String'];
};

export type AdminNotificationResponse = {
  __typename?: 'AdminNotificationResponse';
  edges?: Maybe<Array<Maybe<AdminNotificationEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  mode: NotificationMode;
  orderBy?: InputMaybe<OrderBy>;
};

export type AdminOrganization = {
  __typename?: 'AdminOrganization';
  businessName: Scalars['String'];
  clubhouses?: Maybe<Array<Maybe<BasicClubhouseDetails>>>;
  id: Scalars['String'];
};

export type AdminProduct = {
  __typename?: 'AdminProduct';
  baseCountryCode: CountryCode;
  name: Scalars['String'];
};

export type AdminProductBasic = {
  __typename?: 'AdminProductBasic';
  baseCountryCode: CountryCode;
  itemCode: Scalars['String'];
  name: Scalars['String'];
  packageType: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  priceAceCoins?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['String']>;
  validity?: Maybe<Scalars['String']>;
};

export type AdminProductEdge = {
  __typename?: 'AdminProductEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminProductBasic>;
};

export type AdminProductPackageAddInput = {
  description?: InputMaybe<Scalars['String']>;
  invoiceNo?: InputMaybe<Scalars['String']>;
  itemCode: Scalars['String'];
  memberUuid: Scalars['String'];
  membershipUuid: Scalars['String'];
  paymentMethod?: InputMaybe<PaymentMethod>;
  paymentRef?: InputMaybe<Scalars['String']>;
};

export type AdminProductResponse = {
  __typename?: 'AdminProductResponse';
  edges?: Maybe<Array<Maybe<AdminProductEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminProductsArgs = {
  after?: InputMaybe<Scalars['String']>;
  countryFilter?: InputMaybe<CountryCode>;
  first?: InputMaybe<Scalars['Int']>;
  mainCategory?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type AdminReportAcTransactionDeleteInput = {
  transactionId: Scalars['String'];
};

export type AdminReportAcTransactionEdge = {
  __typename?: 'AdminReportAcTransactionEdge';
  cursor?: Maybe<Scalars['Int']>;
  node?: Maybe<AceCoinTransaction>;
};

export type AdminReportAcTransactionRefundInput = {
  transactionId: Scalars['String'];
};

export type AdminReportAcTransactionResponse = {
  __typename?: 'AdminReportAcTransactionResponse';
  edges?: Maybe<Array<Maybe<AdminReportAcTransactionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminReportAcTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<Scalars['String']>;
  transactionTypeFilter?: InputMaybe<AcTransactionType>;
};

export type AdminReportCreditTransaction = {
  __typename?: 'AdminReportCreditTransaction';
  amount: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  member?: Maybe<AdminReportCreditTransactionMember>;
  memberId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  type?: Maybe<AceCoinTransactionType>;
};

export type AdminReportCreditTransactionEdge = {
  __typename?: 'AdminReportCreditTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminReportCreditTransaction>;
};

export type AdminReportCreditTransactionMember = {
  __typename?: 'AdminReportCreditTransactionMember';
  firstName?: Maybe<Scalars['String']>;
  individualId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type AdminReportCreditTransactionResponse = {
  __typename?: 'AdminReportCreditTransactionResponse';
  edges?: Maybe<Array<Maybe<AdminReportCreditTransactionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminReportCreditTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  memberUuidFilter?: InputMaybe<Scalars['String']>;
  membershipUuidFilter?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type AdminReportHitPayCharge = {
  __typename?: 'AdminReportHitPayCharge';
  amount?: Maybe<Scalars['String']>;
  buyer_email?: Maybe<Scalars['String']>;
  buyer_name: Scalars['String'];
  buyer_phone?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  fees?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  payment_method?: Maybe<Scalars['String']>;
  payment_type?: Maybe<Scalars['String']>;
  quantity: Scalars['Int'];
  refunded_amount?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  updated_at?: Maybe<Scalars['String']>;
};

export type AdminReportHitPayChargeArgs = {
  paymentRef: Scalars['String'];
};

export type AdminReportHitPayChargeEdge = {
  __typename?: 'AdminReportHitPayChargeEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminReportHitPayCharge>;
};

export type AdminReportHitPayChargeResponse = {
  __typename?: 'AdminReportHitPayChargeResponse';
  edges?: Maybe<Array<Maybe<AdminReportHitPayChargeEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminReportHitPayChargesArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  paymentMethodFilter?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type AdminReportInvoiceArgs = {
  invoiceNo: Scalars['String'];
};

export type AdminReportInvoiceEdge = {
  __typename?: 'AdminReportInvoiceEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<InvoiceBasic>;
};

export type AdminReportInvoiceResponse = {
  __typename?: 'AdminReportInvoiceResponse';
  edges?: Maybe<Array<Maybe<AdminReportInvoiceEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminReportInvoicesArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  invoiceNumberFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  paymentMethodFilter?: InputMaybe<PaymentMethod>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type AdminReportMemberStat = {
  __typename?: 'AdminReportMemberStat';
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  individualId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  stat?: Maybe<Scalars['Float']>;
};

export type AdminReportMemberStatEdge = {
  __typename?: 'AdminReportMemberStatEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminReportMemberStat>;
};

export type AdminReportMemberStatResponse = {
  __typename?: 'AdminReportMemberStatResponse';
  edges?: Maybe<Array<Maybe<AdminReportMemberStatEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export enum AdminReportMemberStatType {
  CreditTransactionCount = 'CREDIT_TRANSACTION_COUNT',
  LessonRedemptionCount = 'LESSON_REDEMPTION_COUNT',
  OrderCount = 'ORDER_COUNT',
  SubscriptionCount = 'SUBSCRIPTION_COUNT'
}

export type AdminReportMemberStatsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  sortOrder?: InputMaybe<Direction>;
  statType: AdminReportMemberStatType;
};

export type AdminReportOrderArgs = {
  orderNo: Scalars['String'];
};

export type AdminReportOrderEdge = {
  __typename?: 'AdminReportOrderEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<OrderBasic>;
};

export type AdminReportOrderPageInfo = {
  __typename?: 'AdminReportOrderPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  noOfNonConnectedOrders?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type AdminReportOrderRecordStatusUpdate = {
  __typename?: 'AdminReportOrderRecordStatusUpdate';
  status?: Maybe<OrderRecordUpdateStatus>;
};

export type AdminReportOrderRecordStatusUpdateInput = {
  orderNo: Scalars['String'];
};

export type AdminReportOrderResponse = {
  __typename?: 'AdminReportOrderResponse';
  edges?: Maybe<Array<Maybe<AdminReportOrderEdge>>>;
  pageInfo?: Maybe<AdminReportOrderPageInfo>;
};

export type AdminReportOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  memberUuidFilter?: InputMaybe<Scalars['String']>;
  membershipUuidFilter?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  nonConnectedFilter?: InputMaybe<Scalars['Boolean']>;
  orderBy?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type AdminReportUpdateOrderConnectionInput = {
  memberUuid?: InputMaybe<Scalars['String']>;
  membershipUuid?: InputMaybe<Scalars['String']>;
  nonMemberCustomer?: InputMaybe<Scalars['Boolean']>;
  orderNo: Scalars['String'];
};

export type AdminResendOnboardingEmailInput = {
  userId: Scalars['String'];
};

export type AdminReservedId = {
  __typename?: 'AdminReservedId';
  id?: Maybe<Scalars['Int']>;
  membershipId?: Maybe<Scalars['String']>;
  onHoldExpiresAt?: Maybe<Scalars['DateTime']>;
  onHoldFor?: Maybe<Scalars['String']>;
  status?: Maybe<ReservedIdStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type AdminReservedIds = {
  __typename?: 'AdminReservedIds';
  idOnHold?: Maybe<Scalars['String']>;
  idOnHoldExpiresAt?: Maybe<Scalars['DateTime']>;
  reservedIds?: Maybe<Array<Maybe<AdminReservedId>>>;
};

export type AdminReservedIdsArgs = {
  temp?: InputMaybe<Scalars['String']>;
};

export type AdminResponse = {
  __typename?: 'AdminResponse';
  edges?: Maybe<Array<Maybe<AdminEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminScoreCard = {
  __typename?: 'AdminScoreCard';
  cdn: Scalars['String'];
  scorecard: Array<Maybe<AdminGameRoundScoreCard>>;
};

export type AdminScoreCardArgs = {
  accessToken: Scalars['String'];
  gameRoundId: Scalars['Int'];
};

export type AdminShopDetails = {
  __typename?: 'AdminShopDetails';
  CDN?: Maybe<Scalars['String']>;
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  clientType?: Maybe<AdminShopDetailsClientType>;
  country?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  isEquipmentStorage?: Maybe<Scalars['Boolean']>;
  isLeftHanded?: Maybe<Scalars['Boolean']>;
  isParking?: Maybe<Scalars['Boolean']>;
  isProLesson?: Maybe<Scalars['Boolean']>;
  isShopCloseNextDay?: Maybe<Scalars['Boolean']>;
  isSwingplate?: Maybe<Scalars['Boolean']>;
  ownerEmail?: Maybe<Scalars['String']>;
  ownerMobilePhone?: Maybe<Scalars['String']>;
  ownerMobilePhoneCountry?: Maybe<Scalars['String']>;
  ownerName?: Maybe<Scalars['String']>;
  shopCloseTime?: Maybe<Scalars['String']>;
  shopGuid?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  shopOpenTime?: Maybe<Scalars['String']>;
  shopPhone?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type AdminShopDetailsArg = {
  accessToken: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminShopDetailsClientType = {
  __typename?: 'AdminShopDetailsClientType';
  lesson?: Maybe<Scalars['Boolean']>;
  practice?: Maybe<Scalars['Boolean']>;
  screen?: Maybe<Scalars['Boolean']>;
};

export type AdminShopGetNotificationInput = {
  accessToken: Scalars['String'];
  notificationId: Scalars['String'];
};

export type AdminShopNotification = {
  __typename?: 'AdminShopNotification';
  message: Scalars['String'];
};

export type AdminShopOptionsDrivingRange = {
  __typename?: 'AdminShopOptionsDrivingRange';
  courseId?: Maybe<Scalars['String']>;
  difficulty?: Maybe<Scalars['Int']>;
  distanceUnit?: Maybe<Scalars['Int']>;
  isBallTrajectory?: Maybe<Scalars['Boolean']>;
  isFixedCamera?: Maybe<Scalars['Boolean']>;
  isSwingVideo?: Maybe<Scalars['Boolean']>;
  teeHeight?: Maybe<Scalars['Int']>;
  useSwingPlate?: Maybe<Scalars['Int']>;
  windSpeed?: Maybe<Scalars['Int']>;
};

export type AdminShopOptionsDrivingRangeArgs = {
  accessToken: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminShopOptionsRealTime = {
  __typename?: 'AdminShopOptionsRealTime';
  drivingRangeTimeLimit?: Maybe<Scalars['Int']>;
  gameHoleTypeLimit?: Maybe<Scalars['Int']>;
  gameTypeLimit?: Maybe<Scalars['Int']>;
  isContentLimit?: Maybe<Scalars['Boolean']>;
  paymentType?: Maybe<Scalars['Int']>;
  playerCountLimit?: Maybe<Scalars['Int']>;
  usageTime?: Maybe<Scalars['Int']>;
};

export type AdminShopOptionsRealTimeArgs = {
  accessToken: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminShopOptionsRound = {
  __typename?: 'AdminShopOptionsRound';
  concede?: Maybe<Scalars['Float']>;
  distanceUnit?: Maybe<Scalars['Int']>;
  greenLocation?: Maybe<Scalars['Int']>;
  greenSpeed?: Maybe<Scalars['Int']>;
  holeCupLocation?: Maybe<Scalars['Int']>;
  isBestVideo?: Maybe<Scalars['Boolean']>;
  isCaddyVoice?: Maybe<Scalars['Boolean']>;
  isUseObHazardTee?: Maybe<Scalars['Boolean']>;
  mulliganCount?: Maybe<Scalars['Int']>;
  puttingLine?: Maybe<Scalars['Int']>;
  useSwingPlate?: Maybe<Scalars['Int']>;
  windSpeed?: Maybe<Scalars['Int']>;
};

export type AdminShopOptionsRoundArgs = {
  accessToken: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminShopSendNotificationInput = {
  accessToken: Scalars['String'];
  clientGuid: Scalars['String'];
  message: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminShopTokensInput = {
  temp?: InputMaybe<Scalars['String']>;
};

export type AdminSimulatorUsageArgs = {
  accessToken?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type AdminStaffBasic = {
  __typename?: 'AdminStaffBasic';
  baseCountryCode?: Maybe<CountryCode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  firstName: Scalars['String'];
  lastAccessTokenDate?: Maybe<Scalars['DateTime']>;
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  organization?: Maybe<AdminOrganization>;
  profileImageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<AdminStatus>;
  userId: Scalars['String'];
  userName: Scalars['String'];
};

export type AdminStaffEdge = {
  __typename?: 'AdminStaffEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AdminStaffBasic>;
};

export type AdminStaffResponse = {
  __typename?: 'AdminStaffResponse';
  edges?: Maybe<Array<Maybe<AdminStaffEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type AdminStaffsArgs = {
  after?: InputMaybe<Scalars['String']>;
  countryFilter?: InputMaybe<CountryCode>;
  first?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
};

export enum AdminStatus {
  Active = 'ACTIVE',
  Pending = 'PENDING',
  Suspended = 'SUSPENDED'
}

export type AdminTerminateMembershipInput = {
  membershipUuid: Scalars['String'];
};

export type AdminUnlockClientInput = {
  accessToken: Scalars['String'];
  clientGuid: Scalars['String'];
  drivingRangeTimeLimit: Scalars['Int'];
  gameHoleTypeLimit: Scalars['String'];
  gameTypeLimit: Scalars['String'];
  isContentLimit: Scalars['Boolean'];
  paymentType: Scalars['String'];
  playerCountLimit: Scalars['String'];
  shopGuid: Scalars['String'];
  usageTime: Scalars['Int'];
};

export type AdminUpdateAdminArgs = {
  userId: Scalars['String'];
};

export type AdminUpdateAdminInput = {
  authorizedClubhouses?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  baseCountryCode?: InputMaybe<CountryCode>;
  deleteImage?: InputMaybe<Scalars['Boolean']>;
  displayName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  profileImageFile?: InputMaybe<File>;
};

export type AdminUpdateClientContentLimitInput = {
  accessToken: Scalars['String'];
  clientGuid: Scalars['String'];
  players: Scalars['String'];
  shopGuid: Scalars['String'];
};

export type AdminUpdateCoachArgs = {
  userId: Scalars['String'];
};

export type AdminUpdateCoachBookable = {
  __typename?: 'AdminUpdateCoachBookable';
  isBookable: Scalars['Boolean'];
};

export type AdminUpdateCoachBookableInput = {
  isBookable: Scalars['Boolean'];
};

export type AdminUpdateCoachInput = {
  baseCountryCode?: InputMaybe<CountryCode>;
  coachBirthPlace?: InputMaybe<Scalars['String']>;
  coachBirthdate?: InputMaybe<Scalars['String']>;
  coachExperiences?: InputMaybe<Scalars['JSON']>;
  coachExpertise?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  coachLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  coachLevel?: InputMaybe<CoachLevel>;
  coachListOrder?: InputMaybe<Scalars['Int']>;
  coachPGANo?: InputMaybe<Scalars['String']>;
  coachPGAType?: InputMaybe<Scalars['String']>;
  coachPGAYear?: InputMaybe<Scalars['String']>;
  coachPublished?: InputMaybe<Scalars['Boolean']>;
  deleteImage?: InputMaybe<Scalars['Boolean']>;
  displayName?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: InputMaybe<Gender>;
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  profileImageFile?: InputMaybe<File>;
};

export type AdminUpdateFacilityInput = {
  facilityName?: InputMaybe<Scalars['String']>;
  facilityType?: InputMaybe<FacilityType>;
  id: Scalars['String'];
};

export type AdminUpdateMembershipTypeInput = {
  baseCountryCode?: InputMaybe<CountryCode>;
  compDailySimulatorUse?: InputMaybe<Scalars['Int']>;
  compDailySimulatorUseType?: InputMaybe<CompDailySimulatorUseType>;
  compFieldLessons?: InputMaybe<Scalars['Int']>;
  compIndoorLessons?: InputMaybe<Scalars['Int']>;
  compLocker?: InputMaybe<Scalars['Boolean']>;
  deleteImage?: InputMaybe<Scalars['Boolean']>;
  designatedPrivateRoom?: InputMaybe<Scalars['Boolean']>;
  discount?: InputMaybe<Scalars['Int']>;
  imageFile?: InputMaybe<File>;
  initialCredits?: InputMaybe<Scalars['Int']>;
  internalName?: InputMaybe<Scalars['String']>;
  isCorporate?: InputMaybe<Scalars['Boolean']>;
  isEnabled?: InputMaybe<Scalars['Boolean']>;
  lineItemCode?: InputMaybe<Scalars['String']>;
  loungeAccess?: InputMaybe<Scalars['Boolean']>;
  mainTypeSlug?: InputMaybe<Scalars['String']>;
  monthlySub?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Int']>;
  publicName?: InputMaybe<Scalars['String']>;
  signupProductSidasInsoles?: InputMaybe<Scalars['Boolean']>;
  subscriptionlineItemCode?: InputMaybe<Scalars['String']>;
  supplementaryTypeSlug?: InputMaybe<Scalars['String']>;
  tagBgColorCode?: InputMaybe<Scalars['String']>;
  tagText?: InputMaybe<Scalars['String']>;
  tagTextColorCode?: InputMaybe<Scalars['String']>;
  tier?: InputMaybe<MembershipTypeTier>;
  versionSlug?: InputMaybe<Scalars['String']>;
};

export type AdminUpdateNicknameInput = {
  memberUuid: Scalars['String'];
  newNickname: Scalars['String'];
};

export type AdminUpdateOrganizationInput = {
  addressCity?: InputMaybe<Scalars['String']>;
  addressCountry?: InputMaybe<Scalars['String']>;
  addressPostalCode?: InputMaybe<Scalars['String']>;
  addressState?: InputMaybe<Scalars['String']>;
  addressStreet1?: InputMaybe<Scalars['String']>;
  addressStreet2?: InputMaybe<Scalars['String']>;
  businessName?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
};

export type AdminUpdatePricingInput = {
  sgBayHourlyRate?: InputMaybe<Scalars['Int']>;
  sgBayHourlyRateVIP?: InputMaybe<Scalars['Int']>;
  sgBayMaxHours?: InputMaybe<Scalars['Int']>;
  sgBayMaxPlayers?: InputMaybe<Scalars['Int']>;
  sgBookingCancellationPenalty?: InputMaybe<Scalars['Int']>;
  sgBookingLapsedPenalty?: InputMaybe<Scalars['Int']>;
  sgHole9MaxHours?: InputMaybe<Scalars['Int']>;
  sgHole9MaxPlayers?: InputMaybe<Scalars['Int']>;
  sgHole9PerPlayerRate?: InputMaybe<Scalars['Int']>;
  sgHole9PerPlayerRateVIP?: InputMaybe<Scalars['Int']>;
  sgHole18MaxHours?: InputMaybe<Scalars['Int']>;
  sgHole18MaxPlayers?: InputMaybe<Scalars['Int']>;
  sgHole18PerPlayerRate?: InputMaybe<Scalars['Int']>;
  sgHole18PerPlayerRateVIP?: InputMaybe<Scalars['Int']>;
  sgLessonField?: InputMaybe<Scalars['Int']>;
  sgLessonFieldVIP?: InputMaybe<Scalars['Int']>;
  sgLessonIndoor?: InputMaybe<Scalars['Int']>;
  sgLessonIndoorPuttingGreen?: InputMaybe<Scalars['Int']>;
  sgLessonIndoorPuttingGreenVIP?: InputMaybe<Scalars['Int']>;
  sgLessonIndoorVIP?: InputMaybe<Scalars['Int']>;
  sgPrivateRoomHourlyRate?: InputMaybe<Scalars['Int']>;
  sgPrivateRoomHourlyRateVIP?: InputMaybe<Scalars['Int']>;
  sgPrivateRoomMaxHours?: InputMaybe<Scalars['Int']>;
  sgPrivateRoomMaxPlayers?: InputMaybe<Scalars['Int']>;
  sgPuttingGreenHourlyRate?: InputMaybe<Scalars['Int']>;
  sgPuttingGreenHourlyRateVIP?: InputMaybe<Scalars['Int']>;
  sgPuttingGreenMaxMins?: InputMaybe<Scalars['Int']>;
  sgPuttingGreenMaxPlayers?: InputMaybe<Scalars['Int']>;
  viBayHourlyRate?: InputMaybe<Scalars['Int']>;
  viBayHourlyRateVIP?: InputMaybe<Scalars['Int']>;
  viBayMaxHours?: InputMaybe<Scalars['Int']>;
  viBayMaxPlayers?: InputMaybe<Scalars['Int']>;
  viBookingCancellationPenalty?: InputMaybe<Scalars['Int']>;
  viBookingLapsedPenalty?: InputMaybe<Scalars['Int']>;
  viHole9MaxHours?: InputMaybe<Scalars['Int']>;
  viHole9MaxPlayers?: InputMaybe<Scalars['Int']>;
  viHole9PerPlayerRate?: InputMaybe<Scalars['Int']>;
  viHole9PerPlayerRateVIP?: InputMaybe<Scalars['Int']>;
  viHole18MaxHours?: InputMaybe<Scalars['Int']>;
  viHole18MaxPlayers?: InputMaybe<Scalars['Int']>;
  viHole18PerPlayerRate?: InputMaybe<Scalars['Int']>;
  viHole18PerPlayerRateVIP?: InputMaybe<Scalars['Int']>;
  viLessonField?: InputMaybe<Scalars['Int']>;
  viLessonFieldVIP?: InputMaybe<Scalars['Int']>;
  viLessonIndoor?: InputMaybe<Scalars['Int']>;
  viLessonIndoorPuttingGreen?: InputMaybe<Scalars['Int']>;
  viLessonIndoorPuttingGreenVIP?: InputMaybe<Scalars['Int']>;
  viLessonIndoorVIP?: InputMaybe<Scalars['Int']>;
  viPrivateRoomHourlyRate?: InputMaybe<Scalars['Int']>;
  viPrivateRoomHourlyRateVIP?: InputMaybe<Scalars['Int']>;
  viPrivateRoomMaxHours?: InputMaybe<Scalars['Int']>;
  viPrivateRoomMaxPlayers?: InputMaybe<Scalars['Int']>;
  viPuttingGreenHourlyRate?: InputMaybe<Scalars['Int']>;
  viPuttingGreenHourlyRateVIP?: InputMaybe<Scalars['Int']>;
  viPuttingGreenMaxMins?: InputMaybe<Scalars['Int']>;
  viPuttingGreenMaxPlayers?: InputMaybe<Scalars['Int']>;
};

export type AdminUpdateReservedId = {
  __typename?: 'AdminUpdateReservedId';
  onHoldExpiresAt?: Maybe<Scalars['DateTime']>;
};

export type AdminUpdateReservedIdInput = {
  hold?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['String']>;
};

export type AdminUpdateShopDetailsInput = {
  CDN?: InputMaybe<Scalars['String']>;
  accessToken?: InputMaybe<Scalars['String']>;
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  currency?: InputMaybe<Scalars['String']>;
  introImage?: InputMaybe<Scalars['String']>;
  isParking?: InputMaybe<Scalars['Boolean']>;
  isShopCloseNextDay?: InputMaybe<Scalars['Boolean']>;
  logoImage?: InputMaybe<Scalars['String']>;
  ownerEmail?: InputMaybe<Scalars['String']>;
  ownerMobilePhone?: InputMaybe<Scalars['String']>;
  ownerMobilePhoneCountry?: InputMaybe<Scalars['String']>;
  ownerName?: InputMaybe<Scalars['String']>;
  shopCloseTime?: InputMaybe<Scalars['String']>;
  shopGuid?: InputMaybe<Scalars['String']>;
  shopName?: InputMaybe<Scalars['String']>;
  shopOpenTime?: InputMaybe<Scalars['String']>;
  shopPhone?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export type AdminUpdateShopOptionsDrivingRangeInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  courseId?: InputMaybe<Scalars['String']>;
  difficulty?: InputMaybe<Scalars['Int']>;
  distanceUnit?: InputMaybe<Scalars['Int']>;
  isBallTrajectory?: InputMaybe<Scalars['Boolean']>;
  isFixedCamera?: InputMaybe<Scalars['Boolean']>;
  isSwingVideo?: InputMaybe<Scalars['Boolean']>;
  shopGuid?: InputMaybe<Scalars['String']>;
  teeHeight?: InputMaybe<Scalars['Int']>;
  useSwingPlate?: InputMaybe<Scalars['Int']>;
  windSpeed?: InputMaybe<Scalars['Int']>;
};

export type AdminUpdateShopOptionsRealTimeInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  drivingRangeTimeLimit?: InputMaybe<Scalars['Int']>;
  gameHoleTypeLimit?: InputMaybe<Scalars['Int']>;
  gameTypeLimit?: InputMaybe<Scalars['Int']>;
  isContentLimit?: InputMaybe<Scalars['Boolean']>;
  paymentType?: InputMaybe<Scalars['Int']>;
  playerCountLimit?: InputMaybe<Scalars['Int']>;
  shopGuid?: InputMaybe<Scalars['String']>;
  usageTime?: InputMaybe<Scalars['Int']>;
};

export type AdminUpdateShopOptionsRoundInput = {
  accessToken?: InputMaybe<Scalars['String']>;
  concede?: InputMaybe<Scalars['Int']>;
  distanceUnit?: InputMaybe<Scalars['Int']>;
  greenLocation?: InputMaybe<Scalars['Int']>;
  greenSpeed?: InputMaybe<Scalars['Int']>;
  holeCupLocation?: InputMaybe<Scalars['Int']>;
  isBestVideo?: InputMaybe<Scalars['Boolean']>;
  isCaddyVoice?: InputMaybe<Scalars['Boolean']>;
  isUseObHazardTee?: InputMaybe<Scalars['Boolean']>;
  mulliganCount?: InputMaybe<Scalars['Int']>;
  puttingLine?: InputMaybe<Scalars['Int']>;
  shopGuid?: InputMaybe<Scalars['String']>;
  useSwingPlate?: InputMaybe<Scalars['Int']>;
  windSpeed?: InputMaybe<Scalars['Int']>;
};

export type AdminUpdateSidasInsolesInput = {
  memberUuid: Scalars['String'];
  transactionType: AdminUpdateSidasInsolesTransactionType;
};

export enum AdminUpdateSidasInsolesTransactionType {
  Adjustment = 'ADJUSTMENT',
  Complimentary = 'COMPLIMENTARY',
  Redemption = 'REDEMPTION'
}

export type AdminsArgs = {
  after?: InputMaybe<Scalars['String']>;
  countryFilter?: InputMaybe<CountryCode>;
  first?: InputMaybe<Scalars['Int']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  userType?: InputMaybe<UserType>;
};

export type AppData = {
  __typename?: 'AppData';
  androidUrl: Scalars['String'];
  androidVersion: Scalars['String'];
  cancellationConditions: CancellationConditions;
  iosUrl: Scalars['String'];
  iosVersion: Scalars['String'];
  promptMessage: Scalars['String'];
  promptUser: Scalars['Boolean'];
  sidasUrl: Scalars['String'];
  whatsapp: Scalars['String'];
};

export type AppDineMenu = {
  __typename?: 'AppDineMenu';
  foodUri?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  wineUri?: Maybe<Scalars['String']>;
};

export type AppDineMenuArgs = {
  countryCode?: InputMaybe<CountryCode>;
};

export type AppMainSlider = {
  __typename?: 'AppMainSlider';
  slides: Array<AppMainSliderSlide>;
};

export type AppMainSliderArgs = {
  countryCode?: InputMaybe<CountryCode>;
};

export type AppMainSliderSlide = {
  __typename?: 'AppMainSliderSlide';
  id: Scalars['ID'];
  link?: Maybe<AppMainSliderSlideLink>;
  posterTitle?: Maybe<Scalars['String']>;
  posterUri?: Maybe<Scalars['String']>;
  slidePayload?: Maybe<Scalars['JSON']>;
  slideUri?: Maybe<Scalars['String']>;
};

export type AppMainSliderSlideLink = {
  __typename?: 'AppMainSliderSlideLink';
  posterPayload?: Maybe<Scalars['JSON']>;
  route?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  webViewUrl?: Maybe<Scalars['String']>;
};

export type AppMainSliderSlideV2 = {
  __typename?: 'AppMainSliderSlideV2';
  id: Scalars['ID'];
  isVideo?: Maybe<Scalars['Boolean']>;
  isYoutube?: Maybe<Scalars['Boolean']>;
  link?: Maybe<AppMainSliderSlideLink>;
  order: Scalars['Int'];
  posterTitle?: Maybe<Scalars['String']>;
  posterUri?: Maybe<Scalars['String']>;
  slidePayload?: Maybe<Scalars['JSON']>;
  slideUri?: Maybe<Scalars['String']>;
};

export type AppMainSliderV2 = {
  __typename?: 'AppMainSliderV2';
  slides: Array<AppMainSliderSlideV2>;
};

export type AppMainSliderV2Args = {
  countryCode?: InputMaybe<CountryCode>;
};

export type AppManifest = {
  __typename?: 'AppManifest';
  androidUrl: Scalars['String'];
  androidVersion: Scalars['String'];
  bookingVersion: Scalars['String'];
  cancellationConditions: CancellationConditions;
  iosUrl: Scalars['String'];
  iosVersion: Scalars['String'];
  promptMessage: Scalars['String'];
  promptUser: Scalars['Boolean'];
  whatsapp: Scalars['String'];
};

export type AppManifestArgs = {
  temp?: InputMaybe<Scalars['Boolean']>;
};

export type AppManifestV2 = {
  __typename?: 'AppManifestV2';
  aceCoinTopUpPromoBody?: Maybe<Scalars['String']>;
  aceCoinTopUpPromoHeader?: Maybe<Scalars['String']>;
  androidUrl: Scalars['String'];
  androidVersion: Scalars['String'];
  boniqStoreUrl: Scalars['String'];
  cancellationConditions: CancellationConditions;
  concierge: Scalars['String'];
  guestMember: GuestMember;
  iosUrl: Scalars['String'];
  iosVersion: Scalars['String'];
  isRealTimeEnabled: Scalars['Boolean'];
  manualReloadTs?: Maybe<Scalars['DateTime']>;
  promptMessage: Scalars['String'];
  promptUser: Scalars['Boolean'];
  sidasStoreUrl: Scalars['String'];
  translationVersion: Scalars['Int'];
  useWebsiteRates?: Maybe<Scalars['Boolean']>;
  websiteRateUrl?: Maybe<Scalars['String']>;
  whatsapp: Scalars['String'];
};

export type AppMedia = {
  __typename?: 'AppMedia';
  description?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Int']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  videoUrl?: Maybe<Scalars['String']>;
};

export type AppMediaArgs = {
  countryCode?: InputMaybe<CountryCode>;
};

export type AppMediaYouTubeVideo = {
  __typename?: 'AppMediaYouTubeVideo';
  date?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type AppMediaYouTubeVideoArgs = {
  videoId: Scalars['String'];
};

export enum AppNotificationOperation {
  Account = 'ACCOUNT',
  Acecoins = 'ACECOINS',
  Bookings = 'BOOKINGS',
  CombinedServices = 'COMBINED_SERVICES',
  GameCredit = 'GAME_CREDIT',
  Inbox = 'INBOX',
  LessonVouchers = 'LESSON_VOUCHERS',
  Notification = 'NOTIFICATION',
  Orders = 'ORDERS',
  PractiseCredit = 'PRACTISE_CREDIT',
  Subscriptions = 'SUBSCRIPTIONS'
}

export type AppTelemetry = {
  __typename?: 'AppTelemetry';
  androidVersion?: Maybe<Scalars['String']>;
  iosVersion?: Maybe<Scalars['String']>;
  promptMessage?: Maybe<Scalars['String']>;
  promptUser?: Maybe<Scalars['Boolean']>;
};

export type AppTelemetryInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  deviceBrand?: InputMaybe<Scalars['String']>;
  deviceManufacturer?: InputMaybe<Scalars['String']>;
  deviceModelId?: InputMaybe<Scalars['String']>;
  deviceModelName?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  deviceProductName?: InputMaybe<Scalars['String']>;
  devicePushToken?: InputMaybe<Scalars['String']>;
  osName?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  pushNotificationAppEnabled?: InputMaybe<Scalars['Boolean']>;
};

export type AppTelemetryV2Input = {
  appVersion?: InputMaybe<Scalars['String']>;
  biometricAppEnabled?: InputMaybe<Scalars['Boolean']>;
  deviceBrand?: InputMaybe<Scalars['String']>;
  deviceManufacturer?: InputMaybe<Scalars['String']>;
  deviceModelId?: InputMaybe<Scalars['String']>;
  deviceModelName?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  deviceProductName?: InputMaybe<Scalars['String']>;
  devicePushToken?: InputMaybe<Scalars['String']>;
  osName?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  pushNotificationAppEnabled?: InputMaybe<Scalars['Boolean']>;
};

export enum ArchivedBy {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  System = 'SYSTEM'
}

export enum AuditLogOutcome {
  Fail = 'FAIL',
  Success = 'SUCCESS'
}

export type AuthenticatedAdmin = {
  __typename?: 'AuthenticatedAdmin';
  clubhouseCode?: Maybe<Scalars['String']>;
  clubhouses: Array<Maybe<Scalars['String']>>;
  firstName: Scalars['String'];
  lastLogin?: Maybe<LoginInfo>;
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  permissions?: Maybe<Array<Maybe<Scalars['String']>>>;
  profileImageUrl: Scalars['String'];
  ud?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  userType: Scalars['String'];
  yourIp: Scalars['String'];
};

export type AuthenticatedMember = {
  __typename?: 'AuthenticatedMember';
  id: Scalars['String'];
  member?: Maybe<Scalars['String']>;
  memberId?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
};

export type BasicClubhouseDetails = {
  __typename?: 'BasicClubhouseDetails';
  clubhouseCode: Scalars['String'];
  name: Scalars['String'];
};

export type BaySimulator = {
  __typename?: 'BaySimulator';
  blockedFromBooking: Scalars['Boolean'];
  clientname: Scalars['String'];
  clubhouseId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export enum BookingStatus {
  Active = 'ACTIVE',
  Cancelled = 'CANCELLED',
  Completed = 'COMPLETED',
  Lapsed = 'LAPSED',
  Pending = 'PENDING'
}

export enum BookingType {
  Game = 'GAME',
  LessonField = 'LESSON_FIELD',
  LessonIndoor = 'LESSON_INDOOR',
  LessonOutdoor = 'LESSON_OUTDOOR',
  Practise = 'PRACTISE'
}

export type CancellationConditions = {
  __typename?: 'CancellationConditions';
  GAME: Scalars['Int'];
  LESSON_FIELD: Scalars['Int'];
  LESSON_INDOOR: Scalars['Int'];
  LESSON_OUTDOOR: Scalars['Int'];
  PRACTISE: Scalars['Int'];
};

export type CancellationEmailPenaltyLineItem = {
  __typename?: 'CancellationEmailPenaltyLineItem';
  amount: Scalars['String'];
  package?: Maybe<CancellationEmailPenaltyLineItemPackage>;
  type: Scalars['String'];
};

export type CancellationEmailPenaltyLineItemPackage = {
  __typename?: 'CancellationEmailPenaltyLineItemPackage';
  name: Scalars['String'];
  no: Scalars['String'];
};

export enum CancelledBy {
  Admin = 'ADMIN',
  Coach = 'COACH',
  Member = 'MEMBER',
  System = 'SYSTEM'
}

export type ClientStatus = {
  __typename?: 'ClientStatus';
  clients: Scalars['JSON'];
  shopGuid: Scalars['String'];
};

export type ClubhouseOrganization = {
  __typename?: 'ClubhouseOrganization';
  businessName: Scalars['String'];
  id: Scalars['String'];
};

export type ClubhouseOverride = {
  __typename?: 'ClubhouseOverride';
  closureMessage?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
};

export type Coach = SystemUser & {
  __typename?: 'Coach';
  baseCountryCode?: Maybe<CountryCode>;
  coachBirthPlace?: Maybe<Scalars['String']>;
  coachBirthdate?: Maybe<Scalars['String']>;
  coachExperiences?: Maybe<Scalars['JSON']>;
  coachExpertise?: Maybe<Array<Maybe<Scalars['String']>>>;
  coachLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  coachLevel?: Maybe<CoachLevel>;
  coachListOrder?: Maybe<Scalars['Int']>;
  coachPGANo?: Maybe<Scalars['String']>;
  coachPGAType?: Maybe<Scalars['String']>;
  coachPGAYear?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Gender>;
  lastAccessTokenDate?: Maybe<Scalars['DateTime']>;
  lastLogin?: Maybe<LoginInfo>;
  lastLoginDate?: Maybe<Scalars['DateTime']>;
  lastName: Scalars['String'];
  logins: Array<Maybe<LoginInfo>>;
  mobileNumber?: Maybe<Scalars['String']>;
  organization: AdminOrganization;
  profileImageUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  status: AdminStatus;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  userType: UserType;
};

export type CoachAccountSettings = {
  __typename?: 'CoachAccountSettings';
  baseCountryCode: CountryCode;
  baseGmtOffset: Scalars['Int'];
  myClubhouses?: Maybe<Array<Maybe<CoachClubhouse>>>;
  slug: Scalars['String'];
};

export type CoachAvailabilityCalendarItems = {
  __typename?: 'CoachAvailabilityCalendarItems';
  clubhouses: Array<Maybe<Scalars['String']>>;
  date: Scalars['String'];
  hasIndoorBooking?: Maybe<Scalars['Boolean']>;
  hasOutdoorBooking?: Maybe<Scalars['Boolean']>;
  isBlocked?: Maybe<Scalars['Boolean']>;
  isIndoorBookingFull?: Maybe<Scalars['Boolean']>;
};

export type CoachAvailabilityCalendarItemsArgs = {
  currentDate: Scalars['String'];
};

export enum CoachAvailabilityCalendarItemsMode {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR'
}

export type CoachAvailabilityDayItem = {
  __typename?: 'CoachAvailabilityDayItem';
  bi0800?: Maybe<Scalars['String']>;
  bi0900?: Maybe<Scalars['String']>;
  bi1000?: Maybe<Scalars['String']>;
  bi1100?: Maybe<Scalars['String']>;
  bi1200?: Maybe<Scalars['String']>;
  bi1300?: Maybe<Scalars['String']>;
  bi1400?: Maybe<Scalars['String']>;
  bi1500?: Maybe<Scalars['String']>;
  bi1600?: Maybe<Scalars['String']>;
  bi1700?: Maybe<Scalars['String']>;
  bi1800?: Maybe<Scalars['String']>;
  bi1900?: Maybe<Scalars['String']>;
  bi2000?: Maybe<Scalars['String']>;
  bi2100?: Maybe<Scalars['String']>;
  biOutdoor?: Maybe<Scalars['String']>;
  bs0800?: Maybe<Scalars['String']>;
  bs0900?: Maybe<Scalars['String']>;
  bs1000?: Maybe<Scalars['String']>;
  bs1100?: Maybe<Scalars['String']>;
  bs1200?: Maybe<Scalars['String']>;
  bs1300?: Maybe<Scalars['String']>;
  bs1400?: Maybe<Scalars['String']>;
  bs1500?: Maybe<Scalars['String']>;
  bs1600?: Maybe<Scalars['String']>;
  bs1700?: Maybe<Scalars['String']>;
  bs1800?: Maybe<Scalars['String']>;
  bs1900?: Maybe<Scalars['String']>;
  bs2000?: Maybe<Scalars['String']>;
  bs2100?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
  hasOutdoorBooking?: Maybe<Scalars['Boolean']>;
  l0800?: Maybe<Scalars['String']>;
  l0900?: Maybe<Scalars['String']>;
  l1000?: Maybe<Scalars['String']>;
  l1100?: Maybe<Scalars['String']>;
  l1200?: Maybe<Scalars['String']>;
  l1300?: Maybe<Scalars['String']>;
  l1400?: Maybe<Scalars['String']>;
  l1500?: Maybe<Scalars['String']>;
  l1600?: Maybe<Scalars['String']>;
  l1700?: Maybe<Scalars['String']>;
  l1800?: Maybe<Scalars['String']>;
  l1900?: Maybe<Scalars['String']>;
  l2000?: Maybe<Scalars['String']>;
  l2100?: Maybe<Scalars['String']>;
};

export type CoachAvailabilityDayItemArgs = {
  dateId: Scalars['String'];
};

export enum CoachAvailabilityDayItemMode {
  Group = 'GROUP',
  Individual = 'INDIVIDUAL'
}

export type CoachAvailabilityDayItemTemplate = {
  __typename?: 'CoachAvailabilityDayItemTemplate';
  id: Scalars['String'];
  l0800?: Maybe<Scalars['String']>;
  l0900?: Maybe<Scalars['String']>;
  l1000?: Maybe<Scalars['String']>;
  l1100?: Maybe<Scalars['String']>;
  l1200?: Maybe<Scalars['String']>;
  l1300?: Maybe<Scalars['String']>;
  l1400?: Maybe<Scalars['String']>;
  l1500?: Maybe<Scalars['String']>;
  l1600?: Maybe<Scalars['String']>;
  l1700?: Maybe<Scalars['String']>;
  l1800?: Maybe<Scalars['String']>;
  l1900?: Maybe<Scalars['String']>;
  l2000?: Maybe<Scalars['String']>;
  l2100?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CoachAvailabilityDayItemTemplateArgs = {
  id: Scalars['String'];
};

export type CoachAvailabilityDayItemTemplateCreate = {
  __typename?: 'CoachAvailabilityDayItemTemplateCreate';
  id: Scalars['String'];
  status: SystemOperationStatus;
};

export type CoachAvailabilityDayItemTemplateCreateInput = {
  l0800?: InputMaybe<Scalars['String']>;
  l0900?: InputMaybe<Scalars['String']>;
  l1000?: InputMaybe<Scalars['String']>;
  l1100?: InputMaybe<Scalars['String']>;
  l1200?: InputMaybe<Scalars['String']>;
  l1300?: InputMaybe<Scalars['String']>;
  l1400?: InputMaybe<Scalars['String']>;
  l1500?: InputMaybe<Scalars['String']>;
  l1600?: InputMaybe<Scalars['String']>;
  l1700?: InputMaybe<Scalars['String']>;
  l1800?: InputMaybe<Scalars['String']>;
  l1900?: InputMaybe<Scalars['String']>;
  l2000?: InputMaybe<Scalars['String']>;
  l2100?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CoachAvailabilityDayItemTemplateDeleteInput = {
  id: Scalars['String'];
};

export type CoachAvailabilityDayItemTemplateUpdateInput = {
  id: Scalars['String'];
  l0800?: InputMaybe<Scalars['String']>;
  l0900?: InputMaybe<Scalars['String']>;
  l1000?: InputMaybe<Scalars['String']>;
  l1100?: InputMaybe<Scalars['String']>;
  l1200?: InputMaybe<Scalars['String']>;
  l1300?: InputMaybe<Scalars['String']>;
  l1400?: InputMaybe<Scalars['String']>;
  l1500?: InputMaybe<Scalars['String']>;
  l1600?: InputMaybe<Scalars['String']>;
  l1700?: InputMaybe<Scalars['String']>;
  l1800?: InputMaybe<Scalars['String']>;
  l1900?: InputMaybe<Scalars['String']>;
  l2000?: InputMaybe<Scalars['String']>;
  l2100?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type CoachAvailabilityDayUpsertInput = {
  coachId?: InputMaybe<Scalars['String']>;
  date: Scalars['String'];
  l0800?: InputMaybe<Scalars['String']>;
  l0900?: InputMaybe<Scalars['String']>;
  l1000?: InputMaybe<Scalars['String']>;
  l1100?: InputMaybe<Scalars['String']>;
  l1200?: InputMaybe<Scalars['String']>;
  l1300?: InputMaybe<Scalars['String']>;
  l1400?: InputMaybe<Scalars['String']>;
  l1500?: InputMaybe<Scalars['String']>;
  l1600?: InputMaybe<Scalars['String']>;
  l1700?: InputMaybe<Scalars['String']>;
  l1800?: InputMaybe<Scalars['String']>;
  l1900?: InputMaybe<Scalars['String']>;
  l2000?: InputMaybe<Scalars['String']>;
  l2100?: InputMaybe<Scalars['String']>;
};

export type CoachBooking = {
  __typename?: 'CoachBooking';
  cancellationDateTime?: Maybe<Scalars['DateTime']>;
  cancellationReason?: Maybe<Scalars['String']>;
  cancelledBy?: Maybe<CancelledBy>;
  cancelledByAdminName?: Maybe<Scalars['String']>;
  coachId?: Maybe<Scalars['String']>;
  coachName?: Maybe<Scalars['String']>;
  commentByCoach?: Maybe<Scalars['String']>;
  commentInternal?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<CreatedBy>;
  createdByAdminName?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  dateMonthStr?: Maybe<Scalars['String']>;
  dayOfWeekStr?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  durationStr?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  endTimeStr?: Maybe<Scalars['String']>;
  facilityType?: Maybe<FacilityType>;
  fieldLessonDetails?: Maybe<Scalars['String']>;
  groupMembers?: Maybe<Array<Maybe<GroupMember>>>;
  id: Scalars['String'];
  isGroupBooking: Scalars['Boolean'];
  member: Scalars['String'];
  memberIndividualId: Scalars['String'];
  memberUuid: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  noOfPlayers: Scalars['Int'];
  resourceName?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  startTimeStr?: Maybe<Scalars['String']>;
  status: BookingStatus;
  type: BookingType;
  venue?: Maybe<Scalars['String']>;
  yearStr?: Maybe<Scalars['String']>;
};

export type CoachBookingArgs = {
  bookingId: Scalars['String'];
};

export type CoachBookingBasic = {
  __typename?: 'CoachBookingBasic';
  cancellationDateTime?: Maybe<Scalars['DateTime']>;
  coachDisplayName?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  dateMonthStr?: Maybe<Scalars['String']>;
  dateStr: Scalars['String'];
  dayOfWeekStr?: Maybe<Scalars['String']>;
  durationStr?: Maybe<Scalars['String']>;
  endTime: Scalars['DateTime'];
  endTimeStr?: Maybe<Scalars['String']>;
  groupBookingId?: Maybe<Scalars['String']>;
  groupBookingIds: Array<Maybe<Scalars['String']>>;
  id: Scalars['String'];
  isGroupBooking: Scalars['Boolean'];
  member: Scalars['String'];
  resourceName?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  startTimeStr?: Maybe<Scalars['String']>;
  status: BookingStatus;
  type: BookingType;
  venue?: Maybe<Scalars['String']>;
  yearStr?: Maybe<Scalars['String']>;
};

export type CoachBookingCoach = {
  __typename?: 'CoachBookingCoach';
  baseCountryCode?: Maybe<CountryCode>;
  coachPublished?: Maybe<Scalars['Boolean']>;
  displayName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type CoachBookingCombinedAvailability = {
  __typename?: 'CoachBookingCombinedAvailability';
  coachId: Scalars['String'];
  coachSlug: Scalars['String'];
  dateObjs: Array<Maybe<CoachBookingCombinedAvailabilityDateObj>>;
  name: Scalars['String'];
};

export type CoachBookingCombinedAvailabilityArgs = {
  currentDate: Scalars['String'];
};

export type CoachBookingCombinedAvailabilityDateObj = {
  __typename?: 'CoachBookingCombinedAvailabilityDateObj';
  clubhouses: Array<Maybe<Scalars['String']>>;
  date: Scalars['String'];
  hasIndoorBooking: Scalars['Boolean'];
  hasOutdoorBooking: Scalars['Boolean'];
  isDayBlocked: Scalars['Boolean'];
};

export type CoachBookingCombinedAvailabilityDayItem = {
  __typename?: 'CoachBookingCombinedAvailabilityDayItem';
  bi0800?: Maybe<Scalars['String']>;
  bi0900?: Maybe<Scalars['String']>;
  bi1000?: Maybe<Scalars['String']>;
  bi1100?: Maybe<Scalars['String']>;
  bi1200?: Maybe<Scalars['String']>;
  bi1300?: Maybe<Scalars['String']>;
  bi1400?: Maybe<Scalars['String']>;
  bi1500?: Maybe<Scalars['String']>;
  bi1600?: Maybe<Scalars['String']>;
  bi1700?: Maybe<Scalars['String']>;
  bi1800?: Maybe<Scalars['String']>;
  bi1900?: Maybe<Scalars['String']>;
  bi2000?: Maybe<Scalars['String']>;
  bi2100?: Maybe<Scalars['String']>;
  biOutdoor?: Maybe<Scalars['String']>;
  bs0800?: Maybe<Scalars['String']>;
  bs0900?: Maybe<Scalars['String']>;
  bs1000?: Maybe<Scalars['String']>;
  bs1100?: Maybe<Scalars['String']>;
  bs1200?: Maybe<Scalars['String']>;
  bs1300?: Maybe<Scalars['String']>;
  bs1400?: Maybe<Scalars['String']>;
  bs1500?: Maybe<Scalars['String']>;
  bs1600?: Maybe<Scalars['String']>;
  bs1700?: Maybe<Scalars['String']>;
  bs1800?: Maybe<Scalars['String']>;
  bs1900?: Maybe<Scalars['String']>;
  bs2000?: Maybe<Scalars['String']>;
  bs2100?: Maybe<Scalars['String']>;
  clubhouses: Array<Maybe<CoachClubhouse>>;
  date?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  hasOutdoorBooking?: Maybe<Scalars['Boolean']>;
  l0800?: Maybe<Scalars['String']>;
  l0900?: Maybe<Scalars['String']>;
  l1000?: Maybe<Scalars['String']>;
  l1100?: Maybe<Scalars['String']>;
  l1200?: Maybe<Scalars['String']>;
  l1300?: Maybe<Scalars['String']>;
  l1400?: Maybe<Scalars['String']>;
  l1500?: Maybe<Scalars['String']>;
  l1600?: Maybe<Scalars['String']>;
  l1700?: Maybe<Scalars['String']>;
  l1800?: Maybe<Scalars['String']>;
  l1900?: Maybe<Scalars['String']>;
  l2000?: Maybe<Scalars['String']>;
  l2100?: Maybe<Scalars['String']>;
};

export type CoachBookingCombinedAvailabilityDayItemArgs = {
  coachId: Scalars['String'];
  dateId: Scalars['String'];
};

export type CoachBookingCommentUpdate = {
  __typename?: 'CoachBookingCommentUpdate';
  commentByCoach?: Maybe<Scalars['String']>;
};

export type CoachBookingCommentUpdateInput = {
  body: Scalars['String'];
  bookingId: Scalars['String'];
};

export type CoachBookingEdge = {
  __typename?: 'CoachBookingEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CoachBookingBasic>;
};

export type CoachBookingResponse = {
  __typename?: 'CoachBookingResponse';
  edges?: Maybe<Array<Maybe<CoachBookingEdge>>>;
  firstBookingDate?: Maybe<Scalars['DateTime']>;
  lastBookingDate?: Maybe<Scalars['DateTime']>;
  pageInfo?: Maybe<PageInfo>;
};

export type CoachBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  allBookings?: InputMaybe<Scalars['Boolean']>;
  bookingIdFilter?: InputMaybe<Scalars['String']>;
  bookingTypes: Array<InputMaybe<BookingType>>;
  coachId?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  memberUuid?: InputMaybe<Scalars['String']>;
  nameFilter?: InputMaybe<Scalars['String']>;
  orderBy?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<BookingStatus>;
  venueFilter?: InputMaybe<Scalars['String']>;
};

export type CoachClubhouse = {
  __typename?: 'CoachClubhouse';
  clubhouseCode: Scalars['String'];
  displayName: Scalars['String'];
  tagBgColorCode: Scalars['String'];
  tagText: Scalars['String'];
  tagTextColorCode: Scalars['String'];
};

export type CoachExperience = {
  __typename?: 'CoachExperience';
  details: Scalars['String'];
  period: Scalars['String'];
};

export type CoachGroupBooking = {
  __typename?: 'CoachGroupBooking';
  coachId?: Maybe<Scalars['String']>;
  coachName?: Maybe<Scalars['String']>;
  commentInternal?: Maybe<Scalars['String']>;
  dateMonthStr?: Maybe<Scalars['String']>;
  dayOfWeekStr?: Maybe<Scalars['String']>;
  durationStr?: Maybe<Scalars['String']>;
  endTimeStr: Scalars['String'];
  facilityType?: Maybe<FacilityType>;
  fieldLessonDetails?: Maybe<Scalars['String']>;
  gameCourseType?: Maybe<GameCourseType>;
  id: Scalars['String'];
  member: Scalars['String'];
  memberIndividualId: Scalars['String'];
  mobileNumber: Scalars['String'];
  resourceName?: Maybe<Scalars['String']>;
  startTimeStr?: Maybe<Scalars['String']>;
  type: BookingType;
  venue?: Maybe<Scalars['String']>;
  yearStr?: Maybe<Scalars['String']>;
};

export type CoachGroupBookingsArgs = {
  bookingIds: Array<Scalars['String']>;
};

export enum CoachLevel {
  Pro = 'PRO',
  SemiPro = 'SEMI_PRO'
}

export type CoachMember = {
  __typename?: 'CoachMember';
  accountType?: Maybe<AccountType>;
  birthday?: Maybe<Scalars['String']>;
  companyContactPersonEmail?: Maybe<Scalars['String']>;
  companyContactPersonFirstName?: Maybe<Scalars['String']>;
  companyContactPersonLastName?: Maybe<Scalars['String']>;
  companyName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender?: Maybe<Gender>;
  handed: Handed;
  individualId: Scalars['String'];
  lastName: Scalars['String'];
  memberUuid: Scalars['String'];
  members: Array<Maybe<AdminMemberMember>>;
  membershipType?: Maybe<MembershipTypeBasic>;
  membershipUuid: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  nationality?: Maybe<Scalars['String']>;
  relationshipToMainMember?: Maybe<Relationship>;
  serviceNote?: Maybe<Scalars['String']>;
};

export type CoachMemberArgs = {
  memberUuid: Scalars['String'];
};

export type CoachMemberBasic = {
  __typename?: 'CoachMemberBasic';
  accountType?: Maybe<AccountType>;
  firstName: Scalars['String'];
  id: Scalars['String'];
  individualId: Scalars['String'];
  lastName: Scalars['String'];
  memberName?: Maybe<Scalars['String']>;
  membershipId: Scalars['String'];
  membershipType?: Maybe<MembershipTypeBasic>;
  nextBookingDateStr?: Maybe<Scalars['String']>;
  nextBookingStartTime?: Maybe<Scalars['DateTime']>;
  nextBookingVenue?: Maybe<Scalars['String']>;
  noOfBookings?: Maybe<Scalars['Int']>;
};

export type CoachMemberEdge = {
  __typename?: 'CoachMemberEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CoachMemberBasic>;
};

export type CoachMemberLessonNote = {
  __typename?: 'CoachMemberLessonNote';
  bookingId?: Maybe<Scalars['String']>;
  comment: Scalars['String'];
  dateStr: Scalars['String'];
  id: Scalars['String'];
  lessonType: Scalars['String'];
  memberIndividualId: Scalars['String'];
  memberName: Scalars['String'];
  memberUuid: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
  venueOther?: Maybe<Scalars['String']>;
};

export type CoachMemberLessonNoteArgs = {
  id: Scalars['String'];
};

export type CoachMemberLessonNoteBasic = {
  __typename?: 'CoachMemberLessonNoteBasic';
  bookingId?: Maybe<Scalars['String']>;
  dateStr: Scalars['String'];
  id: Scalars['String'];
  lessonType: Scalars['String'];
  venue: Scalars['String'];
};

export type CoachMemberLessonNoteCreateInput = {
  bookingId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
  date: Scalars['String'];
  lessonType: Scalars['String'];
  memberUuid: Scalars['String'];
  venue: Scalars['String'];
};

export type CoachMemberLessonNoteDelete = {
  __typename?: 'CoachMemberLessonNoteDelete';
  hasFeedback: Scalars['Boolean'];
};

export type CoachMemberLessonNoteDeleteInput = {
  memberUuid: Scalars['String'];
  noteId: Scalars['String'];
};

export type CoachMemberLessonNoteEdge = {
  __typename?: 'CoachMemberLessonNoteEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CoachMemberLessonNoteBasic>;
};

export type CoachMemberLessonNoteMember = {
  __typename?: 'CoachMemberLessonNoteMember';
  id: Scalars['String'];
  memberIndividualId: Scalars['String'];
  memberName: Scalars['String'];
  noOfLessonNotes?: Maybe<Scalars['Int']>;
};

export type CoachMemberLessonNoteMemberEdge = {
  __typename?: 'CoachMemberLessonNoteMemberEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CoachMemberLessonNoteMember>;
};

export type CoachMemberLessonNoteMemberResponse = {
  __typename?: 'CoachMemberLessonNoteMemberResponse';
  edges?: Maybe<Array<Maybe<CoachMemberLessonNoteMemberEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CoachMemberLessonNoteMembersArgs = {
  nameFilter?: InputMaybe<Scalars['String']>;
};

export type CoachMemberLessonNoteResponse = {
  __typename?: 'CoachMemberLessonNoteResponse';
  edges?: Maybe<Array<Maybe<CoachMemberLessonNoteEdge>>>;
  memberIndividualId: Scalars['String'];
  memberName: Scalars['String'];
  memberUuid: Scalars['String'];
  pageInfo?: Maybe<PageInfo>;
};

export type CoachMemberLessonNoteUpdateInput = {
  comment: Scalars['String'];
  date?: InputMaybe<Scalars['String']>;
  lessonType?: InputMaybe<Scalars['String']>;
  noteId: Scalars['String'];
  venue?: InputMaybe<Scalars['String']>;
};

export type CoachMemberLessonNotesArgs = {
  memberUuid: Scalars['String'];
};

export type CoachMemberResponse = {
  __typename?: 'CoachMemberResponse';
  edges?: Maybe<Array<Maybe<CoachMemberEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type CoachMembersArgs = {
  nameFilter?: InputMaybe<Scalars['String']>;
  tierFilter?: InputMaybe<Scalars['String']>;
};

export enum CoachSlotStatus {
  Blocked = 'BLOCKED',
  BookedLessonIndoor = 'BOOKED_LESSON_INDOOR',
  BookedLessonIndoorForced = 'BOOKED_LESSON_INDOOR_FORCED',
  BookedLessonOutdoor = 'BOOKED_LESSON_OUTDOOR',
  BookedLessonOutdoorForced = 'BOOKED_LESSON_OUTDOOR_FORCED'
}

export enum CompDailySimulatorUseType {
  Na = 'NA',
  Restricted = 'RESTRICTED',
  Unrestricted = 'UNRESTRICTED'
}

export enum CompDailySimulatorUseTypeOverwrite {
  Na = 'NA',
  Restricted = 'RESTRICTED',
  RestrictedPm = 'RESTRICTED_PM',
  Unrestricted = 'UNRESTRICTED'
}

export enum CountryCode {
  Sg = 'SG',
  Vi = 'VI',
  Vn = 'VN'
}

export type CourseMapCoordinate = {
  __typename?: 'CourseMapCoordinate';
  downX?: Maybe<Scalars['Float']>;
  downY?: Maybe<Scalars['Float']>;
  holeNumber?: Maybe<Scalars['Int']>;
  rightX?: Maybe<Scalars['Float']>;
  rightY?: Maybe<Scalars['Float']>;
  worldLeftTopX?: Maybe<Scalars['Float']>;
  worldLeftTopY?: Maybe<Scalars['Float']>;
  worldSizeX?: Maybe<Scalars['Float']>;
  worldSizeY?: Maybe<Scalars['Float']>;
};

export type CreateMemberInput = {
  addressCity?: InputMaybe<Scalars['String']>;
  addressCountry: Scalars['String'];
  addressPostalCode: Scalars['String'];
  addressState?: InputMaybe<Scalars['String']>;
  addressStreet1: Scalars['String'];
  addressStreet2?: InputMaybe<Scalars['String']>;
  bankTransferRef?: InputMaybe<Scalars['String']>;
  birthdayDay: Scalars['String'];
  birthdayMonth: Scalars['String'];
  birthdayYear: Scalars['String'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender: Gender;
  handed: Handed;
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  nationality?: InputMaybe<Scalars['String']>;
  partialNricOrFin?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  profileImageFile?: InputMaybe<File>;
  relationshipToMainMember?: InputMaybe<Relationship>;
  sameAddressAsMainMember?: InputMaybe<Scalars['Boolean']>;
};

export enum CreatedBy {
  Admin = 'ADMIN',
  Coach = 'COACH',
  Member = 'MEMBER',
  System = 'SYSTEM'
}

export type CreditTransaction = {
  __typename?: 'CreditTransaction';
  amount: Scalars['Float'];
  authorizedAdjuster?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isSpent?: Maybe<Scalars['Boolean']>;
  type?: Maybe<AceCoinTransactionType>;
};

export enum CurrencyCode {
  Sgd = 'SGD',
  Usd = 'USD',
  Vnd = 'VND'
}

export enum DbServiceEnvType {
  Dev = 'DEV',
  Na = 'NA',
  Prod = 'PROD',
  Test = 'TEST'
}

export type DevAceCoinsLedger = {
  __typename?: 'DevAceCoinsLedger';
  aceCoins: Scalars['Float'];
  calculated: Scalars['Float'];
  difference: Scalars['Float'];
  mainMember: Scalars['String'];
  membershipId: Scalars['String'];
  membershipType?: Maybe<MembershipTypeBasic>;
  membershipUuid: Scalars['String'];
  transactions?: Maybe<Array<Maybe<AceCoinLedgerTransaction>>>;
};

export type DevAceCoinsLedgerArgs = {
  membershipUuid: Scalars['String'];
};

export type DevAceCoinsLineItemDeleteInput = {
  invoiceNo?: InputMaybe<Scalars['String']>;
  transactionId: Scalars['String'];
};

export type DevAppTranslation = {
  __typename?: 'DevAppTranslation';
  language: Scalars['String'];
  locale: Scalars['String'];
  payload: Scalars['JSON'];
};

export type DevAppTranslationInput = {
  language: Scalars['String'];
  locale: Scalars['String'];
  payload: Scalars['JSON'];
};

export type DevAppTranslationsUpdateInput = {
  translations: Array<DevAppTranslationInput>;
};

export type DevEMenuImportOrdersInput = {
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};

export type DevEMenuMatchOrders = {
  __typename?: 'DevEMenuMatchOrders';
  numberOfOrdersMatched?: Maybe<Scalars['Int']>;
};

export type DevEMenuMatchOrdersInput = {
  temp?: InputMaybe<Scalars['String']>;
};

export type DevEMenuUpsertCreditTransactionsInput = {
  temp?: InputMaybe<Scalars['String']>;
};

export type DevKGolfCreateAccounts = {
  __typename?: 'DevKGolfCreateAccounts';
  noOfKGolfAccountCreated: Scalars['Int'];
};

export type DevKGolfMemberJoin = {
  __typename?: 'DevKGolfMemberJoin';
  kGolfAccountCreationError: Scalars['Boolean'];
  kGolfPassword: Scalars['String'];
  kGolfUserId: Scalars['String'];
  nickname: Scalars['String'];
};

export type DevManagementSystemAdminNotificationSendInput = {
  devId: Scalars['String'];
  message: Scalars['String'];
  title: Scalars['String'];
};

export type DevManagementSystemAdminViewportReloadRequestInput = {
  userId: Scalars['String'];
};

export type DevManagementSystemServerEnvironments = {
  __typename?: 'DevManagementSystemServerEnvironments';
  environments: Scalars['JSON'];
};

export type DevManagementSystemServerStatus = {
  __typename?: 'DevManagementSystemServerStatus';
  dbService?: Maybe<DevManagementSystemServerStatusDbService>;
};

export type DevManagementSystemServerStatusDbService = {
  __typename?: 'DevManagementSystemServerStatusDbService';
  envType?: Maybe<DbServiceEnvType>;
  url?: Maybe<Scalars['String']>;
};

export type DevManagementSystemUserTelemetry = {
  __typename?: 'DevManagementSystemUserTelemetry';
  eventId: Scalars['String'];
  pageName: Scalars['String'];
  resourceId?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceType?: Maybe<UserTelemetryResourceType>;
  ts: Scalars['String'];
  userId: Scalars['String'];
  userName: Scalars['String'];
  userProfileImageUrl?: Maybe<Scalars['String']>;
};

export type DevMemberAceCoinIssue = {
  __typename?: 'DevMemberAceCoinIssue';
  aceCoins: Scalars['Float'];
  calculated: Scalars['Float'];
  credits: Scalars['Float'];
  difference: Scalars['Float'];
  errorType: Scalars['String'];
  memberName: Scalars['String'];
  memberUuid: Scalars['String'];
  membershipId: Scalars['String'];
  membershipType: MembershipTypeBasic;
  membershipUuid: Scalars['String'];
};

export type DevNotificationAppSendInput = {
  temp?: InputMaybe<Scalars['String']>;
};

export type DevUtilityEnvs = {
  __typename?: 'DevUtilityEnvs';
  kGolfEnv: Scalars['JSON'];
};

export enum DeviceType {
  Mobile = 'MOBILE',
  Pc = 'PC',
  Tablet = 'TABLET'
}

export enum Direction {
  Asc = 'asc',
  Desc = 'desc'
}

export enum DurationType {
  Hour = 'HOUR',
  Min = 'MIN'
}

export type EndCoordinates = {
  __typename?: 'EndCoordinates';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  z?: Maybe<Scalars['Float']>;
};

export type ExpirableCreditBasic = {
  __typename?: 'ExpirableCreditBasic';
  amount: Scalars['Float'];
  expiryDate: Scalars['DateTime'];
  id: Scalars['String'];
};

export type Facility = {
  __typename?: 'Facility';
  clientGuid?: Maybe<Scalars['String']>;
  clientName?: Maybe<Scalars['String']>;
  clientOrder?: Maybe<Scalars['Int']>;
  facilityCode?: Maybe<Scalars['String']>;
  facilityName?: Maybe<Scalars['String']>;
  facilityType?: Maybe<FacilityType>;
  id?: Maybe<Scalars['String']>;
};

export type FacilityAvailability = {
  __typename?: 'FacilityAvailability';
  clubhouseCode: Scalars['String'];
  facilityCode: Scalars['String'];
  facilityName: Scalars['String'];
  facilityType: FacilityType;
};

export type FacilityAvailabilityCalendarItems = {
  __typename?: 'FacilityAvailabilityCalendarItems';
  date: Scalars['String'];
  isBlocked?: Maybe<Scalars['Boolean']>;
};

export type FacilityBasic = {
  __typename?: 'FacilityBasic';
  facilityCode: Scalars['String'];
  facilityName: Scalars['String'];
  facilityType: FacilityType;
};

export enum FacilitySlotStatus {
  BookedGame = 'BOOKED_GAME',
  BookedLesson = 'BOOKED_LESSON',
  BookedPractise = 'BOOKED_PRACTISE'
}

export enum FacilityType {
  OpenBay = 'OPEN_BAY',
  PrivateRoom = 'PRIVATE_ROOM',
  PuttingGreen = 'PUTTING_GREEN',
  Studio = 'STUDIO'
}

export enum FacilityUsageDuration {
  Hour_1 = 'HOUR_1',
  Hour_2 = 'HOUR_2',
  Hour_3 = 'HOUR_3',
  Hour_4 = 'HOUR_4',
  Hour_5 = 'HOUR_5',
  HourHalf = 'HOUR_HALF'
}

export type File = {
  name: Scalars['String'];
  size: Scalars['Int'];
  type: Scalars['String'];
};

export type FileUploadCredentials = {
  __typename?: 'FileUploadCredentials';
  fields: Scalars['JSONObject'];
  signedUrl: Scalars['String'];
};

export type FileUploadObject = {
  __typename?: 'FileUploadObject';
  assetUrl: Scalars['String'];
  clientImageFieldName: Scalars['String'];
  fields: Scalars['JSONObject'];
  index: Scalars['Int'];
  signedUrl: Scalars['String'];
};

export type FormHelperAceCoinType = {
  __typename?: 'FormHelperAceCoinType';
  currencyCode: CurrencyCode;
  price: Scalars['Int'];
};

export type FormHelperAceCoinTypeArgs = {
  countryCode?: InputMaybe<CountryCode>;
  memberUuid?: InputMaybe<Scalars['String']>;
};

export type FormHelperBooking = {
  __typename?: 'FormHelperBooking';
  clubhouses: Array<Maybe<AdminClubhouseBasic>>;
};

export type FormHelperBookingArgs = {
  countryCode?: InputMaybe<CountryCode>;
};

export type FormHelperNewAdmin = {
  __typename?: 'FormHelperNewAdmin';
  clubhouses: Array<Maybe<AdminClubhouseBasic>>;
};

export type FormHelperNewAdminArgs = {
  temp?: InputMaybe<Scalars['String']>;
};

export type FormHelperNewBlackOut = {
  __typename?: 'FormHelperNewBlackOut';
  clubhouseCode?: Maybe<Scalars['String']>;
  date: Scalars['String'];
  errorMessage?: Maybe<Scalars['String']>;
  facilities: Array<Maybe<FacilityBasic>>;
  isCreateAllowed: Scalars['Boolean'];
};

export type FormHelperNewBlackOutArgs = {
  clubhouseCode?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['String']>;
};

export type FormHelperNewCoach = {
  __typename?: 'FormHelperNewCoach';
  countries: Array<Maybe<Scalars['String']>>;
};

export type FormHelperNewCoachArgs = {
  temp?: InputMaybe<Scalars['String']>;
};

export type FormHelperNewMembership = {
  __typename?: 'FormHelperNewMembership';
  membershipTypes: Array<Maybe<FormHelperNewMembershipMembershipType>>;
};

export type FormHelperNewMembershipArgs = {
  temp?: InputMaybe<Scalars['String']>;
};

export type FormHelperNewMembershipMembershipType = {
  __typename?: 'FormHelperNewMembershipMembershipType';
  amtAwardMain: Scalars['Int'];
  amtAwardSub: Scalars['Int'];
  amtPurchaseMain: Scalars['Int'];
  amtPurchaseSub: Scalars['Int'];
  baseCountryCode: CountryCode;
  hasReservedIds?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  internalName: Scalars['String'];
  isCorporate: Scalars['Boolean'];
  lessonIndoorCredit: Scalars['Int'];
  lessonOutdoorCredit: Scalars['Int'];
  parentTypeId?: Maybe<Scalars['String']>;
  price: Scalars['Int'];
  supplementaryTypePrice: Scalars['Int'];
  supplementaryTypeSlug?: Maybe<Scalars['String']>;
  versionSlug: Scalars['String'];
};

export type FormHelperProductPackage = {
  __typename?: 'FormHelperProductPackage';
  itemCode: Scalars['String'];
  name: Scalars['String'];
};

export type FormHelperProductPackages = {
  __typename?: 'FormHelperProductPackages';
  packages: Array<Maybe<FormHelperProductPackage>>;
};

export type FormHelperProductPackagesArgs = {
  temp?: InputMaybe<Scalars['String']>;
};

export type GameBookingConditions = {
  __typename?: 'GameBookingConditions';
  maxDays: Scalars['Int'];
  maxPaxBay: Scalars['Int'];
  maxPaxRoom: Scalars['Int'];
  maxPaxStudio: Scalars['Int'];
  timeLimitMinsPerPax9Hole: Scalars['Int'];
  timeLimitMinsPerPax18Hole: Scalars['Int'];
};

export enum GameCourseType {
  Hole_9 = 'HOLE_9',
  Hole_18 = 'HOLE_18'
}

export type GameRound = {
  __typename?: 'GameRound';
  cdn: Scalars['String'];
  scorecard: Array<Maybe<GameRoundScoreCard>>;
};

export type GameRoundCourseInfo = {
  __typename?: 'GameRoundCourseInfo';
  courseInTotalPar: Scalars['Int'];
  courseOutTotalPar: Scalars['Int'];
  coursePar: Array<Scalars['Int']>;
  courseTotalPar: Scalars['Int'];
  intro: Scalars['String'];
  logo: Scalars['String'];
  newPerioHiddenHole: Scalars['String'];
};

export type GameRoundGameInfo = {
  __typename?: 'GameRoundGameInfo';
  clientGuid: Scalars['String'];
  clientName: Scalars['String'];
  completeHoleCount: Scalars['Int'];
  concede: Scalars['Int'];
  courseInfo: GameRoundCourseInfo;
  courseName: Scalars['String'];
  distanceUnit: Scalars['String'];
  gameEndTime: Scalars['Int'];
  gameHoleType: Scalars['String'];
  gameRoundId: Scalars['Int'];
  gameStartTime: Scalars['Int'];
  gameType: Scalars['Int'];
  gameTypeText: Scalars['String'];
  greenLocation: Scalars['String'];
  greenSpeed: Scalars['String'];
  holeCupLocation: Scalars['String'];
  isBestVideo: Scalars['Boolean'];
  isCaddyVoice: Scalars['Boolean'];
  isUseObHazardTee: Scalars['Boolean'];
  mulliganCount: Scalars['Int'];
  playHoleCount: Scalars['Int'];
  puttingLine: Scalars['String'];
  shopGuid: Scalars['String'];
  shopName: Scalars['String'];
  tournamentIdx: Scalars['Int'];
  useSwingPlate: Scalars['String'];
  windSpeed: Scalars['String'];
};

export type GameRoundHoleInfo = {
  __typename?: 'GameRoundHoleInfo';
  holeNumber: Scalars['Int'];
  holeOutType: Scalars['Int'];
  isBestVideo: Scalars['Boolean'];
  isConcede: Scalars['Boolean'];
  isLongest: Scalars['Boolean'];
  isMulligan: Scalars['Boolean'];
  isNearpin: Scalars['Boolean'];
  score: Scalars['Int'];
};

export type GameRoundPlayerInfo = {
  __typename?: 'GameRoundPlayerInfo';
  completeHoleCount: Scalars['Int'];
  difficulty: Scalars['Int'];
  handicap: Scalars['Int'];
  holeInfo: Array<GameRoundHoleInfo>;
  inTotalScore: Scalars['Int'];
  nickname: Scalars['String'];
  outTotalScore: Scalars['Int'];
  playerType: Scalars['Int'];
  rank: Scalars['Int'];
  startHole: Scalars['Int'];
  team: Scalars['Int'];
  totalScore: Scalars['String'];
  userGuid: Scalars['String'];
};

export type GameRoundScoreCard = {
  __typename?: 'GameRoundScoreCard';
  gameInfo: GameRoundGameInfo;
  playerInfo: Array<Maybe<GameRoundPlayerInfo>>;
};

export enum GameType {
  Hole_9 = 'HOLE_9',
  Hole_18 = 'HOLE_18'
}

export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Undisclosed = 'UNDISCLOSED'
}

export type GroupLessonPartner = {
  facilityCode: Scalars['String'];
  memberUuid: Scalars['String'];
};

export type GroupMember = {
  __typename?: 'GroupMember';
  bookingId: Scalars['String'];
  individualId: Scalars['String'];
  memberUuid: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type GuestMember = {
  __typename?: 'GuestMember';
  guestMemberUuid: Scalars['String'];
  name: Scalars['String'];
};

export enum Handed {
  Left = 'LEFT',
  Right = 'RIGHT'
}

export type HitPayPaymentRequest = {
  __typename?: 'HitPayPaymentRequest';
  checkoutUrl?: Maybe<Scalars['String']>;
  requestId?: Maybe<Scalars['String']>;
};

export enum IndoorLessonType {
  Field = 'FIELD',
  Normal = 'NORMAL',
  Putting = 'PUTTING'
}

export enum IndoorLocation {
  BaySimulator = 'BAY_SIMULATOR',
  PrivateRoom = 'PRIVATE_ROOM',
  PuttingGreen = 'PUTTING_GREEN'
}

export enum InternalAccountType {
  Na = 'NA',
  Staff = 'STAFF',
  Test = 'TEST'
}

export type Invoice = {
  __typename?: 'Invoice';
  additionalDiscount?: Maybe<Scalars['Float']>;
  amount: Scalars['Float'];
  gst?: Maybe<Scalars['Float']>;
  invoiceNo: Scalars['String'];
  lineItems?: Maybe<Array<Maybe<InvoiceLineItem>>>;
  memberDiscount?: Maybe<Scalars['Float']>;
  memberIndividualId?: Maybe<Scalars['String']>;
  memberName?: Maybe<Scalars['String']>;
  memberUuid?: Maybe<Scalars['String']>;
  paymentCurrency?: Maybe<PaymentCurrency>;
  paymentMethod: PaymentMethod;
  serviceFee?: Maybe<Scalars['Float']>;
  strDate: Scalars['String'];
  strTime: Scalars['String'];
  subTotal: Scalars['Float'];
  transactionDateTime: Scalars['DateTime'];
  venue?: Maybe<Scalars['String']>;
};

export type InvoiceBasic = {
  __typename?: 'InvoiceBasic';
  account?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  amount: Scalars['Float'];
  invoiceNo: Scalars['String'];
  paymentCurrency?: Maybe<PaymentCurrency>;
  paymentMethod: Scalars['String'];
  transactionDateTime: Scalars['DateTime'];
  venue?: Maybe<Scalars['String']>;
};

export type InvoiceLineItem = {
  __typename?: 'InvoiceLineItem';
  name: Scalars['String'];
  qty: Scalars['Float'];
  salesPrice: Scalars['Float'];
};

export type KGolfLoginData = {
  __typename?: 'KGolfLoginData';
  access_token?: Maybe<Scalars['String']>;
  cdn?: Maybe<Scalars['String']>;
  expires_in?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  profilePath?: Maybe<Scalars['String']>;
  refresh_token?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  token_type?: Maybe<Scalars['String']>;
};

export type KGolfMemberGet = {
  __typename?: 'KGolfMemberGet';
  address1?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  cdn?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  gender?: Maybe<Scalars['Int']>;
  isTermsAgreeNotificationSMS?: Maybe<Scalars['Boolean']>;
  mobilePhone?: Maybe<Scalars['String']>;
  mobilePhoneCountry?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  profilePath?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  zipcode?: Maybe<Scalars['String']>;
};

export type KGolfMemberJoin = {
  __typename?: 'KGolfMemberJoin';
  kGolfAccountCreationError?: Maybe<Scalars['Boolean']>;
  kGolfPassword?: Maybe<Scalars['String']>;
  kGolfUserId?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
};

export type KGolfMemberLogin = {
  __typename?: 'KGolfMemberLogin';
  accessToken?: Maybe<Scalars['String']>;
  cdn?: Maybe<Scalars['String']>;
  ewxpiresIn?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['Int']>;
  guid?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  profilePath?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
};

export type KGolfShopLogin = {
  __typename?: 'KGolfShopLogin';
  accessToken?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  expiresIn?: Maybe<Scalars['Int']>;
  managerId?: Maybe<Scalars['String']>;
  managerIdx?: Maybe<Scalars['Int']>;
  managerName?: Maybe<Scalars['String']>;
  managerType?: Maybe<Scalars['String']>;
  refreshToken?: Maybe<Scalars['String']>;
  scope?: Maybe<Scalars['String']>;
  shopGuid?: Maybe<Scalars['String']>;
  shopName?: Maybe<Scalars['String']>;
  tokenType?: Maybe<Scalars['String']>;
};

export type KGolfUpdateMemberInput = {
  address1?: InputMaybe<Scalars['String']>;
  address2?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Scalars['Int']>;
  isTermsAgreeNotificationSMS?: InputMaybe<Scalars['Boolean']>;
  mobilePhone?: InputMaybe<Scalars['String']>;
  mobilePhoneCountry?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  province?: InputMaybe<Scalars['String']>;
  zipcode?: InputMaybe<Scalars['String']>;
};

export enum LessonVoucherTransactionType {
  Adjustment = 'ADJUSTMENT',
  Award = 'AWARD',
  Purchase = 'PURCHASE',
  Redemption = 'REDEMPTION',
  Transfer = 'TRANSFER'
}

export type LineItem = {
  __typename?: 'LineItem';
  brand?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  eMenuId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  itemCode?: Maybe<Scalars['String']>;
  itemName: Scalars['String'];
  mCategoryId?: Maybe<Scalars['Int']>;
  mainCategory?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['Int']>;
  price: Scalars['Float'];
  qty: Scalars['Float'];
  salesPrice: Scalars['Float'];
  transactionId?: Maybe<Scalars['String']>;
  vendor?: Maybe<Scalars['String']>;
};

export type LineItemInput = {
  __typename?: 'LineItemInput';
  brand?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  itemCode?: Maybe<Scalars['String']>;
  itemName: Scalars['String'];
  mCategoryId?: Maybe<Scalars['Int']>;
  mainCategory?: Maybe<Scalars['String']>;
  memberUuid?: Maybe<Scalars['String']>;
  price: Scalars['Float'];
  qty: Scalars['Int'];
  salesPrice: Scalars['Float'];
  vendor?: Maybe<Scalars['String']>;
};

export type LoginInfo = {
  __typename?: 'LoginInfo';
  city?: Maybe<Scalars['String']>;
  ipAddress?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['DateTime']>;
};

export type ManagementSystem = {
  __typename?: 'ManagementSystem';
  eventId: Scalars['String'];
  evictionFieldNames: Array<Maybe<Scalars['String']>>;
  operation?: Maybe<ManagementSystemPublishOperation>;
  payload?: Maybe<Scalars['JSON']>;
  ref?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  userProfileImageUrl?: Maybe<Scalars['String']>;
};

export type ManagementSystemArgs = {
  userId: Scalars['String'];
};

export enum ManagementSystemPublishOperation {
  AdminBookingCreate = 'ADMIN_BOOKING_CREATE',
  AdminBookingUpdate = 'ADMIN_BOOKING_UPDATE',
  AdminMembershipServiceCreate = 'ADMIN_MEMBERSHIP_SERVICE_CREATE',
  AdminMemberCreate = 'ADMIN_MEMBER_CREATE',
  AdminMemberCreditUpdate = 'ADMIN_MEMBER_CREDIT_UPDATE',
  AdminMemberProfileUpdate = 'ADMIN_MEMBER_PROFILE_UPDATE',
  AdminOrganizationUpdate = 'ADMIN_ORGANIZATION_UPDATE',
  CoachAvailabilityDayUpsert = 'COACH_AVAILABILITY_DAY_UPSERT',
  DevAdminNotification = 'DEV_ADMIN_NOTIFICATION',
  DevAdminTelemetryRequest = 'DEV_ADMIN_TELEMETRY_REQUEST',
  DevAdminViewportReloadRequest = 'DEV_ADMIN_VIEWPORT_RELOAD_REQUEST',
  LegacyApiBooingCreate = 'LEGACY_API_BOOING_CREATE',
  MemberBookingCreate = 'MEMBER_BOOKING_CREATE',
  MemberKioskLogin = 'MEMBER_KIOSK_LOGIN',
  PublicApiQuery = 'PUBLIC_API_QUERY',
  Webhook = 'WEBHOOK'
}

export type MemberAccount = {
  __typename?: 'MemberAccount';
  aceCoin: MemberAccountAceCoin;
  benefits: MemberAccountBenefits;
  defaultBaseCountryCode: CountryCode;
  defaultGmtOffset: Scalars['Int'];
  profile: MemberAccountProfile;
  simulator: MemberAccountSimulator;
};

export type MemberAccountAcTransactionEdge = {
  __typename?: 'MemberAccountAcTransactionEdge';
  cursor?: Maybe<Scalars['Int']>;
  node?: Maybe<AceCoinTransaction>;
};

export type MemberAccountAcTransactionResponse = {
  __typename?: 'MemberAccountAcTransactionResponse';
  edges?: Maybe<Array<Maybe<MemberAccountAcTransactionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberAccountAcTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberAccountAceCoin = {
  __typename?: 'MemberAccountAceCoin';
  balance: Scalars['Float'];
  currentSpent: Scalars['Float'];
};

export type MemberAccountArchiveInput = {
  reason: Scalars['String'];
};

export type MemberAccountBenefits = {
  __typename?: 'MemberAccountBenefits';
  gameCredit: Scalars['Int'];
  lessonVoucherField: Scalars['Int'];
  lessonVoucherIndoor: Scalars['Int'];
  practiseCredit: Scalars['Int'];
  sidasInsoles: Scalars['Int'];
  simulatorHasPrivilege?: Maybe<Scalars['Boolean']>;
  simulatorHasPrivilegeHrs?: Maybe<Scalars['Int']>;
  simulatorUsageConditionMonFri?: Maybe<Scalars['String']>;
  simulatorUsageConditionMonSun?: Maybe<Scalars['String']>;
  simulatorUsageConditionSatSun?: Maybe<Scalars['String']>;
  simulatorUsageDateStr?: Maybe<Scalars['String']>;
  simulatorUsageTimeStr?: Maybe<Scalars['String']>;
};

export type MemberAccountBlackDiamondOverview = {
  __typename?: 'MemberAccountBlackDiamondOverview';
  hasSubscription?: Maybe<Scalars['Boolean']>;
  simulatorConditionMonFri?: Maybe<Scalars['String']>;
  simulatorConditionMonFriLocal?: Maybe<Scalars['String']>;
  simulatorConditionMonSun?: Maybe<Scalars['String']>;
  simulatorConditionMonSunLocal?: Maybe<Scalars['String']>;
  simulatorConditionSatSun?: Maybe<Scalars['String']>;
  simulatorConditionSatSunLocal?: Maybe<Scalars['String']>;
  simulatorDescription?: Maybe<Scalars['String']>;
  simulatorDescriptionLocal?: Maybe<Scalars['String']>;
  simulatorIsRedeemed?: Maybe<Scalars['Boolean']>;
  subscriptionDescription?: Maybe<Scalars['String']>;
  subscriptionDescriptionLocal?: Maybe<Scalars['String']>;
  subscriptionStatus?: Maybe<SubscriptionStatus>;
};

export type MemberAccountCoach = {
  __typename?: 'MemberAccountCoach';
  coachExperiences: Array<CoachExperience>;
  coachExpertise: Array<Scalars['String']>;
  coachLanguages: Array<Scalars['String']>;
  coachLessonField?: Maybe<Scalars['Int']>;
  coachLessonFieldVIP?: Maybe<Scalars['Int']>;
  coachLessonIndoor?: Maybe<Scalars['Int']>;
  coachLessonIndoorVIP?: Maybe<Scalars['Int']>;
  coachPGANo?: Maybe<Scalars['String']>;
  coachPGAYear?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  isFreelancer: Scalars['Boolean'];
  profileImageUrl: Scalars['String'];
  socialInstagram?: Maybe<Scalars['String']>;
};

export type MemberAccountCoachArgs = {
  coachId: Scalars['String'];
};

export type MemberAccountCoachBasic = {
  __typename?: 'MemberAccountCoachBasic';
  coachExpertise: Array<Scalars['String']>;
  displayName: Scalars['String'];
  profileImageUrl: Scalars['String'];
  socialInstagram?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
};

export type MemberAccountExpirableCredit = {
  __typename?: 'MemberAccountExpirableCredit';
  amount: Scalars['Float'];
  balance: Scalars['Float'];
  comment?: Maybe<Scalars['String']>;
  creditType: Scalars['String'];
  dateAdded: Scalars['DateTime'];
  dateAddedStr: Scalars['String'];
  expiryDate: Scalars['DateTime'];
  expiryDateStr: Scalars['String'];
  id: Scalars['String'];
};

export type MemberAccountExpirableCreditEdge = {
  __typename?: 'MemberAccountExpirableCreditEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MemberAccountExpirableCredit>;
};

export type MemberAccountExpirableCreditResponse = {
  __typename?: 'MemberAccountExpirableCreditResponse';
  edges?: Maybe<Array<Maybe<MemberAccountExpirableCreditEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberAccountExpirableCreditsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberAccountFindMember = {
  __typename?: 'MemberAccountFindMember';
  memberFound: Scalars['Boolean'];
  memberName?: Maybe<Scalars['String']>;
};

export type MemberAccountFindMemberArgs = {
  targetMemberCardId: Scalars['String'];
};

export type MemberAccountInvoiceArgs = {
  invoiceNo: Scalars['String'];
};

export type MemberAccountInvoiceBasic = {
  __typename?: 'MemberAccountInvoiceBasic';
  amount: Scalars['Float'];
  invoiceNo: Scalars['String'];
  paymentCurrency?: Maybe<PaymentCurrency>;
  paymentMethod: Scalars['String'];
  transactionDateTime: Scalars['DateTime'];
  venue?: Maybe<Scalars['String']>;
};

export type MemberAccountInvoicesArgs = {
  yearMonth?: InputMaybe<Scalars['String']>;
};

export type MemberAccountLessonNote = {
  __typename?: 'MemberAccountLessonNote';
  bookingId?: Maybe<Scalars['String']>;
  coachDisplayName: Scalars['String'];
  comment: Scalars['String'];
  dateStr: Scalars['String'];
  id: Scalars['String'];
  lessonType: Scalars['String'];
  venue: Scalars['String'];
};

export type MemberAccountLessonNoteArgs = {
  id: Scalars['String'];
};

export type MemberAccountLessonNoteBasic = {
  __typename?: 'MemberAccountLessonNoteBasic';
  bookingId?: Maybe<Scalars['String']>;
  coachDisplayName: Scalars['String'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  lessonType: Scalars['String'];
  venue: Scalars['String'];
};

export type MemberAccountLessonNoteEdge = {
  __typename?: 'MemberAccountLessonNoteEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MemberAccountLessonNoteBasic>;
};

export type MemberAccountLessonNoteResponse = {
  __typename?: 'MemberAccountLessonNoteResponse';
  edges?: Maybe<Array<Maybe<MemberAccountLessonNoteEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberAccountLessonNotesArgs = {
  after?: InputMaybe<Scalars['String']>;
  coachIdFilter?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberAccountNotificationArgs = {
  notificationOnMemberId: Scalars['String'];
};

export type MemberAccountNotificationBasic = {
  __typename?: 'MemberAccountNotificationBasic';
  createdAt: Scalars['DateTime'];
  expiry?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  isReadDate?: Maybe<Scalars['DateTime']>;
  mode?: Maybe<NotificationMode>;
  notificationOnMemberId?: Maybe<Scalars['String']>;
  preview: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type MemberAccountNotificationDelete = {
  __typename?: 'MemberAccountNotificationDelete';
  newNotificationCount: Scalars['Int'];
};

export type MemberAccountNotificationDeleteInput = {
  notificationId: Scalars['String'];
};

export type MemberAccountNotificationEdge = {
  __typename?: 'MemberAccountNotificationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MemberAccountNotificationBasic>;
};

export type MemberAccountNotificationResponse = {
  __typename?: 'MemberAccountNotificationResponse';
  edges?: Maybe<Array<Maybe<MemberAccountNotificationEdge>>>;
  pageInfo?: Maybe<MemberAccountNotificationsPageInfo>;
};

export type MemberAccountNotificationUpdateInput = {
  isDismissed?: InputMaybe<Scalars['Boolean']>;
  isRead?: InputMaybe<Scalars['Boolean']>;
  notificationId: Scalars['String'];
};

export type MemberAccountNotificationUpdateV2 = {
  __typename?: 'MemberAccountNotificationUpdateV2';
  newNotificationCount: Scalars['Int'];
};

export type MemberAccountNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberAccountNotificationsPageInfo = {
  __typename?: 'MemberAccountNotificationsPageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  newNotificationCount?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
};

export type MemberAccountPackage = {
  __typename?: 'MemberAccountPackage';
  amount: Scalars['Float'];
  categoryDisplayName?: Maybe<Scalars['String']>;
  dateAdded?: Maybe<Scalars['DateTime']>;
  dateAddedStr?: Maybe<Scalars['String']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  expiryDateStr?: Maybe<Scalars['String']>;
  expiryWarning?: Maybe<Scalars['Boolean']>;
  groupMemberIndividualId: Scalars['String'];
  groupMemberName: Scalars['String'];
  hasSupplementaryRedemption: Scalars['Boolean'];
  isDailyRedeemed: Scalars['Boolean'];
  packageName: Scalars['String'];
  serviceId: Scalars['String'];
  supplementaryAmount: Scalars['Float'];
  supplementaryUnitName: Scalars['String'];
  transactions: Array<Maybe<ServiceTransactionBasic>>;
  unitType: Scalars['String'];
};

export type MemberAccountPackageArgs = {
  serviceId: Scalars['String'];
};

export type MemberAccountPackageBasic = {
  __typename?: 'MemberAccountPackageBasic';
  amount: Scalars['Float'];
  categoryDisplayName: Scalars['String'];
  dateAdded?: Maybe<Scalars['DateTime']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  expiryWarning?: Maybe<Scalars['Boolean']>;
  isDailyRedeemed: Scalars['Boolean'];
  packageType: Scalars['String'];
  parentServiceId?: Maybe<Scalars['String']>;
  serviceId: Scalars['String'];
  serviceName: Scalars['String'];
  supplementaryAmount: Scalars['Float'];
  supplementaryUnitName: Scalars['String'];
  unitType: Scalars['String'];
};

export type MemberAccountPackageBuyInput = {
  itemCode: Scalars['String'];
};

export type MemberAccountPackageEdge = {
  __typename?: 'MemberAccountPackageEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MemberAccountPackageBasic>;
};

export type MemberAccountPackageOfferArgs = {
  itemCode: Scalars['String'];
};

export type MemberAccountPackageOffers = {
  __typename?: 'MemberAccountPackageOffers';
  types: Array<Maybe<MemberPackageOfferType>>;
};

export type MemberAccountPackageOverview = {
  __typename?: 'MemberAccountPackageOverview';
  count: Scalars['Int'];
  expiryCreditCount: Scalars['Float'];
  expiryDescription: Scalars['String'];
  expiryDescriptionLocal: Scalars['String'];
};

export type MemberAccountPackageResponse = {
  __typename?: 'MemberAccountPackageResponse';
  edges?: Maybe<Array<Maybe<MemberAccountPackageEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberAccountPackagesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  mode: MemberAccountPackagesMode;
  orderBy?: InputMaybe<OrderBy>;
};

export enum MemberAccountPackagesMode {
  Active = 'ACTIVE',
  Completed = 'COMPLETED'
}

export type MemberAccountProfile = {
  __typename?: 'MemberAccountProfile';
  creditToNextLevel: Scalars['Float'];
  email: Scalars['String'];
  firstName: Scalars['String'];
  gender: Gender;
  handed: Handed;
  lastName: Scalars['String'];
  memberId: Scalars['String'];
  membershipDisplayName: Scalars['String'];
  mobileNumber: Scalars['String'];
  nextLevelDisplayName: Scalars['String'];
  startDate: Scalars['DateTime'];
  subscription: MemberAccountProfileSubscription;
};

export type MemberAccountProfileSubscription = {
  __typename?: 'MemberAccountProfileSubscription';
  daysLeft: Scalars['Int'];
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  status: SubscriptionStatus;
};

export type MemberAccountPushTokenAddInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  expoProjectId: Scalars['String'];
  pushToken: Scalars['String'];
};

export type MemberAccountPushTokenDeleteInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  pushToken: Scalars['String'];
};

export type MemberAccountRates = {
  __typename?: 'MemberAccountRates';
  currencySymbol: Scalars['String'];
  games: Array<MemberAccountRatesGame>;
  isVip: Scalars['Boolean'];
  lesson?: Maybe<MemberAccountRatesLesson>;
  practice?: Maybe<MemberAccountRatesPractice>;
};

export type MemberAccountRatesGame = {
  __typename?: 'MemberAccountRatesGame';
  rateBay: Scalars['Float'];
  rateBayVip: Scalars['Float'];
  rateRoom: Scalars['Float'];
  rateRoomVip: Scalars['Float'];
  title: Scalars['String'];
  unit: Scalars['String'];
};

export type MemberAccountRatesLesson = {
  __typename?: 'MemberAccountRatesLesson';
  field: MemberAccountRatesLessonField;
  junior: Array<MemberAccountRatesLessonJunior>;
  lesson: Array<MemberAccountRatesLessonLesson>;
};

export type MemberAccountRatesLessonField = {
  __typename?: 'MemberAccountRatesLessonField';
  indoor9Hole: MemberAccountRatesLessonFieldIndoor9Hole;
  indoor18Hole: MemberAccountRatesLessonFieldIndoor18Hole;
  outdoor: MemberAccountRatesLessonFieldOutdoor;
};

export type MemberAccountRatesLessonFieldIndoor9Hole = {
  __typename?: 'MemberAccountRatesLessonFieldIndoor9Hole';
  rate: Scalars['Float'];
  title: Scalars['String'];
};

export type MemberAccountRatesLessonFieldIndoor18Hole = {
  __typename?: 'MemberAccountRatesLessonFieldIndoor18Hole';
  rate: Scalars['Float'];
  title: Scalars['String'];
};

export type MemberAccountRatesLessonFieldOutdoor = {
  __typename?: 'MemberAccountRatesLessonFieldOutdoor';
  rate: Scalars['Float'];
  title: Scalars['String'];
};

export type MemberAccountRatesLessonJunior = {
  __typename?: 'MemberAccountRatesLessonJunior';
  displayName: Scalars['String'];
  period: Scalars['String'];
  rate: Scalars['Float'];
  tc: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type MemberAccountRatesLessonLesson = {
  __typename?: 'MemberAccountRatesLessonLesson';
  displayName: Scalars['String'];
  period: Scalars['String'];
  rateGroup: Scalars['Float'];
  rateIndividual: Scalars['Float'];
  rateIndividualVip: Scalars['Float'];
  tc: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type MemberAccountRatesPractice = {
  __typename?: 'MemberAccountRatesPractice';
  elite: Array<MemberAccountRatesPracticeElite>;
  normal: Array<MemberAccountRatesPracticeNormal>;
  premium: Array<MemberAccountRatesPracticePremium>;
};

export type MemberAccountRatesPracticeElite = {
  __typename?: 'MemberAccountRatesPracticeElite';
  categoryListOrder?: Maybe<Scalars['Int']>;
  displayName: Scalars['String'];
  isEarlyBird: Scalars['Boolean'];
  limit: Scalars['String'];
  period: Scalars['String'];
  rate: Scalars['Float'];
  tc: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type MemberAccountRatesPracticeNormal = {
  __typename?: 'MemberAccountRatesPracticeNormal';
  facilityType: FacilityType;
  rate: Scalars['Float'];
  rateVip: Scalars['Float'];
  title: Scalars['String'];
};

export type MemberAccountRatesPracticePremium = {
  __typename?: 'MemberAccountRatesPracticePremium';
  categoryListOrder?: Maybe<Scalars['Int']>;
  displayName: Scalars['String'];
  isEarlyBird: Scalars['Boolean'];
  period: Scalars['String'];
  rate: Scalars['Float'];
  tc: Array<Scalars['String']>;
  title: Scalars['String'];
};

export type MemberAccountServiceTransactionEdge = {
  __typename?: 'MemberAccountServiceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ServiceTransactionBasic>;
};

export type MemberAccountServiceTransactionResponse = {
  __typename?: 'MemberAccountServiceTransactionResponse';
  edges?: Maybe<Array<Maybe<MemberAccountServiceTransactionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberAccountServiceTransactionV2Edge = {
  __typename?: 'MemberAccountServiceTransactionV2Edge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<ServiceTransactionBasic>;
};

export type MemberAccountServiceTransactionV2Response = {
  __typename?: 'MemberAccountServiceTransactionV2Response';
  edges?: Maybe<Array<Maybe<MemberAccountServiceTransactionV2Edge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberAccountServiceTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberAccountSimulator = {
  __typename?: 'MemberAccountSimulator';
  nickname: Scalars['String'];
  password: Scalars['String'];
  userId: Scalars['String'];
};

export type MemberAccountSubscription = {
  __typename?: 'MemberAccountSubscription';
  amount: Scalars['Float'];
  deductionDate: Scalars['DateTime'];
  deductionDateStr: Scalars['String'];
  description: Scalars['String'];
  id: Scalars['String'];
};

export type MemberAccountSubscriptionEdge = {
  __typename?: 'MemberAccountSubscriptionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MemberAccountSubscription>;
};

export type MemberAccountSubscriptionResponse = {
  __typename?: 'MemberAccountSubscriptionResponse';
  edges?: Maybe<Array<Maybe<MemberAccountSubscriptionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  status?: Maybe<SubscriptionStatus>;
  subscriptionEndDate?: Maybe<Scalars['DateTime']>;
  subscriptionEndDateStr?: Maybe<Scalars['String']>;
  subscriptionStartDate?: Maybe<Scalars['DateTime']>;
  subscriptionStartDateStr?: Maybe<Scalars['String']>;
};

export type MemberAccountSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberAccountV2 = {
  __typename?: 'MemberAccountV2';
  aceCoins: Scalars['Float'];
  blackDiamondOverview?: Maybe<MemberAccountBlackDiamondOverview>;
  bookingGame: Scalars['Int'];
  bookingLesson: Scalars['Int'];
  bookingPractise: Scalars['Int'];
  creditField: Scalars['Float'];
  creditGame: Scalars['Float'];
  creditIndoor: Scalars['Float'];
  creditPractise: Scalars['Float'];
  defaultBaseCountryCode: CountryCode;
  defaultGmtOffset: Scalars['Int'];
  email: Scalars['String'];
  gender?: Maybe<Gender>;
  handed?: Maybe<Handed>;
  hasFeedback: Scalars['Boolean'];
  hasPackageOffers: Scalars['Boolean'];
  individualId: Scalars['String'];
  memberCardProps: MemberCardProps;
  memberName: Scalars['String'];
  mobileNumber?: Maybe<Scalars['String']>;
  newNotifications: Scalars['Int'];
  packageOverview?: Maybe<MemberAccountPackageOverview>;
  simulator: MemberAccountSimulator;
  startDate?: Maybe<Scalars['DateTime']>;
  startDateStr?: Maybe<Scalars['String']>;
  tierName: Scalars['String'];
};

export type MemberAddFriendInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  loginId: Scalars['String'];
};

export type MemberAddPushTokenInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  pushToken: Scalars['String'];
};

export type MemberApp = {
  __typename?: 'MemberApp';
  eventId?: Maybe<Scalars['String']>;
  evictionFieldNames: Array<Maybe<Scalars['String']>>;
  memberUuid?: Maybe<Scalars['String']>;
};

export type MemberAppArgs = {
  memberUuid: Scalars['String'];
};

export enum MemberAppPublishOperation {
  UpdatedAccount = 'UPDATED_ACCOUNT'
}

export type MemberAuditDataInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  browserName?: InputMaybe<Scalars['String']>;
  browserVersion?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  deviceBrand?: InputMaybe<Scalars['String']>;
  deviceModelId?: InputMaybe<Scalars['String']>;
  deviceModelName?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  osName?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  timezoneOffset?: InputMaybe<Scalars['Int']>;
};

export enum MemberAuditLogEventName {
  BookingCancel = 'BOOKING_CANCEL',
  BookingCreate = 'BOOKING_CREATE',
  KioskLogin = 'KIOSK_LOGIN',
  Login = 'LOGIN',
  LoginBiometric = 'LOGIN_BIOMETRIC'
}

export type MemberAuditLogSaveDataInput = {
  appVersion?: InputMaybe<Scalars['String']>;
  browserName?: InputMaybe<Scalars['String']>;
  browserVersion?: InputMaybe<Scalars['String']>;
  createdAt?: InputMaybe<Scalars['DateTime']>;
  description?: InputMaybe<Scalars['String']>;
  deviceBrand?: InputMaybe<Scalars['String']>;
  deviceModelId?: InputMaybe<Scalars['String']>;
  deviceModelName?: InputMaybe<Scalars['String']>;
  deviceName?: InputMaybe<Scalars['String']>;
  eventName?: InputMaybe<MemberAuditLogEventName>;
  ipAddress?: InputMaybe<Scalars['String']>;
  memberId: Scalars['String'];
  osName?: InputMaybe<Scalars['String']>;
  osVersion?: InputMaybe<Scalars['String']>;
  timezoneOffset?: InputMaybe<Scalars['Int']>;
  typeRefId?: InputMaybe<Scalars['String']>;
};

export type MemberAuthResponse = {
  __typename?: 'MemberAuthResponse';
  accessToken: Scalars['String'];
  authenticatedMember: AuthenticatedMember;
  countryCode?: Maybe<CountryCode>;
  isVip?: Maybe<Scalars['Boolean']>;
  refreshToken: Scalars['String'];
};

export type MemberBooking = {
  __typename?: 'MemberBooking';
  amountInAceCoins: Scalars['String'];
  amountInCredit: Scalars['String'];
  amountInLocalCurrency?: Maybe<Scalars['String']>;
  cancellationDateTime?: Maybe<Scalars['DateTime']>;
  coach?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  description: Scalars['String'];
  durationStr: Scalars['String'];
  endTime: Scalars['DateTime'];
  facilityTypeStr?: Maybe<Scalars['String']>;
  fieldLessonDetails?: Maybe<Scalars['String']>;
  gameTypeStr?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  noOfPlayersStr?: Maybe<Scalars['String']>;
  rateInAceCoins: Scalars['String'];
  rateInLocalCurrency?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  status: BookingStatus;
  textCancellationBody: Scalars['String'];
  textCancellationBodyLocal: Scalars['String'];
  textCancellationHeader: Scalars['String'];
  textCancellationHeaderLocal: Scalars['String'];
  type: BookingType;
  typeStr: Scalars['String'];
  venue: Scalars['String'];
};

export type MemberBookingArgs = {
  bookingId: Scalars['String'];
};

export type MemberBookingBasic = {
  __typename?: 'MemberBookingBasic';
  cancellationDateTime?: Maybe<Scalars['DateTime']>;
  coachDisplayName?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  endTime: Scalars['DateTime'];
  id: Scalars['String'];
  isGroupBooking?: Maybe<Scalars['Boolean']>;
  resourceName?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  status: BookingStatus;
  type: BookingType;
  venue: Scalars['String'];
};

export type MemberBookingCancelInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  bookingId: Scalars['String'];
};

export type MemberBookingCheckout = {
  __typename?: 'MemberBookingCheckout';
  amountInAceCoins: Scalars['String'];
  amountInCredit: Scalars['String'];
  amountInLocalCurrency?: Maybe<Scalars['String']>;
  balanceBefore: Scalars['String'];
  description: Scalars['String'];
  rateInAceCoins: Scalars['String'];
  rateInLocalCurrency?: Maybe<Scalars['String']>;
  textCancellationBody: Scalars['String'];
  textCancellationBodyLocal: Scalars['String'];
  textCancellationHeader: Scalars['String'];
  textCancellationHeaderLocal: Scalars['String'];
  textConfirm: Scalars['String'];
  textConfirmLocal: Scalars['String'];
  textDoubleBookingBody: Scalars['String'];
  textDoubleBookingBodyLocal: Scalars['String'];
  textDoubleBookingHeader: Scalars['String'];
  textDoubleBookingHeaderLocal: Scalars['String'];
  textRights: Scalars['String'];
  textRightsLocal: Scalars['String'];
};

export type MemberBookingCheckoutV2 = {
  __typename?: 'MemberBookingCheckoutV2';
  amountInAceCoins: Scalars['String'];
  amountInCredit: Scalars['String'];
  amountInLocalCurrency?: Maybe<Scalars['String']>;
  balanceBefore: Scalars['String'];
  description: Scalars['String'];
  rateInAceCoins: Scalars['String'];
  rateInLocalCurrency?: Maybe<Scalars['String']>;
  textCancellationBody?: Maybe<Scalars['String']>;
  textCancellationBodyLocal?: Maybe<Scalars['String']>;
  textCancellationHeader?: Maybe<Scalars['String']>;
  textCancellationHeaderLocal?: Maybe<Scalars['String']>;
  textConfirm: Scalars['String'];
  textConfirmLocal: Scalars['String'];
  textDoubleBookingBody?: Maybe<Scalars['String']>;
  textDoubleBookingBodyLocal?: Maybe<Scalars['String']>;
  textDoubleBookingHeader?: Maybe<Scalars['String']>;
  textDoubleBookingHeaderLocal?: Maybe<Scalars['String']>;
  textReminderBody?: Maybe<Scalars['String']>;
  textReminderBodyLocal?: Maybe<Scalars['String']>;
  textReminderHeader?: Maybe<Scalars['String']>;
  textReminderHeaderLocal?: Maybe<Scalars['String']>;
  textRights: Scalars['String'];
  textRightsLocal: Scalars['String'];
};

export type MemberBookingClubhouse = {
  __typename?: 'MemberBookingClubhouse';
  clubhouseCode: Scalars['String'];
  displayName: Scalars['String'];
  extendedHourStartsFrom?: Maybe<Scalars['Int']>;
  hasFacilityBay: Scalars['Boolean'];
  hasFacilityPutting: Scalars['Boolean'];
  hasFacilityRoom: Scalars['Boolean'];
  hasFacilityStudio: Scalars['Boolean'];
  tagBgColorCode: Scalars['String'];
  tagText: Scalars['String'];
  tagTextColorCode: Scalars['String'];
};

export type MemberBookingCoach = {
  __typename?: 'MemberBookingCoach';
  coachId?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
};

export type MemberBookingCoachAvailabilityCalendarItems = {
  __typename?: 'MemberBookingCoachAvailabilityCalendarItems';
  calendarItems: Array<Maybe<MemberCoachAvailabilityCalendarItems>>;
};

export type MemberBookingCoachAvailabilityCalendarItemsArgs = {
  coachId: Scalars['String'];
  currentDate: Scalars['String'];
  mode: CoachAvailabilityCalendarItemsMode;
};

export type MemberBookingCoachAvailabilityDayItem = {
  __typename?: 'MemberBookingCoachAvailabilityDayItem';
  clubhouseBgCode: Scalars['String'];
  clubhouseCode: Scalars['String'];
  clubhouseName: Scalars['String'];
  date: Scalars['String'];
  dateLong: Scalars['String'];
  time: Scalars['String'];
  timeNum: Scalars['Int'];
  timeStr: Scalars['String'];
  timeStrSpan1hr: Scalars['String'];
  timeStrSpan2hr: Scalars['String'];
  timeStrSpanPutting: Scalars['String'];
};

export type MemberBookingCoachAvailabilityDayItemArgs = {
  dateId: Scalars['String'];
};

export type MemberBookingCoachAvailabilityDayItemV2 = {
  __typename?: 'MemberBookingCoachAvailabilityDayItemV2';
  clubhouseBgCode: Scalars['String'];
  clubhouseCode: Scalars['String'];
  clubhouseName: Scalars['String'];
  date: Scalars['String'];
  dateLong: Scalars['String'];
  time: Scalars['String'];
  timeNum: Scalars['Int'];
  timeStr: Scalars['String'];
  timeStrSpan1hr: Scalars['String'];
  timeStrSpan2hr: Scalars['String'];
  timeStrSpanPutting: Scalars['String'];
  warmUptimeStrSpan: Scalars['String'];
};

export type MemberBookingCoachAvailabilityDayItemV2Args = {
  dateId: Scalars['String'];
  mode: CoachAvailabilityDayItemMode;
};

export type MemberBookingCoachAvailabilitySlotItem = {
  __typename?: 'MemberBookingCoachAvailabilitySlotItem';
  normalHr1Available: Scalars['Boolean'];
  normalHr2Available: Scalars['Boolean'];
  puttingHr1Available: Scalars['Boolean'];
};

export type MemberBookingCoachAvailabilitySlotItemArgs = {
  clubhouseCode: Scalars['String'];
  dateId: Scalars['String'];
  time: Scalars['String'];
};

export enum MemberBookingCoachesMode {
  Indoor = 'INDOOR',
  Outdoor = 'OUTDOOR'
}

export type MemberBookingCoachesV2Args = {
  mode?: InputMaybe<MemberBookingCoachesMode>;
};

export type MemberBookingEdge = {
  __typename?: 'MemberBookingEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MemberBookingBasic>;
};

export type MemberBookingFacilityAvailabilityCalendarItems = {
  __typename?: 'MemberBookingFacilityAvailabilityCalendarItems';
  calendarItems: Array<Maybe<FacilityAvailabilityCalendarItems>>;
};

export type MemberBookingFacilityAvailabilityCalendarItemsArgs = {
  clubhouseCode: Scalars['String'];
  currentDate: Scalars['String'];
};

export type MemberBookingFacilityAvailabilityDayItem = {
  __typename?: 'MemberBookingFacilityAvailabilityDayItem';
  ampm: Scalars['String'];
  date: Scalars['String'];
  time: Scalars['String'];
  timeNum: Scalars['Int'];
  timeNumEnd?: Maybe<Scalars['Int']>;
  timeStr: Scalars['String'];
  timeStrSpan: Scalars['String'];
};

export type MemberBookingFacilityAvailabilityDayItemArgs = {
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
  duration: FacilityUsageDuration;
  facilityType: FacilityType;
  gameType?: InputMaybe<GameType>;
  noOfPlayers: Scalars['Int'];
};

export type MemberBookingFacilityAvailabilityDayItems = {
  __typename?: 'MemberBookingFacilityAvailabilityDayItems';
  extended: Array<Maybe<MemberBookingFacilityAvailabilityDayItem>>;
  normal: Array<Maybe<MemberBookingFacilityAvailabilityDayItem>>;
};

export type MemberBookingGameCheckoutArgs = {
  duration: FacilityUsageDuration;
  facilityType: FacilityType;
  gameType: GameType;
  noOfPlayers: Scalars['Int'];
};

export type MemberBookingGameCreate = {
  __typename?: 'MemberBookingGameCreate';
  bookingId: Scalars['String'];
  status: SystemOperationStatus;
};

export type MemberBookingGameCreateInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
  duration: FacilityUsageDuration;
  facilityType: FacilityType;
  gameType: GameType;
  noOfPlayers: Scalars['Int'];
  time: Scalars['String'];
};

export type MemberBookingGroupMember = {
  __typename?: 'MemberBookingGroupMember';
  individualId: Scalars['String'];
  memberName: Scalars['String'];
  memberUuid: Scalars['String'];
  packageId: Scalars['String'];
  selfPackageId: Scalars['String'];
  selfPackageName: Scalars['String'];
};

export type MemberBookingGroupMembers = {
  __typename?: 'MemberBookingGroupMembers';
  members: Array<Maybe<MemberBookingGroupMember>>;
};

export type MemberBookingGroupMembersArgs = {
  packageId?: InputMaybe<Scalars['String']>;
};

export type MemberBookingHelper = {
  __typename?: 'MemberBookingHelper';
  clubhouses: Array<Maybe<MemberBookingClubhouse>>;
  gameBookingConditions: GameBookingConditions;
  practiseBookingConditions: PractiseBookingConditions;
};

export type MemberBookingHelperExtendedHour = {
  __typename?: 'MemberBookingHelperExtendedHour';
  textExtendedHourBody?: Maybe<Scalars['String']>;
  textExtendedHourBodyLocal?: Maybe<Scalars['String']>;
  textExtendedHourHeader?: Maybe<Scalars['String']>;
  textExtendedHourHeaderLocal?: Maybe<Scalars['String']>;
};

export type MemberBookingHelperV2 = {
  __typename?: 'MemberBookingHelperV2';
  clubhouses: Array<Maybe<MemberBookingClubhouse>>;
  gameBookingConditions: GameBookingConditions;
  hasGroupMembers: Scalars['Boolean'];
  memberBookingHelperExtendedHour?: Maybe<MemberBookingHelperExtendedHour>;
  practiseBookingConditions: PractiseBookingConditions;
  preConfirmationCopy?: Maybe<PreConfirmationCopy>;
  premiumPackagePractiseConditionCopy?: Maybe<PremiumPackagePractiseConditionCopy>;
  premiumPackagePractiseExpiryDate?: Maybe<Scalars['DateTime']>;
  teeTimeMax: Scalars['Int'];
  teeTimeMin: Scalars['Int'];
};

export type MemberBookingHelperV2Args = {
  bookingType: BookingType;
};

export type MemberBookingLessonIndoorCheckoutArgs = {
  coachId: Scalars['String'];
  isLessonExtended: Scalars['Boolean'];
  lessonType: IndoorLessonType;
};

export type MemberBookingLessonIndoorCreate = {
  __typename?: 'MemberBookingLessonIndoorCreate';
  bookingId: Scalars['String'];
  status: SystemOperationStatus;
};

export type MemberBookingLessonIndoorCreateInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  clubhouseCode: Scalars['String'];
  coachSlug: Scalars['String'];
  date: Scalars['String'];
  isLessonExtended: Scalars['Boolean'];
  lessonType: IndoorLessonType;
  time: Scalars['String'];
};

export type MemberBookingLessonIndoorCreateV2Input = {
  audit?: InputMaybe<MemberAuditDataInput>;
  clubhouseCode: Scalars['String'];
  coachSlug: Scalars['String'];
  date: Scalars['String'];
  groupMemberId?: InputMaybe<Scalars['String']>;
  isLessonExtended: Scalars['Boolean'];
  lessonType: IndoorLessonType;
  time: Scalars['String'];
};

export type MemberBookingLessonOutdoorCheckoutArgs = {
  coachId: Scalars['String'];
};

export type MemberBookingLessonOutdoorCreate = {
  __typename?: 'MemberBookingLessonOutdoorCreate';
  bookingId: Scalars['String'];
  status: SystemOperationStatus;
};

export type MemberBookingLessonOutdoorCreateInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  coachSlug: Scalars['String'];
  date: Scalars['String'];
  details: Scalars['String'];
  venue: Scalars['String'];
};

export type MemberBookingLessonOutdoorCreateV2Input = {
  audit?: InputMaybe<MemberAuditDataInput>;
  coachSlug: Scalars['String'];
  date: Scalars['String'];
  details: Scalars['String'];
  teeTime: Scalars['String'];
  venue: Scalars['String'];
};

export type MemberBookingPractiseCheckoutArgs = {
  duration: FacilityUsageDuration;
  facilityType: FacilityType;
  noOfPlayers: Scalars['Int'];
};

export type MemberBookingPractiseCreate = {
  __typename?: 'MemberBookingPractiseCreate';
  bookingId: Scalars['String'];
  status: SystemOperationStatus;
};

export type MemberBookingPractiseCreateInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
  duration: FacilityUsageDuration;
  facilityType: FacilityType;
  noOfPlayers: Scalars['Int'];
  time: Scalars['String'];
};

export type MemberBookingPractiseCreateV2Input = {
  audit?: InputMaybe<MemberAuditDataInput>;
  clubhouseCode: Scalars['String'];
  date: Scalars['String'];
  duration: FacilityUsageDuration;
  facilityType: FacilityType;
  noOfPlayers: Scalars['Int'];
  time: Scalars['String'];
};

export type MemberBookingResponse = {
  __typename?: 'MemberBookingResponse';
  edges?: Maybe<Array<Maybe<MemberBookingEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberBookingUpdateToGroupInput = {
  bookingId: Scalars['String'];
  partnerMemberUuid: Scalars['String'];
};

export type MemberBookingV2 = {
  __typename?: 'MemberBookingV2';
  amountInAceCoins: Scalars['String'];
  amountInCredit: Scalars['String'];
  amountInLocalCurrency?: Maybe<Scalars['String']>;
  cancellationDateTime?: Maybe<Scalars['DateTime']>;
  coach?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  description: Scalars['String'];
  durationStr: Scalars['String'];
  endTime: Scalars['DateTime'];
  facilityTypeStr?: Maybe<Scalars['String']>;
  fieldLessonDetails?: Maybe<Scalars['String']>;
  gameTypeStr?: Maybe<Scalars['String']>;
  groupMembers?: Maybe<Array<Maybe<GroupMember>>>;
  hasGroupMembers?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isGroupBooking: Scalars['Boolean'];
  noOfPlayersStr?: Maybe<Scalars['String']>;
  rateInAceCoins: Scalars['String'];
  rateInLocalCurrency?: Maybe<Scalars['String']>;
  resourceName?: Maybe<Scalars['String']>;
  startTime: Scalars['DateTime'];
  status: BookingStatus;
  textCancellationBody: Scalars['String'];
  textCancellationBodyLocal: Scalars['String'];
  textCancellationHeader: Scalars['String'];
  textCancellationHeaderLocal: Scalars['String'];
  type: BookingType;
  typeStr: Scalars['String'];
  venue: Scalars['String'];
};

export type MemberBookingsActiveArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberBookingsArgs = {
  after?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['DateTime']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  startTime?: InputMaybe<Scalars['DateTime']>;
  status?: InputMaybe<BookingStatus>;
};

export type MemberBookingsCompletedArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  type?: InputMaybe<Scalars['String']>;
};

export type MemberCardProps = {
  __typename?: 'MemberCardProps';
  bgColor: Scalars['String'];
  bgImageName: Scalars['String'];
  textColor: Scalars['String'];
};

export type MemberCoachAvailabilityCalendarItems = {
  __typename?: 'MemberCoachAvailabilityCalendarItems';
  clubhouses: Array<Maybe<Scalars['String']>>;
  date: Scalars['String'];
};

export type MemberCreditTransactionEdge = {
  __typename?: 'MemberCreditTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CreditTransaction>;
};

export type MemberCreditTransactionResponse = {
  __typename?: 'MemberCreditTransactionResponse';
  edges?: Maybe<Array<Maybe<MemberCreditTransactionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberCreditTransactionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberDeleteRefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type MemberFindMember = {
  __typename?: 'MemberFindMember';
  memberFound: Scalars['Boolean'];
  memberName?: Maybe<Scalars['String']>;
};

export type MemberFindMemberInput = {
  targetMemberCardId: Scalars['String'];
};

export type MemberForgotPassword = {
  __typename?: 'MemberForgotPassword';
  email: Scalars['String'];
  resetToken: Scalars['String'];
};

export type MemberForgotPasswordInput = {
  email: Scalars['String'];
};

export type MemberForgotPasswordSubmitInput = {
  confirmPassword: Scalars['String'];
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetToken: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type MemberFriend = {
  __typename?: 'MemberFriend';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  individualId?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type MemberFriendLastTransfer = {
  __typename?: 'MemberFriendLastTransfer';
  amount: Scalars['Float'];
  date: Scalars['DateTime'];
  email?: Maybe<Scalars['String']>;
  friendName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  individualId?: Maybe<Scalars['String']>;
};

export type MemberFriendV2 = {
  __typename?: 'MemberFriendV2';
  email?: Maybe<Scalars['String']>;
  friendName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  individualId?: Maybe<Scalars['String']>;
};

export type MemberFriendsV2 = {
  __typename?: 'MemberFriendsV2';
  friends: Array<Maybe<MemberFriendV2>>;
  lastTransfer?: Maybe<MemberFriendLastTransfer>;
};

export type MemberGameQrCodeLoginInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  clientToken?: InputMaybe<Scalars['String']>;
};

export type MemberGameRound = {
  __typename?: 'MemberGameRound';
  cdn: Scalars['String'];
  scorecard: Array<Maybe<MemberGameRoundScoreCard>>;
};

export type MemberGameRoundCourseInfo = {
  __typename?: 'MemberGameRoundCourseInfo';
  courseInTotalPar: Scalars['Int'];
  courseMapCoordinates: Array<Maybe<CourseMapCoordinate>>;
  courseOutTotalPar: Scalars['Int'];
  coursePar: Array<Scalars['Int']>;
  courseTotalPar: Scalars['Int'];
  intro: Scalars['String'];
  logo: Scalars['String'];
  newPerioHiddenHole: Scalars['String'];
};

export type MemberGameRoundGameInfo = {
  __typename?: 'MemberGameRoundGameInfo';
  clientGuid: Scalars['String'];
  clientName: Scalars['String'];
  completeHoleCount: Scalars['Int'];
  concede: Scalars['Int'];
  courseId?: Maybe<Scalars['String']>;
  courseInfo: MemberGameRoundCourseInfo;
  courseName: Scalars['String'];
  distanceUnit: Scalars['String'];
  gameEndTime: Scalars['Int'];
  gameHoleType: Scalars['String'];
  gameRoundId: Scalars['Int'];
  gameStartTime: Scalars['Int'];
  gameType: Scalars['Int'];
  gameTypeText: Scalars['String'];
  greenLocation: Scalars['String'];
  greenSpeed: Scalars['String'];
  holeCupLocation: Scalars['String'];
  isBestVideo: Scalars['Boolean'];
  isCaddyVoice: Scalars['Boolean'];
  isUseObHazardTee: Scalars['Boolean'];
  mulliganCount: Scalars['Int'];
  playHoleCount: Scalars['Int'];
  puttingLine: Scalars['String'];
  shopGuid: Scalars['String'];
  shopName: Scalars['String'];
  tournamentIdx: Scalars['Int'];
  useSwingPlate: Scalars['String'];
  windSpeed: Scalars['String'];
};

export type MemberGameRoundPlayerInfo = {
  __typename?: 'MemberGameRoundPlayerInfo';
  completeHoleCount: Scalars['Int'];
  difficulty: Scalars['Int'];
  handicap: Scalars['Int'];
  holes: Array<Maybe<MemberGameRoundPlayerInfoHole>>;
  inTotalScore: Scalars['Int'];
  nickname: Scalars['String'];
  outTotalScore: Scalars['Int'];
  playerType: Scalars['Int'];
  rank: Scalars['Int'];
  startHole: Scalars['Int'];
  team: Scalars['Int'];
  totalScore: Scalars['String'];
  userGuid: Scalars['String'];
};

export type MemberGameRoundPlayerInfoHole = {
  __typename?: 'MemberGameRoundPlayerInfoHole';
  holeNumber: Scalars['Int'];
  holeOutType: Scalars['Int'];
  isBestVideo: Scalars['Boolean'];
  isConcede: Scalars['Boolean'];
  isLongest: Scalars['Boolean'];
  isMulligan: Scalars['Boolean'];
  isNearpin: Scalars['Boolean'];
  score: Scalars['Int'];
  shotCoordinates: Array<Maybe<ShotCoordinate>>;
};

export type MemberGameRoundScoreCard = {
  __typename?: 'MemberGameRoundScoreCard';
  gameRoundInfo: MemberGameRoundGameInfo;
  players: Array<Maybe<MemberGameRoundPlayerInfo>>;
};

export type MemberJwtAccessToken = {
  __typename?: 'MemberJwtAccessToken';
  id?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type MemberJwtRefreshToken = {
  __typename?: 'MemberJwtRefreshToken';
  family?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  member?: Maybe<Scalars['String']>;
  mode?: Maybe<MemberJwtRefreshTokenMode>;
  username?: Maybe<Scalars['String']>;
};

export enum MemberJwtRefreshTokenMode {
  Lt = 'LT',
  St = 'ST'
}

export type MemberLoginInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  email: Scalars['String'];
  mode: MemberRefreshTokenMode;
  password: Scalars['String'];
};

export type MemberLoginQr = {
  __typename?: 'MemberLoginQR';
  accessToken: Scalars['String'];
  authenticatedMember: AuthenticatedMember;
  countryCode?: Maybe<CountryCode>;
  isVip: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  token: Scalars['String'];
};

export type MemberLoginQrArgs = {
  token: Scalars['String'];
};

export type MemberLogoutInput = {
  refreshToken: Scalars['String'];
};

export type MemberMember = {
  __typename?: 'MemberMember';
  memberName: Scalars['String'];
};

export type MemberMemberArgs = {
  tergetMemberIndividualId?: InputMaybe<Scalars['String']>;
};

export type MemberNotification = {
  __typename?: 'MemberNotification';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<CreatedBy>;
  createdByAdminId?: Maybe<Scalars['String']>;
  createdByAdminName?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  isReadDate?: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  mode?: Maybe<NotificationMode>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MemberNotificationArgs = {
  notificationId: Scalars['String'];
};

export type MemberNotificationBasic = {
  __typename?: 'MemberNotificationBasic';
  createdAt: Scalars['DateTime'];
  createdBy?: Maybe<CreatedBy>;
  createdByAdminId?: Maybe<Scalars['String']>;
  createdByAdminName?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['Boolean']>;
  expiryDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['String'];
  isRead?: Maybe<Scalars['Boolean']>;
  isReadDate?: Maybe<Scalars['DateTime']>;
  message: Scalars['String'];
  mode?: Maybe<NotificationMode>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type MemberNotificationDeleteInput = {
  notificationId: Scalars['String'];
};

export type MemberNotificationEdge = {
  __typename?: 'MemberNotificationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MemberNotificationBasic>;
};

export type MemberNotificationResponse = {
  __typename?: 'MemberNotificationResponse';
  edges?: Maybe<Array<Maybe<MemberNotificationEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberNotificationsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberOrderArgs = {
  orderNo: Scalars['String'];
};

export type MemberOrderEdge = {
  __typename?: 'MemberOrderEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<OrderBasic>;
};

export type MemberOrderResponse = {
  __typename?: 'MemberOrderResponse';
  edges?: Maybe<Array<Maybe<MemberOrderEdge>>>;
  pageInfo?: Maybe<PageInfo>;
};

export type MemberOrdersArgs = {
  after?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type MemberPackageOffer = {
  __typename?: 'MemberPackageOffer';
  categoryDisplayName: Scalars['String'];
  conditions: Array<Maybe<Scalars['String']>>;
  credit?: Maybe<Scalars['String']>;
  details: Array<Maybe<Scalars['String']>>;
  extraUnitPrice: Scalars['String'];
  imageUrl: Scalars['String'];
  itemCode: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['Float'];
  priceString: Scalars['String'];
  purchasableByMember: Scalars['Boolean'];
  type: Scalars['String'];
  unitPrice: Scalars['String'];
  validity: Scalars['String'];
};

export type MemberPackageOfferBasic = {
  __typename?: 'MemberPackageOfferBasic';
  credit: Scalars['String'];
  itemCode: Scalars['String'];
  name: Scalars['String'];
  price: Scalars['String'];
  purchasableByMember: Scalars['Boolean'];
  type: Scalars['String'];
  validity: Scalars['String'];
};

export type MemberPackageOfferType = {
  __typename?: 'MemberPackageOfferType';
  categoryDisplayName: Scalars['String'];
  packages: Array<Maybe<MemberPackageOfferBasic>>;
};

export type MemberRefreshAccessTokenInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  mode: MemberRefreshTokenMode;
  refreshToken: Scalars['String'];
};

export enum MemberRefreshTokenMode {
  AppWithoutBiometrics = 'APP_WITHOUT_BIOMETRICS',
  AppWithBiometrics = 'APP_WITH_BIOMETRICS',
  Web = 'WEB'
}

export type MemberRemoveFriendInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  friendUuid: Scalars['String'];
};

export type MemberRemovePushTokenInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  pushToken: Scalars['String'];
};

export type MemberScoreCard = {
  __typename?: 'MemberScoreCard';
  completeHoleCount?: Maybe<Scalars['Int']>;
  courseId?: Maybe<Scalars['String']>;
  courseName?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Int']>;
  gameRoundId?: Maybe<Scalars['Int']>;
  gameType?: Maybe<Scalars['String']>;
  intro?: Maybe<Scalars['String']>;
  isTournament?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  practiceType?: Maybe<Scalars['String']>;
  score?: Maybe<Scalars['Int']>;
  shopName?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Int']>;
};

export type MemberScoreCardArgs = {
  roundIdx: Scalars['Int'];
};

export type MemberScoreCards = {
  __typename?: 'MemberScoreCards';
  cdn: Scalars['String'];
  list: Array<Maybe<MemberScoreCard>>;
  totalCount: Scalars['Int'];
};

export type MemberScoreCardsArgs = {
  temp?: InputMaybe<Scalars['String']>;
};

export type MemberSimulatorPrivilege = {
  __typename?: 'MemberSimulatorPrivilege';
  hasPrivilege: Scalars['Boolean'];
  lastSimulatorUsageDate?: Maybe<Scalars['DateTime']>;
};

export enum MemberStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Lapsed = 'LAPSED',
  Pending = 'PENDING'
}

export type MemberSubscriptionEdge = {
  __typename?: 'MemberSubscriptionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<MembershipSubscription>;
};

export type MemberSubscriptionResponse = {
  __typename?: 'MemberSubscriptionResponse';
  balance?: Maybe<Scalars['Float']>;
  credit?: Maybe<Scalars['Float']>;
  daysLeft?: Maybe<Scalars['Int']>;
  edges?: Maybe<Array<Maybe<MemberSubscriptionEdge>>>;
  endDate?: Maybe<Scalars['DateTime']>;
  mainMemberName?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  membershipUuid?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<PageInfo>;
  startDate?: Maybe<Scalars['DateTime']>;
  status?: Maybe<SubscriptionStatus>;
};

export type MemberSubscriptionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
};

export type MemberTopUpCreditInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  creditAmount: Scalars['Float'];
};

export type MemberTransferCredit = {
  __typename?: 'MemberTransferCredit';
  canSaveMember: Scalars['Boolean'];
  memberName: Scalars['String'];
};

export type MemberTransferCreditInput = {
  creditAmount: Scalars['Float'];
  targetMemberCardId: Scalars['String'];
};

export type MemberUpdateEmailInput = {
  email: Scalars['String'];
};

export type MemberUpdateGenderInput = {
  gender: Gender;
};

export type MemberUpdateHandedInput = {
  handed: Handed;
};

export type MemberUpdateMobileNumberInput = {
  countryCode: Scalars['String'];
  number: Scalars['String'];
};

export type MemberUpdateNicknameInput = {
  nickname: Scalars['String'];
};

export type MemberUpdatePasswordInput = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type MemberWebsiteLoginQrCodeRequestArgs = {
  device: Scalars['String'];
  ip: Scalars['String'];
  location: Scalars['String'];
};

export type MemberWebsiteLoginQrCodeVerification = {
  __typename?: 'MemberWebsiteLoginQRCodeVerification';
  device: Scalars['String'];
  expiry: Scalars['DateTime'];
  location: Scalars['String'];
  verificationCode: Scalars['String'];
};

export type MemberWebsiteLoginQrCodeVerificationArgs = {
  token: Scalars['String'];
};

export type MemberWebsiteLoginWithQrCodeInput = {
  audit?: InputMaybe<MemberAuditDataInput>;
  verificationCode: Scalars['String'];
};

export enum MembershipActivityType {
  TierDowngrade = 'TIER_DOWNGRADE',
  TierUpgrade = 'TIER_UPGRADE'
}

export enum MembershipStatus {
  Active = 'ACTIVE',
  Archived = 'ARCHIVED',
  Lapsed = 'LAPSED',
  Pending = 'PENDING'
}

export type MembershipSubscription = {
  __typename?: 'MembershipSubscription';
  amount?: Maybe<Scalars['Float']>;
  creditDeducted?: Maybe<Scalars['Boolean']>;
  days?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type MembershipTypeBasic = {
  __typename?: 'MembershipTypeBasic';
  baseCountryCode?: Maybe<CountryCode>;
  internalName?: Maybe<Scalars['String']>;
  isCorporate?: Maybe<Scalars['Boolean']>;
  price?: Maybe<Scalars['Int']>;
  tagBgColorCode?: Maybe<Scalars['String']>;
  tagText?: Maybe<Scalars['String']>;
  tagTextColorCode?: Maybe<Scalars['String']>;
};

export enum MembershipTypeTier {
  Lifestyle = 'LIFESTYLE',
  Premium = 'PREMIUM',
  Vip = 'VIP'
}

export type Mutation = {
  __typename?: 'Mutation';
  adminActivateMembership: AdminActivateMembership;
  adminAdminUpdatePassword: SystemOperationStatus;
  adminBookingBlackoutClubhouseUpdate: AdminBookingBlackoutClubhouse;
  adminBookingBlackoutCoachUpdate: AdminBookingBlackoutCoach;
  adminBookingBlackoutCreate: AdminBookingBlackoutCreate;
  adminBookingBlackoutDelete: SystemOperationStatus;
  adminBookingBookingFacilityUpdate: SystemOperationStatus;
  adminBookingCancel: SystemOperationStatus;
  adminBookingGameCreate: AdminBookingGameCreate;
  adminBookingLessonIndoorCreate: AdminBookingLessonIndoorCreate;
  adminBookingLessonOutdoorCreate: AdminBookingLessonOutdoorCreate;
  adminBookingPractiseCreate: AdminBookingPractiseCreate;
  adminBookingSendCancellationNotification: SystemOperationStatus;
  adminBookingUpdate: SystemOperationStatus;
  adminBookingUpdateToGroup: SystemOperationStatus;
  adminClientLoginGuest: SystemOperationStatus;
  adminClientSetup: SystemOperationStatus;
  adminCreateAdmin: NewAdmin;
  adminCreateMembership: AdminNewMembership;
  adminDeleteAdmin: SystemOperationStatus;
  adminDeleteCoach: SystemOperationStatus;
  adminDeleteMembership: SystemOperationStatus;
  adminExtendClientTime: SystemOperationStatus;
  adminForgotPassword: AdminForgotPasswordResponse;
  adminForgotPasswordSubmit: SystemOperationStatus;
  adminLockClient: SystemOperationStatus;
  adminLogin: AdminAuthResponse;
  adminLogout: SystemOperationStatus;
  adminManagementSystemReporting: SystemOperationStatus;
  adminManagementSystemSupportTicketCommentAdd: SystemOperationStatus;
  adminManagementSystemSupportTicketCommentDelete: SystemOperationStatus;
  adminManagementSystemSupportTicketCommentUpdate: SystemOperationStatus;
  adminManagementSystemSupportTicketCreate: AdminManagementSystemSupportTicketCreate;
  adminManagementSystemSupportTicketDelete: SystemOperationStatus;
  adminManagementSystemSupportTicketUpdate: SystemOperationStatus;
  adminManagementSystemUserTelemetry: SystemOperationStatus;
  adminMemberBDFAdd: SystemOperationStatus;
  adminMemberCreateKGolfAccount: SystemOperationStatus;
  adminMemberCreditUpdate: SystemOperationStatus;
  adminMemberInvoiceDelete: SystemOperationStatus;
  adminMemberProductPackageDelete: SystemOperationStatus;
  adminMemberProductPackageExtensionUpdate: AdminMemberProductPackageExtension;
  adminMemberProductPackageGroup: SystemOperationStatus;
  adminMemberProductPackageManage: SystemOperationStatus;
  adminMemberProductPackageShare: SystemOperationStatus;
  adminMemberProductPackageTransfer: SystemOperationStatus;
  adminMemberProfileUpdate: AdminMemberProfileUpdate;
  adminMemberReassignMembershipId: SystemOperationStatus;
  adminMemberResendOnboardingEmail: SystemOperationStatus;
  adminMemberSendResetPasswordEmail: SystemOperationStatus;
  adminMemberServiceTransactionDelete: SystemOperationStatus;
  adminMemberServiceTransactionDevUpdate: SystemOperationStatus;
  adminMemberServiceTransactionUpdate: ServiceTransactionBasic;
  adminMemberServiceUpdate: SystemOperationStatus;
  adminMemberSimulatorUseUpdate: SystemOperationStatus;
  adminMemberTierUpdate: SystemOperationStatus;
  adminMemberTypeUpdate: SystemOperationStatus;
  adminMemberUpdatePassword: SystemOperationStatus;
  adminMembershipSubscriptionActionDates: SystemOperationStatus;
  adminMembershipSubscriptionPause: SystemOperationStatus;
  adminMembershipSubscriptionStart: SystemOperationStatus;
  adminNotificationCreate: SystemOperationStatus;
  adminNotificationDelete: SystemOperationStatus;
  adminNotificationMemberDelete: SystemOperationStatus;
  adminProductPackageAdd: SystemOperationStatus;
  adminRefreshAccessToken: AdminAuthResponse;
  adminReportAcTransactionDelete: SystemOperationStatus;
  adminReportAcTransactionRefund: SystemOperationStatus;
  adminReportOrderRecordStatusUpdate: AdminReportOrderRecordStatusUpdate;
  adminReportUpdateOrderConnection: SystemOperationStatus;
  adminResendOnboardingEmail: SystemOperationStatus;
  adminShopGetNotification: AdminShopNotification;
  adminShopSendNotification: SystemOperationStatus;
  adminShopTokens: ShopsTokens;
  adminTerminateMembership: SystemOperationStatus;
  adminUnlockClient: SystemOperationStatus;
  adminUpdateAdmin: UpdatedAdmin;
  adminUpdateClientContentLimit: SystemOperationStatus;
  adminUpdateCoach: UpdatedCoach;
  adminUpdateCoachBookable: AdminUpdateCoachBookable;
  adminUpdateFacility: SystemOperationStatus;
  adminUpdateMembershipType: AdminMembershipType;
  adminUpdateNickname: SystemOperationStatus;
  adminUpdateOrganization: Organization;
  adminUpdatePricing: Pricing;
  adminUpdateReservedId: AdminUpdateReservedId;
  adminUpdateShopDetails: AdminShopDetails;
  adminUpdateShopOptionsDrivingRange: AdminShopOptionsDrivingRange;
  adminUpdateShopOptionsRealTime: AdminShopOptionsRealTime;
  adminUpdateShopOptionsRound: AdminShopOptionsRound;
  adminUpdateSidasInsoles: SystemOperationStatus;
  appTelemetry: AppTelemetry;
  appTelemetryV2: SystemOperationStatus;
  coachAvailabilityDayItemTemplateCreate: CoachAvailabilityDayItemTemplateCreate;
  coachAvailabilityDayItemTemplateDelete: SystemOperationStatus;
  coachAvailabilityDayItemTemplateUpdate: CoachAvailabilityDayItemTemplate;
  coachAvailabilityDayUpsert?: Maybe<CoachAvailabilityDayItem>;
  coachBookingCommentUpdate: CoachBookingCommentUpdate;
  coachMemberLessonNoteCreate: Scalars['String'];
  coachMemberLessonNoteDelete: CoachMemberLessonNoteDelete;
  coachMemberLessonNoteUpdate: Scalars['String'];
  devAceCoinsLineItemDelete: SystemOperationStatus;
  devAppTranslationsUpdate: SystemOperationStatus;
  devEMenuImportOrders: SystemOperationStatus;
  devEMenuMatchOrders: DevEMenuMatchOrders;
  devEMenuUpsertCreditTransactions: SystemOperationStatus;
  devGraphqlUtilityMutation1: SystemOperationStatus;
  devGraphqlUtilityMutation2: SystemOperationStatus;
  devGraphqlUtilityMutation3: SystemOperationStatus;
  devGraphqlUtilityMutation4: SystemOperationStatus;
  devKGolfCreateAccounts: DevKGolfCreateAccounts;
  devKGolfMemberJoin: DevKGolfMemberJoin;
  devKGolfMemberLogin: KGolfMemberLogin;
  devKGolfShopLogin: KGolfShopLogin;
  devManagementSystemAdminNotificationSend: SystemOperationStatus;
  devManagementSystemAdminTelemetryRequest: SystemOperationStatus;
  devManagementSystemAdminViewportReloadRequest: SystemOperationStatus;
  devManagementSystemServerStatus: DevManagementSystemServerStatus;
  devNotificationAppSend: SystemOperationStatus;
  memberAccountArchive: SystemOperationStatus;
  memberAccountNotificationDelete: MemberAccountNotificationDelete;
  memberAccountNotificationUpdate: SystemOperationStatus;
  memberAccountNotificationUpdateV2: MemberAccountNotificationUpdateV2;
  memberAccountPackageBuy: SystemOperationStatus;
  memberAccountPushTokenAdd: SystemOperationStatus;
  memberAccountPushTokenDelete: SystemOperationStatus;
  memberAddFriend: SystemOperationStatus;
  memberAddPushToken: SystemOperationStatus;
  memberBookingCancel: SystemOperationStatus;
  memberBookingGameCreate: MemberBookingGameCreate;
  memberBookingLessonIndoorCreate: MemberBookingLessonIndoorCreate;
  memberBookingLessonIndoorCreateV2: MemberBookingLessonIndoorCreate;
  memberBookingLessonOutdoorCreate: MemberBookingLessonOutdoorCreate;
  memberBookingLessonOutdoorCreateV2: MemberBookingLessonOutdoorCreate;
  memberBookingPractiseCreate: MemberBookingPractiseCreate;
  memberBookingPractiseCreateV2: MemberBookingPractiseCreate;
  memberBookingUpdateToGroup: MemberBookingV2;
  memberDeleteRefreshToken: SystemOperationStatus;
  memberFindMember: MemberFindMember;
  memberForgotPassword: MemberForgotPassword;
  memberForgotPasswordSubmit: SystemOperationStatus;
  memberGameQRCodeLogin: SystemOperationStatus;
  memberLogin: MemberAuthResponse;
  memberLogout: SystemOperationStatus;
  memberNotificationDelete: SystemOperationStatus;
  memberRefreshAccessToken: MemberAuthResponse;
  memberRemoveFriend: SystemOperationStatus;
  memberRemovePushToken: SystemOperationStatus;
  memberTopUpCredit: HitPayPaymentRequest;
  memberTransferCredit: SystemOperationStatus;
  memberTransferCreditV2: MemberTransferCredit;
  memberUpdateEmail: SystemOperationStatus;
  memberUpdateGender: SystemOperationStatus;
  memberUpdateHanded: SystemOperationStatus;
  memberUpdateMobileNumber: SystemOperationStatus;
  memberUpdateNickname: SystemOperationStatus;
  memberUpdatePassword: SystemOperationStatus;
  memberWebsiteLoginWithQRCode: SystemOperationStatus;
  publicContactForm: SystemOperationStatus;
  publicNewsletter: SystemOperationStatus;
  selfUpdate: SelfUpdated;
  selfUpdatePassword: SystemOperationStatus;
};


export type MutationAdminActivateMembershipArgs = {
  input?: InputMaybe<AdminActivateMembershipInput>;
};


export type MutationAdminAdminUpdatePasswordArgs = {
  input: AdminAdminUpdatePasswordInput;
};


export type MutationAdminBookingBlackoutClubhouseUpdateArgs = {
  input: AdminBookingBlackoutClubhouseUpdateInput;
};


export type MutationAdminBookingBlackoutCoachUpdateArgs = {
  input: AdminBookingBlackoutCoachUpdateInput;
};


export type MutationAdminBookingBlackoutCreateArgs = {
  input: AdminBookingBlackoutCreateInput;
};


export type MutationAdminBookingBlackoutDeleteArgs = {
  input: AdminBookingBlackoutDeleteInput;
};


export type MutationAdminBookingBookingFacilityUpdateArgs = {
  input: AdminBookingBookingFacilityUpdateInput;
};


export type MutationAdminBookingCancelArgs = {
  input: AdminBookingCancelInput;
};


export type MutationAdminBookingGameCreateArgs = {
  input: AdminBookingGameCreateInput;
};


export type MutationAdminBookingLessonIndoorCreateArgs = {
  input: AdminBookingLessonIndoorCreateInput;
};


export type MutationAdminBookingLessonOutdoorCreateArgs = {
  input: AdminBookingLessonOutdoorCreateInput;
};


export type MutationAdminBookingPractiseCreateArgs = {
  input: AdminBookingPractiseCreateInput;
};


export type MutationAdminBookingSendCancellationNotificationArgs = {
  input: AdminBookingSendCancellationNotificationInput;
};


export type MutationAdminBookingUpdateArgs = {
  input: AdminBookingUpdateInput;
};


export type MutationAdminBookingUpdateToGroupArgs = {
  input: AdminBookingUpdateToGroupInput;
};


export type MutationAdminClientLoginGuestArgs = {
  input: AdminClientLoginGuestInput;
};


export type MutationAdminClientSetupArgs = {
  input: AdminClientSetupInput;
};


export type MutationAdminCreateAdminArgs = {
  input: AdminCreateAdminInput;
};


export type MutationAdminCreateMembershipArgs = {
  input: AdminCreateMembershipInput;
};


export type MutationAdminDeleteAdminArgs = {
  input?: InputMaybe<AdminDeleteAdminInput>;
};


export type MutationAdminDeleteCoachArgs = {
  input?: InputMaybe<AdminDeleteCoachInput>;
};


export type MutationAdminDeleteMembershipArgs = {
  input: AdminDeleteMembershipInput;
};


export type MutationAdminExtendClientTimeArgs = {
  input: AdminExtendClientTimeInput;
};


export type MutationAdminForgotPasswordArgs = {
  input: AdminForgotPasswordInput;
};


export type MutationAdminForgotPasswordSubmitArgs = {
  input: AdminForgotPasswordSubmitInput;
};


export type MutationAdminLockClientArgs = {
  input: AdminLockClientInput;
};


export type MutationAdminLoginArgs = {
  input: AdminLoginInput;
};


export type MutationAdminManagementSystemReportingArgs = {
  input: AdminManagementSystemReportingInput;
};


export type MutationAdminManagementSystemSupportTicketCommentAddArgs = {
  input: AdminManagementSystemSupportTicketCommentAddInput;
};


export type MutationAdminManagementSystemSupportTicketCommentDeleteArgs = {
  input: AdminManagementSystemSupportTicketCommentDeleteInput;
};


export type MutationAdminManagementSystemSupportTicketCommentUpdateArgs = {
  input: AdminManagementSystemSupportTicketCommentUpdateInput;
};


export type MutationAdminManagementSystemSupportTicketCreateArgs = {
  input: AdminManagementSystemSupportTicketCreateInput;
};


export type MutationAdminManagementSystemSupportTicketDeleteArgs = {
  input: AdminManagementSystemSupportTicketDeleteInput;
};


export type MutationAdminManagementSystemSupportTicketUpdateArgs = {
  input: AdminManagementSystemSupportTicketUpdateInput;
};


export type MutationAdminManagementSystemUserTelemetryArgs = {
  input: AdminManagementSystemUserTelemetryInput;
};


export type MutationAdminMemberBdfAddArgs = {
  input: AdminMemberBdfAddInput;
};


export type MutationAdminMemberCreateKGolfAccountArgs = {
  input: AdminMemberCreateKGolfAccountInput;
};


export type MutationAdminMemberCreditUpdateArgs = {
  input?: InputMaybe<AdminMemberCreditUpdateInput>;
};


export type MutationAdminMemberInvoiceDeleteArgs = {
  input: AdminMemberInvoiceDeleteInput;
};


export type MutationAdminMemberProductPackageDeleteArgs = {
  input: AdminMemberProductPackageDeleteInput;
};


export type MutationAdminMemberProductPackageExtensionUpdateArgs = {
  input: AdminMemberProductPackageExtensionUpdateInput;
};


export type MutationAdminMemberProductPackageGroupArgs = {
  input: AdminMemberProductPackageGroupInput;
};


export type MutationAdminMemberProductPackageManageArgs = {
  input: AdminMemberProductPackageManageInput;
};


export type MutationAdminMemberProductPackageShareArgs = {
  input: AdminMemberProductPackageShareInput;
};


export type MutationAdminMemberProductPackageTransferArgs = {
  input: AdminMemberProductPackageTransferInput;
};


export type MutationAdminMemberProfileUpdateArgs = {
  input: AdminMemberProfileUpdateInput;
};


export type MutationAdminMemberReassignMembershipIdArgs = {
  input: AdminMemberReassignMembershipIdInput;
};


export type MutationAdminMemberResendOnboardingEmailArgs = {
  input: AdminMemberResendOnboardingEmailInput;
};


export type MutationAdminMemberSendResetPasswordEmailArgs = {
  input: AdminMemberSendResetPasswordEmailInput;
};


export type MutationAdminMemberServiceTransactionDeleteArgs = {
  input: AdminMemberServiceTransactionDeleteInput;
};


export type MutationAdminMemberServiceTransactionDevUpdateArgs = {
  input: AdminMemberServiceTransactionDevUpdateInput;
};


export type MutationAdminMemberServiceTransactionUpdateArgs = {
  input: AdminMemberServiceTransactionUpdateInput;
};


export type MutationAdminMemberServiceUpdateArgs = {
  input: AdminMemberServiceUpdateInput;
};


export type MutationAdminMemberSimulatorUseUpdateArgs = {
  input: AdminMemberSimulatorUseUpdateInput;
};


export type MutationAdminMemberTierUpdateArgs = {
  input: AdminMemberTierUpdateInput;
};


export type MutationAdminMemberTypeUpdateArgs = {
  input: AdminMemberTypeUpdateInput;
};


export type MutationAdminMemberUpdatePasswordArgs = {
  input: AdminMemberUpdatePasswordInput;
};


export type MutationAdminMembershipSubscriptionActionDatesArgs = {
  input: AdminMembershipSubscriptionActionDatesInput;
};


export type MutationAdminMembershipSubscriptionPauseArgs = {
  input: AdminMembershipSubscriptionPauseInput;
};


export type MutationAdminMembershipSubscriptionStartArgs = {
  input: AdminMembershipSubscriptionStartInput;
};


export type MutationAdminNotificationCreateArgs = {
  input: AdminNotificationCreateInput;
};


export type MutationAdminNotificationDeleteArgs = {
  input: AdminNotificationDeleteInput;
};


export type MutationAdminNotificationMemberDeleteArgs = {
  input: AdminNotificationMemberDeleteInput;
};


export type MutationAdminProductPackageAddArgs = {
  input: AdminProductPackageAddInput;
};


export type MutationAdminReportAcTransactionDeleteArgs = {
  input: AdminReportAcTransactionDeleteInput;
};


export type MutationAdminReportAcTransactionRefundArgs = {
  input: AdminReportAcTransactionRefundInput;
};


export type MutationAdminReportOrderRecordStatusUpdateArgs = {
  input: AdminReportOrderRecordStatusUpdateInput;
};


export type MutationAdminReportUpdateOrderConnectionArgs = {
  input: AdminReportUpdateOrderConnectionInput;
};


export type MutationAdminResendOnboardingEmailArgs = {
  input: AdminResendOnboardingEmailInput;
};


export type MutationAdminShopGetNotificationArgs = {
  input: AdminShopGetNotificationInput;
};


export type MutationAdminShopSendNotificationArgs = {
  input: AdminShopSendNotificationInput;
};


export type MutationAdminShopTokensArgs = {
  input?: InputMaybe<AdminShopTokensInput>;
};


export type MutationAdminTerminateMembershipArgs = {
  input: AdminTerminateMembershipInput;
};


export type MutationAdminUnlockClientArgs = {
  input: AdminUnlockClientInput;
};


export type MutationAdminUpdateAdminArgs = {
  args: AdminUpdateAdminArgs;
  input: AdminUpdateAdminInput;
};


export type MutationAdminUpdateClientContentLimitArgs = {
  input: AdminUpdateClientContentLimitInput;
};


export type MutationAdminUpdateCoachArgs = {
  args: AdminUpdateCoachArgs;
  input: AdminUpdateCoachInput;
};


export type MutationAdminUpdateCoachBookableArgs = {
  args: AdminUpdateCoachArgs;
  input: AdminUpdateCoachBookableInput;
};


export type MutationAdminUpdateFacilityArgs = {
  input: AdminUpdateFacilityInput;
};


export type MutationAdminUpdateMembershipTypeArgs = {
  input: AdminUpdateMembershipTypeInput;
};


export type MutationAdminUpdateNicknameArgs = {
  input: AdminUpdateNicknameInput;
};


export type MutationAdminUpdateOrganizationArgs = {
  input: AdminUpdateOrganizationInput;
};


export type MutationAdminUpdatePricingArgs = {
  input: AdminUpdatePricingInput;
};


export type MutationAdminUpdateReservedIdArgs = {
  input?: InputMaybe<AdminUpdateReservedIdInput>;
};


export type MutationAdminUpdateShopDetailsArgs = {
  input: AdminUpdateShopDetailsInput;
};


export type MutationAdminUpdateShopOptionsDrivingRangeArgs = {
  input: AdminUpdateShopOptionsDrivingRangeInput;
};


export type MutationAdminUpdateShopOptionsRealTimeArgs = {
  input: AdminUpdateShopOptionsRealTimeInput;
};


export type MutationAdminUpdateShopOptionsRoundArgs = {
  input: AdminUpdateShopOptionsRoundInput;
};


export type MutationAdminUpdateSidasInsolesArgs = {
  input: AdminUpdateSidasInsolesInput;
};


export type MutationAppTelemetryArgs = {
  input: AppTelemetryInput;
};


export type MutationAppTelemetryV2Args = {
  input: AppTelemetryV2Input;
};


export type MutationCoachAvailabilityDayItemTemplateCreateArgs = {
  input: CoachAvailabilityDayItemTemplateCreateInput;
};


export type MutationCoachAvailabilityDayItemTemplateDeleteArgs = {
  input: CoachAvailabilityDayItemTemplateDeleteInput;
};


export type MutationCoachAvailabilityDayItemTemplateUpdateArgs = {
  input: CoachAvailabilityDayItemTemplateUpdateInput;
};


export type MutationCoachAvailabilityDayUpsertArgs = {
  input: CoachAvailabilityDayUpsertInput;
};


export type MutationCoachBookingCommentUpdateArgs = {
  input: CoachBookingCommentUpdateInput;
};


export type MutationCoachMemberLessonNoteCreateArgs = {
  input: CoachMemberLessonNoteCreateInput;
};


export type MutationCoachMemberLessonNoteDeleteArgs = {
  input: CoachMemberLessonNoteDeleteInput;
};


export type MutationCoachMemberLessonNoteUpdateArgs = {
  input: CoachMemberLessonNoteUpdateInput;
};


export type MutationDevAceCoinsLineItemDeleteArgs = {
  input: DevAceCoinsLineItemDeleteInput;
};


export type MutationDevAppTranslationsUpdateArgs = {
  input: DevAppTranslationsUpdateInput;
};


export type MutationDevEMenuImportOrdersArgs = {
  input: DevEMenuImportOrdersInput;
};


export type MutationDevEMenuMatchOrdersArgs = {
  input: DevEMenuMatchOrdersInput;
};


export type MutationDevEMenuUpsertCreditTransactionsArgs = {
  input?: InputMaybe<DevEMenuUpsertCreditTransactionsInput>;
};


export type MutationDevManagementSystemAdminNotificationSendArgs = {
  input: DevManagementSystemAdminNotificationSendInput;
};


export type MutationDevManagementSystemAdminViewportReloadRequestArgs = {
  input: DevManagementSystemAdminViewportReloadRequestInput;
};


export type MutationDevNotificationAppSendArgs = {
  input: DevNotificationAppSendInput;
};


export type MutationMemberAccountArchiveArgs = {
  input: MemberAccountArchiveInput;
};


export type MutationMemberAccountNotificationDeleteArgs = {
  input: MemberAccountNotificationDeleteInput;
};


export type MutationMemberAccountNotificationUpdateArgs = {
  input: MemberAccountNotificationUpdateInput;
};


export type MutationMemberAccountNotificationUpdateV2Args = {
  input: MemberAccountNotificationUpdateInput;
};


export type MutationMemberAccountPackageBuyArgs = {
  input: MemberAccountPackageBuyInput;
};


export type MutationMemberAccountPushTokenAddArgs = {
  input: MemberAccountPushTokenAddInput;
};


export type MutationMemberAccountPushTokenDeleteArgs = {
  input: MemberAccountPushTokenDeleteInput;
};


export type MutationMemberAddFriendArgs = {
  input: MemberAddFriendInput;
};


export type MutationMemberAddPushTokenArgs = {
  input: MemberAddPushTokenInput;
};


export type MutationMemberBookingCancelArgs = {
  input: MemberBookingCancelInput;
};


export type MutationMemberBookingGameCreateArgs = {
  input: MemberBookingGameCreateInput;
};


export type MutationMemberBookingLessonIndoorCreateArgs = {
  input: MemberBookingLessonIndoorCreateInput;
};


export type MutationMemberBookingLessonIndoorCreateV2Args = {
  input: MemberBookingLessonIndoorCreateV2Input;
};


export type MutationMemberBookingLessonOutdoorCreateArgs = {
  input: MemberBookingLessonOutdoorCreateInput;
};


export type MutationMemberBookingLessonOutdoorCreateV2Args = {
  input: MemberBookingLessonOutdoorCreateV2Input;
};


export type MutationMemberBookingPractiseCreateArgs = {
  input: MemberBookingPractiseCreateInput;
};


export type MutationMemberBookingPractiseCreateV2Args = {
  input: MemberBookingPractiseCreateV2Input;
};


export type MutationMemberBookingUpdateToGroupArgs = {
  input: MemberBookingUpdateToGroupInput;
};


export type MutationMemberDeleteRefreshTokenArgs = {
  input: MemberDeleteRefreshTokenInput;
};


export type MutationMemberFindMemberArgs = {
  input?: InputMaybe<MemberFindMemberInput>;
};


export type MutationMemberForgotPasswordArgs = {
  input: MemberForgotPasswordInput;
};


export type MutationMemberForgotPasswordSubmitArgs = {
  input: MemberForgotPasswordSubmitInput;
};


export type MutationMemberGameQrCodeLoginArgs = {
  input: MemberGameQrCodeLoginInput;
};


export type MutationMemberLoginArgs = {
  input: MemberLoginInput;
};


export type MutationMemberLogoutArgs = {
  input: MemberLogoutInput;
};


export type MutationMemberNotificationDeleteArgs = {
  input: MemberNotificationDeleteInput;
};


export type MutationMemberRefreshAccessTokenArgs = {
  input: MemberRefreshAccessTokenInput;
};


export type MutationMemberRemoveFriendArgs = {
  input: MemberRemoveFriendInput;
};


export type MutationMemberRemovePushTokenArgs = {
  input: MemberRemovePushTokenInput;
};


export type MutationMemberTopUpCreditArgs = {
  input: MemberTopUpCreditInput;
};


export type MutationMemberTransferCreditArgs = {
  input: MemberTransferCreditInput;
};


export type MutationMemberTransferCreditV2Args = {
  input: MemberTransferCreditInput;
};


export type MutationMemberUpdateEmailArgs = {
  input: MemberUpdateEmailInput;
};


export type MutationMemberUpdateGenderArgs = {
  input: MemberUpdateGenderInput;
};


export type MutationMemberUpdateHandedArgs = {
  input: MemberUpdateHandedInput;
};


export type MutationMemberUpdateMobileNumberArgs = {
  input: MemberUpdateMobileNumberInput;
};


export type MutationMemberUpdateNicknameArgs = {
  input: MemberUpdateNicknameInput;
};


export type MutationMemberUpdatePasswordArgs = {
  input: MemberUpdatePasswordInput;
};


export type MutationMemberWebsiteLoginWithQrCodeArgs = {
  input: MemberWebsiteLoginWithQrCodeInput;
};


export type MutationPublicContactFormArgs = {
  input: PublicContactFormInput;
};


export type MutationPublicNewsletterArgs = {
  input: PublicNewsletterInput;
};


export type MutationSelfUpdateArgs = {
  input: SelfUpdateInput;
};


export type MutationSelfUpdatePasswordArgs = {
  input: SelfUpdatePasswordInput;
};

export type NewAdmin = {
  __typename?: 'NewAdmin';
  fileUploadCredentials?: Maybe<FileUploadCredentials>;
  user: Admin;
};

export type NewCoach = {
  __typename?: 'NewCoach';
  coach: Coach;
  fileUploadCredentials?: Maybe<FileUploadCredentials>;
};

export type NonPuttingFacilityDayItem = {
  __typename?: 'NonPuttingFacilityDayItem';
  na0800?: Maybe<Scalars['Boolean']>;
  na0900?: Maybe<Scalars['Boolean']>;
  na1000?: Maybe<Scalars['Boolean']>;
  na1100?: Maybe<Scalars['Boolean']>;
  na1200?: Maybe<Scalars['Boolean']>;
  na1300?: Maybe<Scalars['Boolean']>;
  na1400?: Maybe<Scalars['Boolean']>;
  na1500?: Maybe<Scalars['Boolean']>;
  na1600?: Maybe<Scalars['Boolean']>;
  na1700?: Maybe<Scalars['Boolean']>;
  na1800?: Maybe<Scalars['Boolean']>;
  na1900?: Maybe<Scalars['Boolean']>;
  na2000?: Maybe<Scalars['Boolean']>;
  na2100?: Maybe<Scalars['Boolean']>;
  na2200?: Maybe<Scalars['Boolean']>;
  na2300?: Maybe<Scalars['Boolean']>;
};

export enum NotificationMode {
  Broadcast = 'BROADCAST',
  Single = 'SINGLE'
}

export enum OperationActorType {
  Admin = 'ADMIN',
  Member = 'MEMBER',
  System = 'SYSTEM'
}

export type Order = {
  __typename?: 'Order';
  additionalDiscount?: Maybe<Scalars['Float']>;
  date: Scalars['DateTime'];
  discountPercent?: Maybe<Scalars['Float']>;
  eMenuCustomerName?: Maybe<Scalars['String']>;
  eMenuMemberCardNo?: Maybe<Scalars['String']>;
  gst?: Maybe<Scalars['Float']>;
  isEMenuOrder?: Maybe<Scalars['Boolean']>;
  lineItems: Array<Maybe<LineItem>>;
  memberDiscount?: Maybe<Scalars['Float']>;
  memberId?: Maybe<Scalars['String']>;
  memberIndividualId?: Maybe<Scalars['String']>;
  memberName?: Maybe<Scalars['String']>;
  membershipId?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  nonMemberCustomer?: Maybe<Scalars['Boolean']>;
  orderNo: Scalars['String'];
  paymentCurrency?: Maybe<PaymentCurrency>;
  paymentMethod: Scalars['String'];
  serviceCharge?: Maybe<Scalars['Float']>;
  subTotal?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  venue?: Maybe<Scalars['String']>;
};

export type OrderBasic = {
  __typename?: 'OrderBasic';
  date: Scalars['DateTime'];
  eMenuCustomerName?: Maybe<Scalars['String']>;
  memberName?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['JSON']>;
  nonMemberCustomer: Scalars['Boolean'];
  orderNo: Scalars['String'];
  paymentCurrency?: Maybe<PaymentCurrency>;
  paymentMethod: Scalars['String'];
  status: PaymentStatus;
  subTotal: Scalars['Float'];
  total: Scalars['Float'];
  venue?: Maybe<Scalars['String']>;
};

export type OrderBy = {
  direction?: InputMaybe<Direction>;
  field?: InputMaybe<Scalars['String']>;
};

export enum OrderMetadaProvider {
  Emenu = 'EMENU'
}

export type OrderMetadata = {
  __typename?: 'OrderMetadata';
  eMenuCustomerName?: Maybe<Scalars['String']>;
  eMenuMemberCardNo?: Maybe<Scalars['String']>;
  provider?: Maybe<OrderMetadaProvider>;
};

export enum OrderRecordUpdateStatus {
  Archived = 'ARCHIVED',
  Resynced = 'RESYNCED'
}

export type Organization = {
  __typename?: 'Organization';
  addressCity?: Maybe<Scalars['String']>;
  addressCountry?: Maybe<Scalars['String']>;
  addressPostalCode?: Maybe<Scalars['String']>;
  addressState?: Maybe<Scalars['String']>;
  addressStreet1?: Maybe<Scalars['String']>;
  addressStreet2?: Maybe<Scalars['String']>;
  businessName?: Maybe<Scalars['String']>;
  clubhouses?: Maybe<Array<Maybe<AdminClubhouse>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  organizationNumber?: Maybe<Scalars['Int']>;
  phoneNumber?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  users?: Maybe<Array<Maybe<Admin>>>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  firstRecordDateTime?: Maybe<Scalars['DateTime']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  total?: Maybe<Scalars['Int']>;
};

export enum PaymentCode {
  CreditTopUp = 'CREDIT_TOP_UP',
  SignUp = 'SIGN_UP'
}

export enum PaymentCurrency {
  Sgd = 'SGD',
  Usd = 'USD',
  Vnd = 'VND'
}

export enum PaymentMethod {
  Acecoins = 'ACECOINS',
  BankTransfer = 'BANK_TRANSFER',
  Cash = 'CASH',
  CreditCard = 'CREDIT_CARD',
  Emenu = 'EMENU',
  Hitpay = 'HITPAY',
  Nets = 'NETS',
  Nil = 'NIL',
  Paynow = 'PAYNOW',
  Paypal = 'PAYPAL',
  Voucher = 'VOUCHER'
}

export enum PaymentMode {
  BankTransfer = 'BANK_TRANSFER',
  Emenu = 'EMENU',
  Hitpay = 'HITPAY',
  Nil = 'NIL'
}

export enum PaymentProvider {
  Emenu = 'EMENU',
  Hitpay = 'HITPAY',
  ManagementSystem = 'MANAGEMENT_SYSTEM'
}

export enum PaymentStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Pending = 'PENDING'
}

export type PractiseBookingConditions = {
  __typename?: 'PractiseBookingConditions';
  maxDays: Scalars['Int'];
  maxHrsBay: Scalars['Int'];
  maxHrsRoom: Scalars['Int'];
  maxMinsPutting: Scalars['Int'];
  maxPaxBay: Scalars['Int'];
  maxPaxPutting: Scalars['Int'];
  maxPaxRoom: Scalars['Int'];
};

export type PreConfirmationCopy = {
  __typename?: 'PreConfirmationCopy';
  textCancellationBody?: Maybe<Scalars['String']>;
  textCancellationBodyLocal?: Maybe<Scalars['String']>;
  textCancellationHeader?: Maybe<Scalars['String']>;
  textCancellationHeaderLocal?: Maybe<Scalars['String']>;
  textConfirm?: Maybe<Scalars['String']>;
  textConfirmLocal?: Maybe<Scalars['String']>;
  textDoubleBookingBody?: Maybe<Scalars['String']>;
  textDoubleBookingBodyLocal?: Maybe<Scalars['String']>;
  textDoubleBookingHeader?: Maybe<Scalars['String']>;
  textDoubleBookingHeaderLocal?: Maybe<Scalars['String']>;
  textReminderBody?: Maybe<Scalars['String']>;
  textReminderBodyLocal?: Maybe<Scalars['String']>;
  textReminderHeader?: Maybe<Scalars['String']>;
  textReminderHeaderLocal?: Maybe<Scalars['String']>;
  textRights?: Maybe<Scalars['String']>;
  textRightsLocal?: Maybe<Scalars['String']>;
};

export type PremiumPackagePractiseConditionCopy = {
  __typename?: 'PremiumPackagePractiseConditionCopy';
  textDurationBody?: Maybe<Scalars['String']>;
  textDurationBodyLocal?: Maybe<Scalars['String']>;
  textDurationHeader?: Maybe<Scalars['String']>;
  textDurationHeaderLocal?: Maybe<Scalars['String']>;
  textLocationBody?: Maybe<Scalars['String']>;
  textLocationBodyLocal?: Maybe<Scalars['String']>;
  textLocationHeader?: Maybe<Scalars['String']>;
  textLocationHeaderLocal?: Maybe<Scalars['String']>;
};

export type Pricing = {
  __typename?: 'Pricing';
  sgBayHourlyRate?: Maybe<Scalars['Int']>;
  sgBayHourlyRateVIP?: Maybe<Scalars['Int']>;
  sgBayMaxHours?: Maybe<Scalars['Int']>;
  sgBayMaxPlayers?: Maybe<Scalars['Int']>;
  sgBookingCancellationPenalty?: Maybe<Scalars['Int']>;
  sgBookingLapsedPenalty?: Maybe<Scalars['Int']>;
  sgHole9MaxHours?: Maybe<Scalars['Int']>;
  sgHole9MaxPlayers?: Maybe<Scalars['Int']>;
  sgHole9PerPlayerRate?: Maybe<Scalars['Int']>;
  sgHole9PerPlayerRateVIP?: Maybe<Scalars['Int']>;
  sgHole18MaxHours?: Maybe<Scalars['Int']>;
  sgHole18MaxPlayers?: Maybe<Scalars['Int']>;
  sgHole18PerPlayerRate?: Maybe<Scalars['Int']>;
  sgHole18PerPlayerRateVIP?: Maybe<Scalars['Int']>;
  sgLessonField?: Maybe<Scalars['Int']>;
  sgLessonFieldVIP?: Maybe<Scalars['Int']>;
  sgLessonIndoor?: Maybe<Scalars['Int']>;
  sgLessonIndoorPuttingGreen?: Maybe<Scalars['Int']>;
  sgLessonIndoorPuttingGreenVIP?: Maybe<Scalars['Int']>;
  sgLessonIndoorVIP?: Maybe<Scalars['Int']>;
  sgPrivateRoomHourlyRate?: Maybe<Scalars['Int']>;
  sgPrivateRoomHourlyRateVIP?: Maybe<Scalars['Int']>;
  sgPrivateRoomMaxHours?: Maybe<Scalars['Int']>;
  sgPrivateRoomMaxPlayers?: Maybe<Scalars['Int']>;
  sgPuttingGreenHourlyRate?: Maybe<Scalars['Int']>;
  sgPuttingGreenHourlyRateVIP?: Maybe<Scalars['Int']>;
  sgPuttingGreenMaxMins?: Maybe<Scalars['Int']>;
  sgPuttingGreenMaxPlayers?: Maybe<Scalars['Int']>;
  viBayHourlyRate?: Maybe<Scalars['Int']>;
  viBayHourlyRateVIP?: Maybe<Scalars['Int']>;
  viBayMaxHours?: Maybe<Scalars['Int']>;
  viBayMaxPlayers?: Maybe<Scalars['Int']>;
  viBookingCancellationPenalty?: Maybe<Scalars['Int']>;
  viBookingLapsedPenalty?: Maybe<Scalars['Int']>;
  viHole9MaxHours?: Maybe<Scalars['Int']>;
  viHole9MaxPlayers?: Maybe<Scalars['Int']>;
  viHole9PerPlayerRate?: Maybe<Scalars['Int']>;
  viHole9PerPlayerRateVIP?: Maybe<Scalars['Int']>;
  viHole18MaxHours?: Maybe<Scalars['Int']>;
  viHole18MaxPlayers?: Maybe<Scalars['Int']>;
  viHole18PerPlayerRate?: Maybe<Scalars['Int']>;
  viHole18PerPlayerRateVIP?: Maybe<Scalars['Int']>;
  viLessonField?: Maybe<Scalars['Int']>;
  viLessonFieldVIP?: Maybe<Scalars['Int']>;
  viLessonIndoor?: Maybe<Scalars['Int']>;
  viLessonIndoorPuttingGreen?: Maybe<Scalars['Int']>;
  viLessonIndoorPuttingGreenVIP?: Maybe<Scalars['Int']>;
  viLessonIndoorVIP?: Maybe<Scalars['Int']>;
  viPrivateRoomHourlyRate?: Maybe<Scalars['Int']>;
  viPrivateRoomHourlyRateVIP?: Maybe<Scalars['Int']>;
  viPrivateRoomMaxHours?: Maybe<Scalars['Int']>;
  viPrivateRoomMaxPlayers?: Maybe<Scalars['Int']>;
  viPuttingGreenHourlyRate?: Maybe<Scalars['Int']>;
  viPuttingGreenHourlyRateVIP?: Maybe<Scalars['Int']>;
  viPuttingGreenMaxMins?: Maybe<Scalars['Int']>;
  viPuttingGreenMaxPlayers?: Maybe<Scalars['Int']>;
};

export type PrivateRoom = {
  __typename?: 'PrivateRoom';
  blockedFromBooking: Scalars['Boolean'];
  clientname: Scalars['String'];
  clubhouseId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type PublicClubhouseArgs = {
  countryCode: CountryCode;
};

export type PublicCoach = {
  __typename?: 'PublicCoach';
  baseCountryCode?: Maybe<CountryCode>;
  coachExperiences?: Maybe<Scalars['JSON']>;
  coachExpertise?: Maybe<Array<Maybe<Scalars['String']>>>;
  coachLanguages?: Maybe<Array<Maybe<Scalars['String']>>>;
  coachLessonField?: Maybe<Scalars['Int']>;
  coachLessonFieldVIP?: Maybe<Scalars['Int']>;
  coachLessonIndoor?: Maybe<Scalars['Int']>;
  coachLessonIndoorPuttingGreen?: Maybe<Scalars['Int']>;
  coachLessonIndoorPuttingGreenVIP?: Maybe<Scalars['Int']>;
  coachLessonIndoorVIP?: Maybe<Scalars['Int']>;
  coachListOrder?: Maybe<Scalars['Int']>;
  coachPGANo?: Maybe<Scalars['String']>;
  coachPGAYear?: Maybe<Scalars['String']>;
  displayName: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  profileImageUrl?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type PublicContactFormInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type PublicMembership = {
  __typename?: 'PublicMembership';
  exampleField?: Maybe<Scalars['Int']>;
};

export type PublicNewsletterInput = {
  email?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
};

export enum PublishMode {
  Broadcast = 'BROADCAST',
  Single = 'SINGLE'
}

export type PuttingFacilityDayItem = {
  __typename?: 'PuttingFacilityDayItem';
  na0800?: Maybe<Scalars['Boolean']>;
  na0830?: Maybe<Scalars['Boolean']>;
  na0900?: Maybe<Scalars['Boolean']>;
  na0930?: Maybe<Scalars['Boolean']>;
  na1000?: Maybe<Scalars['Boolean']>;
  na1030?: Maybe<Scalars['Boolean']>;
  na1100?: Maybe<Scalars['Boolean']>;
  na1130?: Maybe<Scalars['Boolean']>;
  na1200?: Maybe<Scalars['Boolean']>;
  na1230?: Maybe<Scalars['Boolean']>;
  na1300?: Maybe<Scalars['Boolean']>;
  na1330?: Maybe<Scalars['Boolean']>;
  na1400?: Maybe<Scalars['Boolean']>;
  na1430?: Maybe<Scalars['Boolean']>;
  na1500?: Maybe<Scalars['Boolean']>;
  na1530?: Maybe<Scalars['Boolean']>;
  na1600?: Maybe<Scalars['Boolean']>;
  na1630?: Maybe<Scalars['Boolean']>;
  na1700?: Maybe<Scalars['Boolean']>;
  na1730?: Maybe<Scalars['Boolean']>;
  na1800?: Maybe<Scalars['Boolean']>;
  na1830?: Maybe<Scalars['Boolean']>;
  na1900?: Maybe<Scalars['Boolean']>;
  na1930?: Maybe<Scalars['Boolean']>;
  na2000?: Maybe<Scalars['Boolean']>;
  na2030?: Maybe<Scalars['Boolean']>;
  na2100?: Maybe<Scalars['Boolean']>;
  na2130?: Maybe<Scalars['Boolean']>;
  na2200?: Maybe<Scalars['Boolean']>;
  na2230?: Maybe<Scalars['Boolean']>;
  na2300?: Maybe<Scalars['Boolean']>;
  na2330?: Maybe<Scalars['Boolean']>;
};

export type PuttingGreen = {
  __typename?: 'PuttingGreen';
  blockedFromBooking: Scalars['Boolean'];
  clubhouseId: Scalars['String'];
  id: Scalars['String'];
  name: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  admin?: Maybe<Admin>;
  adminBooking?: Maybe<AdminBooking>;
  adminBookingAvailability: Scalars['String'];
  adminBookingBlackoutClubhouse: AdminBookingBlackoutClubhouse;
  adminBookingBlackoutCoach: AdminBookingBlackoutCoach;
  adminBookingBlackouts: Array<Maybe<AdminBookingBlackoutBasic>>;
  adminBookingCoachAvailabilityCalendarItems?: Maybe<AdminBookingCoachAvailabilityCalendarItems>;
  adminBookingCoachAvailabilityDayItem?: Maybe<AdminBookingCoachAvailabilityDayItem>;
  adminBookingCoachAvailabilitySlotItem?: Maybe<AdminBookingCoachAvailabilitySlotItem>;
  adminBookingCoaches: Array<Maybe<AdminBookingCoach>>;
  adminBookingCombinedCoachAvailabilityDayItem?: Maybe<AdminBookingCombinedCoachAvailabilityDayItem>;
  adminBookingFacilityAvailabilityCalendarItems?: Maybe<AdminBookingFacilityAvailabilityCalendarItems>;
  adminBookingFacilityAvailabilityDayItem?: Maybe<AdminBookingFacilityAvailabilityDayItem>;
  adminBookingFacilityAvailabilitySlotItem?: Maybe<AdminBookingFacilityAvailabilitySlotItem>;
  adminBookingGameCheckout: AdminBookingCheckout;
  adminBookingGroupMembers: AdminBookingGroupMembers;
  adminBookingLessonIndoorCheckout: AdminBookingCheckout;
  adminBookingLessonOutdoorCheckout: AdminBookingCheckout;
  adminBookingPractiseCheckout: AdminBookingCheckout;
  adminBookingSettings: AdminBookingSettings;
  adminBookings: AdminBookingResponse;
  adminClientStatus: ClientStatus;
  adminClubhouse: AdminClubhouse;
  adminClubhouseFacilities: Array<Facility>;
  adminClubhouseFacilityAvailability: AdminClubhouseFacilityAvailability;
  adminClubhouses: AdminClubhouseResponse;
  adminCoach: AdminCoach;
  adminCoachAvailabilityCalendarItems: Array<Maybe<CoachAvailabilityCalendarItems>>;
  adminCoachCombinedAvailability: Array<Maybe<AdminCoachCombinedAvailability>>;
  adminCoaches: AdminCoachResponse;
  adminCreditTransactions: AdminCreditTransactionResponse;
  adminGameHistory: AdminGameHistory;
  adminGroupBookings: Array<AdminGroupBooking>;
  adminKGolfMember: AdminKGolfMember;
  adminManagementSystemProps: AdminManagementSystemProps;
  adminManagementSystemSupportTicket: AdminManagementSystemSupportTicket;
  adminManagementSystemSupportTicketComments: Array<Maybe<AdminManagementSystemSupportTicketComment>>;
  adminManagementSystemSupportTicketUsers: Array<AdminManagementSystemSupportTicketUser>;
  adminManagementSystemSupportTickets: AdminManagementSystemSupportTicketResponse;
  adminMember: AdminMember;
  adminMemberAcTransactions: AdminMemberAcTransactionResponse;
  adminMemberInvoices: AdminReportInvoiceResponse;
  adminMemberNotifications: AdminMemberNotificationResponse;
  adminMemberProductGroupPackages: AdminMemberProductGroupPackages;
  adminMemberProductPackage: AdminMemberProductPackage;
  adminMemberProductPackageExtensions: AdminMemberProductPackageExtensions;
  adminMemberServiceTransactions: AdminMemberServiceTransactionResponse;
  adminMemberSubscriptions: AdminMemberSubscriptionResponse;
  adminMembers: AdminMemberResponse;
  adminMembershipServices: AdminMembershipServiceResponse;
  adminMembershipSubscriptions: AdminMembershipSubscriptionResponse;
  adminMembershipType: AdminMembershipType;
  adminMembershipTypes: AdminMembershipTypes;
  adminNotification: AdminNotification;
  adminNotifications: AdminNotificationResponse;
  adminProducts: AdminProductResponse;
  adminReportAcTransactions: AdminReportAcTransactionResponse;
  adminReportCreditTransactions: AdminReportCreditTransactionResponse;
  adminReportHitPayCharge: Invoice;
  adminReportHitPayCharges: AdminReportHitPayChargeResponse;
  adminReportInvoice: Invoice;
  adminReportInvoices: AdminReportInvoiceResponse;
  adminReportMemberStats: AdminReportMemberStatResponse;
  adminReportOrder: Order;
  adminReportOrders: AdminReportOrderResponse;
  adminReservedIds?: Maybe<AdminReservedIds>;
  adminScoreCard?: Maybe<Scalars['JSON']>;
  adminShopDetails?: Maybe<AdminShopDetails>;
  adminShopOptionsDrivingRange: AdminShopOptionsDrivingRange;
  adminShopOptionsRealTime: AdminShopOptionsRealTime;
  adminShopOptionsRound: AdminShopOptionsRound;
  adminSimulatorUsage?: Maybe<Scalars['JSON']>;
  adminStaffs: AdminStaffResponse;
  admins: AdminResponse;
  appDineMenu?: Maybe<AppDineMenu>;
  appMainSlider?: Maybe<AppMainSlider>;
  appMainSliderV2?: Maybe<AppMainSliderV2>;
  appManifest: AppManifest;
  appManifestV2: AppManifestV2;
  appMedia?: Maybe<Array<Maybe<AppMedia>>>;
  appMediaYouTubeVideo?: Maybe<AppMediaYouTubeVideo>;
  coachAccountSettings: CoachAccountSettings;
  coachAvailabilityCalendarItems: Array<Maybe<CoachAvailabilityCalendarItems>>;
  coachAvailabilityDayItem?: Maybe<CoachAvailabilityDayItem>;
  coachAvailabilityDayItemTemplate: CoachAvailabilityDayItemTemplate;
  coachAvailabilityDayItemTemplates: Array<Maybe<CoachAvailabilityDayItemTemplate>>;
  coachBooking?: Maybe<CoachBooking>;
  coachBookingCoaches: Array<Maybe<CoachBookingCoach>>;
  coachBookingCombinedAvailability: Array<Maybe<CoachBookingCombinedAvailability>>;
  coachBookingCombinedAvailabilityDayItem?: Maybe<CoachBookingCombinedAvailabilityDayItem>;
  coachBookings: CoachBookingResponse;
  coachGroupBookings: Array<CoachGroupBooking>;
  coachMember?: Maybe<CoachMember>;
  coachMemberLessonNote: CoachMemberLessonNote;
  coachMemberLessonNoteMembers: CoachMemberLessonNoteMemberResponse;
  coachMemberLessonNotes: CoachMemberLessonNoteResponse;
  coachMembers: CoachMemberResponse;
  devAceCoinsLedger: DevAceCoinsLedger;
  devAppTranslations: Array<DevAppTranslation>;
  devGraphqlUtilityQuery1: Scalars['JSON'];
  devGraphqlUtilityQuery2: Scalars['JSON'];
  devGraphqlUtilityQuery3: Scalars['JSON'];
  devGraphqlUtilityQuery4: Scalars['JSON'];
  devKGolfMemberGet: KGolfMemberGet;
  devManagementSystemServerEnvironments: DevManagementSystemServerEnvironments;
  devMemberAceCoinIssue: Array<Maybe<DevMemberAceCoinIssue>>;
  formHelperAceCoinType?: Maybe<FormHelperAceCoinType>;
  formHelperBooking?: Maybe<FormHelperBooking>;
  formHelperNewAdmin?: Maybe<FormHelperNewAdmin>;
  formHelperNewBlackOut?: Maybe<FormHelperNewBlackOut>;
  formHelperNewCoach?: Maybe<FormHelperNewCoach>;
  formHelperNewMembership?: Maybe<FormHelperNewMembership>;
  formHelperProductPackages?: Maybe<FormHelperProductPackages>;
  memberAccount: MemberAccount;
  memberAccountAcTransactions: MemberAccountAcTransactionResponse;
  memberAccountCoach: MemberAccountCoach;
  memberAccountCoaches: Array<Maybe<MemberAccountCoachBasic>>;
  memberAccountExpirableCredits: MemberAccountExpirableCreditResponse;
  memberAccountFindMember: MemberAccountFindMember;
  memberAccountInvoice: Invoice;
  memberAccountInvoices: Array<Maybe<MemberAccountInvoiceBasic>>;
  memberAccountLessonNote: MemberAccountLessonNote;
  memberAccountLessonNotes: MemberAccountLessonNoteResponse;
  memberAccountNotification: MemberNotification;
  memberAccountNotifications: MemberAccountNotificationResponse;
  memberAccountPackage: MemberAccountPackage;
  memberAccountPackageOffer: MemberPackageOffer;
  memberAccountPackageOffers: MemberAccountPackageOffers;
  memberAccountPackages: MemberAccountPackageResponse;
  memberAccountRates: MemberAccountRates;
  memberAccountServiceTransactions: MemberAccountServiceTransactionResponse;
  memberAccountServiceTransactionsV2: MemberAccountServiceTransactionV2Response;
  memberAccountSubscriptions: MemberAccountSubscriptionResponse;
  memberAccountV2: MemberAccountV2;
  memberBooking: MemberBooking;
  memberBookingCoachAvailabilityCalendarItems?: Maybe<MemberBookingCoachAvailabilityCalendarItems>;
  memberBookingCoachAvailabilityDayItem: Array<Maybe<MemberBookingCoachAvailabilityDayItem>>;
  memberBookingCoachAvailabilityDayItemV2: Array<Maybe<MemberBookingCoachAvailabilityDayItemV2>>;
  memberBookingCoachAvailabilitySlotItem: MemberBookingCoachAvailabilitySlotItem;
  memberBookingCoaches: Array<Maybe<MemberBookingCoach>>;
  memberBookingCoachesV2: Array<Maybe<MemberBookingCoach>>;
  memberBookingFacilityAvailabilityCalendarItems?: Maybe<MemberBookingFacilityAvailabilityCalendarItems>;
  memberBookingFacilityAvailabilityDayItem: Array<Maybe<MemberBookingFacilityAvailabilityDayItem>>;
  memberBookingFacilityAvailabilityDayItemV2?: Maybe<MemberBookingFacilityAvailabilityDayItems>;
  memberBookingGameCheckout: MemberBookingCheckout;
  memberBookingGameCheckoutV2: MemberBookingCheckoutV2;
  memberBookingGroupMembers: MemberBookingGroupMembers;
  memberBookingHelper: MemberBookingHelper;
  memberBookingHelperV2: MemberBookingHelperV2;
  memberBookingLessonIndoorCheckout: MemberBookingCheckout;
  memberBookingLessonIndoorCheckoutV2: MemberBookingCheckoutV2;
  memberBookingLessonOutdoorCheckout: MemberBookingCheckout;
  memberBookingLessonOutdoorCheckoutV2: MemberBookingCheckoutV2;
  memberBookingPractiseCheckout: MemberBookingCheckout;
  memberBookingPractiseCheckoutV2: MemberBookingCheckoutV2;
  memberBookingV2: MemberBookingV2;
  memberBookings: MemberBookingResponse;
  memberBookingsActive: MemberBookingResponse;
  memberBookingsCompleted: MemberBookingResponse;
  memberBookingsToday: Array<Maybe<MemberBookingBasic>>;
  memberCreditTransactions: MemberCreditTransactionResponse;
  memberFriends: Array<Maybe<MemberFriend>>;
  memberFriendsV2: MemberFriendsV2;
  memberMember: MemberMember;
  memberNotification: MemberNotification;
  memberNotifications: MemberNotificationResponse;
  memberOrder: Order;
  memberOrders: MemberOrderResponse;
  memberScoreCard: Scalars['JSON'];
  memberScoreCards: Scalars['JSON'];
  memberSimulatorPrivilege: MemberSimulatorPrivilege;
  memberSubscriptions: MemberSubscriptionResponse;
  memberWebsiteLoginQRCodeRequest: Scalars['String'];
  memberWebsiteLoginQRCodeVerification: MemberWebsiteLoginQrCodeVerification;
  organization?: Maybe<Organization>;
  pricing?: Maybe<Pricing>;
  publicClubhouse?: Maybe<Scalars['JSON']>;
  publicCoaches?: Maybe<Array<Maybe<PublicCoach>>>;
  publicMembership: Array<Maybe<PublicMembership>>;
  publicUtilityIpAddress: Scalars['String'];
  self?: Maybe<Self>;
};


export type QueryAdminArgs = {
  args: AdminArgs;
};


export type QueryAdminBookingArgs = {
  args: AdminBookingArgs;
};


export type QueryAdminBookingAvailabilityArgs = {
  args: AdminBookingAvailabilityArgs;
};


export type QueryAdminBookingBlackoutClubhouseArgs = {
  args: AdminBookingBlackoutClubhouseArgs;
};


export type QueryAdminBookingBlackoutCoachArgs = {
  args: AdminBookingBlackoutCoachArgs;
};


export type QueryAdminBookingBlackoutsArgs = {
  args: AdminBookingBlackoutsArgs;
};


export type QueryAdminBookingCoachAvailabilityCalendarItemsArgs = {
  args: AdminBookingCoachAvailabilityCalendarItemsArgs;
};


export type QueryAdminBookingCoachAvailabilityDayItemArgs = {
  args: AdminBookingCoachAvailabilityDayItemArgs;
};


export type QueryAdminBookingCoachAvailabilitySlotItemArgs = {
  args: AdminBookingCoachAvailabilitySlotItemArgs;
};


export type QueryAdminBookingCombinedCoachAvailabilityDayItemArgs = {
  args: AdminBookingCombinedCoachAvailabilityDayItemArgs;
};


export type QueryAdminBookingFacilityAvailabilityCalendarItemsArgs = {
  args: AdminBookingFacilityAvailabilityCalendarItemsArgs;
};


export type QueryAdminBookingFacilityAvailabilityDayItemArgs = {
  args: AdminBookingFacilityAvailabilityDayItemArgs;
};


export type QueryAdminBookingFacilityAvailabilitySlotItemArgs = {
  args: AdminBookingFacilityAvailabilitySlotItemArgs;
};


export type QueryAdminBookingGameCheckoutArgs = {
  args: AdminBookingGameCheckoutArgs;
};


export type QueryAdminBookingGroupMembersArgs = {
  args: AdminBookingGroupMembersArgs;
};


export type QueryAdminBookingLessonIndoorCheckoutArgs = {
  args: AdminBookingLessonIndoorCheckoutArgs;
};


export type QueryAdminBookingLessonOutdoorCheckoutArgs = {
  args: AdminBookingLessonOutdoorCheckoutArgs;
};


export type QueryAdminBookingPractiseCheckoutArgs = {
  args: AdminBookingPractiseCheckoutArgs;
};


export type QueryAdminBookingSettingsArgs = {
  args: AdminBookingSettingsArgs;
};


export type QueryAdminBookingsArgs = {
  args: AdminBookingsArgs;
};


export type QueryAdminClientStatusArgs = {
  args: AdminClientStatusArgs;
};


export type QueryAdminClubhouseArgs = {
  args: AdminClubhouseArgs;
};


export type QueryAdminClubhouseFacilitiesArgs = {
  args: AdminClubhouseFacilitiesArgs;
};


export type QueryAdminClubhouseFacilityAvailabilityArgs = {
  args: AdminClubhouseFacilityAvailabilityArgs;
};


export type QueryAdminClubhousesArgs = {
  args: AdminClubhousesArgs;
};


export type QueryAdminCoachArgs = {
  args: AdminCoachArgs;
};


export type QueryAdminCoachAvailabilityCalendarItemsArgs = {
  args?: InputMaybe<AdminCoachAvailabilityCalendarItemsArgs>;
};


export type QueryAdminCoachCombinedAvailabilityArgs = {
  args: AdminCoachCombinedAvailabilityArgs;
};


export type QueryAdminCoachesArgs = {
  args?: InputMaybe<AdminCoachesArgs>;
};


export type QueryAdminCreditTransactionsArgs = {
  args: AdminCreditTransactionsArgs;
};


export type QueryAdminGameHistoryArgs = {
  args: AdminGameHistoryArgs;
};


export type QueryAdminGroupBookingsArgs = {
  args: AdminGroupBookingsArgs;
};


export type QueryAdminKGolfMemberArgs = {
  args?: InputMaybe<AdminKGolfMemberArgs>;
};


export type QueryAdminManagementSystemSupportTicketArgs = {
  args: AdminManagementSystemSupportTicketArgs;
};


export type QueryAdminManagementSystemSupportTicketCommentsArgs = {
  args: AdminManagementSystemSupportTicketCommentsArgs;
};


export type QueryAdminManagementSystemSupportTicketsArgs = {
  args: AdminManagementSystemSupportTicketsArgs;
};


export type QueryAdminMemberArgs = {
  args: AdminMemberArgs;
};


export type QueryAdminMemberAcTransactionsArgs = {
  args: AdminMemberAcTransactionsArgs;
};


export type QueryAdminMemberInvoicesArgs = {
  args: AdminMemberInvoicesArgs;
};


export type QueryAdminMemberNotificationsArgs = {
  args: AdminMemberNotificationsArgs;
};


export type QueryAdminMemberProductGroupPackagesArgs = {
  args: AdminMemberProductGroupPackagesArgs;
};


export type QueryAdminMemberProductPackageArgs = {
  args: AdminMemberProductPackageArgs;
};


export type QueryAdminMemberProductPackageExtensionsArgs = {
  args: AdminMemberProductPackageExtensionsArgs;
};


export type QueryAdminMemberServiceTransactionsArgs = {
  args: AdminMemberServiceTransactionsArgs;
};


export type QueryAdminMemberSubscriptionsArgs = {
  args: AdminMemberSubscriptionsArgs;
};


export type QueryAdminMembersArgs = {
  args: AdminMembersArgs;
};


export type QueryAdminMembershipServicesArgs = {
  args: AdminMembershipServicesArgs;
};


export type QueryAdminMembershipSubscriptionsArgs = {
  args: AdminMembershipSubscriptionsArgs;
};


export type QueryAdminMembershipTypeArgs = {
  args: AdminMembershipTypeArgs;
};


export type QueryAdminMembershipTypesArgs = {
  args: AdminMembershipTypesArgs;
};


export type QueryAdminNotificationArgs = {
  args: AdminNotificationArgs;
};


export type QueryAdminNotificationsArgs = {
  args: AdminNotificationsArgs;
};


export type QueryAdminProductsArgs = {
  args: AdminProductsArgs;
};


export type QueryAdminReportAcTransactionsArgs = {
  args: AdminReportAcTransactionsArgs;
};


export type QueryAdminReportCreditTransactionsArgs = {
  args: AdminReportCreditTransactionsArgs;
};


export type QueryAdminReportHitPayChargeArgs = {
  args: AdminReportHitPayChargeArgs;
};


export type QueryAdminReportHitPayChargesArgs = {
  args: AdminReportHitPayChargesArgs;
};


export type QueryAdminReportInvoiceArgs = {
  args: AdminReportInvoiceArgs;
};


export type QueryAdminReportInvoicesArgs = {
  args: AdminReportInvoicesArgs;
};


export type QueryAdminReportMemberStatsArgs = {
  args: AdminReportMemberStatsArgs;
};


export type QueryAdminReportOrderArgs = {
  args: AdminReportOrderArgs;
};


export type QueryAdminReportOrdersArgs = {
  args: AdminReportOrdersArgs;
};


export type QueryAdminReservedIdsArgs = {
  args?: InputMaybe<AdminReservedIdsArgs>;
};


export type QueryAdminScoreCardArgs = {
  args: AdminScoreCardArgs;
};


export type QueryAdminShopDetailsArgs = {
  args: AdminShopDetailsArg;
};


export type QueryAdminShopOptionsDrivingRangeArgs = {
  args: AdminShopOptionsDrivingRangeArgs;
};


export type QueryAdminShopOptionsRealTimeArgs = {
  args: AdminShopOptionsRealTimeArgs;
};


export type QueryAdminShopOptionsRoundArgs = {
  args: AdminShopOptionsRoundArgs;
};


export type QueryAdminSimulatorUsageArgs = {
  args: AdminSimulatorUsageArgs;
};


export type QueryAdminStaffsArgs = {
  args?: InputMaybe<AdminStaffsArgs>;
};


export type QueryAdminsArgs = {
  args: AdminsArgs;
};


export type QueryAppDineMenuArgs = {
  args?: InputMaybe<AppDineMenuArgs>;
};


export type QueryAppMainSliderArgs = {
  args?: InputMaybe<AppMainSliderArgs>;
};


export type QueryAppMainSliderV2Args = {
  args: AppMainSliderV2Args;
};


export type QueryAppManifestArgs = {
  args: AppManifestArgs;
};


export type QueryAppMediaArgs = {
  args: AppMediaArgs;
};


export type QueryAppMediaYouTubeVideoArgs = {
  args: AppMediaYouTubeVideoArgs;
};


export type QueryCoachAvailabilityCalendarItemsArgs = {
  args?: InputMaybe<CoachAvailabilityCalendarItemsArgs>;
};


export type QueryCoachAvailabilityDayItemArgs = {
  args: CoachAvailabilityDayItemArgs;
};


export type QueryCoachAvailabilityDayItemTemplateArgs = {
  args: CoachAvailabilityDayItemTemplateArgs;
};


export type QueryCoachBookingArgs = {
  args: CoachBookingArgs;
};


export type QueryCoachBookingCombinedAvailabilityArgs = {
  args: CoachBookingCombinedAvailabilityArgs;
};


export type QueryCoachBookingCombinedAvailabilityDayItemArgs = {
  args: CoachBookingCombinedAvailabilityDayItemArgs;
};


export type QueryCoachBookingsArgs = {
  args: CoachBookingsArgs;
};


export type QueryCoachGroupBookingsArgs = {
  args: CoachGroupBookingsArgs;
};


export type QueryCoachMemberArgs = {
  args: CoachMemberArgs;
};


export type QueryCoachMemberLessonNoteArgs = {
  args: CoachMemberLessonNoteArgs;
};


export type QueryCoachMemberLessonNoteMembersArgs = {
  args: CoachMemberLessonNoteMembersArgs;
};


export type QueryCoachMemberLessonNotesArgs = {
  args: CoachMemberLessonNotesArgs;
};


export type QueryCoachMembersArgs = {
  args: CoachMembersArgs;
};


export type QueryDevAceCoinsLedgerArgs = {
  args?: InputMaybe<DevAceCoinsLedgerArgs>;
};


export type QueryFormHelperAceCoinTypeArgs = {
  args: FormHelperAceCoinTypeArgs;
};


export type QueryFormHelperBookingArgs = {
  args: FormHelperBookingArgs;
};


export type QueryFormHelperNewAdminArgs = {
  args: FormHelperNewAdminArgs;
};


export type QueryFormHelperNewBlackOutArgs = {
  args?: InputMaybe<FormHelperNewBlackOutArgs>;
};


export type QueryFormHelperNewCoachArgs = {
  args: FormHelperNewCoachArgs;
};


export type QueryFormHelperNewMembershipArgs = {
  args: FormHelperNewMembershipArgs;
};


export type QueryFormHelperProductPackagesArgs = {
  args: FormHelperProductPackagesArgs;
};


export type QueryMemberAccountAcTransactionsArgs = {
  args: MemberAccountAcTransactionsArgs;
};


export type QueryMemberAccountCoachArgs = {
  args: MemberAccountCoachArgs;
};


export type QueryMemberAccountExpirableCreditsArgs = {
  args: MemberAccountExpirableCreditsArgs;
};


export type QueryMemberAccountFindMemberArgs = {
  args: MemberAccountFindMemberArgs;
};


export type QueryMemberAccountInvoiceArgs = {
  args: MemberAccountInvoiceArgs;
};


export type QueryMemberAccountInvoicesArgs = {
  args: MemberAccountInvoicesArgs;
};


export type QueryMemberAccountLessonNoteArgs = {
  args: MemberAccountLessonNoteArgs;
};


export type QueryMemberAccountLessonNotesArgs = {
  args: MemberAccountLessonNotesArgs;
};


export type QueryMemberAccountNotificationArgs = {
  args: MemberAccountNotificationArgs;
};


export type QueryMemberAccountNotificationsArgs = {
  args: MemberAccountNotificationsArgs;
};


export type QueryMemberAccountPackageArgs = {
  args: MemberAccountPackageArgs;
};


export type QueryMemberAccountPackageOfferArgs = {
  args: MemberAccountPackageOfferArgs;
};


export type QueryMemberAccountPackagesArgs = {
  args: MemberAccountPackagesArgs;
};


export type QueryMemberAccountServiceTransactionsArgs = {
  args: MemberAccountServiceTransactionsArgs;
};


export type QueryMemberAccountServiceTransactionsV2Args = {
  args: MemberAccountServiceTransactionsArgs;
};


export type QueryMemberAccountSubscriptionsArgs = {
  args: MemberAccountSubscriptionsArgs;
};


export type QueryMemberBookingArgs = {
  args: MemberBookingArgs;
};


export type QueryMemberBookingCoachAvailabilityCalendarItemsArgs = {
  args: MemberBookingCoachAvailabilityCalendarItemsArgs;
};


export type QueryMemberBookingCoachAvailabilityDayItemArgs = {
  args: MemberBookingCoachAvailabilityDayItemArgs;
};


export type QueryMemberBookingCoachAvailabilityDayItemV2Args = {
  args: MemberBookingCoachAvailabilityDayItemV2Args;
};


export type QueryMemberBookingCoachAvailabilitySlotItemArgs = {
  args: MemberBookingCoachAvailabilitySlotItemArgs;
};


export type QueryMemberBookingCoachesV2Args = {
  args?: InputMaybe<MemberBookingCoachesV2Args>;
};


export type QueryMemberBookingFacilityAvailabilityCalendarItemsArgs = {
  args: MemberBookingFacilityAvailabilityCalendarItemsArgs;
};


export type QueryMemberBookingFacilityAvailabilityDayItemArgs = {
  args: MemberBookingFacilityAvailabilityDayItemArgs;
};


export type QueryMemberBookingFacilityAvailabilityDayItemV2Args = {
  args: MemberBookingFacilityAvailabilityDayItemArgs;
};


export type QueryMemberBookingGameCheckoutArgs = {
  args: MemberBookingGameCheckoutArgs;
};


export type QueryMemberBookingGameCheckoutV2Args = {
  args: MemberBookingGameCheckoutArgs;
};


export type QueryMemberBookingGroupMembersArgs = {
  args: MemberBookingGroupMembersArgs;
};


export type QueryMemberBookingHelperV2Args = {
  args: MemberBookingHelperV2Args;
};


export type QueryMemberBookingLessonIndoorCheckoutArgs = {
  args: MemberBookingLessonIndoorCheckoutArgs;
};


export type QueryMemberBookingLessonIndoorCheckoutV2Args = {
  args: MemberBookingLessonIndoorCheckoutArgs;
};


export type QueryMemberBookingLessonOutdoorCheckoutArgs = {
  args: MemberBookingLessonOutdoorCheckoutArgs;
};


export type QueryMemberBookingLessonOutdoorCheckoutV2Args = {
  args: MemberBookingLessonOutdoorCheckoutArgs;
};


export type QueryMemberBookingPractiseCheckoutArgs = {
  args: MemberBookingPractiseCheckoutArgs;
};


export type QueryMemberBookingPractiseCheckoutV2Args = {
  args: MemberBookingPractiseCheckoutArgs;
};


export type QueryMemberBookingV2Args = {
  args: MemberBookingArgs;
};


export type QueryMemberBookingsArgs = {
  args: MemberBookingsArgs;
};


export type QueryMemberBookingsActiveArgs = {
  args: MemberBookingsActiveArgs;
};


export type QueryMemberBookingsCompletedArgs = {
  args: MemberBookingsCompletedArgs;
};


export type QueryMemberCreditTransactionsArgs = {
  args: MemberCreditTransactionsArgs;
};


export type QueryMemberMemberArgs = {
  args: MemberMemberArgs;
};


export type QueryMemberNotificationArgs = {
  args: MemberNotificationArgs;
};


export type QueryMemberNotificationsArgs = {
  args: MemberNotificationsArgs;
};


export type QueryMemberOrderArgs = {
  args: MemberOrderArgs;
};


export type QueryMemberOrdersArgs = {
  args: MemberOrdersArgs;
};


export type QueryMemberScoreCardArgs = {
  args: MemberScoreCardArgs;
};


export type QueryMemberScoreCardsArgs = {
  args: MemberScoreCardsArgs;
};


export type QueryMemberSubscriptionsArgs = {
  args: MemberSubscriptionsArgs;
};


export type QueryMemberWebsiteLoginQrCodeRequestArgs = {
  args: MemberWebsiteLoginQrCodeRequestArgs;
};


export type QueryMemberWebsiteLoginQrCodeVerificationArgs = {
  args: MemberWebsiteLoginQrCodeVerificationArgs;
};


export type QueryPublicClubhouseArgs = {
  args: PublicClubhouseArgs;
};

export enum Relationship {
  Child = 'CHILD',
  Spouse = 'SPOUSE'
}

export enum ReservedIdStatus {
  Assigned = 'ASSIGNED',
  Available = 'AVAILABLE',
  OnHold = 'ON_HOLD'
}

export enum ReservedMembershipIdStatus {
  Assigned = 'ASSIGNED',
  Available = 'AVAILABLE',
  OnHold = 'ON_HOLD'
}

export type ScoreCard = {
  __typename?: 'ScoreCard';
  clientName: Scalars['String'];
  courseIntroImage: Scalars['String'];
  courseLogoImage: Scalars['String'];
  courseName: Scalars['String'];
  endTime: Scalars['Int'];
  gameType: Scalars['Int'];
  gameTypeText: Scalars['String'];
  roundIdx: Scalars['Int'];
  shopName: Scalars['String'];
  startTime: Scalars['Int'];
  strokeTotalScore: Scalars['Int'];
};

export type Self = {
  __typename?: 'Self';
  baseCountryCode?: Maybe<CountryCode>;
  baseGmtOffset?: Maybe<Scalars['Int']>;
  clubhouses?: Maybe<Array<Maybe<SelfClubhouse>>>;
  countries?: Maybe<Array<Maybe<SelfCountry>>>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastLogin?: Maybe<LoginInfo>;
  lastName: Scalars['String'];
  logins: Array<Maybe<LoginInfo>>;
  mobileNumber?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  organizationName: Scalars['String'];
  profileImageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<AdminStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  userType?: Maybe<UserType>;
};

export type SelfClubhouse = {
  __typename?: 'SelfClubhouse';
  clubhouseCode: Scalars['String'];
  name: Scalars['String'];
};

export type SelfCountry = {
  __typename?: 'SelfCountry';
  countryCode: CountryCode;
  name: Scalars['String'];
};

export type SelfUpdateInput = {
  deleteImage?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  mobileNumber?: InputMaybe<Scalars['String']>;
  profileImageFile?: InputMaybe<File>;
};

export type SelfUpdatePasswordInput = {
  confirmPassword: Scalars['String'];
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type SelfUpdated = {
  __typename?: 'SelfUpdated';
  fileUploadCredentials?: Maybe<FileUploadCredentials>;
  self: Self;
};

export enum SentBy {
  Admin = 'ADMIN',
  Coach = 'COACH',
  System = 'SYSTEM'
}

export type ServiceTransactionBasic = {
  __typename?: 'ServiceTransactionBasic';
  amount: Scalars['Float'];
  coach?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  date: Scalars['DateTime'];
  expirableCredit?: Maybe<ExpirableCreditBasic>;
  facilityType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isDailyType?: Maybe<Scalars['Boolean']>;
  isSupplementaryRedemption?: Maybe<Scalars['Boolean']>;
  memberName?: Maybe<Scalars['String']>;
  packageName?: Maybe<Scalars['String']>;
  packageType?: Maybe<Scalars['String']>;
  serviceId?: Maybe<Scalars['String']>;
  serviceType: Scalars['String'];
  supplementaryUnitName?: Maybe<Scalars['String']>;
  transactionType: Scalars['String'];
  venue?: Maybe<Scalars['String']>;
};

export enum ServiceTransactionDirection {
  Decrease = 'DECREASE',
  Increase = 'INCREASE'
}

export enum ServiceTransactionType {
  Adjustment = 'ADJUSTMENT',
  Award = 'AWARD',
  Purchase = 'PURCHASE',
  Redemption = 'REDEMPTION',
  Transfer = 'TRANSFER'
}

export enum ServiceType {
  Game = 'GAME',
  LessonField = 'LESSON_FIELD',
  LessonIndoor = 'LESSON_INDOOR',
  Practise = 'PRACTISE'
}

export type ShopTokens = {
  __typename?: 'ShopTokens';
  clubhouseCode: Scalars['String'];
  clubhouseName: Scalars['String'];
  kgat: Scalars['String'];
  kgrt: Scalars['String'];
  shopGuid: Scalars['String'];
  shopName: Scalars['String'];
};

export type ShopsTokens = {
  __typename?: 'ShopsTokens';
  expires: Scalars['String'];
  shops?: Maybe<Scalars['JSON']>;
  ud?: Maybe<Scalars['DateTime']>;
};

export type ShotCoordinate = {
  __typename?: 'ShotCoordinate';
  endCoordinates?: Maybe<EndCoordinates>;
  gameRoundShotId?: Maybe<Scalars['Int']>;
  shotNumber?: Maybe<Scalars['Int']>;
  startCoordinates?: Maybe<StartCoordinates>;
};

export type SlotItem = {
  __typename?: 'SlotItem';
  bays: Array<Maybe<FacilityAvailability>>;
  puttings: Array<Maybe<FacilityAvailability>>;
  rooms: Array<Maybe<FacilityAvailability>>;
  studios: Array<Maybe<FacilityAvailability>>;
};

export type StartCoordinates = {
  __typename?: 'StartCoordinates';
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
  z?: Maybe<Scalars['Float']>;
};

export enum StringBoolean {
  False = 'FALSE',
  True = 'TRUE'
}

export type Subscription = {
  __typename?: 'Subscription';
  devManagementSystemUserTelemetry?: Maybe<DevManagementSystemUserTelemetry>;
  managementSystem?: Maybe<ManagementSystem>;
  memberApp?: Maybe<MemberApp>;
  memberLoginQR?: Maybe<MemberLoginQr>;
};


export type SubscriptionManagementSystemArgs = {
  args: ManagementSystemArgs;
};


export type SubscriptionMemberAppArgs = {
  args: MemberAppArgs;
};


export type SubscriptionMemberLoginQrArgs = {
  args: MemberLoginQrArgs;
};

export enum SubscriptionStatus {
  Active = 'ACTIVE',
  Na = 'NA',
  Paused = 'PAUSED',
  Pending = 'PENDING'
}

export enum SupportTicketCategory {
  BugFix = 'BUG_FIX',
  EnhanceFeature = 'ENHANCE_FEATURE',
  NewFeature = 'NEW_FEATURE',
  Other = 'OTHER'
}

export enum SupportTicketPriority {
  High = 'HIGH',
  Low = 'LOW',
  Normal = 'NORMAL'
}

export enum SupportTicketStatus {
  AwaitingApproval = 'AWAITING_APPROVAL',
  Closed = 'CLOSED',
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  Open = 'OPEN',
  Queued = 'QUEUED'
}

export enum SystemAuditLogEventName {
  AcecoinPurchase = 'ACECOIN_PURCHASE',
  AcecoinTxnDelete = 'ACECOIN_TXN_DELETE',
  AdminLogin = 'ADMIN_LOGIN',
  BlackoutCreate = 'BLACKOUT_CREATE',
  CronJob = 'CRON_JOB',
  DataExport = 'DATA_EXPORT',
  MembershipDelete = 'MEMBERSHIP_DELETE',
  ServiceTxnDelete = 'SERVICE_TXN_DELETE',
  TierDowngrade = 'TIER_DOWNGRADE',
  TierUpgrade = 'TIER_UPGRADE',
  UiReportingClearCache = 'UI_REPORTING_CLEAR_CACHE'
}

export enum SystemOperationStatus {
  AccessTokenExpired = 'ACCESS_TOKEN_EXPIRED',
  AdminNotFound = 'ADMIN_NOT_FOUND',
  BadRequestException = 'BAD_REQUEST_EXCEPTION',
  BookingNotAllowed = 'BOOKING_NOT_ALLOWED',
  BookingOverlapped = 'BOOKING_OVERLAPPED',
  CannotFindSelf = 'CANNOT_FIND_SELF',
  Fail = 'FAIL',
  IncorrectExistingPassword = 'INCORRECT_EXISTING_PASSWORD',
  InsufficientCredit = 'INSUFFICIENT_CREDIT',
  InvalidCredentials = 'INVALID_CREDENTIALS',
  MemberNotFound = 'MEMBER_NOT_FOUND',
  MemberNotFoundPasswordReset = 'MEMBER_NOT_FOUND_PASSWORD_RESET',
  NoRefreshToken = 'NO_REFRESH_TOKEN',
  PasswordMismatch = 'PASSWORD_MISMATCH',
  ResourceNotAvailable = 'RESOURCE_NOT_AVAILABLE',
  Success = 'SUCCESS',
  SuccessPasswordUpdate = 'SUCCESS_PASSWORD_UPDATE',
  TokenExpired = 'TOKEN_EXPIRED',
  TokenInvalid = 'TOKEN_INVALID',
  UnableToUpdate = 'UNABLE_TO_UPDATE',
  Unauthorized = 'UNAUTHORIZED',
  UniqueConstraintEmail = 'UNIQUE_CONSTRAINT_EMAIL',
  UniqueConstraintViolation = 'UNIQUE_CONSTRAINT_VIOLATION',
  VerificationCodeMismatch = 'VERIFICATION_CODE_MISMATCH',
  VersionMismatch = 'VERSION_MISMATCH'
}

export type SystemUser = {
  baseCountryCode?: Maybe<CountryCode>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  lastLogin?: Maybe<LoginInfo>;
  lastName: Scalars['String'];
  logins?: Maybe<Array<Maybe<LoginInfo>>>;
  mobileNumber?: Maybe<Scalars['String']>;
  organization?: Maybe<AdminOrganization>;
  profileImageUrl?: Maybe<Scalars['String']>;
  status?: Maybe<AdminStatus>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId: Scalars['String'];
  userType?: Maybe<UserType>;
};

export enum TransactionType {
  Credit = 'CREDIT',
  Debit = 'DEBIT'
}

export type UpdatedAdmin = {
  __typename?: 'UpdatedAdmin';
  fileUploadCredentials?: Maybe<FileUploadCredentials>;
  user: Admin;
};

export type UpdatedCoach = {
  __typename?: 'UpdatedCoach';
  coach: AdminCoach;
  fileUploadCredentials?: Maybe<FileUploadCredentials>;
};

export enum UserTelemetryResourceType {
  Booking = 'BOOKING',
  Clubhouse = 'CLUBHOUSE',
  Coach = 'COACH',
  Member = 'MEMBER',
  MyAccount = 'MY_ACCOUNT',
  NotificationMessage = 'NOTIFICATION_MESSAGE',
  Order = 'ORDER',
  OrderAtn = 'ORDER_ATN',
  Organization = 'ORGANIZATION',
  Settings = 'SETTINGS',
  Staff = 'STAFF',
  SystemUser = 'SYSTEM_USER'
}

export enum UserType {
  ClubhouseStaff = 'CLUBHOUSE_STAFF',
  Developer = 'DEVELOPER',
  DeveloperAdmin = 'DEVELOPER_ADMIN',
  ExternalCoach = 'EXTERNAL_COACH',
  MasterCoach = 'MASTER_COACH',
  MasterOwner = 'MASTER_OWNER',
  MasterUser = 'MASTER_USER',
  Owner = 'OWNER',
  User = 'USER'
}

export type ManagementSystemSubscriptionVariables = Exact<{
  args: ManagementSystemArgs;
}>;


export type ManagementSystemSubscription = { __typename?: 'Subscription', managementSystem?: { __typename?: 'ManagementSystem', eventId: string, operation?: ManagementSystemPublishOperation | null, ref?: string | null, userId?: string | null, userName?: string | null, userProfileImageUrl?: string | null } | null };

export type CoachAvailabilityDayUpsertMutationVariables = Exact<{
  input: CoachAvailabilityDayUpsertInput;
}>;


export type CoachAvailabilityDayUpsertMutation = { __typename?: 'Mutation', coachAvailabilityDayUpsert?: { __typename?: 'CoachAvailabilityDayItem', bi0800?: string | null, bi0900?: string | null, bi1000?: string | null, bi1100?: string | null, bi1200?: string | null, bi1300?: string | null, bi1400?: string | null, bi1500?: string | null, bi1600?: string | null, bi1700?: string | null, bi1800?: string | null, bi1900?: string | null, bi2000?: string | null, bi2100?: string | null, bs0800?: string | null, bs0900?: string | null, bs1000?: string | null, bs1100?: string | null, bs1200?: string | null, bs1300?: string | null, bs1400?: string | null, bs1500?: string | null, bs1600?: string | null, bs1700?: string | null, bs1800?: string | null, bs1900?: string | null, bs2000?: string | null, bs2100?: string | null, date?: string | null, l0800?: string | null, l0900?: string | null, l1000?: string | null, l1100?: string | null, l1200?: string | null, l1300?: string | null, l1400?: string | null, l1500?: string | null, l1600?: string | null, l1700?: string | null, l1800?: string | null, l1900?: string | null, l2000?: string | null, l2100?: string | null } | null };

export type CoachAvailabilityDayItemTemplateCreateMutationVariables = Exact<{
  input: CoachAvailabilityDayItemTemplateCreateInput;
}>;


export type CoachAvailabilityDayItemTemplateCreateMutation = { __typename?: 'Mutation', coachAvailabilityDayItemTemplateCreate: { __typename?: 'CoachAvailabilityDayItemTemplateCreate', id: string, status: SystemOperationStatus } };

export type CoachAvailabilityDayItemTemplateUpdateMutationVariables = Exact<{
  input: CoachAvailabilityDayItemTemplateUpdateInput;
}>;


export type CoachAvailabilityDayItemTemplateUpdateMutation = { __typename?: 'Mutation', coachAvailabilityDayItemTemplateUpdate: { __typename?: 'CoachAvailabilityDayItemTemplate', id: string, l0800?: string | null, l0900?: string | null, l1000?: string | null, l1100?: string | null, l1200?: string | null, l1300?: string | null, l1400?: string | null, l1500?: string | null, l1600?: string | null, l1700?: string | null, l1800?: string | null, l1900?: string | null, l2000?: string | null, l2100?: string | null, name: string } };

export type CoachAvailabilityDayItemTemplateDeleteMutationVariables = Exact<{
  input: CoachAvailabilityDayItemTemplateDeleteInput;
}>;


export type CoachAvailabilityDayItemTemplateDeleteMutation = { __typename?: 'Mutation', coachAvailabilityDayItemTemplateDelete: SystemOperationStatus };

export type CoachAvailabilityCalendarItemsQueryVariables = Exact<{
  args?: InputMaybe<CoachAvailabilityCalendarItemsArgs>;
}>;


export type CoachAvailabilityCalendarItemsQuery = { __typename?: 'Query', coachAvailabilityCalendarItems: Array<{ __typename?: 'CoachAvailabilityCalendarItems', clubhouses: Array<string | null>, date: string, hasIndoorBooking?: boolean | null, hasOutdoorBooking?: boolean | null, isBlocked?: boolean | null, isIndoorBookingFull?: boolean | null } | null> };

export type CoachAvailabilityDayItemQueryVariables = Exact<{
  args: CoachAvailabilityDayItemArgs;
}>;


export type CoachAvailabilityDayItemQuery = { __typename?: 'Query', coachAvailabilityDayItem?: { __typename?: 'CoachAvailabilityDayItem', bi0800?: string | null, bi0900?: string | null, bi1000?: string | null, bi1100?: string | null, bi1200?: string | null, bi1300?: string | null, bi1400?: string | null, bi1500?: string | null, bi1600?: string | null, bi1700?: string | null, bi1800?: string | null, bi1900?: string | null, bi2000?: string | null, bi2100?: string | null, biOutdoor?: string | null, bs0800?: string | null, bs0900?: string | null, bs1000?: string | null, bs1100?: string | null, bs1200?: string | null, bs1300?: string | null, bs1400?: string | null, bs1500?: string | null, bs1600?: string | null, bs1700?: string | null, bs1800?: string | null, bs1900?: string | null, bs2000?: string | null, bs2100?: string | null, date?: string | null, hasOutdoorBooking?: boolean | null, l0800?: string | null, l0900?: string | null, l1000?: string | null, l1100?: string | null, l1200?: string | null, l1300?: string | null, l1400?: string | null, l1500?: string | null, l1600?: string | null, l1700?: string | null, l1800?: string | null, l1900?: string | null, l2000?: string | null, l2100?: string | null } | null };

export type CoachAvailabilityDayItemTemplateQueryVariables = Exact<{
  args: CoachAvailabilityDayItemTemplateArgs;
}>;


export type CoachAvailabilityDayItemTemplateQuery = { __typename?: 'Query', coachAvailabilityDayItemTemplate: { __typename?: 'CoachAvailabilityDayItemTemplate', id: string, l0800?: string | null, l0900?: string | null, l1000?: string | null, l1100?: string | null, l1200?: string | null, l1300?: string | null, l1400?: string | null, l1500?: string | null, l1600?: string | null, l1700?: string | null, l1800?: string | null, l1900?: string | null, l2000?: string | null, l2100?: string | null, name: string } };

export type CoachAvailabilityDayItemTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type CoachAvailabilityDayItemTemplatesQuery = { __typename?: 'Query', coachAvailabilityDayItemTemplates: Array<{ __typename?: 'CoachAvailabilityDayItemTemplate', id: string, l0800?: string | null, l0900?: string | null, l1000?: string | null, l1100?: string | null, l1200?: string | null, l1300?: string | null, l1400?: string | null, l1500?: string | null, l1600?: string | null, l1700?: string | null, l1800?: string | null, l1900?: string | null, l2000?: string | null, l2100?: string | null, name: string } | null> };

export type CoachBookingBasicQueryVariables = Exact<{
  args: CoachBookingArgs;
}>;


export type CoachBookingBasicQuery = { __typename?: 'Query', coachBooking?: { __typename?: 'CoachBooking', dateMonthStr?: string | null, dayOfWeekStr?: string | null, durationStr?: string | null, endTimeStr?: string | null, facilityType?: FacilityType | null, id: string, member: string, memberIndividualId: string, resourceName?: string | null, startTimeStr?: string | null, venue?: string | null, yearStr?: string | null } | null };

export type CoachBookingCommentUpdateMutationVariables = Exact<{
  input: CoachBookingCommentUpdateInput;
}>;


export type CoachBookingCommentUpdateMutation = { __typename?: 'Mutation', coachBookingCommentUpdate: { __typename?: 'CoachBookingCommentUpdate', commentByCoach?: string | null } };

export type CoachBookingQueryVariables = Exact<{
  args: CoachBookingArgs;
}>;


export type CoachBookingQuery = { __typename?: 'Query', coachBooking?: { __typename?: 'CoachBooking', cancellationDateTime?: any | null, cancellationReason?: string | null, cancelledBy?: CancelledBy | null, cancelledByAdminName?: string | null, coachId?: string | null, coachName?: string | null, commentByCoach?: string | null, commentInternal?: string | null, createdAt: any, createdBy?: CreatedBy | null, createdByAdminName?: string | null, date: any, duration?: number | null, durationStr?: string | null, endTime: any, fieldLessonDetails?: string | null, id: string, isGroupBooking: boolean, member: string, memberIndividualId: string, memberUuid: string, mobileNumber?: string | null, noOfPlayers: number, resourceName?: string | null, startTime: any, status: BookingStatus, type: BookingType, venue?: string | null, groupMembers?: Array<{ __typename?: 'GroupMember', individualId: string, memberUuid: string, mobileNumber?: string | null, name: string } | null> | null } | null };

export type CoachBookingsQueryVariables = Exact<{
  args: CoachBookingsArgs;
}>;


export type CoachBookingsQuery = { __typename?: 'Query', coachBookings: { __typename?: 'CoachBookingResponse', firstBookingDate?: any | null, lastBookingDate?: any | null, edges?: Array<{ __typename?: 'CoachBookingEdge', cursor?: string | null, node?: { __typename?: 'CoachBookingBasic', cancellationDateTime?: any | null, coachDisplayName?: string | null, date: any, dateMonthStr?: string | null, dateStr: string, dayOfWeekStr?: string | null, durationStr?: string | null, endTime: any, endTimeStr?: string | null, groupBookingId?: string | null, groupBookingIds: Array<string | null>, id: string, isGroupBooking: boolean, member: string, resourceName?: string | null, startTime: any, startTimeStr?: string | null, status: BookingStatus, type: BookingType, venue?: string | null, yearStr?: string | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, firstRecordDateTime?: any | null, hasNextPage?: boolean | null, total?: number | null } | null } };

export type FormHelperBookingQueryVariables = Exact<{
  args: FormHelperBookingArgs;
}>;


export type FormHelperBookingQuery = { __typename?: 'Query', formHelperBooking?: { __typename?: 'FormHelperBooking', clubhouses: Array<{ __typename?: 'AdminClubhouseBasic', clubhouseCode: string, clubhouseId: string, name: string } | null> } | null };

export type CoachGroupBookingsQueryVariables = Exact<{
  args: CoachGroupBookingsArgs;
}>;


export type CoachGroupBookingsQuery = { __typename?: 'Query', coachGroupBookings: Array<{ __typename?: 'CoachGroupBooking', coachId?: string | null, coachName?: string | null, commentInternal?: string | null, dateMonthStr?: string | null, dayOfWeekStr?: string | null, durationStr?: string | null, endTimeStr: string, facilityType?: FacilityType | null, fieldLessonDetails?: string | null, gameCourseType?: GameCourseType | null, id: string, member: string, memberIndividualId: string, mobileNumber: string, resourceName?: string | null, startTimeStr?: string | null, type: BookingType, venue?: string | null, yearStr?: string | null }> };

export type CoachBookingCombinedAvailabilityQueryVariables = Exact<{
  args: CoachBookingCombinedAvailabilityArgs;
}>;


export type CoachBookingCombinedAvailabilityQuery = { __typename?: 'Query', coachBookingCombinedAvailability: Array<{ __typename?: 'CoachBookingCombinedAvailability', coachId: string, coachSlug: string, name: string, dateObjs: Array<{ __typename?: 'CoachBookingCombinedAvailabilityDateObj', clubhouses: Array<string | null>, date: string, hasIndoorBooking: boolean, hasOutdoorBooking: boolean, isDayBlocked: boolean } | null> } | null> };

export type CoachBookingCombinedAvailabilityDayItemQueryVariables = Exact<{
  args: CoachBookingCombinedAvailabilityDayItemArgs;
}>;


export type CoachBookingCombinedAvailabilityDayItemQuery = { __typename?: 'Query', coachBookingCombinedAvailabilityDayItem?: { __typename?: 'CoachBookingCombinedAvailabilityDayItem', bi0800?: string | null, bi0900?: string | null, bi1000?: string | null, bi1100?: string | null, bi1200?: string | null, bi1300?: string | null, bi1400?: string | null, bi1500?: string | null, bi1600?: string | null, bi1700?: string | null, bi1800?: string | null, bi1900?: string | null, bi2000?: string | null, bi2100?: string | null, biOutdoor?: string | null, bs0800?: string | null, bs0900?: string | null, bs1000?: string | null, bs1100?: string | null, bs1200?: string | null, bs1300?: string | null, bs1400?: string | null, bs1500?: string | null, bs1600?: string | null, bs1700?: string | null, bs1800?: string | null, bs1900?: string | null, bs2000?: string | null, bs2100?: string | null, date?: string | null, displayName?: string | null, hasOutdoorBooking?: boolean | null, l0800?: string | null, l0900?: string | null, l1000?: string | null, l1100?: string | null, l1200?: string | null, l1300?: string | null, l1400?: string | null, l1500?: string | null, l1600?: string | null, l1700?: string | null, l1800?: string | null, l1900?: string | null, l2000?: string | null, l2100?: string | null, clubhouses: Array<{ __typename?: 'CoachClubhouse', clubhouseCode: string, displayName: string, tagBgColorCode: string, tagText: string, tagTextColorCode: string } | null> } | null };

export type CoachBookingCoachesBasicQueryVariables = Exact<{ [key: string]: never; }>;


export type CoachBookingCoachesBasicQuery = { __typename?: 'Query', coachBookingCoaches: Array<{ __typename?: 'CoachBookingCoach', displayName?: string | null, userId?: string | null } | null> };

export type AdminLoginMutationVariables = Exact<{
  input: AdminLoginInput;
}>;


export type AdminLoginMutation = { __typename?: 'Mutation', adminLogin: { __typename?: 'AdminAuthResponse', accessToken: string, authenticatedAdmin: { __typename?: 'AuthenticatedAdmin', clubhouseCode?: string | null, clubhouses: Array<string | null>, firstName: string, lastLoginDate?: any | null, lastName: string, permissions?: Array<string | null> | null, profileImageUrl: string, ud?: any | null, userId: string, userType: string, yourIp: string, lastLogin?: { __typename?: 'LoginInfo', city?: string | null, ipAddress?: string | null, time?: any | null } | null } } };

export type AdminLogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type AdminLogoutMutation = { __typename?: 'Mutation', adminLogout: SystemOperationStatus };

export type AdminRefreshAccessTokenMutationVariables = Exact<{ [key: string]: never; }>;


export type AdminRefreshAccessTokenMutation = { __typename?: 'Mutation', adminRefreshAccessToken: { __typename?: 'AdminAuthResponse', accessToken: string, authenticatedAdmin: { __typename?: 'AuthenticatedAdmin', clubhouseCode?: string | null, clubhouses: Array<string | null>, firstName: string, lastLoginDate?: any | null, lastName: string, permissions?: Array<string | null> | null, profileImageUrl: string, ud?: any | null, userId: string, userType: string, yourIp: string, lastLogin?: { __typename?: 'LoginInfo', city?: string | null, ipAddress?: string | null, time?: any | null } | null } } };

export type AdminForgotPasswordMutationVariables = Exact<{
  input: AdminForgotPasswordInput;
}>;


export type AdminForgotPasswordMutation = { __typename?: 'Mutation', adminForgotPassword: { __typename?: 'AdminForgotPasswordResponse', email: string, resetToken: string } };

export type AdminForgotPasswordSubmitMutationVariables = Exact<{
  input: AdminForgotPasswordSubmitInput;
}>;


export type AdminForgotPasswordSubmitMutation = { __typename?: 'Mutation', adminForgotPasswordSubmit: SystemOperationStatus };

export type AdminShopTokensMutationVariables = Exact<{
  input?: InputMaybe<AdminShopTokensInput>;
}>;


export type AdminShopTokensMutation = { __typename?: 'Mutation', adminShopTokens: { __typename?: 'ShopsTokens', expires: string, shops?: any | null, ud?: any | null } };

export type CoachMemberLessonNoteCreateMutationVariables = Exact<{
  input: CoachMemberLessonNoteCreateInput;
}>;


export type CoachMemberLessonNoteCreateMutation = { __typename?: 'Mutation', coachMemberLessonNoteCreate: string };

export type CoachMemberLessonNoteUpdateMutationVariables = Exact<{
  input: CoachMemberLessonNoteUpdateInput;
}>;


export type CoachMemberLessonNoteUpdateMutation = { __typename?: 'Mutation', coachMemberLessonNoteUpdate: string };

export type CoachMemberLessonNoteDeleteMutationVariables = Exact<{
  input: CoachMemberLessonNoteDeleteInput;
}>;


export type CoachMemberLessonNoteDeleteMutation = { __typename?: 'Mutation', coachMemberLessonNoteDelete: { __typename?: 'CoachMemberLessonNoteDelete', hasFeedback: boolean } };

export type CoachMemberQueryVariables = Exact<{
  args: CoachMemberArgs;
}>;


export type CoachMemberQuery = { __typename?: 'Query', coachMember?: { __typename?: 'CoachMember', accountType?: AccountType | null, birthday?: string | null, companyContactPersonEmail?: string | null, companyContactPersonFirstName?: string | null, companyContactPersonLastName?: string | null, companyName?: string | null, email: string, firstName: string, gender?: Gender | null, handed: Handed, individualId: string, lastName: string, membershipUuid: string, memberUuid: string, mobileNumber?: string | null, nationality?: string | null, relationshipToMainMember?: Relationship | null, serviceNote?: string | null, members: Array<{ __typename?: 'AdminMemberMember', accountType?: AccountType | null, firstName?: string | null, id?: string | null, individualId?: string | null, lastName?: string | null } | null>, membershipType?: { __typename?: 'MembershipTypeBasic', baseCountryCode?: CountryCode | null, internalName?: string | null, isCorporate?: boolean | null, price?: number | null, tagBgColorCode?: string | null, tagText?: string | null, tagTextColorCode?: string | null } | null } | null };

export type CoachMembersQueryVariables = Exact<{
  args: CoachMembersArgs;
}>;


export type CoachMembersQuery = { __typename?: 'Query', coachMembers: { __typename?: 'CoachMemberResponse', edges?: Array<{ __typename?: 'CoachMemberEdge', cursor?: string | null, node?: { __typename?: 'CoachMemberBasic', accountType?: AccountType | null, firstName: string, id: string, individualId: string, lastName: string, memberName?: string | null, membershipId: string, nextBookingDateStr?: string | null, nextBookingStartTime?: any | null, nextBookingVenue?: string | null, noOfBookings?: number | null, membershipType?: { __typename?: 'MembershipTypeBasic', baseCountryCode?: CountryCode | null, internalName?: string | null, isCorporate?: boolean | null, price?: number | null, tagBgColorCode?: string | null, tagText?: string | null, tagTextColorCode?: string | null } | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, firstRecordDateTime?: any | null, hasNextPage?: boolean | null, total?: number | null } | null } };

export type CoachMemberLessonNoteQueryVariables = Exact<{
  args: CoachMemberLessonNoteArgs;
}>;


export type CoachMemberLessonNoteQuery = { __typename?: 'Query', coachMemberLessonNote: { __typename?: 'CoachMemberLessonNote', bookingId?: string | null, comment: string, dateStr: string, id: string, lessonType: string, memberIndividualId: string, memberName: string, memberUuid: string, venue?: string | null, venueOther?: string | null } };

export type CoachMemberLessonNoteMembersQueryVariables = Exact<{
  args: CoachMemberLessonNoteMembersArgs;
}>;


export type CoachMemberLessonNoteMembersQuery = { __typename?: 'Query', coachMemberLessonNoteMembers: { __typename?: 'CoachMemberLessonNoteMemberResponse', edges?: Array<{ __typename?: 'CoachMemberLessonNoteMemberEdge', cursor?: string | null, node?: { __typename?: 'CoachMemberLessonNoteMember', id: string, memberIndividualId: string, memberName: string, noOfLessonNotes?: number | null } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, firstRecordDateTime?: any | null, hasNextPage?: boolean | null, total?: number | null } | null } };

export type CoachMemberLessonNotesQueryVariables = Exact<{
  args: CoachMemberLessonNotesArgs;
}>;


export type CoachMemberLessonNotesQuery = { __typename?: 'Query', coachMemberLessonNotes: { __typename?: 'CoachMemberLessonNoteResponse', memberIndividualId: string, memberName: string, memberUuid: string, edges?: Array<{ __typename?: 'CoachMemberLessonNoteEdge', cursor?: string | null, node?: { __typename?: 'CoachMemberLessonNoteBasic', bookingId?: string | null, dateStr: string, id: string, lessonType: string, venue: string } | null } | null> | null, pageInfo?: { __typename?: 'PageInfo', endCursor?: string | null, firstRecordDateTime?: any | null, hasNextPage?: boolean | null, total?: number | null } | null } };

export type SelfUpdateMutationVariables = Exact<{
  input: SelfUpdateInput;
}>;


export type SelfUpdateMutation = { __typename?: 'Mutation', selfUpdate: { __typename?: 'SelfUpdated', fileUploadCredentials?: { __typename?: 'FileUploadCredentials', fields: any, signedUrl: string } | null, self: { __typename?: 'Self', createdAt?: any | null, email: string, firstName: string, lastName: string, mobileNumber?: string | null, organizationId: string, organizationName: string, profileImageUrl?: string | null, status?: AdminStatus | null, updatedAt?: any | null, userId: string, userType?: UserType | null, lastLogin?: { __typename?: 'LoginInfo', city?: string | null, ipAddress?: string | null, time?: any | null } | null, logins: Array<{ __typename?: 'LoginInfo', city?: string | null, ipAddress?: string | null, time?: any | null } | null> } } };

export type SelfUpdatePasswordMutationVariables = Exact<{
  input: SelfUpdatePasswordInput;
}>;


export type SelfUpdatePasswordMutation = { __typename?: 'Mutation', selfUpdatePassword: SystemOperationStatus };

export type SelfQueryVariables = Exact<{ [key: string]: never; }>;


export type SelfQuery = { __typename?: 'Query', self?: { __typename?: 'Self', createdAt?: any | null, email: string, firstName: string, lastName: string, mobileNumber?: string | null, organizationId: string, organizationName: string, profileImageUrl?: string | null, status?: AdminStatus | null, updatedAt?: any | null, userId: string, userType?: UserType | null, lastLogin?: { __typename?: 'LoginInfo', city?: string | null, ipAddress?: string | null, time?: any | null } | null, logins: Array<{ __typename?: 'LoginInfo', city?: string | null, ipAddress?: string | null, time?: any | null } | null> } | null };

export type CoachAccountSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type CoachAccountSettingsQuery = { __typename?: 'Query', coachAccountSettings: { __typename?: 'CoachAccountSettings', baseCountryCode: CountryCode, baseGmtOffset: number, slug: string, myClubhouses?: Array<{ __typename?: 'CoachClubhouse', clubhouseCode: string, displayName: string, tagBgColorCode: string, tagText: string, tagTextColorCode: string } | null> | null } };


export const ManagementSystemDocument = gql`
    subscription ManagementSystem($args: ManagementSystemArgs!) {
  managementSystem(args: $args) {
    eventId
    operation
    ref
    userId
    userName
    userProfileImageUrl
  }
}
    `;

/**
 * __useManagementSystemSubscription__
 *
 * To run a query within a React component, call `useManagementSystemSubscription` and pass it any options that fit your needs.
 * When your component renders, `useManagementSystemSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useManagementSystemSubscription({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useManagementSystemSubscription(baseOptions: Apollo.SubscriptionHookOptions<ManagementSystemSubscription, ManagementSystemSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ManagementSystemSubscription, ManagementSystemSubscriptionVariables>(ManagementSystemDocument, options);
      }
export type ManagementSystemSubscriptionHookResult = ReturnType<typeof useManagementSystemSubscription>;
export type ManagementSystemSubscriptionResult = Apollo.SubscriptionResult<ManagementSystemSubscription>;
export const CoachAvailabilityDayUpsertDocument = gql`
    mutation CoachAvailabilityDayUpsert($input: CoachAvailabilityDayUpsertInput!) {
  coachAvailabilityDayUpsert(input: $input) {
    bi0800
    bi0900
    bi1000
    bi1100
    bi1200
    bi1300
    bi1400
    bi1500
    bi1600
    bi1700
    bi1800
    bi1900
    bi2000
    bi2100
    bs0800
    bs0900
    bs1000
    bs1100
    bs1200
    bs1300
    bs1400
    bs1500
    bs1600
    bs1700
    bs1800
    bs1900
    bs2000
    bs2100
    date
    l0800
    l0900
    l1000
    l1100
    l1200
    l1300
    l1400
    l1500
    l1600
    l1700
    l1800
    l1900
    l2000
    l2100
  }
}
    `;
export type CoachAvailabilityDayUpsertMutationFn = Apollo.MutationFunction<CoachAvailabilityDayUpsertMutation, CoachAvailabilityDayUpsertMutationVariables>;

/**
 * __useCoachAvailabilityDayUpsertMutation__
 *
 * To run a mutation, you first call `useCoachAvailabilityDayUpsertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachAvailabilityDayUpsertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachAvailabilityDayUpsertMutation, { data, loading, error }] = useCoachAvailabilityDayUpsertMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachAvailabilityDayUpsertMutation(baseOptions?: Apollo.MutationHookOptions<CoachAvailabilityDayUpsertMutation, CoachAvailabilityDayUpsertMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachAvailabilityDayUpsertMutation, CoachAvailabilityDayUpsertMutationVariables>(CoachAvailabilityDayUpsertDocument, options);
      }
export type CoachAvailabilityDayUpsertMutationHookResult = ReturnType<typeof useCoachAvailabilityDayUpsertMutation>;
export type CoachAvailabilityDayUpsertMutationResult = Apollo.MutationResult<CoachAvailabilityDayUpsertMutation>;
export type CoachAvailabilityDayUpsertMutationOptions = Apollo.BaseMutationOptions<CoachAvailabilityDayUpsertMutation, CoachAvailabilityDayUpsertMutationVariables>;
export const CoachAvailabilityDayItemTemplateCreateDocument = gql`
    mutation CoachAvailabilityDayItemTemplateCreate($input: CoachAvailabilityDayItemTemplateCreateInput!) {
  coachAvailabilityDayItemTemplateCreate(input: $input) {
    id
    status
  }
}
    `;
export type CoachAvailabilityDayItemTemplateCreateMutationFn = Apollo.MutationFunction<CoachAvailabilityDayItemTemplateCreateMutation, CoachAvailabilityDayItemTemplateCreateMutationVariables>;

/**
 * __useCoachAvailabilityDayItemTemplateCreateMutation__
 *
 * To run a mutation, you first call `useCoachAvailabilityDayItemTemplateCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachAvailabilityDayItemTemplateCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachAvailabilityDayItemTemplateCreateMutation, { data, loading, error }] = useCoachAvailabilityDayItemTemplateCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachAvailabilityDayItemTemplateCreateMutation(baseOptions?: Apollo.MutationHookOptions<CoachAvailabilityDayItemTemplateCreateMutation, CoachAvailabilityDayItemTemplateCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachAvailabilityDayItemTemplateCreateMutation, CoachAvailabilityDayItemTemplateCreateMutationVariables>(CoachAvailabilityDayItemTemplateCreateDocument, options);
      }
export type CoachAvailabilityDayItemTemplateCreateMutationHookResult = ReturnType<typeof useCoachAvailabilityDayItemTemplateCreateMutation>;
export type CoachAvailabilityDayItemTemplateCreateMutationResult = Apollo.MutationResult<CoachAvailabilityDayItemTemplateCreateMutation>;
export type CoachAvailabilityDayItemTemplateCreateMutationOptions = Apollo.BaseMutationOptions<CoachAvailabilityDayItemTemplateCreateMutation, CoachAvailabilityDayItemTemplateCreateMutationVariables>;
export const CoachAvailabilityDayItemTemplateUpdateDocument = gql`
    mutation CoachAvailabilityDayItemTemplateUpdate($input: CoachAvailabilityDayItemTemplateUpdateInput!) {
  coachAvailabilityDayItemTemplateUpdate(input: $input) {
    id
    l0800
    l0900
    l1000
    l1100
    l1200
    l1300
    l1400
    l1500
    l1600
    l1700
    l1800
    l1900
    l2000
    l2100
    name
  }
}
    `;
export type CoachAvailabilityDayItemTemplateUpdateMutationFn = Apollo.MutationFunction<CoachAvailabilityDayItemTemplateUpdateMutation, CoachAvailabilityDayItemTemplateUpdateMutationVariables>;

/**
 * __useCoachAvailabilityDayItemTemplateUpdateMutation__
 *
 * To run a mutation, you first call `useCoachAvailabilityDayItemTemplateUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachAvailabilityDayItemTemplateUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachAvailabilityDayItemTemplateUpdateMutation, { data, loading, error }] = useCoachAvailabilityDayItemTemplateUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachAvailabilityDayItemTemplateUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CoachAvailabilityDayItemTemplateUpdateMutation, CoachAvailabilityDayItemTemplateUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachAvailabilityDayItemTemplateUpdateMutation, CoachAvailabilityDayItemTemplateUpdateMutationVariables>(CoachAvailabilityDayItemTemplateUpdateDocument, options);
      }
export type CoachAvailabilityDayItemTemplateUpdateMutationHookResult = ReturnType<typeof useCoachAvailabilityDayItemTemplateUpdateMutation>;
export type CoachAvailabilityDayItemTemplateUpdateMutationResult = Apollo.MutationResult<CoachAvailabilityDayItemTemplateUpdateMutation>;
export type CoachAvailabilityDayItemTemplateUpdateMutationOptions = Apollo.BaseMutationOptions<CoachAvailabilityDayItemTemplateUpdateMutation, CoachAvailabilityDayItemTemplateUpdateMutationVariables>;
export const CoachAvailabilityDayItemTemplateDeleteDocument = gql`
    mutation CoachAvailabilityDayItemTemplateDelete($input: CoachAvailabilityDayItemTemplateDeleteInput!) {
  coachAvailabilityDayItemTemplateDelete(input: $input)
}
    `;
export type CoachAvailabilityDayItemTemplateDeleteMutationFn = Apollo.MutationFunction<CoachAvailabilityDayItemTemplateDeleteMutation, CoachAvailabilityDayItemTemplateDeleteMutationVariables>;

/**
 * __useCoachAvailabilityDayItemTemplateDeleteMutation__
 *
 * To run a mutation, you first call `useCoachAvailabilityDayItemTemplateDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachAvailabilityDayItemTemplateDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachAvailabilityDayItemTemplateDeleteMutation, { data, loading, error }] = useCoachAvailabilityDayItemTemplateDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachAvailabilityDayItemTemplateDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CoachAvailabilityDayItemTemplateDeleteMutation, CoachAvailabilityDayItemTemplateDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachAvailabilityDayItemTemplateDeleteMutation, CoachAvailabilityDayItemTemplateDeleteMutationVariables>(CoachAvailabilityDayItemTemplateDeleteDocument, options);
      }
export type CoachAvailabilityDayItemTemplateDeleteMutationHookResult = ReturnType<typeof useCoachAvailabilityDayItemTemplateDeleteMutation>;
export type CoachAvailabilityDayItemTemplateDeleteMutationResult = Apollo.MutationResult<CoachAvailabilityDayItemTemplateDeleteMutation>;
export type CoachAvailabilityDayItemTemplateDeleteMutationOptions = Apollo.BaseMutationOptions<CoachAvailabilityDayItemTemplateDeleteMutation, CoachAvailabilityDayItemTemplateDeleteMutationVariables>;
export const CoachAvailabilityCalendarItemsDocument = gql`
    query CoachAvailabilityCalendarItems($args: CoachAvailabilityCalendarItemsArgs) {
  coachAvailabilityCalendarItems(args: $args) {
    clubhouses
    date
    hasIndoorBooking
    hasOutdoorBooking
    isBlocked
    isIndoorBookingFull
  }
}
    `;

/**
 * __useCoachAvailabilityCalendarItemsQuery__
 *
 * To run a query within a React component, call `useCoachAvailabilityCalendarItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachAvailabilityCalendarItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachAvailabilityCalendarItemsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachAvailabilityCalendarItemsQuery(baseOptions?: Apollo.QueryHookOptions<CoachAvailabilityCalendarItemsQuery, CoachAvailabilityCalendarItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachAvailabilityCalendarItemsQuery, CoachAvailabilityCalendarItemsQueryVariables>(CoachAvailabilityCalendarItemsDocument, options);
      }
export function useCoachAvailabilityCalendarItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachAvailabilityCalendarItemsQuery, CoachAvailabilityCalendarItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachAvailabilityCalendarItemsQuery, CoachAvailabilityCalendarItemsQueryVariables>(CoachAvailabilityCalendarItemsDocument, options);
        }
export type CoachAvailabilityCalendarItemsQueryHookResult = ReturnType<typeof useCoachAvailabilityCalendarItemsQuery>;
export type CoachAvailabilityCalendarItemsLazyQueryHookResult = ReturnType<typeof useCoachAvailabilityCalendarItemsLazyQuery>;
export type CoachAvailabilityCalendarItemsQueryResult = Apollo.QueryResult<CoachAvailabilityCalendarItemsQuery, CoachAvailabilityCalendarItemsQueryVariables>;
export const CoachAvailabilityDayItemDocument = gql`
    query CoachAvailabilityDayItem($args: CoachAvailabilityDayItemArgs!) {
  coachAvailabilityDayItem(args: $args) {
    bi0800
    bi0900
    bi1000
    bi1100
    bi1200
    bi1300
    bi1400
    bi1500
    bi1600
    bi1700
    bi1800
    bi1900
    bi2000
    bi2100
    biOutdoor
    bs0800
    bs0900
    bs1000
    bs1100
    bs1200
    bs1300
    bs1400
    bs1500
    bs1600
    bs1700
    bs1800
    bs1900
    bs2000
    bs2100
    date
    hasOutdoorBooking
    l0800
    l0900
    l1000
    l1100
    l1200
    l1300
    l1400
    l1500
    l1600
    l1700
    l1800
    l1900
    l2000
    l2100
  }
}
    `;

/**
 * __useCoachAvailabilityDayItemQuery__
 *
 * To run a query within a React component, call `useCoachAvailabilityDayItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachAvailabilityDayItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachAvailabilityDayItemQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachAvailabilityDayItemQuery(baseOptions: Apollo.QueryHookOptions<CoachAvailabilityDayItemQuery, CoachAvailabilityDayItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachAvailabilityDayItemQuery, CoachAvailabilityDayItemQueryVariables>(CoachAvailabilityDayItemDocument, options);
      }
export function useCoachAvailabilityDayItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachAvailabilityDayItemQuery, CoachAvailabilityDayItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachAvailabilityDayItemQuery, CoachAvailabilityDayItemQueryVariables>(CoachAvailabilityDayItemDocument, options);
        }
export type CoachAvailabilityDayItemQueryHookResult = ReturnType<typeof useCoachAvailabilityDayItemQuery>;
export type CoachAvailabilityDayItemLazyQueryHookResult = ReturnType<typeof useCoachAvailabilityDayItemLazyQuery>;
export type CoachAvailabilityDayItemQueryResult = Apollo.QueryResult<CoachAvailabilityDayItemQuery, CoachAvailabilityDayItemQueryVariables>;
export const CoachAvailabilityDayItemTemplateDocument = gql`
    query CoachAvailabilityDayItemTemplate($args: CoachAvailabilityDayItemTemplateArgs!) {
  coachAvailabilityDayItemTemplate(args: $args) {
    id
    l0800
    l0900
    l1000
    l1100
    l1200
    l1300
    l1400
    l1500
    l1600
    l1700
    l1800
    l1900
    l2000
    l2100
    name
  }
}
    `;

/**
 * __useCoachAvailabilityDayItemTemplateQuery__
 *
 * To run a query within a React component, call `useCoachAvailabilityDayItemTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachAvailabilityDayItemTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachAvailabilityDayItemTemplateQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachAvailabilityDayItemTemplateQuery(baseOptions: Apollo.QueryHookOptions<CoachAvailabilityDayItemTemplateQuery, CoachAvailabilityDayItemTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachAvailabilityDayItemTemplateQuery, CoachAvailabilityDayItemTemplateQueryVariables>(CoachAvailabilityDayItemTemplateDocument, options);
      }
export function useCoachAvailabilityDayItemTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachAvailabilityDayItemTemplateQuery, CoachAvailabilityDayItemTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachAvailabilityDayItemTemplateQuery, CoachAvailabilityDayItemTemplateQueryVariables>(CoachAvailabilityDayItemTemplateDocument, options);
        }
export type CoachAvailabilityDayItemTemplateQueryHookResult = ReturnType<typeof useCoachAvailabilityDayItemTemplateQuery>;
export type CoachAvailabilityDayItemTemplateLazyQueryHookResult = ReturnType<typeof useCoachAvailabilityDayItemTemplateLazyQuery>;
export type CoachAvailabilityDayItemTemplateQueryResult = Apollo.QueryResult<CoachAvailabilityDayItemTemplateQuery, CoachAvailabilityDayItemTemplateQueryVariables>;
export const CoachAvailabilityDayItemTemplatesDocument = gql`
    query CoachAvailabilityDayItemTemplates {
  coachAvailabilityDayItemTemplates {
    id
    l0800
    l0900
    l1000
    l1100
    l1200
    l1300
    l1400
    l1500
    l1600
    l1700
    l1800
    l1900
    l2000
    l2100
    name
  }
}
    `;

/**
 * __useCoachAvailabilityDayItemTemplatesQuery__
 *
 * To run a query within a React component, call `useCoachAvailabilityDayItemTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachAvailabilityDayItemTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachAvailabilityDayItemTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachAvailabilityDayItemTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<CoachAvailabilityDayItemTemplatesQuery, CoachAvailabilityDayItemTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachAvailabilityDayItemTemplatesQuery, CoachAvailabilityDayItemTemplatesQueryVariables>(CoachAvailabilityDayItemTemplatesDocument, options);
      }
export function useCoachAvailabilityDayItemTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachAvailabilityDayItemTemplatesQuery, CoachAvailabilityDayItemTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachAvailabilityDayItemTemplatesQuery, CoachAvailabilityDayItemTemplatesQueryVariables>(CoachAvailabilityDayItemTemplatesDocument, options);
        }
export type CoachAvailabilityDayItemTemplatesQueryHookResult = ReturnType<typeof useCoachAvailabilityDayItemTemplatesQuery>;
export type CoachAvailabilityDayItemTemplatesLazyQueryHookResult = ReturnType<typeof useCoachAvailabilityDayItemTemplatesLazyQuery>;
export type CoachAvailabilityDayItemTemplatesQueryResult = Apollo.QueryResult<CoachAvailabilityDayItemTemplatesQuery, CoachAvailabilityDayItemTemplatesQueryVariables>;
export const CoachBookingBasicDocument = gql`
    query CoachBookingBasic($args: CoachBookingArgs!) {
  coachBooking(args: $args) {
    dateMonthStr
    dayOfWeekStr
    durationStr
    endTimeStr
    facilityType
    id
    member
    memberIndividualId
    resourceName
    startTimeStr
    venue
    yearStr
  }
}
    `;

/**
 * __useCoachBookingBasicQuery__
 *
 * To run a query within a React component, call `useCoachBookingBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachBookingBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachBookingBasicQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachBookingBasicQuery(baseOptions: Apollo.QueryHookOptions<CoachBookingBasicQuery, CoachBookingBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachBookingBasicQuery, CoachBookingBasicQueryVariables>(CoachBookingBasicDocument, options);
      }
export function useCoachBookingBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachBookingBasicQuery, CoachBookingBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachBookingBasicQuery, CoachBookingBasicQueryVariables>(CoachBookingBasicDocument, options);
        }
export type CoachBookingBasicQueryHookResult = ReturnType<typeof useCoachBookingBasicQuery>;
export type CoachBookingBasicLazyQueryHookResult = ReturnType<typeof useCoachBookingBasicLazyQuery>;
export type CoachBookingBasicQueryResult = Apollo.QueryResult<CoachBookingBasicQuery, CoachBookingBasicQueryVariables>;
export const CoachBookingCommentUpdateDocument = gql`
    mutation CoachBookingCommentUpdate($input: CoachBookingCommentUpdateInput!) {
  coachBookingCommentUpdate(input: $input) {
    commentByCoach
  }
}
    `;
export type CoachBookingCommentUpdateMutationFn = Apollo.MutationFunction<CoachBookingCommentUpdateMutation, CoachBookingCommentUpdateMutationVariables>;

/**
 * __useCoachBookingCommentUpdateMutation__
 *
 * To run a mutation, you first call `useCoachBookingCommentUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachBookingCommentUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachBookingCommentUpdateMutation, { data, loading, error }] = useCoachBookingCommentUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachBookingCommentUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CoachBookingCommentUpdateMutation, CoachBookingCommentUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachBookingCommentUpdateMutation, CoachBookingCommentUpdateMutationVariables>(CoachBookingCommentUpdateDocument, options);
      }
export type CoachBookingCommentUpdateMutationHookResult = ReturnType<typeof useCoachBookingCommentUpdateMutation>;
export type CoachBookingCommentUpdateMutationResult = Apollo.MutationResult<CoachBookingCommentUpdateMutation>;
export type CoachBookingCommentUpdateMutationOptions = Apollo.BaseMutationOptions<CoachBookingCommentUpdateMutation, CoachBookingCommentUpdateMutationVariables>;
export const CoachBookingDocument = gql`
    query CoachBooking($args: CoachBookingArgs!) {
  coachBooking(args: $args) {
    cancellationDateTime
    cancellationReason
    cancelledBy
    cancelledByAdminName
    coachId
    coachName
    commentByCoach
    commentInternal
    createdAt
    createdBy
    createdByAdminName
    date
    duration
    durationStr
    endTime
    fieldLessonDetails
    groupMembers {
      individualId
      memberUuid
      mobileNumber
      name
    }
    id
    isGroupBooking
    member
    memberIndividualId
    memberUuid
    mobileNumber
    noOfPlayers
    resourceName
    startTime
    status
    type
    venue
  }
}
    `;

/**
 * __useCoachBookingQuery__
 *
 * To run a query within a React component, call `useCoachBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachBookingQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachBookingQuery(baseOptions: Apollo.QueryHookOptions<CoachBookingQuery, CoachBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachBookingQuery, CoachBookingQueryVariables>(CoachBookingDocument, options);
      }
export function useCoachBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachBookingQuery, CoachBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachBookingQuery, CoachBookingQueryVariables>(CoachBookingDocument, options);
        }
export type CoachBookingQueryHookResult = ReturnType<typeof useCoachBookingQuery>;
export type CoachBookingLazyQueryHookResult = ReturnType<typeof useCoachBookingLazyQuery>;
export type CoachBookingQueryResult = Apollo.QueryResult<CoachBookingQuery, CoachBookingQueryVariables>;
export const CoachBookingsDocument = gql`
    query CoachBookings($args: CoachBookingsArgs!) {
  coachBookings(args: $args) {
    edges {
      cursor
      node {
        cancellationDateTime
        coachDisplayName
        date
        dateMonthStr
        dateStr
        dayOfWeekStr
        durationStr
        endTime
        endTimeStr
        groupBookingId
        groupBookingIds
        id
        isGroupBooking
        member
        resourceName
        startTime
        startTimeStr
        status
        type
        venue
        yearStr
      }
    }
    firstBookingDate
    lastBookingDate
    pageInfo {
      endCursor
      firstRecordDateTime
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useCoachBookingsQuery__
 *
 * To run a query within a React component, call `useCoachBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachBookingsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachBookingsQuery(baseOptions: Apollo.QueryHookOptions<CoachBookingsQuery, CoachBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachBookingsQuery, CoachBookingsQueryVariables>(CoachBookingsDocument, options);
      }
export function useCoachBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachBookingsQuery, CoachBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachBookingsQuery, CoachBookingsQueryVariables>(CoachBookingsDocument, options);
        }
export type CoachBookingsQueryHookResult = ReturnType<typeof useCoachBookingsQuery>;
export type CoachBookingsLazyQueryHookResult = ReturnType<typeof useCoachBookingsLazyQuery>;
export type CoachBookingsQueryResult = Apollo.QueryResult<CoachBookingsQuery, CoachBookingsQueryVariables>;
export const FormHelperBookingDocument = gql`
    query FormHelperBooking($args: FormHelperBookingArgs!) {
  formHelperBooking(args: $args) {
    clubhouses {
      clubhouseCode
      clubhouseId
      name
    }
  }
}
    `;

/**
 * __useFormHelperBookingQuery__
 *
 * To run a query within a React component, call `useFormHelperBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useFormHelperBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFormHelperBookingQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useFormHelperBookingQuery(baseOptions: Apollo.QueryHookOptions<FormHelperBookingQuery, FormHelperBookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FormHelperBookingQuery, FormHelperBookingQueryVariables>(FormHelperBookingDocument, options);
      }
export function useFormHelperBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FormHelperBookingQuery, FormHelperBookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FormHelperBookingQuery, FormHelperBookingQueryVariables>(FormHelperBookingDocument, options);
        }
export type FormHelperBookingQueryHookResult = ReturnType<typeof useFormHelperBookingQuery>;
export type FormHelperBookingLazyQueryHookResult = ReturnType<typeof useFormHelperBookingLazyQuery>;
export type FormHelperBookingQueryResult = Apollo.QueryResult<FormHelperBookingQuery, FormHelperBookingQueryVariables>;
export const CoachGroupBookingsDocument = gql`
    query CoachGroupBookings($args: CoachGroupBookingsArgs!) {
  coachGroupBookings(args: $args) {
    coachId
    coachName
    commentInternal
    dateMonthStr
    dayOfWeekStr
    durationStr
    endTimeStr
    facilityType
    fieldLessonDetails
    gameCourseType
    id
    member
    memberIndividualId
    mobileNumber
    resourceName
    startTimeStr
    type
    venue
    yearStr
  }
}
    `;

/**
 * __useCoachGroupBookingsQuery__
 *
 * To run a query within a React component, call `useCoachGroupBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachGroupBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachGroupBookingsQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachGroupBookingsQuery(baseOptions: Apollo.QueryHookOptions<CoachGroupBookingsQuery, CoachGroupBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachGroupBookingsQuery, CoachGroupBookingsQueryVariables>(CoachGroupBookingsDocument, options);
      }
export function useCoachGroupBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachGroupBookingsQuery, CoachGroupBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachGroupBookingsQuery, CoachGroupBookingsQueryVariables>(CoachGroupBookingsDocument, options);
        }
export type CoachGroupBookingsQueryHookResult = ReturnType<typeof useCoachGroupBookingsQuery>;
export type CoachGroupBookingsLazyQueryHookResult = ReturnType<typeof useCoachGroupBookingsLazyQuery>;
export type CoachGroupBookingsQueryResult = Apollo.QueryResult<CoachGroupBookingsQuery, CoachGroupBookingsQueryVariables>;
export const CoachBookingCombinedAvailabilityDocument = gql`
    query CoachBookingCombinedAvailability($args: CoachBookingCombinedAvailabilityArgs!) {
  coachBookingCombinedAvailability(args: $args) {
    coachId
    coachSlug
    dateObjs {
      clubhouses
      date
      hasIndoorBooking
      hasOutdoorBooking
      isDayBlocked
    }
    name
  }
}
    `;

/**
 * __useCoachBookingCombinedAvailabilityQuery__
 *
 * To run a query within a React component, call `useCoachBookingCombinedAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachBookingCombinedAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachBookingCombinedAvailabilityQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachBookingCombinedAvailabilityQuery(baseOptions: Apollo.QueryHookOptions<CoachBookingCombinedAvailabilityQuery, CoachBookingCombinedAvailabilityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachBookingCombinedAvailabilityQuery, CoachBookingCombinedAvailabilityQueryVariables>(CoachBookingCombinedAvailabilityDocument, options);
      }
export function useCoachBookingCombinedAvailabilityLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachBookingCombinedAvailabilityQuery, CoachBookingCombinedAvailabilityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachBookingCombinedAvailabilityQuery, CoachBookingCombinedAvailabilityQueryVariables>(CoachBookingCombinedAvailabilityDocument, options);
        }
export type CoachBookingCombinedAvailabilityQueryHookResult = ReturnType<typeof useCoachBookingCombinedAvailabilityQuery>;
export type CoachBookingCombinedAvailabilityLazyQueryHookResult = ReturnType<typeof useCoachBookingCombinedAvailabilityLazyQuery>;
export type CoachBookingCombinedAvailabilityQueryResult = Apollo.QueryResult<CoachBookingCombinedAvailabilityQuery, CoachBookingCombinedAvailabilityQueryVariables>;
export const CoachBookingCombinedAvailabilityDayItemDocument = gql`
    query CoachBookingCombinedAvailabilityDayItem($args: CoachBookingCombinedAvailabilityDayItemArgs!) {
  coachBookingCombinedAvailabilityDayItem(args: $args) {
    bi0800
    bi0900
    bi1000
    bi1100
    bi1200
    bi1300
    bi1400
    bi1500
    bi1600
    bi1700
    bi1800
    bi1900
    bi2000
    bi2100
    biOutdoor
    bs0800
    bs0900
    bs1000
    bs1100
    bs1200
    bs1300
    bs1400
    bs1500
    bs1600
    bs1700
    bs1800
    bs1900
    bs2000
    bs2100
    clubhouses {
      clubhouseCode
      displayName
      tagBgColorCode
      tagText
      tagTextColorCode
    }
    date
    displayName
    hasOutdoorBooking
    l0800
    l0900
    l1000
    l1100
    l1200
    l1300
    l1400
    l1500
    l1600
    l1700
    l1800
    l1900
    l2000
    l2100
  }
}
    `;

/**
 * __useCoachBookingCombinedAvailabilityDayItemQuery__
 *
 * To run a query within a React component, call `useCoachBookingCombinedAvailabilityDayItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachBookingCombinedAvailabilityDayItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachBookingCombinedAvailabilityDayItemQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachBookingCombinedAvailabilityDayItemQuery(baseOptions: Apollo.QueryHookOptions<CoachBookingCombinedAvailabilityDayItemQuery, CoachBookingCombinedAvailabilityDayItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachBookingCombinedAvailabilityDayItemQuery, CoachBookingCombinedAvailabilityDayItemQueryVariables>(CoachBookingCombinedAvailabilityDayItemDocument, options);
      }
export function useCoachBookingCombinedAvailabilityDayItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachBookingCombinedAvailabilityDayItemQuery, CoachBookingCombinedAvailabilityDayItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachBookingCombinedAvailabilityDayItemQuery, CoachBookingCombinedAvailabilityDayItemQueryVariables>(CoachBookingCombinedAvailabilityDayItemDocument, options);
        }
export type CoachBookingCombinedAvailabilityDayItemQueryHookResult = ReturnType<typeof useCoachBookingCombinedAvailabilityDayItemQuery>;
export type CoachBookingCombinedAvailabilityDayItemLazyQueryHookResult = ReturnType<typeof useCoachBookingCombinedAvailabilityDayItemLazyQuery>;
export type CoachBookingCombinedAvailabilityDayItemQueryResult = Apollo.QueryResult<CoachBookingCombinedAvailabilityDayItemQuery, CoachBookingCombinedAvailabilityDayItemQueryVariables>;
export const CoachBookingCoachesBasicDocument = gql`
    query CoachBookingCoachesBasic {
  coachBookingCoaches {
    displayName
    userId
  }
}
    `;

/**
 * __useCoachBookingCoachesBasicQuery__
 *
 * To run a query within a React component, call `useCoachBookingCoachesBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachBookingCoachesBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachBookingCoachesBasicQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachBookingCoachesBasicQuery(baseOptions?: Apollo.QueryHookOptions<CoachBookingCoachesBasicQuery, CoachBookingCoachesBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachBookingCoachesBasicQuery, CoachBookingCoachesBasicQueryVariables>(CoachBookingCoachesBasicDocument, options);
      }
export function useCoachBookingCoachesBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachBookingCoachesBasicQuery, CoachBookingCoachesBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachBookingCoachesBasicQuery, CoachBookingCoachesBasicQueryVariables>(CoachBookingCoachesBasicDocument, options);
        }
export type CoachBookingCoachesBasicQueryHookResult = ReturnType<typeof useCoachBookingCoachesBasicQuery>;
export type CoachBookingCoachesBasicLazyQueryHookResult = ReturnType<typeof useCoachBookingCoachesBasicLazyQuery>;
export type CoachBookingCoachesBasicQueryResult = Apollo.QueryResult<CoachBookingCoachesBasicQuery, CoachBookingCoachesBasicQueryVariables>;
export const AdminLoginDocument = gql`
    mutation AdminLogin($input: AdminLoginInput!) {
  adminLogin(input: $input) {
    accessToken
    authenticatedAdmin {
      clubhouseCode
      clubhouses
      firstName
      lastLogin {
        city
        ipAddress
        time
      }
      lastLoginDate
      lastName
      permissions
      profileImageUrl
      ud
      userId
      userType
      yourIp
    }
  }
}
    `;
export type AdminLoginMutationFn = Apollo.MutationFunction<AdminLoginMutation, AdminLoginMutationVariables>;

/**
 * __useAdminLoginMutation__
 *
 * To run a mutation, you first call `useAdminLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLoginMutation, { data, loading, error }] = useAdminLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminLoginMutation(baseOptions?: Apollo.MutationHookOptions<AdminLoginMutation, AdminLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLoginMutation, AdminLoginMutationVariables>(AdminLoginDocument, options);
      }
export type AdminLoginMutationHookResult = ReturnType<typeof useAdminLoginMutation>;
export type AdminLoginMutationResult = Apollo.MutationResult<AdminLoginMutation>;
export type AdminLoginMutationOptions = Apollo.BaseMutationOptions<AdminLoginMutation, AdminLoginMutationVariables>;
export const AdminLogoutDocument = gql`
    mutation AdminLogout {
  adminLogout
}
    `;
export type AdminLogoutMutationFn = Apollo.MutationFunction<AdminLogoutMutation, AdminLogoutMutationVariables>;

/**
 * __useAdminLogoutMutation__
 *
 * To run a mutation, you first call `useAdminLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminLogoutMutation, { data, loading, error }] = useAdminLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdminLogoutMutation(baseOptions?: Apollo.MutationHookOptions<AdminLogoutMutation, AdminLogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminLogoutMutation, AdminLogoutMutationVariables>(AdminLogoutDocument, options);
      }
export type AdminLogoutMutationHookResult = ReturnType<typeof useAdminLogoutMutation>;
export type AdminLogoutMutationResult = Apollo.MutationResult<AdminLogoutMutation>;
export type AdminLogoutMutationOptions = Apollo.BaseMutationOptions<AdminLogoutMutation, AdminLogoutMutationVariables>;
export const AdminRefreshAccessTokenDocument = gql`
    mutation AdminRefreshAccessToken {
  adminRefreshAccessToken {
    accessToken
    authenticatedAdmin {
      clubhouseCode
      clubhouses
      firstName
      lastLogin {
        city
        ipAddress
        time
      }
      lastLoginDate
      lastName
      permissions
      profileImageUrl
      ud
      userId
      userType
      yourIp
    }
  }
}
    `;
export type AdminRefreshAccessTokenMutationFn = Apollo.MutationFunction<AdminRefreshAccessTokenMutation, AdminRefreshAccessTokenMutationVariables>;

/**
 * __useAdminRefreshAccessTokenMutation__
 *
 * To run a mutation, you first call `useAdminRefreshAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminRefreshAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminRefreshAccessTokenMutation, { data, loading, error }] = useAdminRefreshAccessTokenMutation({
 *   variables: {
 *   },
 * });
 */
export function useAdminRefreshAccessTokenMutation(baseOptions?: Apollo.MutationHookOptions<AdminRefreshAccessTokenMutation, AdminRefreshAccessTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminRefreshAccessTokenMutation, AdminRefreshAccessTokenMutationVariables>(AdminRefreshAccessTokenDocument, options);
      }
export type AdminRefreshAccessTokenMutationHookResult = ReturnType<typeof useAdminRefreshAccessTokenMutation>;
export type AdminRefreshAccessTokenMutationResult = Apollo.MutationResult<AdminRefreshAccessTokenMutation>;
export type AdminRefreshAccessTokenMutationOptions = Apollo.BaseMutationOptions<AdminRefreshAccessTokenMutation, AdminRefreshAccessTokenMutationVariables>;
export const AdminForgotPasswordDocument = gql`
    mutation AdminForgotPassword($input: AdminForgotPasswordInput!) {
  adminForgotPassword(input: $input) {
    email
    resetToken
  }
}
    `;
export type AdminForgotPasswordMutationFn = Apollo.MutationFunction<AdminForgotPasswordMutation, AdminForgotPasswordMutationVariables>;

/**
 * __useAdminForgotPasswordMutation__
 *
 * To run a mutation, you first call `useAdminForgotPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminForgotPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminForgotPasswordMutation, { data, loading, error }] = useAdminForgotPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminForgotPasswordMutation(baseOptions?: Apollo.MutationHookOptions<AdminForgotPasswordMutation, AdminForgotPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminForgotPasswordMutation, AdminForgotPasswordMutationVariables>(AdminForgotPasswordDocument, options);
      }
export type AdminForgotPasswordMutationHookResult = ReturnType<typeof useAdminForgotPasswordMutation>;
export type AdminForgotPasswordMutationResult = Apollo.MutationResult<AdminForgotPasswordMutation>;
export type AdminForgotPasswordMutationOptions = Apollo.BaseMutationOptions<AdminForgotPasswordMutation, AdminForgotPasswordMutationVariables>;
export const AdminForgotPasswordSubmitDocument = gql`
    mutation AdminForgotPasswordSubmit($input: AdminForgotPasswordSubmitInput!) {
  adminForgotPasswordSubmit(input: $input)
}
    `;
export type AdminForgotPasswordSubmitMutationFn = Apollo.MutationFunction<AdminForgotPasswordSubmitMutation, AdminForgotPasswordSubmitMutationVariables>;

/**
 * __useAdminForgotPasswordSubmitMutation__
 *
 * To run a mutation, you first call `useAdminForgotPasswordSubmitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminForgotPasswordSubmitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminForgotPasswordSubmitMutation, { data, loading, error }] = useAdminForgotPasswordSubmitMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminForgotPasswordSubmitMutation(baseOptions?: Apollo.MutationHookOptions<AdminForgotPasswordSubmitMutation, AdminForgotPasswordSubmitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminForgotPasswordSubmitMutation, AdminForgotPasswordSubmitMutationVariables>(AdminForgotPasswordSubmitDocument, options);
      }
export type AdminForgotPasswordSubmitMutationHookResult = ReturnType<typeof useAdminForgotPasswordSubmitMutation>;
export type AdminForgotPasswordSubmitMutationResult = Apollo.MutationResult<AdminForgotPasswordSubmitMutation>;
export type AdminForgotPasswordSubmitMutationOptions = Apollo.BaseMutationOptions<AdminForgotPasswordSubmitMutation, AdminForgotPasswordSubmitMutationVariables>;
export const AdminShopTokensDocument = gql`
    mutation AdminShopTokens($input: AdminShopTokensInput) {
  adminShopTokens(input: $input) {
    expires
    shops
    ud
  }
}
    `;
export type AdminShopTokensMutationFn = Apollo.MutationFunction<AdminShopTokensMutation, AdminShopTokensMutationVariables>;

/**
 * __useAdminShopTokensMutation__
 *
 * To run a mutation, you first call `useAdminShopTokensMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdminShopTokensMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adminShopTokensMutation, { data, loading, error }] = useAdminShopTokensMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAdminShopTokensMutation(baseOptions?: Apollo.MutationHookOptions<AdminShopTokensMutation, AdminShopTokensMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdminShopTokensMutation, AdminShopTokensMutationVariables>(AdminShopTokensDocument, options);
      }
export type AdminShopTokensMutationHookResult = ReturnType<typeof useAdminShopTokensMutation>;
export type AdminShopTokensMutationResult = Apollo.MutationResult<AdminShopTokensMutation>;
export type AdminShopTokensMutationOptions = Apollo.BaseMutationOptions<AdminShopTokensMutation, AdminShopTokensMutationVariables>;
export const CoachMemberLessonNoteCreateDocument = gql`
    mutation CoachMemberLessonNoteCreate($input: CoachMemberLessonNoteCreateInput!) {
  coachMemberLessonNoteCreate(input: $input)
}
    `;
export type CoachMemberLessonNoteCreateMutationFn = Apollo.MutationFunction<CoachMemberLessonNoteCreateMutation, CoachMemberLessonNoteCreateMutationVariables>;

/**
 * __useCoachMemberLessonNoteCreateMutation__
 *
 * To run a mutation, you first call `useCoachMemberLessonNoteCreateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachMemberLessonNoteCreateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachMemberLessonNoteCreateMutation, { data, loading, error }] = useCoachMemberLessonNoteCreateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachMemberLessonNoteCreateMutation(baseOptions?: Apollo.MutationHookOptions<CoachMemberLessonNoteCreateMutation, CoachMemberLessonNoteCreateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachMemberLessonNoteCreateMutation, CoachMemberLessonNoteCreateMutationVariables>(CoachMemberLessonNoteCreateDocument, options);
      }
export type CoachMemberLessonNoteCreateMutationHookResult = ReturnType<typeof useCoachMemberLessonNoteCreateMutation>;
export type CoachMemberLessonNoteCreateMutationResult = Apollo.MutationResult<CoachMemberLessonNoteCreateMutation>;
export type CoachMemberLessonNoteCreateMutationOptions = Apollo.BaseMutationOptions<CoachMemberLessonNoteCreateMutation, CoachMemberLessonNoteCreateMutationVariables>;
export const CoachMemberLessonNoteUpdateDocument = gql`
    mutation CoachMemberLessonNoteUpdate($input: CoachMemberLessonNoteUpdateInput!) {
  coachMemberLessonNoteUpdate(input: $input)
}
    `;
export type CoachMemberLessonNoteUpdateMutationFn = Apollo.MutationFunction<CoachMemberLessonNoteUpdateMutation, CoachMemberLessonNoteUpdateMutationVariables>;

/**
 * __useCoachMemberLessonNoteUpdateMutation__
 *
 * To run a mutation, you first call `useCoachMemberLessonNoteUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachMemberLessonNoteUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachMemberLessonNoteUpdateMutation, { data, loading, error }] = useCoachMemberLessonNoteUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachMemberLessonNoteUpdateMutation(baseOptions?: Apollo.MutationHookOptions<CoachMemberLessonNoteUpdateMutation, CoachMemberLessonNoteUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachMemberLessonNoteUpdateMutation, CoachMemberLessonNoteUpdateMutationVariables>(CoachMemberLessonNoteUpdateDocument, options);
      }
export type CoachMemberLessonNoteUpdateMutationHookResult = ReturnType<typeof useCoachMemberLessonNoteUpdateMutation>;
export type CoachMemberLessonNoteUpdateMutationResult = Apollo.MutationResult<CoachMemberLessonNoteUpdateMutation>;
export type CoachMemberLessonNoteUpdateMutationOptions = Apollo.BaseMutationOptions<CoachMemberLessonNoteUpdateMutation, CoachMemberLessonNoteUpdateMutationVariables>;
export const CoachMemberLessonNoteDeleteDocument = gql`
    mutation CoachMemberLessonNoteDelete($input: CoachMemberLessonNoteDeleteInput!) {
  coachMemberLessonNoteDelete(input: $input) {
    hasFeedback
  }
}
    `;
export type CoachMemberLessonNoteDeleteMutationFn = Apollo.MutationFunction<CoachMemberLessonNoteDeleteMutation, CoachMemberLessonNoteDeleteMutationVariables>;

/**
 * __useCoachMemberLessonNoteDeleteMutation__
 *
 * To run a mutation, you first call `useCoachMemberLessonNoteDeleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCoachMemberLessonNoteDeleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [coachMemberLessonNoteDeleteMutation, { data, loading, error }] = useCoachMemberLessonNoteDeleteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCoachMemberLessonNoteDeleteMutation(baseOptions?: Apollo.MutationHookOptions<CoachMemberLessonNoteDeleteMutation, CoachMemberLessonNoteDeleteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CoachMemberLessonNoteDeleteMutation, CoachMemberLessonNoteDeleteMutationVariables>(CoachMemberLessonNoteDeleteDocument, options);
      }
export type CoachMemberLessonNoteDeleteMutationHookResult = ReturnType<typeof useCoachMemberLessonNoteDeleteMutation>;
export type CoachMemberLessonNoteDeleteMutationResult = Apollo.MutationResult<CoachMemberLessonNoteDeleteMutation>;
export type CoachMemberLessonNoteDeleteMutationOptions = Apollo.BaseMutationOptions<CoachMemberLessonNoteDeleteMutation, CoachMemberLessonNoteDeleteMutationVariables>;
export const CoachMemberDocument = gql`
    query CoachMember($args: CoachMemberArgs!) {
  coachMember(args: $args) {
    accountType
    birthday
    companyContactPersonEmail
    companyContactPersonFirstName
    companyContactPersonLastName
    companyName
    email
    firstName
    gender
    handed
    individualId
    lastName
    members {
      accountType
      firstName
      id
      individualId
      lastName
    }
    membershipType {
      baseCountryCode
      internalName
      isCorporate
      price
      tagBgColorCode
      tagText
      tagTextColorCode
    }
    membershipUuid
    memberUuid
    mobileNumber
    nationality
    relationshipToMainMember
    serviceNote
  }
}
    `;

/**
 * __useCoachMemberQuery__
 *
 * To run a query within a React component, call `useCoachMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachMemberQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachMemberQuery(baseOptions: Apollo.QueryHookOptions<CoachMemberQuery, CoachMemberQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachMemberQuery, CoachMemberQueryVariables>(CoachMemberDocument, options);
      }
export function useCoachMemberLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachMemberQuery, CoachMemberQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachMemberQuery, CoachMemberQueryVariables>(CoachMemberDocument, options);
        }
export type CoachMemberQueryHookResult = ReturnType<typeof useCoachMemberQuery>;
export type CoachMemberLazyQueryHookResult = ReturnType<typeof useCoachMemberLazyQuery>;
export type CoachMemberQueryResult = Apollo.QueryResult<CoachMemberQuery, CoachMemberQueryVariables>;
export const CoachMembersDocument = gql`
    query CoachMembers($args: CoachMembersArgs!) {
  coachMembers(args: $args) {
    edges {
      cursor
      node {
        accountType
        firstName
        id
        individualId
        lastName
        memberName
        membershipId
        membershipType {
          baseCountryCode
          internalName
          isCorporate
          price
          tagBgColorCode
          tagText
          tagTextColorCode
        }
        nextBookingDateStr
        nextBookingStartTime
        nextBookingVenue
        noOfBookings
      }
    }
    pageInfo {
      endCursor
      firstRecordDateTime
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useCoachMembersQuery__
 *
 * To run a query within a React component, call `useCoachMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachMembersQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachMembersQuery(baseOptions: Apollo.QueryHookOptions<CoachMembersQuery, CoachMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachMembersQuery, CoachMembersQueryVariables>(CoachMembersDocument, options);
      }
export function useCoachMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachMembersQuery, CoachMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachMembersQuery, CoachMembersQueryVariables>(CoachMembersDocument, options);
        }
export type CoachMembersQueryHookResult = ReturnType<typeof useCoachMembersQuery>;
export type CoachMembersLazyQueryHookResult = ReturnType<typeof useCoachMembersLazyQuery>;
export type CoachMembersQueryResult = Apollo.QueryResult<CoachMembersQuery, CoachMembersQueryVariables>;
export const CoachMemberLessonNoteDocument = gql`
    query CoachMemberLessonNote($args: CoachMemberLessonNoteArgs!) {
  coachMemberLessonNote(args: $args) {
    bookingId
    comment
    dateStr
    id
    lessonType
    memberIndividualId
    memberName
    memberUuid
    venue
    venueOther
  }
}
    `;

/**
 * __useCoachMemberLessonNoteQuery__
 *
 * To run a query within a React component, call `useCoachMemberLessonNoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachMemberLessonNoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachMemberLessonNoteQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachMemberLessonNoteQuery(baseOptions: Apollo.QueryHookOptions<CoachMemberLessonNoteQuery, CoachMemberLessonNoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachMemberLessonNoteQuery, CoachMemberLessonNoteQueryVariables>(CoachMemberLessonNoteDocument, options);
      }
export function useCoachMemberLessonNoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachMemberLessonNoteQuery, CoachMemberLessonNoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachMemberLessonNoteQuery, CoachMemberLessonNoteQueryVariables>(CoachMemberLessonNoteDocument, options);
        }
export type CoachMemberLessonNoteQueryHookResult = ReturnType<typeof useCoachMemberLessonNoteQuery>;
export type CoachMemberLessonNoteLazyQueryHookResult = ReturnType<typeof useCoachMemberLessonNoteLazyQuery>;
export type CoachMemberLessonNoteQueryResult = Apollo.QueryResult<CoachMemberLessonNoteQuery, CoachMemberLessonNoteQueryVariables>;
export const CoachMemberLessonNoteMembersDocument = gql`
    query CoachMemberLessonNoteMembers($args: CoachMemberLessonNoteMembersArgs!) {
  coachMemberLessonNoteMembers(args: $args) {
    edges {
      cursor
      node {
        id
        memberIndividualId
        memberName
        noOfLessonNotes
      }
    }
    pageInfo {
      endCursor
      firstRecordDateTime
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useCoachMemberLessonNoteMembersQuery__
 *
 * To run a query within a React component, call `useCoachMemberLessonNoteMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachMemberLessonNoteMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachMemberLessonNoteMembersQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachMemberLessonNoteMembersQuery(baseOptions: Apollo.QueryHookOptions<CoachMemberLessonNoteMembersQuery, CoachMemberLessonNoteMembersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachMemberLessonNoteMembersQuery, CoachMemberLessonNoteMembersQueryVariables>(CoachMemberLessonNoteMembersDocument, options);
      }
export function useCoachMemberLessonNoteMembersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachMemberLessonNoteMembersQuery, CoachMemberLessonNoteMembersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachMemberLessonNoteMembersQuery, CoachMemberLessonNoteMembersQueryVariables>(CoachMemberLessonNoteMembersDocument, options);
        }
export type CoachMemberLessonNoteMembersQueryHookResult = ReturnType<typeof useCoachMemberLessonNoteMembersQuery>;
export type CoachMemberLessonNoteMembersLazyQueryHookResult = ReturnType<typeof useCoachMemberLessonNoteMembersLazyQuery>;
export type CoachMemberLessonNoteMembersQueryResult = Apollo.QueryResult<CoachMemberLessonNoteMembersQuery, CoachMemberLessonNoteMembersQueryVariables>;
export const CoachMemberLessonNotesDocument = gql`
    query CoachMemberLessonNotes($args: CoachMemberLessonNotesArgs!) {
  coachMemberLessonNotes(args: $args) {
    edges {
      cursor
      node {
        bookingId
        dateStr
        id
        lessonType
        venue
      }
    }
    memberIndividualId
    memberName
    memberUuid
    pageInfo {
      endCursor
      firstRecordDateTime
      hasNextPage
      total
    }
  }
}
    `;

/**
 * __useCoachMemberLessonNotesQuery__
 *
 * To run a query within a React component, call `useCoachMemberLessonNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachMemberLessonNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachMemberLessonNotesQuery({
 *   variables: {
 *      args: // value for 'args'
 *   },
 * });
 */
export function useCoachMemberLessonNotesQuery(baseOptions: Apollo.QueryHookOptions<CoachMemberLessonNotesQuery, CoachMemberLessonNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachMemberLessonNotesQuery, CoachMemberLessonNotesQueryVariables>(CoachMemberLessonNotesDocument, options);
      }
export function useCoachMemberLessonNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachMemberLessonNotesQuery, CoachMemberLessonNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachMemberLessonNotesQuery, CoachMemberLessonNotesQueryVariables>(CoachMemberLessonNotesDocument, options);
        }
export type CoachMemberLessonNotesQueryHookResult = ReturnType<typeof useCoachMemberLessonNotesQuery>;
export type CoachMemberLessonNotesLazyQueryHookResult = ReturnType<typeof useCoachMemberLessonNotesLazyQuery>;
export type CoachMemberLessonNotesQueryResult = Apollo.QueryResult<CoachMemberLessonNotesQuery, CoachMemberLessonNotesQueryVariables>;
export const SelfUpdateDocument = gql`
    mutation SelfUpdate($input: SelfUpdateInput!) {
  selfUpdate(input: $input) {
    fileUploadCredentials {
      fields
      signedUrl
    }
    self {
      createdAt
      email
      firstName
      lastLogin {
        city
        ipAddress
        time
      }
      lastName
      logins {
        city
        ipAddress
        time
      }
      mobileNumber
      organizationId
      organizationName
      profileImageUrl
      status
      updatedAt
      userId
      userType
    }
  }
}
    `;
export type SelfUpdateMutationFn = Apollo.MutationFunction<SelfUpdateMutation, SelfUpdateMutationVariables>;

/**
 * __useSelfUpdateMutation__
 *
 * To run a mutation, you first call `useSelfUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfUpdateMutation, { data, loading, error }] = useSelfUpdateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfUpdateMutation(baseOptions?: Apollo.MutationHookOptions<SelfUpdateMutation, SelfUpdateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelfUpdateMutation, SelfUpdateMutationVariables>(SelfUpdateDocument, options);
      }
export type SelfUpdateMutationHookResult = ReturnType<typeof useSelfUpdateMutation>;
export type SelfUpdateMutationResult = Apollo.MutationResult<SelfUpdateMutation>;
export type SelfUpdateMutationOptions = Apollo.BaseMutationOptions<SelfUpdateMutation, SelfUpdateMutationVariables>;
export const SelfUpdatePasswordDocument = gql`
    mutation SelfUpdatePassword($input: SelfUpdatePasswordInput!) {
  selfUpdatePassword(input: $input)
}
    `;
export type SelfUpdatePasswordMutationFn = Apollo.MutationFunction<SelfUpdatePasswordMutation, SelfUpdatePasswordMutationVariables>;

/**
 * __useSelfUpdatePasswordMutation__
 *
 * To run a mutation, you first call `useSelfUpdatePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSelfUpdatePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [selfUpdatePasswordMutation, { data, loading, error }] = useSelfUpdatePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSelfUpdatePasswordMutation(baseOptions?: Apollo.MutationHookOptions<SelfUpdatePasswordMutation, SelfUpdatePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SelfUpdatePasswordMutation, SelfUpdatePasswordMutationVariables>(SelfUpdatePasswordDocument, options);
      }
export type SelfUpdatePasswordMutationHookResult = ReturnType<typeof useSelfUpdatePasswordMutation>;
export type SelfUpdatePasswordMutationResult = Apollo.MutationResult<SelfUpdatePasswordMutation>;
export type SelfUpdatePasswordMutationOptions = Apollo.BaseMutationOptions<SelfUpdatePasswordMutation, SelfUpdatePasswordMutationVariables>;
export const SelfDocument = gql`
    query Self {
  self {
    createdAt
    email
    firstName
    lastLogin {
      city
      ipAddress
      time
    }
    lastName
    logins {
      city
      ipAddress
      time
    }
    mobileNumber
    organizationId
    organizationName
    profileImageUrl
    status
    updatedAt
    userId
    userType
  }
}
    `;

/**
 * __useSelfQuery__
 *
 * To run a query within a React component, call `useSelfQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelfQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelfQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelfQuery(baseOptions?: Apollo.QueryHookOptions<SelfQuery, SelfQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
      }
export function useSelfLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelfQuery, SelfQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelfQuery, SelfQueryVariables>(SelfDocument, options);
        }
export type SelfQueryHookResult = ReturnType<typeof useSelfQuery>;
export type SelfLazyQueryHookResult = ReturnType<typeof useSelfLazyQuery>;
export type SelfQueryResult = Apollo.QueryResult<SelfQuery, SelfQueryVariables>;
export const CoachAccountSettingsDocument = gql`
    query CoachAccountSettings {
  coachAccountSettings {
    baseCountryCode
    baseGmtOffset
    myClubhouses {
      clubhouseCode
      displayName
      tagBgColorCode
      tagText
      tagTextColorCode
    }
    slug
  }
}
    `;

/**
 * __useCoachAccountSettingsQuery__
 *
 * To run a query within a React component, call `useCoachAccountSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCoachAccountSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCoachAccountSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useCoachAccountSettingsQuery(baseOptions?: Apollo.QueryHookOptions<CoachAccountSettingsQuery, CoachAccountSettingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CoachAccountSettingsQuery, CoachAccountSettingsQueryVariables>(CoachAccountSettingsDocument, options);
      }
export function useCoachAccountSettingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CoachAccountSettingsQuery, CoachAccountSettingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CoachAccountSettingsQuery, CoachAccountSettingsQueryVariables>(CoachAccountSettingsDocument, options);
        }
export type CoachAccountSettingsQueryHookResult = ReturnType<typeof useCoachAccountSettingsQuery>;
export type CoachAccountSettingsLazyQueryHookResult = ReturnType<typeof useCoachAccountSettingsLazyQuery>;
export type CoachAccountSettingsQueryResult = Apollo.QueryResult<CoachAccountSettingsQuery, CoachAccountSettingsQueryVariables>;