//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/checkbox.ts

import { StyleConfig } from '_types';

export const Checkbox: StyleConfig = {
  //
  baseStyle: {
    container: {},
    control: {},
    icon: {},
    label: {
      fontFamily: `-apple-system,"system-ui","San Francisco","Segoe UI",Roboto,"Helvetica Neue"`,
      fontWeight: 400,
    },
  },

  sizes: {},

  variants: {
    primary: (props) => {
      const { colorMode } = props;

      return {
        container: {},
        control: {
          _checked: {
            _hover: {
              bg:
                colorMode === 'dark'
                  ? 'theme-primary-b-dark'
                  : 'theme-primary-b-dark',
              borderColor:
                colorMode === 'dark'
                  ? 'theme-primary-b-dark'
                  : 'theme-primary-b-dark',
            },
            bg:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
          },
          _disabled: {},
          _focus: {
            boxShadow: 'none',
            outlineColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            outlineOffset: '3px',
            outlineStyle: 'dashed',
            outlineWidth: '1px',
          },
          _hover: {
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
          },
          _indeterminate: {
            _hover: {
              bg:
                colorMode === 'dark'
                  ? 'theme-primary-b-dark'
                  : 'theme-primary-b-dark',
            },
            bg:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
            borderColor:
              colorMode === 'dark'
                ? 'theme-primary-b-dark'
                : 'theme-primary-b-dark',
          },
          _invalid: {
            borderColor:
              colorMode === 'dark'
                ? 'brd-critical-subdued'
                : 'brd-critical-subdued',
          },
          borderColor:
            colorMode === 'dark'
              ? 'brd-neutral-subdued-light'
              : 'brd-neutral-subdued-light',
        },
        icon: {},
        label: {
          color: colorMode === 'dark' ? '#000000' : '#000000',
          fontSize: 'sm',
        },
      };
    },
  },
};
