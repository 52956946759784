//* https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/components/divider.ts

import { StyleConfig } from '_types';

export const Divider: StyleConfig = {
  baseStyle: (props) => {
    const { _colorMode, _colorScheme } = props;

    return {
      borderColor: 'inherit',
      opacity: 1,
    };
  },

  sizes: {},

  variants: {
    dashed: (props) => {
      const { _colorMode, _colorScheme } = props;

      return {
        borderStyle: 'dashed',
      };
    },

    solid: (props) => {
      const { _colorScheme, colorMode } = props;

      return {
        borderColor: colorMode === 'dark' ? 'divider' : 'divider',
        borderStyle: 'solid',
      };
    },
  },
};
