import { isApolloError } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { openAlertModalAction } from 'store/ui';

export interface UseStatusHandlerParams {
  custom?: Record<string, string>;
  error?: any;
  message?: string;
  modal?: boolean;
  setError?: any;
  title?: string;
}

interface ErrorMessageHelperParams {
  errorMessage: string;
  field: string;
}

export const useStatusHandler = () => {
  const dispatch = useDispatch();

  const statusHandler = (params: UseStatusHandlerParams) => {
    const { error, message = '', modal = false, setError, title = '' } = params;

    const errorMessageHelper = (props: ErrorMessageHelperParams) => {
      const { errorMessage, field } = props;

      let formattedMessage = errorMessage;

      if (params.custom && params.custom[field]) {
        formattedMessage = params.custom[field];
      }

      return { formattedMessage };
    };

    //
    //
    //

    let conditionalTitle = title;
    let conditionalMessage = message;
    let modalRequired = false;

    if (message) {
      conditionalMessage = message;
    }

    if (title) {
      conditionalTitle = title;
    }

    if (modal) {
      modalRequired = true;
    }

    if (error) {
      if (isApolloError(error)) {
        // TODO - need a more robust way to determine if string is a serialized value
        if (error.message.substring(0, 1) === '{') {
          const errorObj = JSON.parse(error.message);

          if (errorObj.fields && setError) {
            errorObj.fields.forEach((field: string) => {
              const { formattedMessage } = errorMessageHelper({
                errorMessage: errorObj.message,
                field,
              });

              setError(field, {
                message: formattedMessage,
                type: 'custom',
              });
            });
          }

          if (errorObj.custom && setError) {
            errorObj.custom.forEach((error: any) => {
              const { formattedMessage } = errorMessageHelper({
                errorMessage: error.message,
                field: error.field,
              });

              setError(error.field, {
                message: formattedMessage,
                type: 'custom',
              });
            });
          }

          conditionalMessage = errorObj.message;
          conditionalTitle = 'Error';
        }

        if (error.message.substring(0, 1) !== '{') {
          modalRequired = true;
          conditionalTitle = 'Error';
          conditionalMessage = error.message;
        }
      }
    }

    if (modalRequired) {
      dispatch(
        openAlertModalAction({
          message: conditionalMessage,
          title: conditionalTitle,
        }),
      );
    }
  };

  return {
    statusHandler,
  };
};
