import { Layout } from './Layout';
import { LoginComponent } from './LoginComponent';
import { ServerStatus } from './ServerStatus';
import { UnderMaintenance } from './UnderMaintenance';

function Component() {
  return (
    <Layout>
      <LoginComponent />
      {/* <UnderMaintenance /> */}
      {/* <ServerStatus /> */}
    </Layout>
  );
}

export const LoginPage = Component;
